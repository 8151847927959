export const ModalCalculadoraEnemNovoValidarFormulario = (passoAtual, apresentarErro = true) => {
    let campos = document.querySelectorAll(
        `.calculadora-enem__passo[data-numero-passo="${passoAtual}"] input,
        .calculadora-enem__passo[data-numero-passo="${passoAtual}"] select
    `);
    let validacaoCampos = ModalCalculadoraEnemNovoValidarCampos(campos);

    ModalCalculadoraEnemNovoApresentarErroNosCampos(
        campos,
        validacaoCampos,
        apresentarErro
    );

    if (validacaoCampos.find((validacao) => validacao === false) === undefined) {
        return true;
    }
    
    return false;
}

const ModalCalculadoraEnemNovoValidarCampos = (campos) => {
    return [...campos].map((item) => {
        if (!item.checkValidity()) {
            if (item.nodeName == 'SELECT' && item.value !== '') {
                return true;
            }
            
            return false;
        }

        return true;
    });
}

const ModalCalculadoraEnemNovoApresentarErroNosCampos = (campos, validacaoCampos, apresentarErro) => {
    for (let i = 0; i < validacaoCampos.length; i++) {        
        if (validacaoCampos[i] === false) {
            if (apresentarErro) {
                campos[i].classList.add('calculadora-enem__input--invalido');
            }
        } else {
            campos[i].classList.remove('calculadora-enem__input--invalido');
        }
    }
}