import { ModalCalculadoraEnemNovoAlteracoesNaEtapaEnem } from "./ModalCalculadoraEnemNovoAlteracoesNaEtapaEnem";
import { ModalCalculadoraEnemNovoPreencherInformacoesDeTrocaDeCurso } from "./ModalCalculadoraEnemNovoPreencherInformacoesDeTrocaDeCurso";
import { ModalCalculadoraEnemNovoValidarFormulario } from "./ModalCalculadoraEnemNovoValidarFormulario";
import { ModalCalculadoraEnemVerificarLead } from "./ModalCalculadoraEnemNovoVerificarLead";
import ModalCalculadoraEnemVerificarCampos from "./ModalCalculadoraEnemVerificarCampos";

export const ModalCalculadoraEnemNovoAvancarEtapa = () => {
    let passoAtual = parseInt(document.querySelector('.calculadora-enem__passo--ativo')?.dataset.numeroPasso);
    let divPassoAtivo = <HTMLDivElement>document.querySelector('.calculadora-enem__passo--ativo');
    let proximoPasso = passoAtual + 1;

    if (
        passoAtual == 1
        && document.querySelector('.modal-calculadora-enem .calculadora-enem__botao--avancar')?.textContent == 'Alterar'
    ) {
        proximoPasso = 3;
    }

    if (ModalCalculadoraEnemVerificarCampos(divPassoAtivo) === true) {
        ModalCalculadoraEnemNovoMudarPassos(proximoPasso, 'avancar');
    }

    ModalCalculadoraEnemNovoAtivarBotaoAvancar(proximoPasso);
    ModalCalculadoraEnemNovoAlteracoesNaEtapaEnem(proximoPasso);

    if (passoAtual == 0 && ModalCalculadoraEnemNovoValidarFormulario(divPassoAtivo.dataset.numeroPasso, false)) {
        ModalCalculadoraEnemVerificarLead();
    }

    if (passoAtual == 1) {
        ModalCalculadoraEnemNovoPreencherInformacoesDeTrocaDeCurso();
    }
}

export const ModalCalculadoraEnemNovoMudarPassos = (proximoPasso, direcao = 'avancar') => {
    let divPassoAtivo = <HTMLDivElement>document.querySelector('.calculadora-enem__passo--ativo');
    let divProximoPasso = <HTMLDivElement>document.querySelector(`.calculadora-enem__passo[data-numero-passo="${proximoPasso}"]`);

    if (!divProximoPasso) {
        return;
    }

    if (direcao == 'avancar' && !ModalCalculadoraEnemNovoValidarFormulario(divPassoAtivo.dataset.numeroPasso, true)) {
        return;
    }

    divPassoAtivo.classList.remove('calculadora-enem__passo--ativo');
    divProximoPasso.classList.add('calculadora-enem__passo--ativo');

    ModalCalculadoraEnemNovoMudarPassosTitulo(proximoPasso);
    ModalCalculadoraEnemNovoMudarPassosDots(proximoPasso);
    ModalCalculadoraEnemNovoAtivarBotaoVoltar(proximoPasso);
    ModalCalculadoraEnemNovoAtivarBotaoAvancar(proximoPasso);
}

export const ModalCalculadoraEnemNovoMudarPassosTitulo = (proximoPasso) => {
    let divTituloPassoAtivo = document.querySelector('.calculadora-enem__titulo--etapa--ativo');
    let divTituloProximoPasso = document.querySelector(`.calculadora-enem__titulo--etapa[data-numero-passo="${proximoPasso}"]`);

    divTituloPassoAtivo?.classList.remove('calculadora-enem__titulo--etapa--ativo');
    divTituloProximoPasso?.classList.add('calculadora-enem__titulo--etapa--ativo');
}

const ModalCalculadoraEnemNovoAtivarBotaoVoltar = (proximoPasso) => {
    if (proximoPasso > 0) {
        document.querySelector('.calculadora-enem__botao--voltar')?.classList.remove('calculadora-enem__botao--oculto');
        return;
    }

    document.querySelector('.calculadora-enem__botao--voltar')?.classList.add('calculadora-enem__botao--oculto');
}

const ModalCalculadoraEnemNovoMudarPassosDots = (proximoPasso) => {
    let dotAtivo = <HTMLSpanElement>document.querySelector('.calculadora-enem__dot--ativo');
    let dotProximoPasso = document.querySelector(`.calculadora-enem__dot[data-numero-passo="${proximoPasso}"]`);
    let dots = document.querySelectorAll('.calculadora-enem__dot');

    dotAtivo?.classList.remove('calculadora-enem__dot--ativo');
    dotProximoPasso?.classList.add('calculadora-enem__dot--ativo');

    dots.forEach((dot) => {
        dot.classList.remove('calculadora-enem__dot--concluido');
    });

    for (let i = (proximoPasso - 1); i >= 0; i--) {
        document.querySelector(`.calculadora-enem__dot[data-numero-passo="${i}"]`)?.
            classList.add('calculadora-enem__dot--concluido');
    }
}

export const ModalCalculadoraEnemNovoAtivarBotaoAvancar = (passoAtual) => {
    let botaoAvancar = document.querySelector('.calculadora-enem__botao--avancar');

    if (!ModalCalculadoraEnemNovoValidarFormulario(passoAtual, false)) {
        botaoAvancar?.classList.add('calculadora-enem__botao--inativo');
        return;
    }

    botaoAvancar?.classList.remove('calculadora-enem__botao--inativo');
}
