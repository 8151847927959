import { EnviaLead } from "../../Utils/enviaLead";
import { verificaEmailSalesforce } from "../../Utils/verificaEmailSalesforce";
import { ModalCalculadoraEnemVerificarCamposTipoRegex } from "./ModalCalculadoraEnemVerificarCampos";

export const ModalCalculadoraEnemVerificarLead = () => {
    let identificador = 'Calcule Desconto';
    let emailCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__input--email');
    let nonceLeadByEmail = <HTMLInputElement>document.querySelector('#wp_nonce_lead_by_email')

    verificaEmailSalesforce(emailCampo.value, nonceLeadByEmail.value).then((retorno) => {
        if (retorno.Id) {
            sessionStorage.setItem('leadAtualId', retorno.Id);

            return;
        }

        sessionStorage.setItem('leadAtualId', '');

        let nomeCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__input--nome');
        let telefoneCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__input--celular');

        let dadosLead = Array();

        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[nomeCampo.type].test(nomeCampo.value.trim())) {
            return 'Nome inválido. Por favor, tente novamente.';
        }

        dadosLead.push({
            name: 'nome',
            value: nomeCampo.value
        });

        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[telefoneCampo.type].test(telefoneCampo.value.trim())) {
            return 'Celular inválido. Por favor, tente novamente.'
        }

        dadosLead.push({
            name: 'telefone',
            value: telefoneCampo.value
        });

        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[emailCampo.type].test(emailCampo.value.trim())) {
            return 'Email inválido. Por favor, tente novamente.'
        }

        dadosLead.push({
            name: 'email',
            value: emailCampo.value
        });

        return EnviaLead(
            identificador,
            'cadastro-desconto',
            dadosLead
        );
    });
}