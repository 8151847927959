/**
 * Função que inicializa o carrossel das bolsas de estudo com descontos.
 *
 * @function
 * @returns {void}
 */
export const BolsasDeEstudoDescontos = () => {
  $('.bolsas-estudo-descontos__list').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });
}
