import { CreateAjax } from "./createAjax";

export const GetCookie = (name: string) => {
  const value = `; ${document.cookie}`.match(`; ${name}=([^;]+)`);
  return value ? value[1] : '';
}

export const SetCookie = async (name: string, value: string, expiresInDays: number) => {
  const expires = new Date(Date.now() + expiresInDays * 864e5).toUTCString();

  const dadosEncrypt = new FormData();
  dadosEncrypt.append('action', 'encrypted');
  dadosEncrypt.append('valor', value.toString());
  // @ts-ignore: Unreachable code error
  dadosEncrypt.append('nonce', Crypt.encrypt);
  const retornoEncriptado = await CreateAjax(dadosEncrypt);
  const valorEncriptado = retornoEncriptado.valor.toString();

  document.cookie = `${name}=${valorEncriptado};expires=${expires};path=/`;
}
