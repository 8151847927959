export const StartSliderCondicoesCopyrightFooter = () => {
  let sliders: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.footer__condicoes-copyright .condicoes__item');
  let slidersTextos: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.footer__condicoes-copyright .condicoes__item .condicoes__texto');
  let tempoPrimeiroSlider: number = parseInt(sliders[0]?.dataset?.tempo || '1') * 1000;

  if (
    sliders[0] &&
    sliders[0].clientWidth < slidersTextos[0].clientWidth
  ) {
    let acao = setTimeout(() => {
      slidersTextos[0].style.animationDuration = `${parseInt(sliders[0].dataset.tempo || '1')}s`;
      slidersTextos[0].style.animationPlayState = 'running';
      clearTimeout(acao);
    }, 3000);
  }

  let sliderCondicoesCopyrightOptions: JQuerySlickOptions = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: tempoPrimeiroSlider,
    prevArrow: '<button type="button" class="condicoes__seta condicoes__seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
    nextArrow: '<button type="button" class="condicoes__seta condicoes__seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>',
    responsive: [{
      breakpoint: 992,
      settings: "unslick"
    }]
  };

  $('.footer__condicoes-copyright .condicoes__itens').slick(sliderCondicoesCopyrightOptions);

  $('.footer__condicoes-copyright .condicoes__itens').on('afterChange', () => {
    let sliderAtivo: HTMLElement = <HTMLElement>document.querySelector('.footer__condicoes-copyright .condicoes__item.slick-active');
    let sliderAtivoTexto: HTMLElement = <HTMLElement>document.querySelector('.footer__condicoes-copyright .condicoes__item.slick-active .condicoes__texto');
    let sliderAtivoTempo: number = parseInt(sliderAtivo?.dataset?.tempo || '1') * 1000;

    let sliders = <NodeListOf<HTMLElement>>document.querySelectorAll('.footer__condicoes-copyright .condicoes__item .condicoes__texto');
    sliders.forEach(slide => slide.style.animationPlayState = 'paused');

    if (sliderAtivo.clientWidth < sliderAtivoTexto.clientWidth) {
      let acao = setTimeout(() => {
        sliderAtivoTexto.style.animationDuration = `${parseInt(sliderAtivo.dataset?.tempo || '1')}s`;
        sliderAtivoTexto.style.animationPlayState = 'running';
        clearTimeout(acao);
      }, 3000);
    }

    $('.footer__condicoes-copyright .condicoes__itens').slick("slickSetOption", "autoplaySpeed", sliderAtivoTempo);
  });
}