import { CalculadoraEnemValidar } from "./CalculadoraEnemValidar";

export const CalculadoraEnemValidacaoCampos = () => {
    document.querySelectorAll('.calculadora-enem__input').forEach((elemento) => {
        elemento.addEventListener('keyup', () => {
            CalculadoraEnemValidar(
                document.querySelector('.calculadora-enem__formulario').dataset.passoAtual,
                false
            );
        });
    });

    document.querySelectorAll('.calculadora-enem__select').forEach((elemento) => {
        elemento.addEventListener('change', () => {
            CalculadoraEnemValidar(
                document.querySelector('.calculadora-enem__formulario').dataset.passoAtual,
                false
            );
        });
    });
}