const itens = <HTMLElement>document.querySelector('.sessao-espaco-do-candidato > .mosaico > .editais > .conteudo > .itens');
const boxAction = <HTMLElement>document.querySelector('.sessao-espaco-do-candidato > .mosaico > .editais > .box-action');
const editais = <HTMLElement>document.querySelector('.sessao-espaco-do-candidato > .mosaico > .editais');
const todosOsItens = <HTMLElement>document.querySelector('.sessao-espaco-do-candidato > .mosaico > .editais > .conteudo > .todos-os-itens');
const conteudo = <HTMLElement>document.querySelector('.sessao-espaco-do-candidato > .mosaico > .editais > .conteudo')

const DisplayTodosOsItens = () => {
  const botao = <HTMLElement>document
    .querySelector('.sessao-espaco-do-candidato>.mosaico>.editais>.box-action>.conteudo>.botao');

  const Acao = () => {
    itens.style.display = "none";
    boxAction.style.display = "none";
    editais.style.gridTemplateRows = "auto";
    todosOsItens.style.display = "flex";

    conteudo.style.padding = document.body.clientWidth > 600
      ? "30px 60px" : "30px";
  }

  botao.addEventListener('click', () => Acao());
}
const DisplayItensIniciais = () => {
  const botao = <HTMLElement>document
    .querySelector('.sessao-espaco-do-candidato>.mosaico>.editais>.conteudo>.todos-os-itens>.botoes>.botao');

  const Acao = () => {
    itens.style.display = "grid";
    boxAction.style.display = "block";
    todosOsItens.style.display = "none";

    editais.style.gridTemplateRows = document.body.clientWidth > 600
      ? "auto 73px" : "auto 140px";

    conteudo.style.padding = document.body.clientWidth > 600
      ? "30px 60px 73px" : "30px";
  }

  botao.addEventListener('click', () => Acao());
}

export const EspacoDoCandidato = () => {
  DisplayTodosOsItens();
  DisplayItensIniciais();
}
