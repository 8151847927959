import { GetCookie, SetCookie } from "../../Utils/cookies";
import { CreateAjax } from "../../Utils/createAjax";
import { DadoDeLead, EnviaLead } from "../../Utils/enviaLead";
import { tiposDePaginas, verificaEmailSalesforce } from "../../Utils/verificaEmailSalesforce";

const portalCodigo = {
  'www.brazcubas.edu.br': '001f400001JOQ0xAAH',
  'www.cesuca.edu.br': '001f400001JOPqQAAX',
  'www.ceunsp.edu.br': '001f400001JNs4TAAT',
  'www.cruzeirodosul.edu.br': '001f400001JOPrYAAX',
  'www.cruzeirodosulvirtual.com.br': '001f400001JOPquAAH',
  'www.fapipinhais.edu.br': '001Np00000VvhMlIAJ',
  'www.fsg.edu.br': '001f400001JOPpwAAH',
  'www.modulo.edu.br': '001f400001JOPq1AAH',
  'www.udf.edu.br': '001f400001JOPqBAAX',
  'www.unicid.edu.br': '001f400001JOPg5AAH',
  'www.unifran.edu.br': '001f400001JOPokAAH',
  'www.unipe.edu.br': '001f400001JOPpEAAX',
  'www.up.edu.br': '001f400001QJsvWAAT',
  'homologa2019.www.brazcubas.edu.br': '001f400001JOQ0xAAH',
  'homologa2019.www.cesuca.edu.br': '001f400001JOPqQAAX',
  'homologa2019.www.ceunsp.edu.br': '001f400001JNs4TAAT',
  'homologa2019.www.cruzeirodosul.edu.br': '001f400001JOPrYAAX',
  'homologa2019.www.cruzeirodosulvirtual.com.br': '001f400001JOPquAAH',
  'homologa2019.www.fapipinhais.edu.br': '001Np00000VvhMlIAJ',
  'homologa2019.www.fsg.edu.br': '001f400001JOPpwAAH',
  'homologa2019.www.modulo.edu.br': '001f400001JOPq1AAH',
  'homologa2019.www.udf.edu.br': '001f400001JOPqBAAX',
  'homologa2019.www.unicid.edu.br': '001f400001JOPg5AAH',
  'homologa2019.www.unifran.edu.br': '001f400001JOPokAAH',
  'homologa2019.www.unipe.edu.br': '001f400001JOPpEAAX',
  'homologa2019.www.up.edu.br': '001f400001QJsvWAAT',
  'desenv.www.brazcubas.edu.br': '001f400001JOQ0xAAH',
  'desenv.www.cesuca.edu.br': '001f400001JOPqQAAX',
  'desenv.www.ceunsp.edu.br': '001f400001JNs4TAAT',
  'desenv.www.cruzeirodosul.edu.br': '001f400001JOPrYAAX',
  'desenv.www.cruzeirodosulvirtual.com.br': '001f400001JOPquAAH',
  'desenv.www.fsg.edu.br': '001f400001JOPpwAAH',
  'desenv.www.modulo.edu.br': '001f400001JOPq1AAH',
  'desenv.www.udf.edu.br': '001f400001JOPqBAAX',
  'desenv.www.unicid.edu.br': '001f400001JOPg5AAH',
  'desenv.www.unifran.edu.br': '001f400001JOPokAAH',
  'desenv.www.unipe.edu.br': '001f400001JOPpEAAX',
  'desenv.www.up.edu.br': '001f400001QJsvWAAT',
  'desenv.www.fapipinhais.edu.br': '001Np00000VvhMlIAJ',
  'localhost:8012': '001f400001JOQ0xAAH',
  'localhost:8008': '001f400001JOPqQAAX',
  'localhost:8006': '001f400001JNs4TAAT',
  'localhost:8003': '001f400001JOPrYAAX',
  'localhost:8014': '001f400001JOPquAAH',
  'localhost:8011': '001f400001JOPpwAAH',
  'localhost:8009': '001f400001JOPq1AAH',
  'localhost:8007': '001f400001JOPqBAAX',
  'localhost:8002': '001f400001JOPg5AAH',
  'localhost:8004': '001f400001JOPokAAH',
  'localhost:8005': '001f400001JOPpEAAX',
  'localhost:8013': '001f400001QJsvWAAT',
  'localhost:8015': '001Np00000VvhMlIAJ',
};

const TesteVocacionalEnviarLead = async (dados: DadoDeLead[] = []) => {
  const dadosUrl = new URLSearchParams(location.search);
  const identificador = dados.find(item => item.name === 'identificador')?.value || '';
  const nonceNewLead = dados.find(item => item.name === 'nonce-new-lead')?.value || '';
  const email = dados.find(item => item.name === 'email')?.value || '';
  const celular = dados.find(item => item.name === 'celular')?.value || '';
  const uf = dados.find(item => item.name === 'uf')?.value || '';
  const cidade = dados.find(item => item.name === 'cidade')?.value || '';
  const nomeCompleto = dados.find(item => item.name === 'nome-completo')?.value || '';
  const nome = dados.find(item => item.name === 'nome')?.value || '';
  const sobrenome = dados.find(item => item.name === 'sobrenome')?.value || '';

  const form = new FormData();

  form.append("nonce", nonceNewLead);
  form.append("Email", email);
  form.append("Identificador__c", identificador);
  (celular !== '') && form.append("MobilePhone", celular);
  (uf !== '') && form.append("UF__c", uf);
  (cidade !== '') && form.append("cidade", cidade);
  (nome !== '') && form.append("FirstName", nome);
  (sobrenome !== '') && form.append("LastName", sobrenome);

  if (nomeCompleto !== '') {
    const ncSplit = nomeCompleto.split(' ');
    const ncPrimeiroNome = ncSplit[0];
    const ncUltimoNome = ncSplit[ncSplit.length - 1];

    !form.has("FirstName") && (
      (ncPrimeiroNome !== '') && form.append("FirstName", ncPrimeiroNome)
    );

    !form.has("LastName") && ((ncUltimoNome !== '')
      ? form.append("LastName", ncUltimoNome)
      : form.append("LastName", ncPrimeiroNome));
  }

  const dadosCookies = new URLSearchParams(GetCookie('parsedQS'));

  form.append("Conteudo_da_Campanha__c", dadosUrl.get('utm_content') || dadosCookies.get('utm_content') || '');
  form.append("Meio__c", dadosUrl.get('utm_medium') || dadosCookies.get('utm_medium') || '(direct)');
  form.append("Nome_da_Campanha__c", dadosUrl.get('utm_campaign') || dadosCookies.get('utm_campaign') || '');
  form.append("Origem__c", dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)');
  form.append("Termo_da_Campanha__c", dadosUrl.get('utm_term') || dadosCookies.get('utm_term') || '');
  form.append('LeadSource', dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)');
  form.append('GCLID__c', dadosUrl.get('gclid') || dadosCookies.get('gclid') || '');

  form.append("tipo", tiposDePaginas[window.location.pathname.split('/')[1]] || 'grad');
  form.append("action", "newLead");

  await CreateAjax(form)
    .then(response => JSON.parse(response))
    .then(response => {
      if (response.LeadId) {
        sessionStorage.setItem('leadAtualId', response.LeadId);
        sessionStorage.setItem('leadAtualEmail', email);
      }
    });
}

const TesteVocacionalCriarLead = async (dados: DadoDeLead[] = []) => {
  const nonceleadByEmail = dados.find(item => item.name === 'nonce-lead-by-email')?.value || '';
  const identificador = dados.find(item => item.name === 'identificador')?.value || '';
  const email = dados.find(item => item.name === 'email')?.value || '';
  const celular = dados.find(item => item.name === 'celular')?.value || '';
  const nomeCompleto = dados.find(item => item.name === 'nome-completo')?.value || '';
  const nome = dados.find(item => item.name === 'nome')?.value || '';
  const sobrenome = dados.find(item => item.name === 'sobrenome')?.value || '';

  await verificaEmailSalesforce(email, nonceleadByEmail)
    .then(async (retorno) => {
      if (retorno.Id) {
        sessionStorage.setItem('leadAtualId', retorno.Id);

        const seletorDeEstado = <HTMLSelectElement | null>document.querySelector('.modulo-teste-vocacional select[name=uf]');
        const seletorDeCidade = <HTMLSelectElement | null>document.querySelector('.modulo-teste-vocacional select[name=cidade]');

        if (seletorDeEstado === null && seletorDeCidade === null) {
          const leadAtual = sessionStorage.getItem('leadAtual') || '[]';
          const codigoIes = portalCodigo[window.location.hostname] ||
            portalCodigo[`${window.location.hostname}:${window.location.port}`] || '';

          let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);


          dadosLead = dadosLead.filter(item => (
            item.name !== 'celular' &&
            item.name !== 'nome' &&
            item.name !== 'sobrenome'
          ));

          const leadNome = nomeCompleto == ''
            ? `${nome} ${sobrenome}` : nomeCompleto;

          dadosLead.push(
            { name: 'telefone', value: celular },
            { name: 'nome', value: leadNome },
            { name: 'email', value: email },
            { name: 'ies', value: codigoIes },
          );

          sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
          SetCookie('leadAtual', JSON.stringify(dadosLead), 1);
          EnviaLead(identificador, 'modal', dadosLead);
        }

        return;
      }

      TesteVocacionalEnviarLead(dados);
    });
};

export default TesteVocacionalCriarLead;