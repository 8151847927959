const CxSliderGaleriaStartSlider = () => {
  $('.slider-galeria__slides').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      }
    }],
  });
}

export const CxSliderGaleria = () => {
  CxSliderGaleriaStartSlider();
}