import { DataLayerCreateCliqueBotao } from "../../Utils/dataLayerCreate";

const BotoesDataLayerListaCardSaibaMais = () => {
  let listaCardSaibaMais = <NodeListOf<HTMLElement>>document.querySelectorAll('.formas-ingresso .lista-cards .botao--ir-ate');

  listaCardSaibaMais.forEach(
    (botao: HTMLElement) => botao.addEventListener(
      'click',
      (evento: Event) => {
        let elementoClicado = <HTMLElement>evento.target;

        let secaoMenu = Array
          .from(elementoClicado
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.children[1]
            ?.children[1]
            ?.children
            || [])
          .filter(item => item.classList.contains('ativo'))[0]
          .textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        let subSecao = elementoClicado
          ?.previousElementSibling
          ?.previousElementSibling
          ?.children[1]
          ?.textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        secaoMenu = secaoMenu.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        subSecao = subSecao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        DataLayerCreateCliqueBotao(`${secaoMenu}-${subSecao}:saiba-mais`);
      }
    ));
}

const BotoesDataLayerBeneficiosSaibaMais = () => {
  let beneficiosSaibaMais = <NodeListOf<HTMLElement>>document.querySelectorAll('.formas-ingresso .beneficios .botao--ir-ate');

  beneficiosSaibaMais.forEach(
    (botao: HTMLElement) => botao.addEventListener(
      'click',
      (evento: Event) => {
        let elementoClicado = <HTMLElement>evento.target;

        let secaoMenu = Array
          .from(elementoClicado
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.children[1]
            ?.children[1]
            ?.children
            || [])
          .filter(item => item.classList.contains('ativo'))[0]
          .textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        let subSecao = elementoClicado
          ?.parentElement
          ?.previousElementSibling
          ?.children[1]
          ?.textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        secaoMenu = secaoMenu.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        subSecao = subSecao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        DataLayerCreateCliqueBotao(`${secaoMenu}-${subSecao}:saiba-mais`);
      }
    )
  )
}

const BotoesDataLayerListaCardInscrevaSe = () => {
  let listaCardInscrevaSe = <NodeListOf<HTMLElement>>document.querySelectorAll('.formas-ingresso .lista-cards .botao--primario');

  listaCardInscrevaSe.forEach(
    (botao: HTMLElement) => botao.addEventListener(
      'click',
      (evento: Event) => {
        let elementoClicado = <HTMLElement>evento.target;

        let secaoMenu = Array
          .from(elementoClicado
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.children[1]
            ?.children[1]
            ?.children
            || [])
          .filter(item => item.classList.contains('ativo'))[0]
          .textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        let subSecao = elementoClicado
          ?.parentElement
          ?.parentElement
          ?.children[0]
          ?.children[0]
          ?.children[1]
          ?.textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        let estato = 'inscricao';
        if (elementoClicado?.textContent?.replace(new RegExp('^\\s+|\\s+$', 'g'), '') === 'Avise-me') {
          estato = 'avise-me';
        }

        secaoMenu = secaoMenu.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        subSecao = subSecao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        DataLayerCreateCliqueBotao(`${secaoMenu}-${subSecao}:${estato}`);
      }
    ));
}

const BotoesDataLayerBeneficiosInscrevaSe = () => {
  let beneficiosSaibaMais = <NodeListOf<HTMLElement>>document.querySelectorAll('.formas-ingresso .beneficios .botao--primario');

  beneficiosSaibaMais.forEach(
    (botao: HTMLElement) => botao.addEventListener(
      'click',
      (evento: Event) => {
        let elementoClicado = <HTMLElement>evento.target;
        let secaoMenu = Array
          .from(elementoClicado
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.parentElement
            ?.children[1]
            ?.children[1]
            ?.children
            || [])
          .filter(item => item.classList.contains('ativo'))[0]
          .textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        let subSecao = elementoClicado
          ?.parentElement
          ?.previousElementSibling
          ?.previousElementSibling
          ?.children[1]
          ?.textContent
          ?.replace(new RegExp('^\\s+|\\s+$', 'g'), '')
          || '';

        let estato = 'inscricao';
        if (elementoClicado?.textContent?.replace(new RegExp('^\\s+|\\s+$', 'g'), '') === 'Avise-me') {
          estato = 'avise-me';
        }

        secaoMenu = secaoMenu.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        subSecao = subSecao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
        DataLayerCreateCliqueBotao(`${secaoMenu}-${subSecao}:${estato}`);
      }
    )
  )
}

export const BotoesDataLayer = () => {
  BotoesDataLayerListaCardSaibaMais();
  BotoesDataLayerListaCardInscrevaSe();
  BotoesDataLayerBeneficiosSaibaMais();
  BotoesDataLayerBeneficiosInscrevaSe();
}