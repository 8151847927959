import sliderBoxesAtualizaBullets from "./sliderBoxesAtualizaBullets";

const sliderBoxesAlterarSlider = (objetivo) => {
    let slidePrincipal = document.querySelector('.slider-boxes__slide--principal');
    let slideSegundo = document.querySelector('.slider-boxes__slide--segundo');
    let slideTerceiro = document.querySelector('.slider-boxes__slide--terceiro');

    if (Number.isInteger(parseInt(objetivo))) {
        let slideObjetivo = document.querySelector('.slider-boxes__slide[data-numero-slide="' + objetivo + '"]');

        if (slideObjetivo?.classList[1] == 'slider-boxes__slide--terceiro') {
            let slidePrincipal = document.querySelector('.slider-boxes__slide--principal');
            let slideSegundo = document.querySelector('.slider-boxes__slide--segundo');

            slideObjetivo?.classList.add('slider-boxes__slide--principal');
            slideObjetivo?.classList.remove('slider-boxes__slide--terceiro');

            slidePrincipal?.classList.add('slider-boxes__slide--segundo');
            slidePrincipal?.classList.remove('slider-boxes__slide--principal');

            slideSegundo?.classList.add('slider-boxes__slide--terceiro');
            slideSegundo?.classList.remove('slider-boxes__slide--segundo');

            sliderBoxesAtualizaBullets();
        } else if (slideObjetivo?.classList[1] == 'slider-boxes__slide--segundo') {
            let slidePrincipal = document.querySelector('.slider-boxes__slide--principal');
            let slideTerceiro = document.querySelector('.slider-boxes__slide--terceiro');

            slideObjetivo?.classList.add('slider-boxes__slide--principal');
            slideObjetivo?.classList.remove('slider-boxes__slide--segundo');

            slidePrincipal?.classList.add('slider-boxes__slide--terceiro');
            slidePrincipal?.classList.remove('slider-boxes__slide--principal');

            slideTerceiro?.classList.add('slider-boxes__slide--segundo');
            slideTerceiro?.classList.remove('slider-boxes__slide--terceiro');

            sliderBoxesAtualizaBullets();
        }

        document.querySelectorAll('.slider-boxes__slide--principal .slider-boxes__video')
            .forEach((video: any) => {
                video.currentTime = 0;
                video.play();
            }
            );

        return;
    }

    if (objetivo == 'anterior') {
        slideTerceiro?.classList.add('slider-boxes__slide--principal');
        slideTerceiro?.classList.remove('slider-boxes__slide--terceiro');

        slideSegundo?.classList.add('slider-boxes__slide--terceiro');
        slideSegundo?.classList.remove('slider-boxes__slide--segundo');

        slidePrincipal?.classList.add('slider-boxes__slide--segundo');
        slidePrincipal?.classList.remove('slider-boxes__slide--principal');

        sliderBoxesAtualizaBullets();

        document.querySelectorAll('.slider-boxes__slide--principal .slider-boxes__video')
            .forEach((video: any) => {
                video.currentTime = 0;
                video.play();
            }
            );

        return;
    }

    slidePrincipal?.classList.add('slider-boxes__slide--terceiro');
    slidePrincipal?.classList.remove('slider-boxes__slide--principal');

    slideSegundo?.classList.add('slider-boxes__slide--principal');
    slideSegundo?.classList.remove('slider-boxes__slide--segundo');

    slideTerceiro?.classList.add('slider-boxes__slide--segundo');
    slideTerceiro?.classList.remove('slider-boxes__slide--terceiro');

    sliderBoxesAtualizaBullets();

    document.querySelectorAll('.slider-boxes__slide--principal .slider-boxes__video')
        .forEach((video: any) => {
            video.currentTime = 0;
            video.play();
        }
        );

    return;
}

export default sliderBoxesAlterarSlider;