import { CreateAjax } from "../../Utils/createAjax";
import { CalculadoraEnemBuscarNota } from "./CalculadoraEnemBuscarNota";
import { CalculadoraEnemMudaPassos } from "./CalculadoraEnemMudaPassos";
import { CalculadoraEnemValidar } from "./CalculadoraEnemValidar";

const CalculadoraEnemInput = () => {
    document.querySelectorAll('.calculadora-enem__input, .calculadora-enem__select').forEach((elemento) => {
        elemento.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();

                let formulario = <HTMLElement>document.querySelector('.calculadora-enem__formulario');

                let validacao = CalculadoraEnemValidar(
                    formulario.dataset.passoAtual
                );

                if (validacao) {
                    CalculadoraEnemMudaPassos('avancar');
                }
            }
        });
    });

    document.querySelector('#calculadora-enem__input--nota-enem')?.addEventListener('keyup', () => {
        CalculadoraEnemBuscarNota();
    });

    document.querySelector('#calculadora-enem__select--curso')?.addEventListener('change', () => {
        // Liberar escolha de país no formulário EAD
        if (document.querySelector('#calculadora-enem__select--pais')) {
            document.querySelector('#calculadora-enem__select--pais')?.classList.remove('calculadora-enem__select--desabilitado');
        }

        if (document.querySelector('#calculadora-enem__select--periodo')) {
            let loader = <HTMLDivElement>document.querySelector('#calculadora-enem__select--curso')?.parentElement?.nextElementSibling;
            let periodoGrupo = <HTMLDivElement>document.querySelector('#calculadora-enem__select--periodo')?.parentElement;

            loader.classList.add('calculadora-enem__select-loader--ativo');
            periodoGrupo.classList.add('calculadora-enem__grupo-campo--oculto');

            let codCurso = <HTMLInputElement>document.querySelector('#calculadora-enem__select--curso');
            let nonceGetPeriodoCurso = <HTMLInputElement>document.querySelector('#calculadora-enem__action--get-periodo-curso');

            let dados = new FormData();
            dados.append('action', 'getPeriodoCurso');
            dados.append('codCurso', codCurso.value);
            dados.append('nonce', nonceGetPeriodoCurso.value);

            let periodoElemento = <HTMLElement>document.querySelector('#calculadora-enem__select--periodo');
            periodoElemento.innerHTML = '';
            let opcaoPeriodo = document.createElement('option');
            opcaoPeriodo.setAttribute('disabled', '');
            opcaoPeriodo.setAttribute('selected', '');

            periodoElemento.appendChild(opcaoPeriodo);

            CreateAjax(dados).then((retorno) => {
                retorno.forEach((periodo) => {
                    let opcaoPeriodo = document.createElement('option');
                    opcaoPeriodo.setAttribute('value', periodo);
                    opcaoPeriodo.textContent = periodo;

                    periodoElemento.appendChild(opcaoPeriodo);
                });
            });

            setTimeout(() => {
                loader?.classList.remove('calculadora-enem__select-loader--ativo');
                periodoGrupo.classList.remove('calculadora-enem__grupo-campo--oculto');
            }, 1000);
        }
    });
}

export default CalculadoraEnemInput;