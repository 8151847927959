const ModuloFormulario = () => {
    let telasInput: HTMLInputElement | null = document.querySelector("#dados-passos-modulo-formulario");
    let telas = JSON.parse(decodeURIComponent(telasInput?.value || '[]'));

    telas.map((elemento: Object) => {
        elemento['tituloTela'] = elemento['tituloTela'].replace(/\+/g, ' ');
        return elemento
    });

    document.querySelector('#modulo-formulario__botao--avancar')?.addEventListener('click', (evento: Event) => {
        evento.preventDefault();
        const elemento = <HTMLElement>evento.target;

        let proximaTela = parseInt(elemento.dataset.proximaTela || '');
        let passoAtual = 'modulo-formulario__inputs--' + telas[proximaTela - 1]['idTela'];

        if (!validarCamposPasso(passoAtual)) {
            return;
        }

        // Alterando título do passo
        alterarTituloPasso(telas[proximaTela]['tituloTela']);
        alterarBotaoPasso(telas[proximaTela]['nomeBotaoAvancar']);

        // Definindo passo para botão de voltar
        const botaoVoltar = <HTMLElement>document.querySelector('#modulo-formulario__botao--voltar');
        botaoVoltar.dataset.telaAnterior = (proximaTela - 2 == 0)
            ? '1' : ((proximaTela - 2) < 0)
                ? '0' : (proximaTela - 2).toString();

        botaoVoltar.classList.remove('modulo-formulario__botao--oculto');

        // Alterando bullets e exibição de passo correto no formulário
        alterarItensNavegacao('avancar');
        exibirPassoFormulario(
            telas[proximaTela - 1]['idTela'],
            telas[proximaTela]['idTela']
        );

        if (!validarCamposPasso('modulo-formulario__inputs--' + telas[proximaTela]['idTela'], false)) {
            document.querySelector('#modulo-formulario__botao--avancar')?.classList
                .add('modulo-formulario__botao--inativo');
        }

        // Ocultando info's de form para exibição de mensagem de sucesso
        if (proximaTela == 2) {
            document.querySelector('.modulo-formulario__form')?.classList
                .add('modulo-formulario__form--oculto');

            document.querySelector('.modulo-formulario__secao-formulario')?.classList
                .add('modulo-formulario__secao-formulario--fit-content');

            enviaLeadSalesforce();
        }

        elemento.dataset.proximaTela = ((proximaTela + 1) >= 3) ? '2' : (proximaTela + 1).toString();
    });

    document.querySelector('#modulo-formulario__botao--voltar')?.addEventListener('click', (evento: Event) => {
        evento.preventDefault();
        const elemento = <HTMLElement>evento.target;

        // Definindo passo para botão de avançar
        const telaAnterior = parseInt(elemento.dataset.telaAnterior || '');
        const botaoAvancar = <HTMLElement>document.querySelector('#modulo-formulario__botao--avancar');
        botaoAvancar.dataset.proximaTela = (telaAnterior + 2 == 2)
            ? '1' : ((telaAnterior + 2) >= 3)
                ? '2' : (telaAnterior + 2).toString();

        alterarItensNavegacao('voltar');
        exibirPassoFormulario(telas[telaAnterior + 1]['idTela'], telas[telaAnterior]['idTela']);
        alterarTituloPasso(telas[telaAnterior]['tituloTela']);
        alterarBotaoPasso(telas[telaAnterior]['nomeBotaoAvancar']);
        validarCamposPasso('modulo-formulario__inputs--' + telas[telaAnterior]['idTela'], false);

        if (telaAnterior == 0) {
            document.querySelector('#modulo-formulario__botao--voltar')
                ?.classList.add('modulo-formulario__botao--oculto');
        }

        elemento.dataset.telaAnterior = ((telaAnterior - 1) <= 0)
            ? '0' : (telaAnterior - 1).toString();
    });

    function alterarItensNavegacao(evento: string) {
        let itemNavegacao1 = <HTMLElement>document.querySelector('.modulo-formulario__item-navegacao:first-of-type');
        let itemNavegacao2 = <HTMLElement>document.querySelector('.modulo-formulario__item-navegacao:last-of-type');

        if (evento == 'avancar') {
            // Alterandos os Bullets Points ao avançar a tela
            itemNavegacao1.classList.add('modulo-formulario__item-navegacao--passado');
            itemNavegacao1.classList.remove('modulo-foromulario__item-navegacao--ativo');
            itemNavegacao2.classList.add('modulo-formulario__item-navegacao--ativo');
            return;
        }

        // Alterandos os Bullets Points ao voltar a tela
        itemNavegacao1.classList.remove('modulo-formulario__item-navegacao--passado');
        itemNavegacao1.classList.add('modulo-formulario__item-navegacao--ativo');
        itemNavegacao2.classList.remove('modulo-formulario__item-navegacao--ativo');
    }

    function exibirPassoFormulario(nomePassoAntigo, nomePassoAtual) {
        let passoAntigo = <HTMLElement>document.querySelector('#modulo-formulario__inputs--' + nomePassoAntigo);
        let passoAtual = <HTMLElement>document.querySelector('#modulo-formulario__inputs--' + nomePassoAtual);

        // Ocultando e exibindo as telas
        passoAntigo.classList.remove('modulo-formulario__inputs--ativo');
        passoAtual.classList.add('modulo-formulario__inputs--ativo');
    }

    function validarCamposPasso(idPassoAtual, apresentarErro = true) {
        // Faz a validação dos campos antes de passar para o próximo passo.

        let campos = <NodeListOf<HTMLInputElement>>document.querySelectorAll('#' + idPassoAtual + ' .modulo-formulario__input, #' + idPassoAtual + ' .modulo-formulario__select');
        let botaoAvancar = <HTMLElement>document.querySelector('#modulo-formulario__botao--avancar');

        if (![...campos].map(
            (item: HTMLInputElement) => item.checkValidity()
        ).includes(false)) {
            botaoAvancar.classList.remove('modulo-formulario__botao--inativo');
            return true;
        }

        botaoAvancar.classList.add('modulo-formulario__botao--inativo');

        campos.forEach((campo) => {
            if (campo.checkValidity() === false) {
                if (campo.nodeName == 'SELECT' && campo.value !== '') {
                    campo.style.border = '1px solid var(--cor-branca)';
                }

                if (apresentarErro) {
                    campo.style.border = '1px solid #FF0000';
                }

            } else {
                campo.style.border = '1px solid var(--cor-branca)';
            }
        });

        return false;
    }

    function alterarTituloPasso(titulo) {
        // Altera o título do passo ao trocar para o próximo
        let elementoTitulo = <HTMLElement>document.querySelector(".modulo-formulario__titulo-passo-formulario");
        elementoTitulo.innerHTML = titulo;
    }

    // Código responsável por girar a seta do select, que é personalizada
    document.querySelectorAll('.modulo-formulario__grupo .modulo-formulario__select').forEach((item) => {
        item.addEventListener('focus', () => {
            item.parentElement?.style.setProperty('--arrow-icon-rotate', '180deg');
        });

        item.addEventListener('focusout', () => {
            item.parentElement?.style.setProperty('--arrow-icon-rotate', '0deg');
        });
    });

    if (document.querySelector(".modulo-formulario__geolocalizacao-input#geolocalizacao")) {
        document.querySelector(".modulo-formulario__geolocalizacao-input#geolocalizacao")?.addEventListener('change', (event) => {
            const elemento = <HTMLInputElement>event.target;
            if (elemento.checked) {
                if (!navigator.geolocation) {
                    alert("A geo localização não é suportada pelo seu navegador.")
                    return;
                };

                navigator.geolocation.getCurrentPosition(showPositionPoloValor);
                return;
            }
        });
    }

    function getEstados(valorPais) {
        let nonceValue = document.querySelector('#wp_nonce_get_estados').value;
        let campoEstado = document.querySelector('.modulo-formulario__select[name="estado"]');
        if (campoEstado) campoEstado.innerHTML = '<option disabled selected></option>';

        campoEstado.disabled = false;

        $.ajax({
            url: WpObj.ajaxurl,
            method: "POST",
            data: {
                action: "getEstados",
                pais: valorPais,
                nonce: nonceValue
            }
        }).done((dados) => {
            let estados = JSON.parse(dados);

            estados.forEach((estado) => {
                let option = document.createElement("option");
                option.text = estado.nome;
                option.value = estado.sigla;
                campoEstado.appendChild(option);
            });
        });
    }

    function getCidades(valorEstado) {
        let nonceValue = document.querySelector('#wp_nonce_get_cidades').value;
        let campoCidade = document.querySelector('.modulo-formulario__select[name="cidade"]');
        if (campoCidade) campoCidade.innerHTML = '<option disabled selected></option>';

        campoCidade.disabled = false;

        $.ajax({
            url: WpObj.ajaxurl,
            method: "POST",
            data: {
                action: "getCidades",
                estado: valorEstado,
                nonce: nonceValue
            }
        }).done((dados) => {
            let cidades = JSON.parse(dados);

            cidades.forEach((cidade) => {
                let option = document.createElement("option");
                option.text = cidade.nome;
                option.value = cidade.coordenadas;
                campoCidade.appendChild(option);
            });
        });
    }

    function getPolos(valorCidade) {
        let nonceValue = document.querySelector('#wp_nonce_get_polos_geral').value;
        let campoPolos = document.querySelector('.modulo-formulario__select[name="polo"]');
        if (campoPolos) campoPolos.innerHTML = '<option disabled selected></option>';

        campoPolos.disabled = false;

        $.ajax({
            url: WpObj.ajaxurl,
            method: "POST",
            data: {
                action: "getPolosGeral",
                coordenadas: valorCidade,
                nonce: nonceValue
            }
        }).done((dados) => {
            let polos = JSON.parse(dados);

            polos.forEach((polo) => {
                let option = document.createElement("option");
                option.text = polo.nome;
                option.value = polo.id;
                campoPolos.appendChild(option);
            });
        });
    }

    function showPositionPoloValor(position) {
        let nonceValue = document.querySelector('#wp_nonce_get_polos_data').value;

        $.ajax({
            url: WpObj.ajaxurl,
            method: "POST",
            data: {
                action: "getPolosData",
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                nonce: nonceValue
            }
        }).done((dados) => {
            if (dados.length < 2) return;
            let polos = JSON.parse(dados);

            let campoPolos = document.querySelector('.modulo-formulario__select[name="polo"]');

            document.querySelector('.modulo-formulario__geolocalizacao-resultado span').textContent = `${polos[0].cidade}/${polos[0].uf}`;
            document.querySelector('.modulo-formulario__geolocalizacao-resultado').style.display = 'block';

            document.querySelectorAll('.modulo-formulario__grupo.modulo-formulario__grupo--seta-select').forEach((item) => {
                item.style.opacity = '1';
                item.style.pointerEvents = 'all';
                item.children[0].disabled = false;

                if (item.children[0].name != 'polo') item.style.display = 'none';
            });

            document.querySelector(`.modulo-formulario__select option[value="${polos[0].pais}"]`).selected = true;

            let optionUf = document.createElement('option');
            optionUf.text = polos[0].uf;
            optionUf.value = polos[0].uf;
            optionUf.selected = true;
            document.querySelector('.modulo-formulario__select[name="estado"]').appendChild(optionUf);

            let optionCidade = document.createElement('option');
            optionCidade.text = polos[0].cidade;
            optionCidade.value = polos[0].cidade;
            optionCidade.selected = true;
            document.querySelector('.modulo-formulario__select[name="cidade"]').appendChild(optionCidade);

            polos.forEach((polo) => {
                let option = document.createElement("option");
                option.text = polo.nome;
                option.value = polo.id;
                campoPolos.appendChild(option);
            });
        });
    }

    if (document.querySelector('.modulo-formulario__select[name="pais"]')) {
        document.querySelector('.modulo-formulario__select[name="pais"]').addEventListener('change', (event) => {
            getEstados(event.target.value);

            document.querySelector('.modulo-formulario__select[name="estado"')
                .parentElement.classList.remove('modulo-formulario__grupo--disabled');
        });
    }

    if (document.querySelector('.modulo-formulario__select[name="estado"]')) {
        document.querySelector('.modulo-formulario__select[name="estado"]').addEventListener('change', (event) => {
            getCidades(event.target.value);

            document.querySelector('.modulo-formulario__select[name="cidade"')
                .parentElement.classList.remove('modulo-formulario__grupo--disabled');
        });
    }

    if (document.querySelector('.modulo-formulario__select[name="cidade"]')) {
        document.querySelector('.modulo-formulario__select[name="cidade"]').addEventListener('change', (event) => {
            getPolos(event.target.value);

            document.querySelector('.modulo-formulario__select[name="polo"')
                .parentElement.classList.remove('modulo-formulario__grupo--disabled');
        });
    }

    if (document.querySelector('.modulo-formulario__select[name="campus"]')) {
        document.querySelector('.modulo-formulario__select[name="campus"]').addEventListener('change', (event) => {
            document.querySelector('.modulo-formulario__select[name="periodo"')
                .parentElement.classList.remove('modulo-formulario__grupo--disabled');
        });
    }

    if (document.querySelector('.modulo-formulario__geolocalizacao-input')) {
        document.querySelector('.modulo-formulario__geolocalizacao-input').addEventListener('change', (event) => {
            if (event.target.checked) {
                if (!navigator.geolocation) {
                    alert("A geo localização não é suportada pelo seu navegador.")
                    return;
                };

                document.querySelector('.modulo-formulario__select[name="pais"]').parentElement.style.display = 'none';
                document.querySelector('.modulo-formulario__select[name="estado"]').parentElement.style.display = 'none';
                document.querySelector('.modulo-formulario__select[name="cidade"]').parentElement.style.display = 'none';

                navigator.geolocation.getCurrentPosition(showPositionPoloValor);
                return;
            }

            document.querySelector('.modulo-formulario__select[name="pais"]').parentElement.style.display = 'flex';
            document.querySelector('.modulo-formulario__select[name="estado"]').parentElement.style.display = 'flex';
            document.querySelector('.modulo-formulario__select[name="cidade"]').parentElement.style.display = 'flex';

            document.querySelector('.modulo-formulario__geolocalizacao-resultado').style.display = 'none';
            document.querySelectorAll(`.modulo-formulario__select option[disabled]`).forEach((item) => {
                item.selected = true;
            });
        });
    }


    document.querySelectorAll('.modulo-formulario__input:last-of-type').forEach((elemento) => {
        elemento.addEventListener('keyup', () => {
            validarCamposPasso(elemento.parentElement.parentElement.id, false);
        });
    });

    document.querySelectorAll('.modulo-formulario__select').forEach((elemento) => {
        elemento.addEventListener('change', () => {
            validarCamposPasso(elemento.parentElement.parentElement.id, false);
        });
    });

    document.querySelectorAll('.modulo-formulario__grupo--disabled').forEach((elemento) => {
        elemento.addEventListener('click', () => {
            if (elemento.classList.contains('modulo-formulario__grupo--disabled')) {
                validarCamposPasso(elemento.parentElement.id);
            }
        });
    });

    if (document.querySelector('.modulo-formulario__select[name="polo"]')) {
        document.querySelector('.modulo-formulario__select[name="polo"]').addEventListener('change', () => {
            let campoPolo = document.querySelector('.modulo-formulario__select[name="polo"]');

            if (campoPolo.value !== '' || campoPolo.value !== 'undefined' || campoPolo.value !== null) {
                campoPolo.style.border = "1px solid var(--cor-branca)";
            }
        });
    }

    function enviaLeadSalesforce() {
        let dados = new Map();
        let identificadorSalesforceElemento = <HTMLInputElement>document.querySelector('input#identificador-salesforce');
        let identificadorSalesforce = identificadorSalesforceElemento.value;
        let identificadorSalesforceTratado = decodeURI(identificadorSalesforce);

        const formularioInputs = <NodeListOf<HTMLInputElement>>document.querySelectorAll('.modulo-formulario__input, .modulo-formulario__select, input#assunto-salesforce');
        formularioInputs.forEach((item) => {
            dados.set(item.name, item.value);
        });

        dados = Object.fromEntries(dados);

        $.ajax({
            // @ts-ignore: Unreachable code error
            url: WpObj.ajaxurl,
            method: "POST",
            data: {
                action: "processFields",
                // @ts-ignore: Unreachable code error
                nonce: ProcessFields.nonce,
                tipoformulario: 'modal',
                identificador: identificadorSalesforceTratado,
                // @ts-ignore: Unreachable code error
                idAnalytics: idAnalyticsValue,
                inputs: dados
            }
        });
    }

    function alterarBotaoPasso(tituloBotao) {
        let botao = <HTMLElement>document.querySelector('#modulo-formulario__botao--avancar');
        botao.innerHTML = tituloBotao;
    }
}

export default ModuloFormulario;