import { VisibilidadeElementoScroll } from "../../Utils/visibilidadeElementoScroll";

const bannerComBotoesIniciar = () => {
  window.addEventListener("load", () => {
    $('.banner-buttons__sliders').slick({
      infinite: true,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 8000,
    });

    if (document.querySelectorAll('.banner-buttons__sliders ul.slick-dots li').length === 1) {
      const slickDots: HTMLElement | null = document.querySelector('.banner-buttons__sliders ul.slick-dots');
      if (!slickDots) return;

      slickDots.style.display = 'none';
    };
  });

  window.addEventListener('scroll', () => {
    const btnFloat: HTMLElement | null = document.querySelector('.banner-buttons__btn-floating');
    if (!btnFloat) return;

    if (window.innerWidth <= 992) {
      VisibilidadeElementoScroll(btnFloat, 500, '.banner-buttons__btn-floating--active');
    } else {
      VisibilidadeElementoScroll(btnFloat, 785, '.banner-buttons__btn-floating--active');
    }
  });
}

export default bannerComBotoesIniciar;