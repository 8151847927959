import 'flexslider';

export const SliderComite = () => {
  $('#cComite').flexslider({
    animation: "slide",
    animationLoop: true,
    itemWidth: 228,
    itemMargin: 26,
    minItems: 1,
    maxItems: 3
  });
}