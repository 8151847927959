const ModalProvaTradicionalIniciar = () => {
    document.querySelectorAll('.formasingresso-conteudo-item-col-protradicional #avise-me-tradicional,.formasingresso-conteudo-item-col-protradicional #inscreva-se-tradicional').forEach((elemento) => {
        elemento.addEventListener(
            'click',
            () => {
                document.querySelector(".modal-prova-antecipada")?.classList.toggle("modal-desativado");

                let ativo = !document.querySelector(".modal-prova-antecipada").classList.contains("modal-desativado");
                if (ativo) {
                    document.documentElement.style.overflow = 'hidden';
                }
            }
        );
    });
}

export default ModalProvaTradicionalIniciar;