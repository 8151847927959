
import { ModalCalculadoraEnemAtualizarCursosPorCampus, ModalCalculadoraEnemAtualizarPeriodoPorCurso } from "./ModalCalculadoraEnemAtualizarCursosPorCampus";
import { ModalCalculadoraEnemNovoAlterarCurso } from "./ModalCalculadoraEnemNovoAlterarCurso";
import { ModalCalculadoraEnemNovoApresentarValores } from "./ModalCalculadoraEnemNovoApresentarValores";
import { CalculadoraEnemNovoEnviarLead } from "./ModalCalculadoraEnemNovoEnviarLead";
import { ModalCalculadoraEnemNovoFecharModal } from "./ModalCalculadoraEnemNovoFecharModal";
import { ModalCalculadoraEnemNovoBuscarGeolocalizacao, ModalCalculadoraEnemNovoLiberarLocalizacao, ModalCalculadoraEnemNovoMostrarCidades, ModalCalculadoraEnemNovoMostrarEstados, ModalCalculdaoraEnemNovoMostrarPolos } from "./ModalCalculadoraEnemNovoLiberarLocalizacao";
import { ModalCalculadoraEnemNovoAtivarModalPorUrl } from "./ModalCalculadoraEnemNovoModalPorUrl";
import { ModalCalculadoraEnemNovoMudarPassos, ModalCalculadoraEnemNovoAtivarBotaoAvancar, ModalCalculadoraEnemNovoAvancarEtapa } from "./ModalCalculadoraEnemNovoMudarPassos";

export const ModalCalculadoraEnemNovo = () => {
    ModalCalculadoraEnemNovoAtivarModalPorUrl(window.location.hash);

    document.querySelectorAll('a').forEach((elemento) => {

        let idModal = document.querySelector('.modal-calculadora-enem')?.id;

        if (elemento.href.indexOf(idModal) >= 0) {
            elemento.addEventListener('click', () => {
                let modal = document.querySelector('.modal-calculadora-enem');
                modal?.classList.add('modal-calculadora-enem--ativo');
            });
        }
    });

    window.addEventListener('load', () => {
        ModalCalculadoraEnemNovoAtivarModalPorUrl(window.location.hash);

        document.querySelectorAll('a').forEach((elemento) => {

            let idModal = document.querySelector('.modal-calculadora-enem')?.id;

            if (elemento.href.indexOf(idModal) >= 0) {
                elemento.addEventListener('click', () => {
                    let modal = document.querySelector('.modal-calculadora-enem');
                    modal?.classList.add('modal-calculadora-enem--ativo');
                });
            }
        });
    });

    document.querySelectorAll('#calculadora-enem__checkbox--geolocalizacao').forEach((elemento) => {
        elemento.addEventListener('change', () => {
            ModalCalculadoraEnemNovoBuscarGeolocalizacao(elemento.checked);
        });
    });

    document.querySelectorAll('.calculadora-enem__botao--avancar').forEach((botao) => {
        botao.addEventListener('click', () => {
            ModalCalculadoraEnemNovoAvancarEtapa();
        });
    });

    document.querySelectorAll('.calculadora-enem__passo[data-numero-passo="0"] input').forEach((campo) => {
        campo.addEventListener('keyup', (key) => {
            if (key.code == 'Enter') {
                ModalCalculadoraEnemNovoAvancarEtapa();
            }
        });
    });

    document.querySelectorAll('.calculadora-enem__botao--voltar').forEach((botao) => {
        botao.addEventListener('click', () => {
            let proximoPasso = parseInt(document.querySelector('.calculadora-enem__passo--ativo')?.dataset.numeroPasso) - 1;

            ModalCalculadoraEnemNovoMudarPassos(proximoPasso, 'voltar');
            ModalCalculadoraEnemNovoAtivarBotaoAvancar(proximoPasso);
        });
    });

    document.querySelectorAll('.calculadora-enem__passos input')?.forEach((campo) => {
        campo.addEventListener('keyup', () => {
            let passoAtualCampo = campo.parentElement?.parentElement?.dataset.numeroPasso;
            ModalCalculadoraEnemNovoAtivarBotaoAvancar(passoAtualCampo);
        });
    });

    document.querySelectorAll('.calculadora-enem__passos select')?.forEach((select) => {
        select.addEventListener('change', () => {
            let passoAtualCampo = select.parentElement?.parentElement?.dataset.numeroPasso;
            ModalCalculadoraEnemNovoAtivarBotaoAvancar(passoAtualCampo);
        });
    });

    document.querySelector('#calculadora-enem__input--nota-enem')?.addEventListener('keyup', (evento) => {
        let notaEnem = <HTMLInputElement>document.querySelector('#calculadora-enem__input--nota-enem');
        if (notaEnem.value.length >= 3 && notaEnem.value.length <= 4) {
            ModalCalculadoraEnemNovoApresentarValores(notaEnem.value);
        }
    });

    document.querySelectorAll('.modal-calculadora-enem__fechar').forEach((botao) => {
        botao.addEventListener('click', () => {
            ModalCalculadoraEnemNovoFecharModal();
        });
    });

    document.querySelectorAll('.calculadora-enem__trocar-curso').forEach((elemento) => {
        elemento.addEventListener('click', () => {
            ModalCalculadoraEnemNovoAlterarCurso();
        });
    });

    document.querySelectorAll('#calculadora-enem__select--campus').forEach((elemento) => {
        elemento.addEventListener('change', () => {
            ModalCalculadoraEnemAtualizarCursosPorCampus(elemento);
        });
    });

    document.querySelectorAll('#calculadora-enem__select--curso').forEach((elemento) => {
        if (!document.querySelector('#calculadora-enem__action--get-polos')) {
            elemento.addEventListener('change', () => {
                ModalCalculadoraEnemAtualizarPeriodoPorCurso(elemento);
            });
        }
    });

    // Se for EAD. Verificando se campos de nonce existem para formulário específico para EAD.
    if (document.querySelector('#calculadora-enem__action--get-polos')) {
        document.querySelector('#calculadora-enem__select--curso')?.addEventListener('change', () => {
            ModalCalculadoraEnemNovoLiberarLocalizacao();
        });

        document.querySelectorAll('#calculadora-enem__select--pais').forEach((elemento) => {
            elemento.addEventListener('change', () => {
                ModalCalculadoraEnemNovoMostrarEstados();
            });
        });

        document.querySelectorAll('#calculadora-enem__select--estado').forEach((elemento) => {
            elemento.addEventListener('change', () => {
                ModalCalculadoraEnemNovoMostrarCidades();
            });
        });

        document.querySelectorAll('#calculadora-enem__select--cidade').forEach((elemento) => {
            elemento.addEventListener('change', () => {
                ModalCalculdaoraEnemNovoMostrarPolos();
            });
        });
    }
}