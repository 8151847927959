/**
 * Exibe ou oculta o botão flutuante com base na posicao do scroll.
 * @param {HTMLElement} btnFloat - Elemento que cont m o bot o flutuante.
 * @param {number} heightBreakpoint - Valor de scroll que define o ponto de exibicao do bot o.
 */
export const VisibilidadeElementoScroll = (
  btnFloat: HTMLElement, heightBreakpoint: number, classeAtiva: string
) => {
  let valorScroll = window.innerWidth <= 992 ? (document.body.scrollTop > 0 ? document.body.scrollTop : window.scrollY) : window.scrollY;

  if (valorScroll > heightBreakpoint) {
    if (!btnFloat.classList.contains(classeAtiva)) {
      btnFloat.classList.add(classeAtiva);
    }
  } else {
    if (btnFloat.classList.contains(classeAtiva)) {
      btnFloat.classList.remove(classeAtiva);
    }
  }
}