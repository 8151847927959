import 'flexslider';

export const CxIndicadoresIes = () => {
  if ($("#instituicoes").length) {
    $('#instituicoes').flexslider({
      animation: "slide",
      animationLoop: true,
      itemWidth: 116,
      itemMargin: 20,
      minItems: 1,
      maxItems: 3
    });
  }
}