import { DataLayerCreateCliqueBotao } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerTelaUm = () => {
  const botoesInscrevaSe = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-um-acoes .sidebar-pdp-tela-um-acoes-botao[data-status=""]');

  const botoesAviseMe = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-um-acoes .sidebar-pdp-tela-um-acoes-botao[data-status="aviseMe"]');

  const botoesVerInvestimentos = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-um-acoes .sidebar-pdp-tela-um-acoes-botao:not([data-status])');

  botoesInscrevaSe
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao('card:inscricao')));

  botoesAviseMe
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao('card:avise-me')));

  botoesVerInvestimentos
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao('card:ver-investimento')));
}