import { CreateAjax } from "../../Utils/createAjax";

interface Curso {
  curso: string,
  cursourl: string
};

const seletorTipoCurso: HTMLSelectElement = <HTMLSelectElement>document.querySelector('.pesquisa-curso__select');
const pesquisaCursoListaArray: HTMLInputElement = <HTMLInputElement>document.querySelector('.pesquisa-curso-lista-array');
const boxResultadoPesquisaCursoLista: HTMLElement = <HTMLElement>document.querySelector('.pesquisa-curso__campo--resultado .conteudo');
const boxResultadoPesquisaCurso: HTMLElement = <HTMLElement>document.querySelector('.pesquisa-curso__campo--resultado');
const inputBusca: HTMLInputElement = <HTMLInputElement>document.querySelector('.pesquisa-curso .pesquisa-curso__input');
const linkBusca: HTMLAnchorElement = <HTMLAnchorElement>document.querySelector('.pesquisa-curso__icone-input');
const pesquisaCursoCampoInput: HTMLAnchorElement = <HTMLAnchorElement>document.querySelector('.pesquisa-curso__campo--input');

const tipoCursoVitrine = {
  "graduacao": "/graduacao/",
  "mbaEspecializacao": "/pos-graduacao/",
  "mestradoDoutorado": "/mestrado-e-doutorado/",
  "livres": "/cursos-livres/",
  "idiomas": "/cursos-de-idiomas/",
  "tecnicos": "/curso-tecnico/",
  "preparatorios": "/cursos-preparatorios/",
  "segundaGraduacao": "/segunda-graduacao/",
  "formacaoPedagogica": "/formacao-pedagogica/"
};

const RedefinirListaCurso = (
  cursos: Curso[]
): void => {
  boxResultadoPesquisaCursoLista.innerHTML = '';
  if (cursos.length == 0) {
    boxResultadoPesquisaCursoLista.innerHTML = `<p class="conteudo__aviso">${boxResultadoPesquisaCursoLista.dataset.aviso}</p>`;
    return;
  }

  let listaCursos = '';
  cursos.forEach(curso => {
    listaCursos += `<li data-value="${curso.curso}" data-url="${curso.cursourl}"><p>${curso.curso}</p></li>`;
  });

  boxResultadoPesquisaCursoLista.innerHTML = listaCursos;
  AdicionarAcaoClickObjetosListaCursos();
}

const AdicionarAcaoClickObjetosListaCursos = (): void => {
  let resultadoPesquisaCursoItens: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.pesquisa-curso__campo--resultado .conteudo li');

  resultadoPesquisaCursoItens.forEach((curso: HTMLElement) => curso
    .addEventListener('click', () => {
      inputBusca.focus();
      inputBusca.value = curso.dataset.value || inputBusca.value;
      RedefinirListaCurso(FiltraListaCursos());
      IrDefinirUrlCurso();
      IrPagina();

      setTimeout(() => {
        inputBusca.classList.add('ativo');
        boxResultadoPesquisaCurso.classList.add('ativo');
        pesquisaCursoCampoInput.classList.add('ativo');
      }, 500);
    })
  );
}

const FiltraListaCursos = (): Curso[] => {
  return JSON.parse(pesquisaCursoListaArray.value)
    .filter(curso => {
      if (inputBusca.value == '') { return true; }
      return curso.curso.toLowerCase().includes(inputBusca.value.toLowerCase());
    });
}

const IrDefinirUrlCurso = (): void => {
  let busca: string = inputBusca.value;
  let cursoSelecionado: HTMLElement | null = <HTMLElement | null>document.querySelector(`.pesquisa-curso__campo--resultado .conteudo li[data-value="${busca}"]`);
  let cursoSelecionadoUrl = cursoSelecionado?.dataset.url || '';
  linkBusca.href = cursoSelecionadoUrl;
}

const IrPagina = (): void => {
  let busca: string = inputBusca.value;

  if (
    linkBusca.href !== '' &&
    linkBusca.href !== window.location.href
  ) { (window as Window).location = linkBusca.href; }

  if (linkBusca.href == '' || linkBusca.href == window.location.href) {
    if (seletorTipoCurso.value == 'todos') {
      seletorTipoCurso.classList.add('erro');
    }

    if (seletorTipoCurso.value != 'todos') {
      seletorTipoCurso.classList.remove('erro');

      if (
        (window as Window).location.hostname.includes('cruzeirodosulvirtual.com.br') &&
        seletorTipoCurso.value == 'mbaEspecializacao'
      ) {
        (window as Window).location = `https://cursos.cruzeirodosulvirtual.com.br/${busca}?_q=${busca}&map=ft`;
      } else {
        (window as Window).location = `${tipoCursoVitrine[seletorTipoCurso.value]}?nome=${busca}`;
      }
    }
  }
}

const IrPaginaEnter = (evento: KeyboardEvent): void => {
  if (evento.key === "Enter") { IrPagina(); }
}

export const BuscarCurso = () => {
  AdicionarAcaoClickObjetosListaCursos();

  inputBusca.addEventListener('keyup', (evento: KeyboardEvent) => {
    RedefinirListaCurso(FiltraListaCursos());
    IrDefinirUrlCurso();
    IrPaginaEnter(evento);
  });

  inputBusca.addEventListener('keypress', (evento: KeyboardEvent) => {
    RedefinirListaCurso(FiltraListaCursos());
    IrDefinirUrlCurso();
    IrPaginaEnter(evento);
  });

  inputBusca.addEventListener('focus', () => {
    boxResultadoPesquisaCurso.classList.add('ativo');
    inputBusca?.classList.add('ativo');
    pesquisaCursoCampoInput.classList.add('ativo');
  });

  inputBusca.addEventListener('focusout', () => {
    setTimeout(() => {
      boxResultadoPesquisaCurso.classList.remove('ativo');
      inputBusca?.classList.remove('ativo');
      pesquisaCursoCampoInput.classList.remove('ativo');
    }, 500);
  });

  linkBusca.addEventListener('click', (evento) => {
    evento.preventDefault();
    IrPagina();
  });
};

export const MudarTipoCurso = async () => {
  await MudarTipoCursoAcao();

  seletorTipoCurso.addEventListener('change', async () => {
    await MudarTipoCursoAcao();
  });
};

const MudarTipoCursoAcao = async () => {
  const pequisaCursosNonceInput: HTMLInputElement = <HTMLInputElement>document.querySelector('.pesquisa-curso-nonce');
  const pequisaCursosNonceValor: string = pequisaCursosNonceInput.value;

  const dadosAjax = new FormData();
  let tipoCurso = seletorTipoCurso.value;

  if (tipoCurso !== 'todos') {
    seletorTipoCurso.classList.remove('erro');
  }

  dadosAjax.append('action', 'getCursosPesquisa');
  dadosAjax.append('tipoCurso', tipoCurso);
  dadosAjax.append('nonce', pequisaCursosNonceValor);
  pesquisaCursoListaArray.value = '[]';

  await CreateAjax(dadosAjax)
    .then((retorno: Curso[]) => {
      pesquisaCursoListaArray.value = JSON.stringify(retorno);
      RedefinirListaCurso(retorno);
    });
};