import { CreateAjax } from "../../Utils/createAjax";
import { IncluirOptionSelect } from "./IncluirOptionSelect";

export const CalculadoraEnemGeolocalizacao = () => {
  document.querySelector('#calculadora-enem__select--pais')?.addEventListener('change', () => {
    let loader = document.querySelector('#calculadora-enem__select--pais')?.parentElement.nextElementSibling;
    let estadoGrupo = document.querySelector('#calculadora-enem__select--estado')?.parentElement;

    let nonce = <HTMLInputElement>document.querySelector('#calculadora-enem__action--get-estados');
    let nonceValor = nonce.value.toString();

    let pais = <HTMLInputElement>document.querySelector('#calculadora-enem__select--pais');
    let paisValor = pais.value.toString();

    loader?.classList.add('calculadora-enem__select-loader--ativo');
    estadoGrupo?.classList.add('calculadora-enem__grupo-campo--oculto');

    let dados = new FormData();
    dados.append('action', 'getEstados');
    dados.append('pais', paisValor);
    dados.append('nonce', nonceValor);

    CreateAjax(dados)
      .then((retorno) => {
        IncluirOptionSelect(
          document.querySelector('#calculadora-enem__select--estado'),
          retorno,
          'sigla',
          'nome',
          'calculadora-enem__select--desabilitado'
        );
      });

    setTimeout(() => {
      loader?.classList.remove('calculadora-enem__select-loader--ativo');
      estadoGrupo?.classList.remove('calculadora-enem__grupo-campo--oculto');
    }, 1000);
  });

  document.querySelector('#calculadora-enem__select--estado')?.addEventListener('change', () => {
    let loader = document.querySelector('#calculadora-enem__select--estado')?.parentElement.nextElementSibling;
    let cidadeGrupo = document.querySelector('#calculadora-enem__select--cidade')?.parentElement;
    let nonce = document.querySelector('#calculadora-enem__action--get-cidades').value;

    loader?.classList.add('calculadora-enem__select-loader--ativo');
    cidadeGrupo?.classList.add('calculadora-enem__grupo-campo--oculto');

    let dados = new FormData();
    dados.append('action', 'getCidades');
    dados.append('pais', document.querySelector('#calculadora-enem__select--pais').value);
    dados.append('estado', document.querySelector('#calculadora-enem__select--estado').value);
    dados.append('nonce', nonce);

    CreateAjax(dados)
      .then((retorno) => {
        IncluirOptionSelect(
          document.querySelector('#calculadora-enem__select--cidade'),
          retorno,
          'coordenadas',
          'nome',
          'calculadora-enem__select--desabilitado'
        );

        setTimeout(() => {
          loader?.classList.remove('calculadora-enem__select-loader--ativo');
          cidadeGrupo?.classList.remove('calculadora-enem__grupo-campo--oculto');
        }, 1000);
      });
  });

  document.querySelector('#calculadora-enem__select--cidade')?.addEventListener('change', () => {
    let loader = document.querySelector('#calculadora-enem__select--cidade')?.parentElement.nextElementSibling;
    let poloGrupo = document.querySelector('#calculadora-enem__select--polo')?.parentElement;
    let nonce = document.querySelector('#calculadora-enem__action--get-polos').value;

    loader?.classList.add('calculadora-enem__select-loader--ativo');
    poloGrupo?.classList.add('calculadora-enem__grupo-campo--oculto');

    let dados = new FormData();
    dados.append('action', 'getPolosGeral');
    dados.append('coordenadas', document.querySelector('#calculadora-enem__select--cidade').value);
    dados.append('nonce', nonce);

    CreateAjax(dados)
      .then((retorno) => {
        IncluirOptionSelect(
          document.querySelector('#calculadora-enem__select--polo'),
          retorno,
          'id',
          'nome',
          'calculadora-enem__select--desabilitado'
        );

        setTimeout(() => {
          loader?.classList.remove('calculadora-enem__select-loader--ativo');
          poloGrupo?.classList.remove('calculadora-enem__grupo-campo--oculto');
        }, 1000);
      });
  });

  document.querySelector('#calculadora-enem__checkbox--geolocalizacao')?.addEventListener('change', (event) => {
    if (!navigator.geolocation) {
      alert("A geo localização não é suportada pelo seu navegador.")
      return;
    };

    navigator.geolocation.getCurrentPosition(CalculadoraEnemLocalizacao);
    return;
  });
}

const CalculadoraEnemLocalizacao = (posicao) => {
  let nonce = <HTMLInputElement>document.querySelector('#wp_nonce_get_polos_data');
  let codigoCurso = <HTMLInputElement>document.querySelector('#codCurs');
  let tipoCurso = <HTMLInputElement>document.querySelector('#tipo_curso');
  let loaderPolo = document.querySelector('#calculadora-enem__select--cidade')?.parentElement?.nextElementSibling;
  let checkboxGeolocalizacao = <HTMLInputElement>document.querySelector('#calculadora-enem__checkbox--geolocalizacao');
  let campoPolos = document.querySelector('#calculadora-enem__select--polo');

  if (checkboxGeolocalizacao.checked) {
    document.querySelector('#calculadora-enem__select--pais')?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');
    document.querySelector('#calculadora-enem__select--estado')?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');
    document.querySelector('#calculadora-enem__select--cidade')?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');
    document.querySelector('#calculadora-enem__select--polo')?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');

    loaderPolo?.classList.add('calculadora-enem__select-loader--ativo');
  } else {
    let pais = document.querySelector('#calculadora-enem__select--pais');
    let estado = document.querySelector('#calculadora-enem__select--estado');
    let cidade = document.querySelector('#calculadora-enem__select--cidade');
    let polo = document.querySelector('#calculadora-enem__select--polo');

    pais?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');
    estado?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');
    cidade?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');
    polo?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');

    pais.value = '';
    estado.value = '';
    cidade.value = '';
    polo.value = '';

    estado?.classList.add('calculadora-enem__select--desabilitado');
    cidade?.classList.add('calculadora-enem__select--desabilitado');
    polo?.classList.add('calculadora-enem__select--desabilitado');

    loaderPolo?.classList.remove('calculadora-enem__select-loader--ativo');

    return;
  }

  $.ajax({
    url: WpObj.ajaxurl,
    method: "POST",
    data: {
      action: "getPolosData",
      lat: posicao.coords.latitude,
      lng: posicao.coords.longitude,
      cursoid: codigoCurso?.value,
      tipocurso: tipoCurso?.value,
      nonce: nonce.value
    }
  }).done((dados) => {
    if (checkboxGeolocalizacao?.checked) {
      if (dados.length < 2) return;

      let polos = JSON.parse(dados);

      // Selecionando país
      document.querySelector(`#calculadora-enem__select--pais option[value="${polos[0].pais}"]`).selected = true;

      // Selecionando Estado
      let estadoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--estado');
      estadoCampo.innerHTML = '';
      let optionEstado = document.createElement('option');
      optionEstado.text = polos[0].uf;
      optionEstado.value = polos[0].uf;
      optionEstado.selected = true;
      estadoCampo?.appendChild(optionEstado);

      let cidadeCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--cidade');
      cidadeCampo.innerHTML = '';
      let optionCidade = document.createElement('option');
      optionCidade.text = polos[0].cidade;
      optionCidade.value = polos[0].cidade;
      optionCidade.selected = true;
      cidadeCampo?.appendChild(optionCidade);

      let poloCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--polo');
      poloCampo.innerHTML = '';

      polos.forEach((polo) => {
        let option = document.createElement("option");
        option.text = polo.nome;
        option.value = polo.id;
        poloCampo?.appendChild(option);
      });

      poloCampo?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');
      poloCampo?.classList.remove('calculadora-enem__select--desabilitado');
      loaderPolo?.classList.remove('calculadora-enem__select-loader--ativo');
    }
  });
}