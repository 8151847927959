export const AdicionarSwipe = (
  elemento: string
): void => {
  const sliders: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll(`${elemento}`);

  sliders.forEach(slider => {
    var velX: number = 0;
    var momentumID: number;

    let isDown: boolean = false;
    let startX: number;
    let scrollLeft: number;

    const cancelMomentumTracking = () => {
      cancelAnimationFrame(momentumID);
    }

    const momentumLoop = () => {
      slider.scrollLeft += velX;
      velX *= 0.95;

      if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop);
      }
    }

    const beginMomentumTracking = () => {
      cancelMomentumTracking();
      momentumID = requestAnimationFrame(momentumLoop);
    }

    slider.addEventListener(
      'mousedown',
      (evento) => {
        isDown = true;
        slider.classList.add('active');
        startX = evento.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
        cancelMomentumTracking();
      }
    );

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
      beginMomentumTracking();
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) {
        return;
      }

      e.preventDefault();

      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX); //scroll-fast
      var prevScrollLeft = slider.scrollLeft;

      slider.scrollLeft = scrollLeft - walk;
      velX = slider.scrollLeft - prevScrollLeft;
    });

    slider.addEventListener('wheel', (e) => {
      cancelMomentumTracking();
    });
  });
};