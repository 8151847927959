import { CreateAjax } from "../../Utils/createAjax";

/**
 * Função que toggle a classe 'desativado' do modal, ativando ou desativando-o.
 * @param {Event} evento - Evento de clique que abriu o modal.
 * @param {HTMLElement} modal - HTMLElement do modal.
 */
export const ToggleConteSuaHistoriaModal = (
  evento: Event,
  modal: HTMLElement
): void => {
  evento.preventDefault();
  modal.classList.toggle('desativado');
}

/**
 * Adiciona evento de clique nos elementos que abrem e fecham o modal.
 * @param {HTMLElement} modulo - O modulo que contem o banner e o modal.
 * @param {HTMLElement} modal - O modal que ser  aberto ou fechado.
 */
export const AbrirFecharModal = (
  modulo: HTMLElement, modal: HTMLElement
): void => {
  const bannerCta = <HTMLElement>modulo.querySelector('.conte-sua-historia-banner-cta');
  const fecharModal = <HTMLElement>modal.querySelector('.conte-sua-historia-modal-fechar');

  [bannerCta, fecharModal].forEach((elemento) => elemento
    .addEventListener('click', (evento: Event) => ToggleConteSuaHistoriaModal(evento, modal)));
};

export const slickSetings = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 1500,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false,
      }
    }
  ]
};

/**
 * Inicia o slider do modulo de conte sua historia.
 *
 * Adiciona as configurações de slider para o elemento com a classe passada
 * como parametro.
 *
 * @param {string} moduloClass - Classe do container do modulo.
 */
export const IniciarSlider = (moduloClass: string): void => {
  $(`.${moduloClass} .conte-sua-historia-slider`)
    .slick(slickSetings);
};

/**
 * Função que adiciona listener para select do modal.
 *
 * Adiciona listener para eventos focus, focusout e change no select do modal.
 * E tambem altera a opacidade e habilita/desabilita a ultima coluna do formulario,
 * baseado no evento que foi disparado.
 *
 * @param {HTMLElement} modal - Elemento do modal.
 */
export const SelectListener = (modal: HTMLElement): void => {
  const elementoBox = <HTMLElement>modal.querySelector('.conte-sua-historia-modal-formulario-select');
  const elemento = <HTMLElement>elementoBox.querySelector('select');
  const colunas = <NodeListOf<HTMLDivElement>>modal.querySelectorAll('.conte-sua-historia-modal-formulario-coluna');
  const ultimaColuna = <HTMLDivElement>colunas[colunas.length - 1];

  ultimaColuna.style.opacity = '0.5';
  ultimaColuna.style.pointerEvents = 'none';

  [
    { "evento": "focus", "rotate": "180deg" },
    { "evento": "focusout", "rotate": "0deg" },
    { "evento": "change", "rotate": "0deg" }
  ].forEach((item => elemento.addEventListener(item.evento,
    () => elementoBox.style.setProperty('--select-arrow-icon-rotate', item.rotate))));

  elemento.addEventListener('change', () => {
    ultimaColuna.style.opacity = '1';
    ultimaColuna.style.pointerEvents = 'all';
  });
};

/**
 * Função que realiza a ação que submete o formulario do modal da modulo "Conte sua Historia"
 * @param evento Evento que chamou a função
 * @param modal Elemento HTML do modal
 * @returns Promise que resolve quando o formulario for submetido com sucesso
 */
export const SubmitModalAcao = async (evento: Event, modal: HTMLElement) => {
  evento.preventDefault();
  const formulario = <EventTarget>evento.target;

  const botao = <HTMLButtonElement>modal.querySelector('.conte-sua-historia-modal-formulario-botao');
  const botaoTexto = <HTMLElement>modal.querySelector('.conte-sua-historia-modal-formulario-botao-texto');
  const botaoTextoConteudo = botaoTexto.textContent;
  const identificador = decodeURI(formulario['identificador'].value);

  const dados = [
    { name: 'nome', value: formulario['nome'].value },
    { name: 'telefone', value: formulario['telefone'].value },
    { name: 'email', value: formulario['email'].value },
    { name: 'estado', value: formulario['estado'].value },
    { name: 'como_virou', value: formulario['como-virou'].value },
    { name: 'curso', value: formulario['curso'].value },
    { name: 'quando_formou', value: formulario['quando-formou'].value },
    { name: 'utm_source', value: formulario['utm_source'] ? formulario['utm_source'].value : '(direct)' },
    { name: 'utm_campaign', value: formulario['utm_campaign'] ? formulario['utm_campaign'].value : '(direct)' },
    { name: 'utm_medium', value: formulario['utm_medium'] ? formulario['utm_medium'].value : '(none)' },
    { name: 'utm_content', value: formulario['utm_content'] ? formulario['utm_content'].value : '(none)' },
    { name: 'utm_term', value: formulario['utm_term'] ? formulario['utm_term'].value : '(none)' },
    { name: 'gclid', value: formulario['gclid'] ? formulario['gclid'].value : '' }
  ];

  botao.disabled = true;
  botaoTexto.textContent = "Carregando...";

  const dadosFormulario = new FormData();
  dadosFormulario.append('action', 'processFields');
  // @ts-ignore: Unreachable code error
  dadosFormulario.append('nonce', ProcessFields.nonce);
  dadosFormulario.append('tipoformulario', 'modal');
  dadosFormulario.append('identificador', identificador);
  // @ts-ignore: Unreachable code error
  dadosFormulario.append('idAnalytics', idAnalyticsValue);
  dados.forEach((dado) => dadosFormulario.append(`inputs[${dado.name}]`, dado.value));

  await CreateAjax(dadosFormulario);
  botao.disabled = false;
  botaoTexto.textContent = botaoTextoConteudo;
}

/**
 * Função que adiciona um evento de submit no formulario do modal,
 * chamando a função SubmitModalAcao com os parametros (evento, modal).
 */
export const SubmitModal = (modal: HTMLElement): void => {
  const formulario = <HTMLFormElement>modal.querySelector('.conte-sua-historia-modal-formulario');

  formulario.addEventListener('submit', (evento: Event) => {
    evento.preventDefault();
    SubmitModalAcao(evento, modal);
  });
}

/**
 * Função principal do Módulo de Conte Sua História.
 * Responsavel por inicializar o Slider, adicionar o listener de abertura e fechamento do modal,
 * adicionar o listener de envio do formulario e inicializar o listener de seleção das opções
 * dos selects do formulario.
 * @param {HTMLElement} modulo - Elemento HTML do m dulo.
 * @param {HTMLElement} modal - Elemento HTML do modal.
 */
export const ConteSuaHistoria = (
  modulo: HTMLElement, modal: HTMLElement
): void => {
  AbrirFecharModal(modulo, modal);
  IniciarSlider(modulo.classList[0]);
  SelectListener(modal);
  SubmitModal(modal);
};

export default ConteSuaHistoria;