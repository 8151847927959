const TesteVocacionalPerguntas = (modulo: HTMLElement) => {
  const perguntasLi = <NodeListOf<HTMLLIElement>>modulo.querySelectorAll('#tv-perguntas ul li');
  const paginas = <NodeListOf<HTMLLIElement>>modulo.querySelectorAll('.tv-pagina');
  const botaoVerResultado = <HTMLButtonElement>modulo.querySelector('.tv-pagina:last-child .tv-botao-proxima');
  const divPerguntas = <HTMLDivElement>modulo.querySelector('.perguntas');

  perguntasLi?.forEach(item => item.addEventListener('click', () => {
    const inputElement = <HTMLInputElement>item.querySelector('input');
    inputElement.checked = true;
  }));

  paginas.forEach((item) => {
    const divBotoes = document.createElement('div');
    divBotoes.classList.add('tv-botoes');

    const botoes = item.querySelectorAll('button');
    botoes.forEach((botao) => {
      divBotoes.appendChild(botao);
    });

    item.appendChild(divBotoes);
  });

  botaoVerResultado?.addEventListener('click', () => {
    divPerguntas.classList.add('resultado');
  })
}

export default TesteVocacionalPerguntas;