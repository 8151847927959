/**
 * Adiciona a classe bannerHome-active no header se a largura da tela for menor ou igual a 992px
 */
export const AdicionarClasseBannerHomeActive = (): void => {
  if (window.screen.width <= 992) {
    const header = <HTMLElement>document.querySelector('.header');
    header.classList.add('bannerHome-active');
  }
}

/**
 * Define o atributo de duração para o elemento pai de um vídeo e redefine o vídeo.
 *
 * Esta função calcula a duração do vídeo em milissegundos,
 * subtrai 500 milissegundos e define este valor como o atributo 'data-time'
 * no elemento pai do vídeo. Em seguida, pausa o vídeo
 * e redefine seu tempo atual para o início.
 *
 * @param {HTMLVideoElement} video - The video element to set the duration for.
 */
export const DefinirDuracaoVideo = (
  video: HTMLVideoElement
): void => {
  const duracaoVideoMilisegundos = video.duration * 1000;
  const elementoPai = <HTMLElement>video.parentElement;

  elementoPai.setAttribute('data-time', `${duracaoVideoMilisegundos - 500}`);
  video.pause();
  video.currentTime = 0;
}

/**
 * Retorna a dura o do primeiro slide do banner em milissegundos.
 *
 * Se o atributo data-time do primeiro item do banner não for encontrado,
 * retorna o valor padr o de 10 segundos (10000 milissegundos).
 *
 * @returns {number} A dura o do primeiro slide em milissegundos.
 */
export const DuracaoPrimeiroSlide = (): number => {
  const itensBanner = <NodeListOf<HTMLElement>>document.querySelectorAll('.itemBannerHome');
  const datasetTime = parseInt(itensBanner[0]?.dataset.time ?? '10000');
  return isNaN(datasetTime) ? 10000 : datasetTime;
}

const ConfiguracoesSlider = (slideTimeInitial: number) => {
  return {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    fade: true,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: slideTimeInitial,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [{
      breakpoint: 960,
      settings: {
        arrows: false,
        dots: true,
        autoplaySpeed: slideTimeInitial
      }
    }]
  }
}

/**
  * Função chamada apos a troca de slide.
  *
  * Essa função fara o video do slide atual ser reproduzido.
  *
  * @param {Event} e O objeto de evento.
  * @param {Slick} slick A instancia do slider slick.
  */
export const SliderFunctionAfterChange = (e, slick) => {
  const slideAtual = <HTMLElement>document.querySelector(`div[data-slick-index="${slick.currentSlide}"]`);
  const duracaoSlideAtual = slideAtual.dataset.time;
  const slideTime = parseInt(duracaoSlideAtual ?? '10000');

  $('#bannerHome').slick("setOption", "autoplaySpeed", slideTime);

  const desktop = `.videoDesktop${slick.currentSlide}`;
  $(desktop).trigger('play');

  const mobile = `.videoMobile${slick.currentSlide}`;
  $(mobile).trigger('play');
}

/**
 * Função executada antes da mudança de slide.
 *
 * Esta função pausa o vídeo no slide atual e redefine seu tempo de reprodução para zero.
 * Ela tem como alvo os elementos de vídeo tanto para desktop quanto para mobile, identificados pelo índice do slide atual.
 *
 * @param {any} slick - A instância do slider slick.
 * @param {any} currentSlide - O objeto do slide atual.
 * @param {any} nextSlide - O objeto do próximo slide.
 */
const SlideFunctionBeforeChange = (slick, currentSlide, nextSlide) => {
  const desktop = `.videoDesktop${currentSlide.currentSlide}`;
  const elementoDesktop = <any>document.querySelector(desktop);

  const mobile = `.videoMobile${currentSlide.currentSlide}`;
  const elementoMobile = <any>document.querySelector(mobile);

  setTimeout(() => {
    if ($(desktop).length > 0) {
      $(desktop).trigger('pause');
      elementoDesktop.currentTime = 0;
    }

    if ($(mobile).length > 0) {
      $(mobile).trigger('pause');
      elementoMobile.currentTime = 0;
    }
  }, 200);
}

/**
 * Inicializa o slider slick para a seção do banner home.
 *
 * @param {number} slideTimeInitial - O tempo inicial de reprodução automática em milissegundos.
 */
const IniciaSlide = (slideTimeInitial: number) => {
  $('#bannerHome').slick(ConfiguracoesSlider(slideTimeInitial))
    .on('afterChange', SliderFunctionAfterChange)
    .on('beforeChange', SlideFunctionBeforeChange);
}

/**
 * Inicializa a se o do banner home definindo classes ativas,
 * definindo durações de videos e inicializando o slider slick.
 * Initializes the banner home section by setting active classes,
 * defining video durations, and starting the slick slider.
 *
 * Essa função primeiro adiciona uma classe ativa para o do banner home.
 * Em seguida, ela iteração sobre todos os elementos de video para definir seus atributos de duração.
 * Apos determinar o tempo inicial do slide, ela inicializa o slider com esse tempo.
 * Por fim, ela dispara a ação de reprodução para os primeiros videos desktop e mobile.
 */
const BannerHome = () => {
  AdicionarClasseBannerHomeActive();

  const videos = document.querySelectorAll('video');
  videos.forEach(DefinirDuracaoVideo);

  const slideTimeInitial = DuracaoPrimeiroSlide();
  IniciaSlide(slideTimeInitial);

  $('.videoDesktop0').trigger('play');
  $('.videoMobile0').trigger('play');
}

export default BannerHome;