import { CreateAjax } from "../../Utils/createAjax";

export const ModalCalculadoraEnemNovoLiberarLocalizacao = () => {
    let paisCampo = document.querySelector('#calculadora-enem__select--pais');
    paisCampo?.classList.remove('calculadora-enem__select--desabilitado');
}

export const ModalCalculadoraEnemNovoMostrarEstados = () => {
    let paisCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--pais');
    let estadoCampo = document.querySelector('#calculadora-enem__select--estado');

    ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(estadoCampo);
    ModalCalculadoraEnemNovoBuscarEstados(paisCampo.value, estadoCampo);
}

const ModalCalculadoraEnemNovoBuscarEstados = (pais, selectCampo) => {
    let nonceCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__action--get-estados');
    let nonceCampoValor = nonceCampo?.value.toString() || '';

    let dados = new FormData();
    dados.append('action', 'getEstados');
    dados.append('pais', pais);
    dados.append('nonce', nonceCampoValor);

    CreateAjax(dados).then((retorno) => {
        let dadosOption = Array();
        retorno.forEach((item) => {
            dadosOption.push({
                'texto': item.nome,
                'valor': item.sigla
            })
        });

        ModalCalculadoraEnemNovoApresentarOptionsNoSelect(
            selectCampo,
            dadosOption
        );

        ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(selectCampo);
    });
}

export const ModalCalculadoraEnemNovoMostrarCidades = () => {
    let estadoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--estado');
    let cidadeCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--cidade');

    ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(cidadeCampo);
    ModalCalculadoraEnemNovoBuscarCidades(estadoCampo.value, cidadeCampo);
}

const ModalCalculadoraEnemNovoBuscarCidades = (estado, selectCampo) => {
    let nonceCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__action--get-cidades');

    let dados = new FormData();
    dados.append('action', 'getCidades');
    dados.append('nonce', nonceCampo.value);
    dados.append('estado', estado);

    CreateAjax(dados).then((retorno) => {
        let dadosOption = Array();

        retorno.forEach((item) => {
            dadosOption.push({
                'texto': item.nome,
                'valor': item.coordenadas
            });
        });

        ModalCalculadoraEnemNovoApresentarOptionsNoSelect(
            selectCampo,
            dadosOption
        );

        ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(selectCampo);
    });
}

export const ModalCalculdaoraEnemNovoMostrarPolos = () => {
    let cidadeCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--cidade');
    let poloCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--polo');

    ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(poloCampo);
    ModalCalculadoraEnemNovoBuscarPolos(cidadeCampo.value).then((retorno) => {
        let dadosOption = Array();

        retorno.forEach((item) => {
            dadosOption.push({
                'texto': item.nome,
                'valor': item.id
            });
        });

        ModalCalculadoraEnemNovoApresentarOptionsNoSelect(
            poloCampo,
            dadosOption
        );

        ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(poloCampo);
    });
}

const ModalCalculadoraEnemNovoBuscarPolos = (coordenadas) => {
    let nonceCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__action--get-polos');

    let dados = new FormData();
    dados.append('action', 'getPolosGeral');
    dados.append('nonce', nonceCampo.value);
    dados.append('coordenadas', coordenadas);

    return CreateAjax(dados).then((retorno) => {
        return retorno;
    });
}

export const ModalCalculadoraEnemNovoApresentarOptionsNoSelect = (elemento, dados) => {
    elemento.innerHTML = '';

    let option = document.createElement('option');
    option.setAttribute('value', '');
    option.setAttribute('selected', '');
    option.setAttribute('disabled', '');
    elemento.appendChild(option);

    dados.forEach((dado) => {
        option = document.createElement('option');
        option.text = dado.texto;
        option.value = dado.valor;
        elemento.appendChild(option);
    });

    elemento.classList.remove('calculadora-enem__select--desabilitado');
}

export const ModalCalculadoraEnemNovoMudaEstadoLoaderCampo = (elemento) => {
    let loader = <HTMLDivElement>elemento.parentElement?.previousElementSibling;

    if (loader.classList.contains('calculadora-enem__select-loader--ativo')) {
        setTimeout(() => {
            loader?.classList.remove('calculadora-enem__select-loader--ativo');
            elemento.parentElement.classList.remove('calculadora-enem__grupo-campo--oculto');
        }, 1000);

        return;
    }

    loader?.classList.add('calculadora-enem__select-loader--ativo');
    elemento.parentElement.classList.add('calculadora-enem__grupo-campo--oculto');
}

export const ModalCalculadoraEnemNovoBuscarGeolocalizacao = (localizacaoAtiva) => {
    if (!navigator.geolocation) {
        alert("A geo localização não é suportada pelo seu navegador.")
        return;
    }

    if (localizacaoAtiva) {
        navigator.geolocation.getCurrentPosition((posicao) => {
            let coordenadas = posicao.coords.latitude + ', ' + posicao.coords.longitude;
            let poloCampo = document.querySelector('#calculadora-enem__select--polo');

            ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(poloCampo);
            ModalCalculadoraEnemNovoMudarVisualizacaoCamposLocalizacao('ocultar');

            ModalCalculadoraEnemNovoBuscarPolos(coordenadas).then((retorno) => {
                let dadosOption = Array();

                ModalCalculadoraEnemNovoPreencherCamposOcultosGeolocalizacao(retorno[0]);

                retorno.forEach((item) => {
                    dadosOption.push({
                        'texto': item.nome,
                        'valor': item.id
                    });
                });

                ModalCalculadoraEnemNovoApresentarOptionsNoSelect(
                    poloCampo,
                    dadosOption
                );

                ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(poloCampo);
            });
        });

        return;
    }

    ModalCalculadoraEnemNovoMudarVisualizacaoCamposLocalizacao('apresentar');
    ModalCalculadoraEnemNovoResetarCamposLocalizacao();
}

const ModalCalculadoraEnemNovoMudarVisualizacaoCamposLocalizacao = (acao) => {
    let paisCampo = document.querySelector('#calculadora-enem__select--pais');
    let estadoCampo = document.querySelector('#calculadora-enem__select--estado');
    let cidadeCampo = document.querySelector('#calculadora-enem__select--cidade');

    if (acao == 'apresentar') {
        paisCampo?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');
        estadoCampo?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');
        cidadeCampo?.parentElement?.classList.remove('calculadora-enem__grupo-campo--oculto');

        return;
    }

    paisCampo?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');
    estadoCampo?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');
    cidadeCampo?.parentElement?.classList.add('calculadora-enem__grupo-campo--oculto');
}

const ModalCalculadoraEnemNovoPreencherCamposOcultosGeolocalizacao = (dados) => {
    let paisCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--pais');
    let estadoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--estado');
    let cidadeCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--cidade');

    paisCampo.value = 'BRASIL';

    estadoCampo.innerHTML = '';
    cidadeCampo.innerHTML = '';
    let option = document.createElement('option');
    option.text = dados.uf;
    option.value = dados.uf;
    estadoCampo?.appendChild(option);

    option = document.createElement('option');
    option.text = dados.cidade;
    option.value = dados.cidade;
    cidadeCampo?.appendChild(option);
}

const ModalCalculadoraEnemNovoResetarCamposLocalizacao = () => {
    let paisCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--pais');
    let estadoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--estado');
    let cidadeCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--cidade');
    let poloCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--polo');

    paisCampo.value = '';
    estadoCampo.innerHTML = '';
    cidadeCampo.innerHTML = '';
    poloCampo.innerHTML = '';

    paisCampo.classList.remove('calculadora-enem__select--desabilitado');
    estadoCampo.classList.add('calculadora-enem__select--desabilitado');
    cidadeCampo.classList.add('calculadora-enem__select--desabilitado');
    poloCampo.classList.add('calculadora-enem__select--desabilitado');
}