import { CreateAjax } from "../../Utils/createAjax";

const BuscaTurmasPorLocalidade = () => {
  const seletores = <NodeListOf<HTMLElement>>document.querySelectorAll('select[name="informacoes-rapidas__cidade"]');
  const linhas = <NodeListOf<HTMLElement>>document.querySelectorAll('.linha-dois__dados-turma, .linha-tres__dados-turma, .sidebar-pdp-tela-um-informacoes-rapidas');

  const AcaoSeletor = (evento: Event) => {
    const nonceElemento = <HTMLInputElement>document.querySelector('#wp_nonce_get_turmas_pos_presencial');
    const nonceValor = nonceElemento.value;

    const codigoCursosElemento = <HTMLInputElement>document.querySelector('input[name="codCurs"]');
    const codigoCursosValor = codigoCursosElemento.value;

    let seletorTurmas = <HTMLSelectElement>document.querySelector('.informacoes-rapidas select[name="informacoes-rapidas__data-inicio"]');

    if (window.screen.width <= 992) {
      seletorTurmas = <HTMLSelectElement>document.querySelector('.sidebar-pdp-tela-um-filtros-turma-campos select[name="informacoes-rapidas__data-inicio"]');
    }

    let seletorTurmasBox = <HTMLElement>seletorTurmas.parentElement;
    let seletoresTurmasLoader = <NodeListOf<HTMLElement>>document.querySelectorAll('.pdp-novos-carrinhos-tipo--livre-base .campos__select--loading');

    let elemento = <HTMLSelectElement>evento.target;
    let valor = elemento.value;

    linhas.forEach(linha => linha.style.display = 'none');

    seletorTurmasBox.style.display = 'none';
    seletoresTurmasLoader.forEach(seletorTurmasLoader => seletorTurmasLoader.style.display = 'block');

    if (valor) {
      let dados = `?action=getTurmasPosPresencial&codCurs=${codigoCursosValor}&cidade=${valor}&nonce=${nonceValor}`;
      CreateAjax(null, dados).then((retorno) => {
        let dadosRetorno = retorno;

        if (dadosRetorno.nome_turmas) {
          seletorTurmas.innerHTML = '<option value="" disabled selected hidden=" "></option>';

          dadosRetorno.nome_turmas.forEach((turma: string, index: number) => {
            let inscricoesDataInicio = new Date(dadosRetorno[index].dataInicioInscricao).toLocaleDateString();
            let inscricoesFim = new Date(dadosRetorno[index].dataFimInscricao).toLocaleDateString();
            let inscricoes = `de ${inscricoesDataInicio} a ${inscricoesFim}`;

            let aulasDataInicio = new Date(dadosRetorno[index].dataInicioAulas).toLocaleDateString();
            let aulasFim = new Date(dadosRetorno[index].dataFimAulas).toLocaleDateString();
            let aulas = `de ${aulasDataInicio} a ${aulasFim}`;

            let filialTratada = `${dadosRetorno[index].nomeFilial}`
              .replace(' - GRADUAÇÃO EAD', '')
              .replace(' - GRADUAÇÃO PRESENCIAL', '')
              .replace(' - PÓS-GRADUAÇÃO', '')
              .replace('PÓS-', '');
            let filial = filialTratada.charAt(0).toUpperCase() + filialTratada.slice(1).toLowerCase();

            let horario = dadosRetorno[index].horario;
            let local = `${filial} | ${horario}`;

            seletorTurmas.innerHTML += `<option data-inscricoes="${inscricoes}" data-aulas="${aulas}" data-local="${local}" value="${turma}">Em ${turma}</option>`;
          })
        }

        seletoresTurmasLoader.forEach(seletorTurmasLoader => seletorTurmasLoader.style.display = 'none');
        seletorTurmasBox.style.display = 'flex';
      });
    }
  }

  seletores.forEach(seletor => seletor.addEventListener('change', (evento: Event) => AcaoSeletor(evento)));
}

const BuscaDadosTurma = () => {
  const seletores = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.informacoes-rapidas select[name="informacoes-rapidas__data-inicio"], .sidebar-pdp-tela-um-filtros-turma-campos select[name="informacoes-rapidas__data-inicio"]');

  const linhas = <NodeListOf<HTMLElement>>document.querySelectorAll('.linha-dois__dados-turma, .linha-tres__dados-turma');
  const linhasMobile = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-tela-um-informacoes-rapidas');

  const itens = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-coluna-dois-item, .informacoes-rapidas-coluna-tres-item, .pdp-novos-carrinhos-tipo--livre-base .sidebar-pdp-tela-um-informacoes-rapidas-item');
  const itensLoaders = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-dado--loading, .eskeleton-load-bar');

  const elementoValorInscricoes = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-inscricoes-valor');
  const elementoValorAulas = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-aulas-valor');
  const elementoValorLocal = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-local-valor');

  const AcaoSeletor = (evento: Event) => {

    let seletorElemeto = <HTMLSelectElement>evento.target;

    let opcaoSelecionada = <HTMLElement>document
      .querySelector(`.informacoes-rapidas select[name="informacoes-rapidas__data-inicio"] option[value="${seletorElemeto.value}"]`);

    if (window.screen.width <= 992) {
      opcaoSelecionada = <HTMLElement>document
        .querySelector(`.sidebar-pdp-tela-um-filtros-turma-campos select[name="informacoes-rapidas__data-inicio"] option[value="${seletorElemeto.value}"]`);
    }

    itens.forEach(item => item.style.display = 'none');
    itensLoaders.forEach(item => item.style.display = 'block');

    linhas.forEach(linha => linha.style.display = 'grid');
    if (screen.width <= 992) {
      linhasMobile.forEach(linha => linha.style.display = 'grid');
    }

    let desativarLoading = setTimeout(() => {
      elementoValorInscricoes.forEach(elemento => elemento.textContent = opcaoSelecionada.dataset.inscricoes || '');
      elementoValorAulas.forEach(elemento => elemento.textContent = opcaoSelecionada.dataset.aulas || '');
      elementoValorLocal.forEach(elemento => elemento.textContent = opcaoSelecionada.dataset.local || '');

      itensLoaders.forEach(item => item.style.display = 'none');
      itens.forEach(item => item.style.display = 'grid');
      clearTimeout(desativarLoading);
    }, 500);
  }

  seletores.forEach(seletor => seletor.addEventListener('change', (evento: Event) => AcaoSeletor(evento)));
};

export const PdpInformacoesRapidasCursosLivresBase = () => {
  if (document.querySelector('.linha-dois__filtros')) {
    BuscaTurmasPorLocalidade();
    BuscaDadosTurma();
  }
}