import { DataLayerCreateCliqueBotao, DataLayerCreateInteracaoFormulario } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerSobreVoceEscolaridade = () => {
  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  let fluxoEventLabel = 'ver-investimento';

  if (fluxo == 'InscrevaSe') {
    fluxoEventLabel = 'inscricao';
  }

  const botoesFinalizacao = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-sobre-voce--escolaridade .sidebar-pdp-tela__proximo');

  const seletores = <NodeListOf<HTMLSelectElement>>document
    .querySelectorAll('.sidebar-pdp-tela-sobre-voce--escolaridade .sidebar-pdp-tela__campo-select');

  botoesFinalizacao
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao(`formulario:${fluxoEventLabel}`)));

  const Acao = (evento: Event) => {
    const elemento = <HTMLSelectElement>evento.target;
    DataLayerCreateInteracaoFormulario(`${fluxoEventLabel}:${elemento.name}`);
  }

  seletores.forEach((elemento: HTMLElement) => elemento
    .addEventListener('change', (evento: Event) => Acao(evento)));
}