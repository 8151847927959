let sliderOptions: JQuerySlickOptions = {
  fade: true,
  cssEase: 'linear',
  speed: 500,
  adaptiveHeight: true,
  dots: true,
  infinite: false,
  prevArrow: '<button type="button" class="informacoes-rapidas-coluna-tres-item-conteudos-slider__seta informacoes-rapidas-coluna-tres-item-conteudos-slider__seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
  nextArrow: '<button type="button" class="informacoes-rapidas-coluna-tres-item-conteudos-slider__seta informacoes-rapidas-coluna-tres-item-conteudos-slider__seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>',
  responsive: [{
    breakpoint: 992,
    settings: {
      variableWidth: false,
    }
  }],
};

export const PdpInformacoesRapidasCursosPosGraduacaoBase = () => {
  let sliders = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-coluna-tres .informacoes-rapidas-coluna-tres-item-conteudos-slider .informacoes-rapidas-coluna-tres-item-conteudos');

  if (sliders.length > 1) {
    $('.informacoes-rapidas-coluna-tres-item-conteudos-slider').slick(sliderOptions);
  }
}