import { SetCookie } from "../../Utils/cookies";
import { CreateAjax } from "../../Utils/createAjax";
import { DadoDeLead } from "../../Utils/enviaLead";

export const CalculadoraEnemBuscarNota = () => {
    let campoNotaEnem = <HTMLInputElement>document.querySelector('#calculadora-enem__input--nota-enem');
    let valorNotaEnem = campoNotaEnem.value;

    if (valorNotaEnem.length > 2) {
        let identificador = <HTMLInputElement>document.querySelector('#calculadora-enem__identificador');
        let campos = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
            .querySelectorAll('.calculadora-enem__passos input, .calculadora-enem__passos select');

        let leadAtual = sessionStorage.getItem('leadAtual') || '[]';
        let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

        campos.forEach((elemento) => {
            let elementoExistente = dadosLead.find(dado => dado.name == elemento.name);

            if (elementoExistente) {
                elementoExistente.value = elemento.value;
            }

            if (!elementoExistente) {
                let dadoLead: DadoDeLead = {
                    name: elemento.name,
                    value: elemento.value
                }

                dadosLead.push(dadoLead);
            }
        });

        sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
        SetCookie('leadAtual', JSON.stringify(dadosLead), 1);

        let tituloNota = document.querySelector('.calculadora-enem__titulo--nota');
        let textoInformacao = document.querySelector('.calculadora-enem__informacao--nota');
        let loader = document.querySelector('.calculadora-enem__loader--nota');
        let boxNota = document.querySelector('.calculadora-enem__valores');

        tituloNota?.classList.add('calculadora-enem__titulo--nota-oculto');
        tituloNota?.classList.remove('calculadora-enem__titulo--nota-inativa');
        textoInformacao?.classList.remove('calculadora-enem__informacao--nota-ativa');
        loader?.classList.add('calculadora-enem__loader--ativo');
        boxNota?.classList.remove('calculadora-enem__valores--ativo');

        let periodo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--periodo');
        let campus = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--campus');
        let curso = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--curso');
        let nonce = <HTMLSelectElement>document.querySelector('#calculadora-enem__action--add-valor-desconto');

        let dados = new FormData();
        dados.append('action', 'addValorDesconto');
        dados.append('nota', valorNotaEnem);
        dados.append('periodo', periodo?.value ?? 'EAD');
        dados.append('campus', campus?.value ?? '');
        dados.append('curso', curso.value ?? '');
        dados.append('nonce', nonce.value ?? '');
        dados.append('idLead', localStorage.getItem('idLead') ?? '');

        CreateAjax(dados).then((retorno) => {
            let spanValorDe = <HTMLElement>document.querySelector('.calculadora-enem__valor-de--span');
            let spanValorPara = <HTMLElement>document.querySelector('.calculadora-enem__valor-para--span');

            spanValorDe.textContent = retorno['valorCheio'];
            spanValorPara.textContent = 'R$ ' + retorno['desconto'];

            let elementoInserirPorcentagem = <HTMLElement>document.querySelector('.calculadora-enem__porcentagem-valor');
            let botaoInscrevaSe = document.querySelector('#btnCALCENEM-Inscrevase');

            botaoInscrevaSe?.setAttribute('href', retorno.formasIngresso.url);

            if (retorno['porcentagem'] > 0) {
                elementoInserirPorcentagem.innerHTML = '-' + retorno['porcentagem'].toString() + '<tspan>%</tspan>';
                document.querySelector('.calculadora-enem__porcentagem')?.classList.remove('calculadora-enem__porcentagem--oculto');
                document.querySelector('.calculadora-enem__valor-de')?.classList.remove('calculadora-enem__valor-de--oculto');
            } else {
                let desconto = parseInt(retorno['desconto']);
                let valorCheio = parseInt(retorno['valorCheio']);
                let porcentagem = (retorno['porcentagem'] === 0) ? 0 : Math.round((desconto * 100) / valorCheio);

                if (porcentagem > 0) {
                    document.querySelector('.calculadora-enem__valor-de')?.classList.remove('calculadora-enem__valor-de--oculto');
                    document.querySelector('.calculadora-enem__porcentagem')?.classList.remove('calculadora-enem__porcentagem--oculto');
                    elementoInserirPorcentagem.innerHTML = '-' + porcentagem.toString() + '<tspan>%</tspan>';
                } else {
                    document.querySelector('.calculadora-enem__valor-de')?.classList.add('calculadora-enem__valor-de--oculto');
                    document.querySelector('.calculadora-enem__porcentagem')?.classList.add('calculadora-enem__porcentagem--oculto');
                    elementoInserirPorcentagem.innerHTML = '';
                }

            }
            setTimeout(() => {
                loader?.classList.remove('calculadora-enem__loader--ativo');
                tituloNota?.classList.remove('calculadora-enem__titulo--nota-oculto');
                boxNota?.classList.add('calculadora-enem__valores--ativo');
            }, 1000);

            return true;
        });
    }
}