import { CreateAjax } from "./createAjax";

export const tiposDePaginas = {
    "graduacao": "grad",
    "pos-graduacao": "pos",
    "cursos-tecnicos": "tec",
    "curso-tecnico": "tec",
    "cursos-tecnicos-e-profissionalizantes": "tec",
    "cursos-profissionalizantes": "prof",
    "curso-profissionalizante": "prof",
    "cursos-livres": "liv",
    "curso-livre": "liv",
};

export const verificaEmailSalesforce = async (email: string, nonce: string) => {
    const formEmail = new FormData();
    formEmail.append("email", email);
    formEmail.append("nonce", nonce);

    formEmail.append("tipo", tiposDePaginas[window.location.pathname.split('/')[1]] || 'grad');
    formEmail.append("action", "leadByEmail");

    return await CreateAjax(formEmail).then(response => JSON.parse(response));
}