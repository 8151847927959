
export const CarregarAlteracaoFonte = () => {
  let elementos = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, small, a, label, button, input, span');

  elementos.forEach((elemento) => {
    elemento.setAttribute('data-font-size', getComputedStyle(elemento)['font-size']);
  });
}

export const AlterarFonte = () => {
  document.querySelectorAll('.alterarFonte').forEach((elemento) => {
    elemento.addEventListener('click', (evento) => {
      const classeElementoClicado = evento.target.className;
      let tipoAlteracao = '+';
      if (classeElementoClicado.indexOf('diminuirFonte') != -1) {
        tipoAlteracao = '-';
      }

      const iconeFonteDefault = <HTMLElement>document.querySelector('.iconeFonteDefault');
      let nivel = parseInt(iconeFonteDefault.getAttribute('nivel') ?? '0');      
      let elementos = document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, small, a, label, button, input, span');
      nivel = tipoAlteracao == '+' ? nivel + 1 : nivel - 1;

      let niveis = {
        0: 1,
        1: 1.1,
        2: 1.2,
        3: 1.3
      };

      // Regras para ativação e desativação de botões
      let diminuirFonte = document.querySelector('.diminuirFonte');
      if (nivel > 0) {
        diminuirFonte?.classList.remove('acessdisabled');
      } else {
        diminuirFonte?.classList.add('acessdisabled');
      }

      let aumentarFonte = document.querySelector('.aumentarFonte');
      if (nivel >= 3) {
        aumentarFonte?.classList.add('acessdisabled');
      } else {
        aumentarFonte?.classList.remove('acessdisabled');
      }

      // Aplicação de tamanho da fonte dentro dos elementos
      elementos.forEach((elemento) => {
        let valor = 'calc(' + elemento.getAttribute('data-font-size') + ' * ' + niveis[nivel] + ')';
        elemento.style.fontSize = valor;
      });

      // Salvamento de nível de fonte
      iconeFonteDefault.setAttribute('nivel', nivel.toString());
    });
  });
}