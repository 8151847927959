/**
 * PreencherDadosPessoais: Prenche todos os inputs com os names: nome, telefone e email;
 * do sidebar com seus respectivos dados armazenados na "sessionStorage" do navegador
 */

export const PdpSidebarPreencherDadosPessoais = (proximaTela) => {
  let nome = <HTMLInputElement>document.querySelector('input[name="nome"].sidebar-pdp-tela-form-input-campo');
  let celular = <HTMLInputElement>document.querySelector('input[name="telefone"].sidebar-pdp-tela-form-input-campo');
  let email = <HTMLInputElement>document.querySelector('input[name="email"].sidebar-pdp-tela-form-input-campo');

  let dominioIes = window.location.host.split('.').find( item => item != 'com' && item != 'br' && item != 'edu' && item != 'www' && item != 'homologa2019');

  if (nome.value != sessionStorage.getItem('nomeLead')){
    sessionStorage.setItem('nomeLead', nome.value);
  }

  if (celular.value != sessionStorage.getItem('celularLead')){
    sessionStorage.setItem('celularLead', celular.value);
  }

  if (email.value != sessionStorage.getItem('emailLead')){
    sessionStorage.setItem('emailLead', email.value);
  }

  if (
    sessionStorage.getItem('nomeLead')
    && sessionStorage.getItem('celularLead')
    && sessionStorage.getItem('emailLead')
  ) {
    nome.value = sessionStorage.getItem('nomeLead') || '';
    celular.value = sessionStorage.getItem('celularLead') || '';
    email.value =  sessionStorage.getItem('emailLead') || '';
    if (
      (
        dominioIes != 'cruzeirodosulvirtual'
        && dominioIes != 'localhost:8014'
      )
      && location.pathname.split('/')[1] == 'graduacao'
    ) {
      proximaTela = 'investimento-graduacao';
    } else {
      proximaTela = 'cinco';
    }

  }

  return proximaTela;
}