/**
 * Função que gerencia a exibição das condições vigentes no modulo ENEM.
 *
 * Ela verifica a presença do elemento '.modulo-enem__link-condicoes-vigentes' e adicona
 * um listener de clique nele. Quando o elemento é clicado, a função verifica se o
 * elemento '#condicoes-vigentes' tem a classe 'modulo-enem__conteudo-condicoes-vigentes--ativo'.
 * Se sim, troca o texto do link para o valor de 'data-texto-link-condicoes' do elemento
 * '.modulo-enem__link-condicoes-vigentes', se não for vazio; se não, troca o texto do link
 * para 'Ocultar as condições vigentes'. Em seguida, remove ou adiciona a classe
 * 'modulo-enem__conteudo-condicoes-vigentes--ativo' do elemento '#condicoes-vigentes',
 * alternando a visibilidade do conteudo.
 */
export const CondicoesVigentesEnem = () => {
  let linkCondicoesVigentes = <HTMLElement>document.querySelector('.modulo-enem__link-condicoes-vigentes');

  const Acao = () => {
    let linkCondicoes = <HTMLElement>document.querySelector('.modulo-enem__link-condicoes-vigentes');
    let condicoesVigentes = <HTMLElement>document.getElementById('condicoes-vigentes');

    if (condicoesVigentes.classList.contains('modulo-enem__conteudo-condicoes-vigentes--ativo')) {
      linkCondicoes.innerHTML = linkCondicoes.dataset.textoLinkCondicoes || '';
    } else {
      linkCondicoes.innerHTML = '<em>Ocultar</em>&nbsp;as condições vigentes';
    }

    condicoesVigentes.classList.toggle('modulo-enem__conteudo-condicoes-vigentes--ativo');
  }

  linkCondicoesVigentes.addEventListener('click', () => Acao());
}