const ConteudoPesquisaNovo = () => {
  document.querySelectorAll('.conteudo-pesquisa-novo .menuop li.current_page_ancestor').forEach((elemento) => {
    elemento.style = '--rotacao-before-epic: rotate(0deg)';
  });

  document.querySelectorAll('.conteudo-pesquisa-novo .menuop li.page_item_has_children > a').forEach((elemento) => {
    if (elemento.getAttribute('href')?.length > 0) {
      elemento.setAttribute('href', 'javascript:void(0)');
      let lista = elemento.nextElementSibling;
      elemento.addEventListener('click', () => {
        if (lista?.classList.contains('ativo')) {
          lista.classList.remove('ativo');
          lista.classList.add('desativado');
          elemento.parentElement.style = '--rotacao-before-epic: rotate(90deg)';
          return;
        }

        lista?.classList.add('ativo');
        lista?.classList.remove('desativado');
        elemento.parentElement.style = '--rotacao-before-epic: rotate(0deg)';
      });
    }

  });
}

export default ConteudoPesquisaNovo;