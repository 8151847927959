import { CreateAjax } from "../../Utils/createAjax";
import { ModalCalculadoraEnemNovoApresentarOptionsNoSelect, ModalCalculadoraEnemNovoMudaEstadoLoaderCampo } from "./ModalCalculadoraEnemNovoLiberarLocalizacao";

export const ModalCalculadoraEnemAtualizarCursosPorCampus = (campusCampo) => {
    let campusId = campusCampo.value;
    let cursoCampo = document.querySelector('#calculadora-enem__select--curso');
    let nonceCampo = <HTMLInputElement>document.querySelector('#modal-calculadora-enem__nonce--get-cursos-campus');

    ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(cursoCampo);

    let dados = new FormData();
    dados.append('action', 'getCursosCampus');
    dados.append('nonce', nonceCampo?.value);
    dados.append('id', campusId);
    
    CreateAjax(dados).then((retorno) => {
        let dadosOption = Array();

        retorno.forEach((curso) => {
            dadosOption.push({
                'texto': curso.descMkt,
                'valor': curso.codCurs,
            });
        });

        ModalCalculadoraEnemNovoApresentarOptionsNoSelect(cursoCampo, dadosOption);
        ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(cursoCampo);
    });
}

export const ModalCalculadoraEnemAtualizarPeriodoPorCurso = (cursoCampo) => {
    let cursoId = cursoCampo.value;
    let periodoCampo = document.querySelector('#calculadora-enem__select--periodo');
    let campusCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--campus');
    let nonceCampo = <HTMLInputElement>document.querySelector('#modal-calculadora-enem__nonce--get-periodo-curso');

    ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(periodoCampo);

    let dados = new FormData();
    dados.append('action', 'getPeriodoCurso');
    dados.append('nonce', nonceCampo.value);
    dados.append('codCurso', cursoId);
    dados.append('campus', campusCampo.value);

    CreateAjax(dados).then((retorno) => {
        let dadosOption = Array();

        retorno.forEach((periodo) => {
            dadosOption.push({
                'texto': periodo,
                'valor': periodo,
            });
        });

        ModalCalculadoraEnemNovoApresentarOptionsNoSelect(periodoCampo, dadosOption);
        ModalCalculadoraEnemNovoMudaEstadoLoaderCampo(periodoCampo);
    });

}