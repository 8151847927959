import { AbrirModalCalculadoraEnem } from "./abrirModalCalculadoraEnem";
import { CondicoesVigentesEnem } from "./condicoesVigentesEnem";

/**
 * Inicializa e gerencia o estado do módulo ENEM.
 *
 * Esta função verifica a presença de elementos relacionados ao módulo ENEM
 * e executa funcionalidades relevantes, como exibir condições e abrir
 * o modal da calculadora.
 *
 * Ela realiza as seguintes operações:
 * - Se um elemento '.calculadora-enem-modal' for encontrado, invoca as funções
 *   `CondicoesVigentesEnem` e `AbrirModalCalculadoraEnem` para lidar com as condições
 *   e interações do modal.
 * - Se um elemento '.calculadora-enem-modal' ou '.modulo-enem' estiver presente,
 *   chama `CondicoesVigentesEnem` para gerenciar a exibição das condições.
 */
export const Enem = () => {
  if (document.querySelector('.calculadora-enem-modal')) {
    CondicoesVigentesEnem();
    AbrirModalCalculadoraEnem();
  }

  if (document.querySelector('.calculadora-enem-modal') || document.querySelector('.modulo-enem')) {
    CondicoesVigentesEnem();
  }
}