export const CxWhatsAppFloat = () => {
  const Acao = () => {
    let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;
    let botao = <HTMLElement>document.querySelector('.btnsfixos-ajuda');

    botao.classList.remove('btnsfixos-ajuda--ativo');
    if (valorScroll >= 800) {
      botao.classList.add('btnsfixos-ajuda--ativo');
    }
  }

  document.addEventListener("scroll", () => Acao());
  document.body.addEventListener("scroll", () => Acao());
}