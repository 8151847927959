const noticiasSlideOptions: JQuerySlickOptions = {
  variableWidth: true,
  swipeToSlide: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  infinite: true,
  dots: false,
  prevArrow: '<button type="button" class="noticias_eventos__item_seta noticias_eventos__item_seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
  nextArrow: '<button type="button" class="noticias_eventos__item_seta noticias_eventos__item_seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>',
  responsive: [{
    breakpoint: 992,
    settings: {
      slidesToShow: 1,
      touchMove: true,
      swipe: true,
    }
  }]
};

const eventosSlideOptions: JQuerySlickOptions = {
  variableWidth: true,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  dots: false,
  prevArrow: '<button type="button" class="noticias_eventos__item_seta noticias_eventos__item_seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
  nextArrow: '<button type="button" class="noticias_eventos__item_seta noticias_eventos__item_seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>',
  responsive: [{
    breakpoint: 992,
    settings: {
      slidesToShow: 1,
      touchMove: true,
      swipe: true,
    }
  }]
};

export const StartSliderNoticias = () => {
  $('.noticias_eventos__modulo .noticias_eventos__slider--noticias .noticias_eventos__itens').slick(noticiasSlideOptions);
};

export const StartSliderEventos = () => {
  $('.noticias_eventos__modulo .noticias_eventos__slider--eventos .noticias_eventos__itens').slick(eventosSlideOptions);
};