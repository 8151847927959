export const CalculadoraEnemValidar = (passoAtual, apresentarErro = true) => {
    // Faz a validação dos campos antes de passar para o próximo passo.

    let campos = document.querySelectorAll(
        `.calculadora-enem__passo[data-numero-passo="${passoAtual}"] input,
        .calculadora-enem__passo[data-numero-passo="${passoAtual}"] select
    `);
    let botaoAvancar = document.querySelector('.calculadora-enem__botao--avancar');

    if (![...campos].map(
        (item) => item.checkValidity()
    ).includes(false)) {
        botaoAvancar?.classList.remove('calculadora-enem__botao--inativo');
        return true;
    }

    botaoAvancar?.classList.add('calculadora-enem__botao--inativo');

    campos.forEach((campo) => {
        if (campo.checkValidity() === false) {
            if (campo.nodeName == 'SELECT' && campo.value !== '') {
                campo.classList.remove('calculadora-enem__input--invalido');
            }

            if (apresentarErro) {
                campo.classList.add('calculadora-enem__input--invalido');
            }
        } else {
            campo.classList.remove('calculadora-enem__input--invalido');
        }
    });

    return false;
}