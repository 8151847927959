export const AtivaAnimacaoRematriculaHeader = () => {
    let animacaoRematriculaHeader = setInterval(() => {
        let linkItem = <HTMLElement>document.querySelector('.lista-links-uteis #id1');

        if (linkItem?.dataset?.rematriculaAtiva == 'false') {
            clearInterval(animacaoRematriculaHeader);
            return;
        }

        let link = <HTMLElement | null>document.querySelector('.lista-linksuteis-link1');
        if (link?.textContent == 'Sou Aluno') {
            link.textContent = linkItem.dataset.rematriculaTexto ?? 'Rematrícula';
            linkItem?.classList.toggle('btnRematriculaTopo');
        } else {
            link && (link.textContent = 'Sou Aluno');
            linkItem?.classList.toggle('btnRematriculaTopo');
        }
    }, 1500);
}