export const TextoAbaLateral = () => {
    let elementos = [
        'convenio',
        'cursos',
        'bee',
        'palestras',
        'tour',
        'patrocinio'
    ];
    
    elementos.forEach((item) => {
        document.querySelector(`#i${item}`)?.addEventListener('click', () => {
            // ativando a opção clicada na lista
            elementos.forEach((elemento) => {
                if (elemento == item) {
                    document.querySelector(`#i${elemento}`)?.classList.add('act');
                } else {
                    document.querySelector(`#i${elemento}`)?.classList.remove('act');
                }
            });

            // ativando o conteudo da opção clicada na lista
            elementos.forEach((elemento) => {
                let nomeElemento = elemento[0].toUpperCase() + elemento.substring(1);
                let divConteudo = <HTMLElement>document.querySelector(`#b${nomeElemento}`);

                if (elemento == item) {
                    divConteudo.style.display = 'block';
                } else {
                    divConteudo.style.display = 'none';
                }
            });
        });
    });
}