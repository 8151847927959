import { SetCookie } from "../../../Utils/cookies";
import { CreateAjax } from "../../../Utils/createAjax";
import { DadoDeLead, EnviaLead } from "../../../Utils/enviaLead";
import { formaDeIngressoSf } from "../../../Utils/formaDeIngressoSf";
import { getCookie } from "../../../Utils/getCookie";
import { PdpSidebarAbrirInvestimento } from "./pdpSidebarAbrirInvestimento";
import { PdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas } from "./pdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas";
import { FormaIngresso, IrParaFichaDeInscricao } from "./pdpSidebarDadosFormaIngresso";
import { PdpSidebarPreencherDadosPessoais } from "./pdpSidebarPreencherDadosPessoais";
import { PdpSidebarValidarCampos } from "./pdpSidebarValidarCampos";

export const CriarLead = async (dados: DadoDeLead[] = []) => {
  const email = dados.find(item => item.name === 'email')?.value || '';
  const telefone = dados.find(item => item.name === 'telefone')?.value || '';
  const nome = dados.find(item => item.name === 'nome')?.value || '';
  const nonceNewLead = dados.find(item => item.name === 'nonce-new-lead')?.value || '';
  const nonceleadByEmail = dados.find(item => item.name === 'nonce-lead-by-email')?.value || '';
  const nomeSplit = nome.split(' ')
  const firstName = nomeSplit[0];
  const lastName = nomeSplit[1] || firstName;
  const dadosUrl = new URLSearchParams(location.search);

  const formEmail = new FormData();
  formEmail.append("email", email);
  formEmail.append("nonce", nonceleadByEmail);

  const tiposDePaginas = {
    "graduacao": "grad",
    "pos-graduacao": "pos",
    "cursos-tecnicos": "tec",
    "curso-tecnico": "tec",
    "cursos-tecnicos-e-profissionalizantes": "tec",
    "cursos-profissionalizantes": "prof",
    "curso-profissionalizante": "prof",
    "cursos-livres": "liv",
    "curso-livre": "liv",
  };

  formEmail.append("tipo", tiposDePaginas[window.location.pathname.split('/')[1]] || 'grad');
  formEmail.append("action", "leadByEmail");

  await CreateAjax(formEmail)
    .then(response => JSON.parse(response))
    .then(response => {
      if (response.LeadId) {
        sessionStorage.setItem('leadAtualId', response.LeadId);
        sessionStorage.setItem('leadAtualEmail', email);
      }
    });

  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  const identificadorIncrevaSeElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-inscreva-se"]');
  const identificadorVerInvestimentoElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-ver-investimento"]');
  let identificador: string = identificadorVerInvestimentoElemento.value;

  if (fluxo == 'InscrevaSe') {
    identificador = identificadorIncrevaSeElemento.value;
  }

  if (sessionStorage.getItem('leadAtualId') && !!!sessionStorage.getItem('leadAtualEmail')) {
    sessionStorage.setItem('leadAtualEmail', email);
  }

  if (
    !!!sessionStorage.getItem('leadAtualId') ||
    sessionStorage.getItem('leadAtualEmail') !== email
  ) {
    const form = new FormData();

    form.append("nonce", nonceNewLead);
    form.append("Email", email);
    form.append("FirstName", firstName);
    form.append("LastName", lastName);
    form.append("MobilePhone", telefone);
    form.append("Identificador__c", identificador);

    const dadosCookies = new URLSearchParams(getCookie('parsedQS'));

    form.append("Conteudo_da_Campanha__c", dadosUrl.get('utm_content') || dadosCookies.get('utm_content') || '');
    form.append("Meio__c", dadosUrl.get('utm_medium') || dadosCookies.get('utm_medium') || '(direct)');
    form.append("Nome_da_Campanha__c", dadosUrl.get('utm_campaign') || dadosCookies.get('utm_campaign') || '');
    form.append("Origem__c", dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)');
    form.append("Termo_da_Campanha__c", dadosUrl.get('utm_term') || dadosCookies.get('utm_term') || '');
    form.append('LeadSource', dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)');
    form.append('GCLID__c', dadosUrl.get('gclid') || dadosCookies.get('gclid') || '');

    form.append("tipo", tiposDePaginas[window.location.pathname.split('/')[1]] || 'grad');
    form.append("action", "newLead");

    await CreateAjax(form)
      .then(response => JSON.parse(response))
      .then(response => {
        if (response.LeadId) {
          sessionStorage.setItem('leadAtualId', response.LeadId);
          sessionStorage.setItem('leadAtualEmail', email);
        }
      });
  }

  const leadId = sessionStorage.getItem('leadAtualId');
  const dadosFormaIngresso = localStorage.getItem('dadosFormaIngresso');

  if (dadosFormaIngresso) {
    let dadosFormaIngressoJson = JSON.parse(dadosFormaIngresso);
    dadosFormaIngressoJson.forEach((elemento: FormaIngresso, index) => {
      if (elemento.url.includes('IdLead=')) {
        const idLead = elemento.url.split('IdLead=')[1];
        if (idLead == '') {
          dadosFormaIngressoJson[index].url = elemento.url.replace('IdLead=', `IdLead=${leadId}`);
        };
      }
    });

    localStorage.setItem('dadosFormaIngresso', JSON.stringify(dadosFormaIngressoJson));
  }

  return;
};

export const SetDadosLeadAtual = () => {
  const camposDadosLeads = document.querySelectorAll(
    `.sidebar-pdp input, .sidebar-pdp select`
  ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;

  let leadAtual = sessionStorage.getItem('leadAtual') || '[]';
  let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

  camposDadosLeads.forEach((elemento) => {
    const elementoExistente = dadosLead.find(dado => dado.name == elemento.name);

    if (elementoExistente) {
      elementoExistente.value = elemento.value;
    } else if (elemento.value != '') {
      dadosLead.push({
        name: elemento.name,
        value: elemento.value
      });
    }
  });

  const tipoPdp = window.location.pathname.split('/')[1];
  let stat = 'sendFormPricePDP';

  switch (tipoPdp) {
    case 'pos-graduacao':
      stat = 'sendFormPricePDPPosVirtual';
      break;
    case 'cursos-livres':
    case 'extensao':
      stat = 'sendFormPricePDPExtensao';
      break;
  }

  dadosLead.push({
    name: 'stat',
    value: stat
  });

  const modalidade = <HTMLInputElement>document
    .querySelector('.sidebar-pdp input[name="modalidade"]');

  dadosLead.push({
    name: 'modalidade',
    value: modalidade.value
  });

  dadosLead.forEach((dadoLead, index) => {
    if (dadoLead.name === 'polo') {
      dadosLead.push({
        name: 'polo_origem',
        value: `${(dadoLead.value ?? 0)}POLO`
      });
    }

    if (dadoLead.name === 'pais') {
      dadosLead.push({
        name: 'Pais',
        value: dadoLead.value
      });

      dadosLead.splice(index, 1);
    }
  });

  let aFormaDeIngresso = formaDeIngressoSf();
  let formaDeIngressoIdArray = dadosLead.map((item, index) => {
    if (item.name == 'forma-ingresso') {
      return index
    }

    return ''
  }).filter(String);

  if (formaDeIngressoIdArray.length > 0) {
    let formaDeIngressoId = formaDeIngressoIdArray[0];

    dadosLead[formaDeIngressoId] = {
      name: 'forma-ingresso',
      value: aFormaDeIngresso
    };
  }

  sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
  SetCookie('leadAtual', JSON.stringify(dadosLead), 1);
}

export const PdpSidebarDePara = (
  telaAtual: string,
  proximaTelaInscrevaSe: string,
  proximaTelaVerInvestimento: string
) => {
  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  let elementosTitulo = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-tela__titulo');
  let sidebarPdp = <HTMLElement>document.querySelector('.sidebar-pdp');

  let proximaTela = proximaTelaVerInvestimento;
  let titulo = 'Investimento';

  if (fluxo == 'InscrevaSe') {
    proximaTela = proximaTelaInscrevaSe;
    titulo = 'Inscreva-se';

    sidebarPdp.classList.remove('sidebar-pdp--ver-investimento');
    sidebarPdp.classList.add('sidebar-pdp--inscreva-se');
  } else {
    sidebarPdp.classList.add('sidebar-pdp--ver-investimento');
    sidebarPdp.classList.remove('sidebar-pdp--inscreva-se');
  }

  elementosTitulo.forEach((elemento: HTMLElement) => {
    elemento.textContent = titulo;
  });

  if (
    telaAtual == 'um' &&
    proximaTela == 'dois'
  ) {
    if (fluxo == 'VerInvestimento') {
      proximaTela = PdpSidebarPreencherDadosPessoais(proximaTela);
    } else {
      PdpSidebarPreencherDadosPessoais(proximaTela);
    }

    PdpSidebarValidarCampos(proximaTela, false);
  }

  if (telaAtual == 'dois') {
    PdpSidebarPreencherDadosPessoais(proximaTela);
  }

  document.querySelector(`.sidebar-pdp-tela-${telaAtual}`)?.classList.remove('sidebar-pdp-tela--ativo');
  document.querySelector(`.sidebar-pdp-tela-${proximaTela}`)?.classList.add('sidebar-pdp-tela--ativo');

  let botaoProximo = <HTMLElement | null>document.querySelector(`.sidebar-pdp-tela__proximo[data-tela-atual="${proximaTela}"]`);
  botaoProximo && (botaoProximo.id = botaoProximo.dataset[`idTela${fluxo}`] || '');

  if (telaAtual == 'um') {
    let camposUtm = <NodeListOf<HTMLInputElement>>document.querySelectorAll('.sidebar-form-investimento input[name="utm_source"], .sidebar-form-investimento input[name="utm_campaign"], .sidebar-form-investimento input[name="utm_medium"], .sidebar-form-investimento input[name="utm_content"], .sidebar-form-investimento input[name="utm_term"], .sidebar-form-investimento input[name="gclid"], .sidebar-pdp input[name="curso"]');

    let dadosLead: DadoDeLead[] = JSON.parse(sessionStorage.getItem('leadAtual') || '[]');

    camposUtm.forEach((elemento) => {
      let dadoLead: DadoDeLead = {
        name: elemento.name,
        value: elemento.value
      }
      dadosLead.push(dadoLead);
    });

    sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
    SetCookie('leadAtual', JSON.stringify(dadosLead), 1);
  }

  let dominioIes = window.location.host.split('.').find(item => item != 'com' && item != 'br' && item != 'edu' && item != 'www' && item != 'homologa2019');

  if (telaAtual !== 'um') {
    if (
      (dominioIes == 'cruzeirodosulvirtual' || dominioIes == 'localhost:8014')
      && telaAtual != 'dois'
    ) {
      PdpSidebarValidarCampos(proximaTela, false);

      let camposDadosLeads = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
        .querySelectorAll(
          `.sidebar-pdp-tela-${telaAtual} input,
          .sidebar-pdp-tela-${telaAtual} select,
          .sidebar-pdp-tela-dois input`);

      let leadAtual = sessionStorage.getItem('leadAtual') || '[]';
      let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

      camposDadosLeads.forEach((elemento) => {
        let elementoExistente = dadosLead.find(dado => dado.name == elemento.name);

        if (elementoExistente) {
          elementoExistente.value = elemento.value;
        }

        if (!elementoExistente) {
          let dadoLead: DadoDeLead = {
            name: elemento.name,
            value: elemento.value
          }

          dadosLead.push(dadoLead);
        }
      });

      let tipoPdp = window.location.pathname.split('/')[1];
      let stat = 'sendFormPricePDP';
      switch (tipoPdp) {
        case 'graduacao':
          stat = 'sendFormPricePDP';
          break;
        case 'pos-graduacao':
          stat = 'sendFormPricePDPPosVirtual';
          break;
        case 'cursos-livres' || 'extensao':
          stat = 'sendFormPricePDPExtensao';
          break;
        default:
          stat = 'sendFormPricePDP';
      }

      dadosLead.push({
        name: 'stat',
        value: stat
      });

      sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
      SetCookie('leadAtual', JSON.stringify(dadosLead), 1);

      let identificadorIncrevaSeElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-inscreva-se"]');
      let identificadorVerInvestimentoElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-ver-investimento"]');
      let identificador: string = identificadorVerInvestimentoElemento.value;

      if (fluxo == 'InscrevaSe') {
        identificador = identificadorIncrevaSeElemento.value;
      }

      let modalidade = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="modalidade"]');
      dadosLead.push({
        name: 'modalidade',
        value: modalidade.value
      });

      dadosLead.forEach((dadoLead, index) => {
        if (dadoLead.name == 'polo') {
          dadosLead.push({
            name: 'polo_origem',
            value: `${(dadoLead.value ?? 0)}POLO`
          });
        }

        if (dadoLead.name == 'pais') {
          dadosLead.push({
            name: 'Pais',
            value: dadoLead.value
          });

          dadosLead.splice(index, 1);
        }
      });
    } else if (dominioIes != 'cruzeirodosulvirtual' && dominioIes != 'localhost:8014') {
      PdpSidebarValidarCampos(proximaTela, false);

      let camposDadosLeads = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
        .querySelectorAll(
          `.sidebar-pdp-tela-${telaAtual} input,
          .sidebar-pdp-tela-${telaAtual} select`);

      let leadAtual = sessionStorage.getItem('leadAtual') || '[]';
      let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

      camposDadosLeads.forEach((elemento) => {
        let elementoExistente = dadosLead.find(dado => dado.name == elemento.name);

        if (elementoExistente) {
          elementoExistente.value = elemento.value;
        }

        if (!elementoExistente) {
          let dadoLead: DadoDeLead = {
            name: elemento.name,
            value: elemento.value
          }

          dadosLead.push(dadoLead);
        }
      });

      let tipoPdp = window.location.pathname.split('/')[1];
      let stat = 'sendFormPricePDP';
      switch (tipoPdp) {
        case 'graduacao':
          stat = 'sendFormPricePDP';
          break;
        case 'pos-graduacao':
          stat = 'sendFormPricePDPPosVirtual';
          break;
        case 'cursos-livres' || 'extensao':
          stat = 'sendFormPricePDPExtensao';
          break;
        default:
          stat = 'sendFormPricePDP';
      }

      dadosLead.push({
        name: 'stat',
        value: stat
      });

      sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
      SetCookie('leadAtual', JSON.stringify(dadosLead), 1);

      let identificadorIncrevaSeElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-inscreva-se"]');
      let identificadorVerInvestimentoElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-ver-investimento"]');
      let identificador: string = identificadorVerInvestimentoElemento.value;

      if (fluxo == 'InscrevaSe') {
        identificador = identificadorIncrevaSeElemento.value;
      }

      let modalidade = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="modalidade"]');
      dadosLead.push({
        name: 'modalidade',
        value: modalidade.value
      });

      dadosLead.forEach((dadoLead, index) => {
        if (dadoLead.name == 'pais') {
          dadosLead.push({
            name: 'Pais',
            value: dadoLead.value
          });

          dadosLead.splice(index, 1);
        }
      });
    }
  }

  if (telaAtual == 'dois') {
    SetDadosLeadAtual();
    CriarLead(JSON.parse(sessionStorage.getItem('leadAtual') || '[]'))
      .then(() => {
        if (proximaTela == 'loading') {
          PdpSidebarDePara('loading', 'quase-la', '');
        }
      });
  }

  if (proximaTela == 'quase-la') {
    IrParaFichaDeInscricao();
  }

  if (
    proximaTela == 'investimento-graduacao' ||
    proximaTela == 'cinco'
  ) {
    let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
    let identificadorIncrevaSeElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-inscreva-se"]');
    let identificadorVerInvestimentoElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-ver-investimento"]');
    let identificador: string = identificadorVerInvestimentoElemento.value;

    SetDadosLeadAtual();
    const leadAtual = sessionStorage.getItem('leadAtual') || '[]';
    const dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

    if (fluxo == 'InscrevaSe') {
      identificador = identificadorIncrevaSeElemento.value;
    }

    EnviaLead(
      identificador,
      'valor-curso',
      dadosLead
    );
  }

  if (proximaTela == 'cinco') {
    setTimeout(() => PdpSidebarAbrirInvestimento(), 1000);
  }

  if (proximaTela == 'investimento-graduacao') {
    PdpSidebarAbrirInvestimento();
    setTimeout(() => PdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas(), 1000);
  }

  if (screen.width <= 992) {
    document.body.style.overflowY = 'hidden';

    if (telaAtual === 'um') {
      document.querySelector(`.sidebar-pdp-tela-${telaAtual}`)?.classList.add('sidebar-pdp-tela--ativo');
    }

    if (proximaTela == 'um') {
      document.body.style.overflowY = '';
    }
  }
}
