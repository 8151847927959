import { ModalCalculadoraEnemNovoAtivarBotaoAvancar, ModalCalculadoraEnemNovoMudarPassosTitulo } from "./ModalCalculadoraEnemNovoMudarPassos";

export const ModalCalculadoraEnemNovoAlterarCurso = () => {
    let divPassoAtivo = <HTMLDivElement>document.querySelector('.calculadora-enem__passo--ativo');
    let divPassoAlterarCurso = <HTMLDivElement>document.querySelector(`.calculadora-enem__passo[data-numero-passo="1"]`);
    let botaoAvancar = <HTMLButtonElement>document.querySelector('.modal-calculadora-enem .calculadora-enem__botao--avancar');

    if (!divPassoAlterarCurso) {
        return;
    }

    divPassoAtivo.classList.remove('calculadora-enem__passo--ativo');
    divPassoAlterarCurso.classList.add('calculadora-enem__passo--ativo');

    if (window.screen.width <= 992) {
        document.querySelector('.modal-calculadora-enem__box:last-of-type')?.classList.remove('modal-calculadora-enem__box--ativo');
    }

    document.querySelector('.calculadora-enem__botoes')?.classList.remove('calculadora-enem__botoes--oculto');
    document.querySelector('.modal-calculadora-enem__resultado')?.classList.add('modal-calculadora-enem__resultado--opaco');
    botaoAvancar.textContent = 'Alterar';
    
    ModalCalculadoraEnemNovoMudarPassosTitulo(1);
    ModalCalculadoraEnemNovoAtivarBotaoAvancar(1);

    ModalCalculadoraEnemNovoEstiloInicialBoxValores();
}

const ModalCalculadoraEnemNovoEstiloInicialBoxValores = () => {
    let escolhaTrocarCurso = document.querySelector('.calculadora-enem__informacoes-escolha');
    let valores = document.querySelector('.calculadora-enem__valores');
    let tituloNota = document.querySelector('.calculadora-enem__titulo--nota');
    let informacaoNota = document.querySelector('.calculadora-enem__informacao--nota');

    escolhaTrocarCurso?.classList.remove('calculadora-enem__informacoes-escolha--ativo');
    valores?.classList.remove('calculadora-enem__valores--ativo');
    tituloNota?.classList.add('calculadora-enem__titulo--nota-inativa');
    informacaoNota?.classList.add('calculadora-enem__informacao--nota-ativa');
}