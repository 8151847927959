export const InscricoesProcesso = () => {

  //Slider Cards Grade Ensino EAD
  if (window.innerWidth < 900) {
    slickGradeEnsinoCompleta();
  }

  window.addEventListener('resize', () => {
    if (window.innerWidth < 768) {
      slickGradeEnsinoCompleta();
    } else {
      $('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta').slick('unslick');
    }
  });
  function slickGradeEnsinoCompleta() {
    if (document.querySelectorAll('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta').length > 0) {
      let cardsGradeEnsino = document.querySelector('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta');
      if (!cardsGradeEnsino?.classList.contains('slick-initialized')) {
        $('#gradeEnsinoCompleta .cardsGradeEnsinoCompleta').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: true,
              },
            },
          ],
        });
      }
    }
  }
}