const conteSuaHistoriaSimples: JQuerySlickOptions = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 1500,
  prevArrow: '<button class="slick-prev slick-arrow" type="button"></button>',
  nextArrow: '<button class="slick-next slick-arrow" type="button"></button>',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false,
        slidesToShow: 1,
        touchMove: true,
        swipe: true,
        swipeToSlide: true,
      }
    }
  ]
};

export const StartSliderConteSuaHistoriaSimples = () => {
  $('.conte_sua_historia_simples__modulo .conte_sua_historia_simples__depoimentos').slick(conteSuaHistoriaSimples);
};

export const DesativarContinuarLendo = () => {
  let textosDepoimento = document.querySelectorAll('.conte_sua_historia_simples__modulo .conteudo__texto');
  let continuarLendoDepoimento: NodeListOf<HTMLElement> = document.querySelectorAll('.conte_sua_historia_simples__modulo .conteudo__ler-mais');

  for (let index = 0; index < textosDepoimento.length; index++) {
    if (textosDepoimento[index].clientHeight == textosDepoimento[index].scrollHeight) {
      continuarLendoDepoimento[index].style.display = 'none';
    }
  }
};