export const TooltipTelaInvestimentoGraduacao = () => {
  const tooltips = <NodeListOf<HTMLElement>>document.querySelectorAll('.pdp-novos-carrinhos .plano__conteudo-valor-para-tooltip');
  const conteudos = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-tela__conteudo');

  const acaoTooltipMouseOver = (evento: Event) => {
    let planoAtivo = <HTMLElement | null>document.querySelector('.pdp-novos-carrinhos .slider-planos .plano.slick-active');
    let planoAtivoHeight = planoAtivo?.offsetHeight;
    let elementoRoot = <HTMLElement>document.querySelector(':root');
    let elementoClicado = <HTMLElement>evento.target;

    elementoRoot.style.setProperty('--tooltip-conteudo-border', '8px 0 8px 8px');
    elementoRoot.style.setProperty('--tooltip-conteudo-margin', '25px 0 0 -170px');
    elementoRoot.style.setProperty('--tooltip-conteudo-transform', '0%');

    if (
      planoAtivoHeight &&
      planoAtivoHeight < 290
    ) {
      elementoRoot.style.setProperty('--tooltip-conteudo-border', '8px 8px 0 8px');
      elementoRoot.style.setProperty('--tooltip-conteudo-margin', '-10px 0 0 -170px');
      elementoRoot.style.setProperty('--tooltip-conteudo-transform', '-100%');
    }

    let tooltipMobileGraduacao = <HTMLElement | null>document
      .querySelector('.pdp-novos-carrinhos-tipo--graduacao-base .plano__conteudo-valor-para-tooltip--conteudo-mobile');

    if (tooltipMobileGraduacao) {
      if (screen.width <= 992) {
        tooltipMobileGraduacao.innerHTML = elementoClicado.dataset.textoTootip || '';
        tooltipMobileGraduacao.style.display = 'block';
        elementoRoot.style.setProperty('--tooltip-conteudo-margin', '26px 0 0 25px');

        if (planoAtivoHeight && planoAtivoHeight < 290) {
          elementoRoot.style.setProperty('--tooltip-conteudo-margin', '-10px 0 0 25px');
        }
      }
    }
  }

  const acaoTooltipMouseOut = (evento: Event) => {
    let tooltipMobileGraduacao = <HTMLElement | null>document
      .querySelector('.pdp-novos-carrinhos-tipo--graduacao-base .plano__conteudo-valor-para-tooltip--conteudo-mobile');

    if (tooltipMobileGraduacao) {
      tooltipMobileGraduacao.style.display = 'none';
    }
  }

  tooltips.forEach(tooltip => {
    tooltip.addEventListener('mouseover', (evento) => acaoTooltipMouseOver(evento));
    tooltip.addEventListener('mouseout', (evento) => acaoTooltipMouseOut(evento));
  });

  const acaoConteudo = () => {
    let tooltipMobileGraduacao = <HTMLElement | null>document
      .querySelector('.pdp-novos-carrinhos-tipo--graduacao-base .plano__conteudo-valor-para-tooltip--conteudo-mobile');

    if (tooltipMobileGraduacao) {
      let tooltip = <HTMLElement | null>document.querySelector('.pdp-novos-carrinhos .slider-planos .plano.slick-active .plano__conteudo-valor-para-tooltip');
      tooltipMobileGraduacao.style.top = `${(tooltip?.getBoundingClientRect().y || 0)}px`;
    }
  }

  conteudos.forEach(conteudo => {
    conteudo.addEventListener('scroll', () => acaoConteudo());
  })
}