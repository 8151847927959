const containerConteudo: HTMLElement = <HTMLElement>document.querySelector('.container-conteudo-pdp');
const sideBar: HTMLElement = <HTMLElement>document.querySelector('.sidebar-pdp');

const AbrirVideoCoordenador = () => {
  document.querySelector('.pdp-sessao__informacoes-coordenador .video')
    ?.addEventListener('click', () => {
      document.body.style.overflowY = document.body.style.overflowY == 'hidden' ? '' : 'hidden';
      containerConteudo.style.zIndex = (containerConteudo.style.zIndex == '1' || containerConteudo.style.zIndex == '') ? '9999' : '1';
      sideBar.style.zIndex = (sideBar.style.zIndex == '-1') ? 'unset' : '-1';
      document.querySelector('.modal__video-coordenador')?.classList.toggle('ativo');

      let headerFixo = <HTMLElement | null>document.querySelector('header.header.header-topo.fixed');
      headerFixo && (headerFixo.style.zIndex = '9999');
    });

  document.querySelector('.modal__video-coordenador')
    ?.addEventListener('click', () => {
      document.body.style.overflowY = document.body.style.overflowY == 'hidden' ? '' : 'hidden';
      containerConteudo.style.zIndex = (containerConteudo.style.zIndex == '1' || containerConteudo.style.zIndex == '') ? '9999' : '1';
      sideBar.style.zIndex = (sideBar.style.zIndex == '-1') ? 'unset' : '-1';
      document.querySelector('.modal__video-coordenador')?.classList.toggle('ativo');

      let headerFixo = <HTMLElement | null>document.querySelector('header.header.header-topo.fixed');
      headerFixo && (headerFixo.style.zIndex = '');
    });
};

export default AbrirVideoCoordenador;
