import { DataLayerCreateInteracaoFormulario } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerTelaQuatro = () => {
  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  let fluxoEventLabel = 'ver-investimento';

  if (fluxo == 'InscrevaSe') {
    fluxoEventLabel = 'inscricao';
  }

  const AcaoFormulario = (evento: Event) => {
    const elemento = <HTMLInputElement>evento.target;
    DataLayerCreateInteracaoFormulario(`${fluxoEventLabel}:${elemento.name}`);
  }

  const seletores = <NodeListOf<HTMLInputElement>>document
    .querySelectorAll('.sidebar-pdp-tela-quatro .sidebar-pdp-tela__campo-select');

  seletores.forEach((elemento: HTMLInputElement) => elemento
    .addEventListener('blur', (evento: Event) => AcaoFormulario(evento)));
}