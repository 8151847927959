import { CreateAjax } from "../../Utils/createAjax";

type CursoSugestivo = {
  nomeCurso: string;
  link: string;
}

/**
 * Cria elementos li e a que ser o preenchidos com as informações de cursos retornadas pela API.
 * @param {CursoSugestivo[]} cursos Informações de cursos a serem adicionadas na lista.
 * @param {HTMLUListElement} lista Elemento ul onde os cursos ser o adicionados.
 */
export const criarOpcoesEmLista = (cursos: CursoSugestivo[], lista: HTMLUListElement) => {
  cursos.forEach((curso) => {
    let a: HTMLAnchorElement = document.createElement('a');
    a.setAttribute('href', '/graduacao/' + curso.link);
    a.setAttribute('target', '_self');
    a.textContent = curso.nomeCurso;

    let li: HTMLLIElement = document.createElement('li');
    li.appendChild(a);

    lista.appendChild(li);
  });

  return lista;
}

/**
 * Cria elementos li e retorna para gerar lista
 * @param {string} texto Informações de cursos a serem adicionadas na lista.
 */
export const criarItemLista = (texto: string) => {
  let li = document.createElement('li');
  li.textContent = texto;

  return li;
}

/**
 * Função que inicializa o campo de busca com todos os listeners para funcionamento
 * @returns {void}
 */
export const campoBusca = () => {

  document.querySelectorAll('input[name="s"]').forEach((elemento) => {
    /**
     * Event listener para quando perder o foco, não mostrar mais autocomplete
     */
    elemento.addEventListener('blur', () => {
      const autocomplete: HTMLElement | null = document.querySelector('#autocomplete');
      if (autocomplete) {
        autocomplete.style.display = 'none';
      }
    });

    /**
     * Event listener para buscar resultado prévio de pesquisa sem precisa submitar campo
     */
    elemento.addEventListener('keyup', (evento) => {
      type InformacoesCursos = {
        grad: CursoSugestivo[],
        ext: CursoSugestivo[],
        pos: CursoSugestivo[]
      };

      const campoBusca = evento.currentTarget as HTMLInputElement;
      const termoBusca = campoBusca.value;

      // Caso não tenha 4 caracteres ou mais no campo de pesquisa, bloquear busca
      if (termoBusca.length < 4) {
        return;
      }

      const nonceBusca: HTMLInputElement | null = document.querySelector('#wp_nonce_search_autocomplete');

      let body = new FormData();
      body.append('termo', termoBusca);
      body.append('action', 'searchAutoComplete');
      body.append('nonce', nonceBusca?.value.toString() ?? '');

      const requisicao = CreateAjax(body);
      requisicao
        .then((dados: InformacoesCursos) => {
          let ul = document.createElement('ul');

          // População de elementos para mostrar no autocomplete
          const cursosGraduacao: CursoSugestivo[] = dados.grad;
          if (cursosGraduacao.length > 0) {
            let li = criarItemLista('Cursos de Graduação');

            ul.appendChild(li);
            ul = criarOpcoesEmLista(cursosGraduacao, ul);
          }

          const cursosPosGraduacao: CursoSugestivo[] = dados.pos;
          if (cursosPosGraduacao.length > 0) {
            let li = criarItemLista('Cursos de Pós-Graduação');

            ul.appendChild(li);
            ul = criarOpcoesEmLista(cursosPosGraduacao, ul);
          }

          const cursosLivres: CursoSugestivo[] = dados.ext;
          if (cursosLivres.length > 0) {
            let li = criarItemLista('Cursos Livres');

            ul.appendChild(li);
            ul = criarOpcoesEmLista(cursosLivres, ul);
          }

          // Inclusão de sugestões no autocomplete e exibição
          const autocomplete: HTMLElement | null = document.querySelector('#autocomplete');
          if (autocomplete) {
            autocomplete.innerHTML = '';
            autocomplete?.append(ul);
            autocomplete.style.display = 'block';
          }
        });
    });
  });
}