import { SetCookie } from "../../Utils/cookies";
import { CreateAjax } from "../../Utils/createAjax";
import { DadoDeLead } from "../../Utils/enviaLead";
import TesteVocacionalCriarLead from "./testeVocacionalCriarLead";
import { TesteVocacionalTelasPerguntas } from "./testeVocacionalTelas";

const TesteVocacionalFormularioValidacao = (formularioDados: FormData, campos: string[]) => {
  const camposRegex = {
    "email": /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    "celular": /^\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}/,
    "identificador": /./
  }

  let validado = true;
  let mensagemErro = '';

  const elementoUf = <HTMLSelectElement | null>document.querySelector('.modulo-teste-vocacional #uf');
  const elementoCidade = <HTMLSelectElement | null>document.querySelector('.modulo-teste-vocacional #cidade');

  if (
    elementoUf !== null &&
    (elementoUf.value == '' || elementoUf.value == null)
  ) {
    validado = false;
    mensagemErro = `O campo uf/estado não foi preenchido corretamente.`;
  }

  if (
    elementoCidade !== null &&
    (elementoCidade.value == '' || elementoCidade.value == null)
  ) {
    validado = false;
    mensagemErro = `O campo cidade não foi preenchido corretamente.`;
  }

  campos.forEach((nome) => {
    if (
      nome !== 'cidade' &&
      nome !== 'uf' &&
      !(camposRegex[nome] || /^[a-zA-Z\s]+$/).test(formularioDados.get(nome)?.toString() || '')
    ) {
      validado = false;
      mensagemErro = `O campo ${nome} não foi preenchido corretamente.`;
    }
  });

  return {
    validado,
    mensagemErro
  }
}

const TesteVocacionalFormularioSubmit = (modulo: HTMLElement, formulario: HTMLFormElement) => {
  const mensagem = <HTMLParagraphElement>modulo.querySelector('.inputs .mensagem');
  const iniciaFormulario = <HTMLButtonElement>modulo.querySelector('.inicia-formulario');
  const formularioDados = new FormData(formulario);

  const camposKeys: string[] = [];
  for (const key of formularioDados.keys()) {
    camposKeys.push(key);
  }

  const formularioValidacao = TesteVocacionalFormularioValidacao(formularioDados, camposKeys);
  const validado = formularioValidacao['validado'];
  const mensagemErro = formularioValidacao['mensagemErro'];

  if (!validado) {
    mensagem.innerHTML = mensagemErro;
    return;
  }

  iniciaFormulario.innerHTML = "Fazer o teste";
  let leadAtual = sessionStorage.getItem('leadAtual') || '[]';
  let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

  let campos = [
    // @ts-ignore: Unreachable code error
    { name: "nonce-new-lead", value: LeadAction.newLead },
    // @ts-ignore: Unreachable code error
    { name: "nonce-lead-by-email", value: LeadAction.leadByEmail },
  ];

  camposKeys.forEach((nome) => {
    const nomeCampo = nome;
    const valorCampo = formularioDados.get(nomeCampo);
    campos.push({ name: nomeCampo, value: valorCampo });
  });

  campos.forEach((elemento) => {
    let elementoExistente = dadosLead.find(dado => dado.name == elemento.name);

    if (elementoExistente) {
      elementoExistente.value = elemento.value;
    }

    if (!elementoExistente) {
      let dadoLead: DadoDeLead = { name: elemento.name, value: elemento.value };
      dadosLead.push(dadoLead);
    }
  });

  sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
  SetCookie('leadAtual', JSON.stringify(dadosLead), 1);
  TesteVocacionalCriarLead(JSON.parse(sessionStorage.getItem('leadAtual') || '[]'));
  TesteVocacionalTelasPerguntas(modulo);
}

const MascaraDeTelefone = (telefone: string): string => {
  return telefone.replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{5})(\d{4})/, '$1-$2')
    .replace(/(\d{4})(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}

const TesteVocacionalFormularioMascaraCelular = (modulo: HTMLElement) => {
  const inputCelular = <HTMLInputElement | null>modulo.querySelector('#celular');

  inputCelular?.addEventListener('input', (event: Event) => {
    const campo = <HTMLInputElement>event.target;
    campo.value = MascaraDeTelefone(campo.value);
  })
}

const TesteVocacionalFormularioGetCidades = (modulo: HTMLElement) => {
  const seletorDeEstado = <HTMLSelectElement | null>modulo.querySelector('select[name=uf]');
  const seletorDeCidade = <HTMLSelectElement | null>modulo.querySelector('select[name=cidade]');

  seletorDeEstado?.addEventListener('change', () => {
    const estadoSelecionado = seletorDeEstado.value.replace(/[<>\/\\;]|--/g, '').trim();
    const dados = new FormData();

    dados.append('action', 'getCidades');
    dados.append('estado', estadoSelecionado);
    // @ts-ignore: Unreachable code error
    dados.append('nonce', TV.getCidadesN);

    if (seletorDeCidade) {
      seletorDeCidade.innerHTML = '<option value="" selected disabled>Carregando cidades...</option>';
    }

    CreateAjax(dados)
      .then((retorno) => {
        if (seletorDeCidade) {
          seletorDeCidade.innerHTML = '<option value="" selected disabled>Cidades</option>';
          retorno.forEach((item) => seletorDeCidade.innerHTML += `<option value="${item.nome}">${item.nome}</option>`);
        }
      })
      .catch(() => null);
  });
}

const TesteVocacionalResetMensagemErro = (modulo: HTMLElement) => modulo.querySelectorAll('input, select')
  .forEach(item => item.addEventListener('change', () => {
    const mensagem = <HTMLParagraphElement>modulo.querySelector('.inputs .mensagem');
    const elementoMensagem = <HTMLParagraphElement>modulo.querySelector('.segundo-formulario .mensagem');

    mensagem.textContent = '';
    elementoMensagem.style.display = 'none';
    elementoMensagem.textContent = '';
  }));

const TesteVocacionalFormulario = (modulo: HTMLElement) => {
  const formulario = <HTMLFormElement>modulo.querySelector('.formulario');

  formulario.addEventListener('submit', (event: SubmitEvent) => {
    event.preventDefault();
    TesteVocacionalFormularioSubmit(modulo, formulario);
  });

  TesteVocacionalFormularioMascaraCelular(modulo);
  TesteVocacionalFormularioGetCidades(modulo);
  TesteVocacionalResetMensagemErro(modulo);
}

export default TesteVocacionalFormulario;