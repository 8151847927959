import { FormaIngresso } from "../Modulos/Pdp/Sidebar/pdpSidebarDadosFormaIngresso";
import { SetCookie } from "./cookies";
import { DadoDeLead } from "./enviaLead";

const dominioIes = window.location.host.split('.').find(item => item != 'com' && item != 'br' && item != 'edu' && item != 'www' && item != 'homologa2019');
const formaIngressoSf18 = (dominioIes == 'cruzeirodosulvirtual' || dominioIes == 'localhost:8014') ? 'Pós-Graduação Online' : 'Pós-Graduação';

const formaIngressoSf = {
  '19': 'Curso Livre',
  '15': 'Técnico',
  '32': 'ENCCEJA',
  '3': 'ENEM',
  '4': 'ENEM / PROUNI',
  '12': 'R2',
  '39': 'Histórico Escolar',
  '28': 'ENEM Medicina',
  '40': 'Segunda Graduação Medicina',
  '36': 'Transferência Medicina',
  '14': 'Medicina',
  '-2': '',
  '17': 'Pedagogia Licenciados',
  '11': 'Vestibular Múltipla Escolha',
  '27': 'Vestibular Redação',
  '1': 'Vestibular Mérito',
  '18': formaIngressoSf18,
  '26': '',
  '5': 'Segunda Graduação',
  '25': 'Segunda Graduação 2.0',
  '6': 'Transferência',
  '-1': '',
};

const formaIngressoSfPath = {
  'cursos-livres': 'Curso Livre',
  'extensao': 'Curso Livre',
  'cursos-tecnicos': 'Técnico',
  'pos-graduacao': formaIngressoSf18,
};

export const formaDeIngressoSf = (): string => {
  const campoFormaIngressoSf: HTMLInputElement | null = document.querySelector('input[name=forma-ingresso]');

  const formasIngresso: FormaIngresso[] = JSON.parse(localStorage.getItem('dadosFormaIngresso') || '[]');
  const formaDeIngressoSelecionadaElemento: HTMLSelectElement | null = document.querySelector('.sidebar-pdp-tela__campo-select[name=forma-ingresso]');
  const formaDeIngressoSelecionada = formaDeIngressoSelecionadaElemento?.value || '';
  const formaDeIngresso: FormaIngresso | undefined = formasIngresso.find(item => item.nome === formaDeIngressoSelecionada);

  if (campoFormaIngressoSf) {
    campoFormaIngressoSf.value = '';

    if (formaDeIngresso) {
      let valorFormaIngresso = formaIngressoSf[formaDeIngresso.tipoFormaIngresso] || '';
      campoFormaIngressoSf.value = valorFormaIngresso;
      return valorFormaIngresso;
    }
  }

  return '';
}

const GetFormaIngressoSf = () => {
  const path = location.pathname.split('/').filter(String)[0];

  if (path !== 'processo-seletivo') {
    return formaIngressoSfPath[path] || '';
  }

  const elementoCodigoFormaIngresso = <HTMLDataElement | null>document.querySelector('#codigo-forma-ingresso');
  const codigoFormaIngresso = elementoCodigoFormaIngresso?.value || '';
  return formaIngressoSf[codigoFormaIngresso] || '';
}

export const SetFormaIngresso = (formaIngressoDefault = '') => {
  const leadAtual = sessionStorage.getItem('leadAtual') || '[]';
  const dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

  let idDosItens = dadosLead
    .map((e, i) => (e !== null && e.name === 'forma-ingresso') ? i : '')
    .filter(String);

  idDosItens.pop();
  idDosItens.forEach(element => {
    delete dadosLead[element];
  });

  const formaIngresso = (formaIngressoDefault === '') ? GetFormaIngressoSf() : formaIngressoDefault;

  const idItem = dadosLead
    .map((e, i) => (e !== null && e.name === 'forma-ingresso') ? i : '')
    .filter(String)[0];

  if (formaIngresso !== '') {
    if (idItem) {
      dadosLead[idItem].value = formaIngresso;
    } else {
      dadosLead.push({ name: 'forma-ingresso', value: formaIngresso });
    }
  }

  let leadAtualAtualizado = JSON.stringify(dadosLead).replace('null,', '').replace('null', '');

  sessionStorage.setItem('leadAtual', leadAtualAtualizado);
  SetCookie('leadAtual', leadAtualAtualizado, 1);
}
