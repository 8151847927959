export const Modalidades = () => {
  document.querySelectorAll('.modalidades__box-explore-cursos')?.forEach((elemento) => {
    elemento.addEventListener('click', (evento: Event) => {
      if (screen.width <= 992) {
        let seta = <HTMLElement>evento.target;
        let links = <HTMLElement>evento.target?.nextElementSibling;

        links.classList.toggle('modalidades__opcoes-cursos--ativo');
        seta?.classList.toggle('modalidades__box-explore-cursos--ativo');
      }
    });
  });
}