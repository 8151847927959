import TesteVocacionalFormulario from "./testeVocacionalFormulario";
import TesteVocacionalPerguntas from "./testeVocacionalPerguntas";
import TesteVocacionalResultados from "./testeVocacionalResultados";
import TesteVocacionalSegundoFormulario from "./testeVocacionalSegundoFormulario";
import TesteVocacionalSlider from "./testeVocacionalSlider";
import { TesteVocacionalTelasFormulario } from "./testeVocacionalTelas";

const TesteVocacional = () => {
  const modulo = <HTMLElement>document.querySelector('.modulo-teste-vocacional');

  TesteVocacionalSlider();
  TesteVocacionalTelasFormulario(modulo);
  TesteVocacionalFormulario(modulo);
  TesteVocacionalPerguntas(modulo);
  TesteVocacionalResultados(modulo);

  const campoUf = <HTMLSelectElement | null>modulo.querySelector('.formulario #uf');
  const campoCidade = <HTMLSelectElement | null>modulo.querySelector('.formulario #cidade');
  (campoUf != null && campoCidade != null) && TesteVocacionalSegundoFormulario(modulo);
}

export default TesteVocacional;