
const ModalProvaTradicionalFechamento = () => {
    document.querySelectorAll('.modal-prova-antecipada__botao-fechamento').forEach((elemento) => {
        elemento.addEventListener(
            'click',
            () => {
                document.querySelector(".modal-prova-antecipada").classList.toggle("modal-desativado");
                document.documentElement.style.overflow = 'visible';
            }
        );
    });
}

export default ModalProvaTradicionalFechamento;