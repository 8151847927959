import { CalculadoraEnemBotoes } from "./CalculadoraEnemBotoes";
import { CalculadoraEnemBuscarNota } from "./CalculadoraEnemBuscarNota";
import { CalculadoraEnemForm } from "./calculadoraEnemForm";
import { CalculadoraEnemGeolocalizacao } from "./CalculadoraEnemGeolocalizacao";
import CalculadoraEnemInput from "./calculadoraEnemInput";
import { CalculadoraEnemTrocarCurso } from "./CalculadoraEnemTrocarCurso";
import { CalculadoraEnemValidacaoCampos } from "./CalculadoraEnemValidacaoCampos";

const CalculadoraEnem = () => {
    CalculadoraEnemValidacaoCampos();
    CalculadoraEnemForm();
    CalculadoraEnemInput();
    CalculadoraEnemBotoes();
    CalculadoraEnemTrocarCurso();
    if (
        document.querySelector('#calculadora-enem__select--pais')
        && document.querySelector('#calculadora-enem__select--estado')
        && document.querySelector('#calculadora-enem__select--cidade')
        && document.querySelector('#calculadora-enem__select--polo')
    ) {
        CalculadoraEnemGeolocalizacao();
    }
}

export default CalculadoraEnem;