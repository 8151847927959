const modal: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal');
const containerConteudo: HTMLElement = <HTMLElement>document.querySelector('.container-conteudo-pdp');
const menu: HTMLElement = <HTMLElement>document.querySelector('header.header.header-topo');
const botao: HTMLElement = <HTMLElement>document.querySelector('.pdp-sessao__sobre .documentos-botao');
const fechar: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal .documentos-modal__botao-fechar');
const listaInputs: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.documentos-modal__input, .documentos-modal__select');
const formularioEtapa1: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal__etapa-1');
const formularioEtapa2: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal__etapa-2');
const footer: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal__footer');
const arquivos: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal__arquivos');
const formulario: HTMLFormElement = <HTMLFormElement>document.querySelector('.documentos-modal__formulario');

const abrirFecharModalArquivosAcao = () => {
  modal.classList.toggle('desativado');
  document.body.style.overflowY = document.body.style.overflowY == 'hidden' ? '' : 'hidden';
  containerConteudo.style.zIndex = (containerConteudo.style.zIndex == '1' || containerConteudo.style.zIndex == '') ? '9999' : '1';
  menu.style.zIndex = (menu.style.zIndex == '1') ? '999' : '1';
  formularioEtapa1.style.display = 'grid';
  formularioEtapa2.style.display = 'grid';
  footer.style.display = 'flex';
  arquivos.style.display = 'none';
}

export const abrirFecharModalArquivos = () => {
  botao.addEventListener('click', () => abrirFecharModalArquivosAcao());
  fechar.addEventListener('click', () => abrirFecharModalArquivosAcao());
};

const verificarInputAcao = () => {
  let botao: HTMLElement = <HTMLElement>document.querySelector('.documentos-modal__botao-enviar');

  if (
    ![...listaInputs].map(
      (item: any) => item.checkValidity()
    ).includes(false)
  ) {
    botao.style.opacity = '1';
    botao.style.pointerEvents = 'all';
    return true;
  }

  botao.style.opacity = '.5';
  botao.style.pointerEvents = 'none';
  return false;
};

export const verificarInput = () => listaInputs.forEach(elemento => {
  elemento.addEventListener('click', () => verificarInputAcao());
  elemento.addEventListener('change', () => verificarInputAcao());
  elemento.addEventListener('keyup', () => verificarInputAcao());
});

export const mostrarArquivos = () => {
  formulario.addEventListener('submit', (evento) => {
    evento.preventDefault();
    formularioEtapa1.style.display = 'none';
    formularioEtapa2.style.display = 'none';
    footer.style.display = 'none';
    arquivos.style.display = 'grid';

    const dadosFormulario = new FormData(formulario);
    let dados = {
      'nome': dadosFormulario.get('nome'),
      'email': dadosFormulario.get('email'),
      'telefone': dadosFormulario.get('telefone'),
      'quanto_curso': dadosFormulario.get('quanto_curso'),
      'quanto_profissional': dadosFormulario.get('quanto_profissional'),
      'utm_source': dadosFormulario.get('utm_source'),
      'utm_campaign': dadosFormulario.get('utm_campaign'),
      'utm_medium': dadosFormulario.get('utm_medium'),
      'utm_content': dadosFormulario.get('utm_content'),
      'utm_term': dadosFormulario.get('utm_term'),
      'gclid': dadosFormulario.get('gclid'),
      'modalidade': 'Presencial',
      'curso': document.querySelector('.topo-pdp-header-titulo')?.textContent
    };

    $.ajax({
      // @ts-ignore: Unreachable code error
      url: WpObj.ajaxurl,
      method: "POST",
      data: {
        action: "processFields",
        // @ts-ignore: Unreachable code error
        nonce: ProcessFields.nonce,
        tipoformulario: 'modal',
        inputs: dados
      }
    });
  });
};