import { pdpSidebarAbrirInvestimentoEad } from './pdpSidebarAbrirInvestimentoEad';
import { pdpSidebarAbrirInvestimentoPresencial } from './pdpSidebarAbrirInvestimentoGraduacaoPresencial';

export const PdpSidebarAbrirInvestimento = () => {
  let dominioIes = window.location.host.split('.').find( item => item != 'com' && item != 'br' && item != 'edu' && item != 'www' && item != 'homologa2019');

  if (
    (
      dominioIes == 'cruzeirodosulvirtual'
      || dominioIes == 'localhost:8014'
    ) || location.pathname.split('/')[1] !== 'graduacao'
  ) {
    pdpSidebarAbrirInvestimentoEad();
  } else if (
    (
      dominioIes != 'fsg' || dominioIes != 'localhost:8011'
      || dominioIes != 'cesuca' || dominioIes != 'localhost:8008'
      || dominioIes != 'cruzeirovirtual' || dominioIes != 'localhost:8014'
    ) && location.pathname.split('/')[1] == 'graduacao'
  ) {
    pdpSidebarAbrirInvestimentoPresencial();
  }
}