import { DataLayerCreateCliqueBotao, DataLayerCreateInteracaoFormulario } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerConteudo = () => {
  document.querySelectorAll('.js-btn-duracaocurso')
    .forEach((element) => element
      .addEventListener('click', () => DataLayerCreateCliqueBotao('duracao:ver-duracao')));

  document.querySelectorAll('.btns-siaa .btn-op3:first-of-type')
    .forEach((element) => element
      .addEventListener('click', () => DataLayerCreateCliqueBotao('mensalidade:conheca-bolsas-e-descontos')));

  document.querySelectorAll('.btns-siaa .btn-op3.js-btn-investimento')
    .forEach(
      (element) => element
        .addEventListener('click', () => DataLayerCreateCliqueBotao('mensalidade:veja-o-investimento'))
    );

  document.querySelectorAll('.cta')
    .forEach((element) => element
      .addEventListener('click', (evento: Event) => {
        const elemento = <HTMLAnchorElement>evento.target;
        DataLayerCreateCliqueBotao(`baixar-material:${elemento.href}`);
      })
    );

  document.querySelectorAll('.mcurricular-semestres-item-btn')
    .forEach((element) => element
      .addEventListener('click', (evento: Event) => {
        const elemento = <HTMLAnchorElement>evento.target;
        DataLayerCreateCliqueBotao(`matriz-curricular:${elemento.dataset.semestre}`)
      })
    );

  document.querySelectorAll('#CFinanciamentos .cfinanciamentos .cfinanciamentos-colunas .cfinanciamentos-citem a')
    .forEach((element) => element
      .addEventListener('click', (evento: Event) => {
        const elemento = <HTMLAnchorElement>evento.target;
        const tituloItem = elemento?.parentElement?.querySelector('.titulo_22')?.textContent || '';
        DataLayerCreateCliqueBotao(`financiamentos:${tituloItem}`);
      })
    );

  document.querySelectorAll('.pduvidas-colduvidas-conteudo')
    .forEach((element) => element
      .addEventListener('click', (evento: Event) => {
        const elemento = <HTMLAnchorElement>evento.target;
        const titulo = elemento.dataset.titulo || elemento?.parentElement?.dataset.titulo || '';
        DataLayerCreateCliqueBotao(`principais-duvidas:${titulo}`);
      })
    );

  document.querySelectorAll('.temduvidas-citem .temduvidas-citem-btn')
    .forEach((element) => element
      .addEventListener('click', (evento: Event) => {
        const elemento = <HTMLAnchorElement>evento.target;
        DataLayerCreateCliqueBotao(`ainda-tem-duvidas:${elemento.textContent}`);
      })
    );

  document.querySelectorAll('.temduvidas-form form input[type=submit]')
    .forEach((element) => element
      .addEventListener('click', (evento: Event) => {
        const elemento = <HTMLInputElement>evento.target;
        DataLayerCreateCliqueBotao(`ainda-tem-duvidas:${elemento.value}`);
      })
    );

  document.querySelectorAll('.temduvidas-form input:not([type=submit]):not([type=hidden]), .temduvidas-form textarea')
    .forEach((element) => element
      .addEventListener('blur', (evento: Event) => {
        const elemento = <HTMLInputElement | HTMLTextAreaElement>evento.target;
        DataLayerCreateInteracaoFormulario(`ainda-tem-duvidas:${elemento.name}`);
      })
    );

  document.querySelectorAll('.temduvidas-form select')
    .forEach((element) => element
      .addEventListener('change', (evento: Event) => {
        const elemento = <HTMLSelectElement>evento.target;
        DataLayerCreateInteracaoFormulario(`ainda-tem-duvidas:${elemento.name}`);
      })
    );

  document.querySelectorAll('.noticias_eventos__modulo .botao--ir-ate')
    .forEach((element) => element
      .addEventListener('click', () => DataLayerCreateCliqueBotao('noticias:ver-todas-noticias')));

  document.querySelectorAll('.noticias_eventos__modulo .noticias_eventos__item:not(.slick-cloned)').forEach(
    (element) => element.addEventListener('click', (evento: Event) => {
      const elemento = <HTMLElement>evento.target;
      const titulo = elemento.querySelector('.noticias_eventos__item_titulo')?.textContent ||
        elemento.parentElement?.querySelector('.noticias_eventos__item_titulo')?.textContent || '';
      DataLayerCreateCliqueBotao(`noticias:${titulo}`);
    })
  );
}