export const BotoesComAviseMe = () => {
  let botaoBanner = <HTMLAnchorElement | null>document.querySelector('.banner.floating .legenda .btn');
  let botaoFlotuante = <HTMLAnchorElement | null>document.querySelector('.div-btn-floating a');

  const Acao = (evento: Event) => {
    evento.preventDefault();

    let botaoClicado = <HTMLAnchorElement>evento.target;
    let url = botaoClicado.href;

    if (url.includes('#aviseMe')) {
      document.querySelector('.modal-avise')?.classList.remove('disable-modal');
      document.querySelector('.bg_lightbox')?.classList.remove('disable-modal');
      document.querySelector('body')?.classList.add('blockBody');
      return;
    }

    const pagina = window.location.origin + window.location.pathname;
    if (url.replace(pagina, '').split('?')[0][0] === '#') {
      let idModal = url.replace(pagina, '').split('?')[0].toString().replace('#', '');
      let modal: HTMLAnchorElement | null = document.querySelector(`#${idModal}.disable-modaldefinitivo`);

      if (modal) {
        document.body.classList.add('blockBody');
        document.querySelector(`.modal-${idModal}-bg`)?.classList.remove("disable-modaldefinitivo");
        modal.classList.remove("disable-modaldefinitivo");
        return;
      }
    }

    if (botaoClicado.target === '_self') {
      window.location.href = url;
    }

    window.open(url, '_blank');
    return;
  }

  botaoBanner?.addEventListener('click', (evento: Event) => Acao(evento));
  botaoFlotuante?.addEventListener('click', (evento: Event) => Acao(evento));
}