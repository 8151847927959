/**
 * Função que inicializa os carrosseis de bolsas de estudo com descontos.
 *
 * @function
 * @returns {void}
 */
export const BolsasDeEstudoDescontosAtualizado = () => {
  for (let i = 1; i <= 5; i++) {
    $('#bolsas-estudo-descontos-atualizado__lista-' + i).slick({
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 992,
          settings: "unslick"
        }
      ]
    });
  }
}
