import { DataLayerCreateCliqueBotao, DataLayerCreateInteracaoFormulario } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerTelaTres = () => {
  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  let fluxoEventLabel = 'ver-investimento';

  if (fluxo == 'InscrevaSe') {
    fluxoEventLabel = 'inscricao';
  }

  const seletores = <NodeListOf<HTMLSelectElement>>document
    .querySelectorAll('.sidebar-pdp-tela-tres .sidebar-pdp-tela__campo-select');

  const AcaoFormulario = (evento: Event) => {
    const elemento = <HTMLInputElement>evento.target;
    DataLayerCreateInteracaoFormulario(`${fluxoEventLabel}:${elemento.name}`);
  }

  seletores.forEach((elemento: HTMLElement) => elemento
    .addEventListener('change', (evento: Event) => AcaoFormulario(evento)));

  const botoesInscrevaSe = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-tres .sidebar-pdp-tela__proximo[data-proxima-tela-inscreva-se="quase-la"], .sidebar-pdp-tela-tres .sidebar-pdp-tela__proximo[data-proxima-tela-ver-investimento="quase-la"]');

  botoesInscrevaSe
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao(`formulario:${fluxoEventLabel}:inscricao`)));
}