export const ImagemOtimizada = () => {
  const pictures = <NodeListOf<HTMLImageElement>>document.querySelectorAll('.imagem-otimizada');

  pictures.forEach((picture: HTMLImageElement) => {
    const template = <HTMLTemplateElement>picture.nextElementSibling;

    picture.classList.remove('imagem-otimizada--loading');
    picture.innerHTML = template.innerHTML;

    const imagem = <HTMLImageElement>picture.querySelector('img');

    imagem.src = picture.dataset.webp || '';
    imagem.srcset = picture.dataset.webps || '';

    imagem.addEventListener('error', () => {
      imagem.srcset = picture.dataset.imagens || '';
      imagem.src = picture.dataset.default || '';
    }, {
      once: true
    })
  });
}