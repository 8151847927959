const ambientes = {
  'www.brazcubas.edu.br': 'brazcubas',
  'www.cesuca.edu.br': 'cesuca',
  'www.ceunsp.edu.br': 'ceunsp',
  'www.cruzeirodosul.edu.br': 'cruzeiro',
  'www.cruzeirodosulvirtual.com.br': 'cruzeirovirtual',
  'www.fapipinhais.edu.br': 'fapi',
  'www.fass.edu.br': 'fass',
  'www.fsg.edu.br': 'fsg',
  'www.modulo.edu.br': 'modulo',
  'www.udf.edu.br': 'udf',
  'www.unicid.edu.br': 'unicid',
  'www.unifran.edu.br': 'unifran',
  'www.unipe.edu.br': 'unipe',
  'www.up.edu.br': 'up',
  'homologa2019.www.brazcubas.edu.br': 'brazcubas',
  'homologa2019.www.cesuca.edu.br': 'cesuca',
  'homologa2019.www.ceunsp.edu.br': 'ceunsp',
  'homologa2019.www.cruzeirodosul.edu.br': 'cruzeiro',
  'homologa2019.www.cruzeirodosulvirtual.com.br': 'cruzeirovirtual',
  'homologa2019.www.fapipinhais.edu.br': 'fapi',
  'homologa2019.www.fass.edu.br': 'fass',
  'homologa2019.www.fsg.edu.br': 'fsg',
  'homologa2019.www.modulo.edu.br': 'modulo',
  'homologa2019.www.udf.edu.br': 'udf',
  'homologa2019.www.unicid.edu.br': 'unicid',
  'homologa2019.www.unifran.edu.br': 'unifran',
  'homologa2019.www.unipe.edu.br': 'unipe',
  'homologa2019.www.up.edu.br': 'up',
  'desenv.www.brazcubas.edu.br': 'brazcubas',
  'desenv.www.cesuca.edu.br': 'cesuca',
  'desenv.www.ceunsp.edu.br': 'ceunsp',
  'desenv.www.cruzeirodosul.edu.br': 'cruzeiro',
  'desenv.www.cruzeirodosulvirtual.com.br': 'cruzeirovirtual',
  'desenv.www.fass.edu.br': 'fass',
  'desenv.www.fsg.edu.br': 'fsg',
  'desenv.www.modulo.edu.br': 'modulo',
  'desenv.www.udf.edu.br': 'udf',
  'desenv.www.unicid.edu.br': 'unicid',
  'desenv.www.unifran.edu.br': 'unifran',
  'desenv.www.unipe.edu.br': 'unipe',
  'desenv.www.up.edu.br': 'up',
  'desenv.www.fapipinhais.edu.br': 'fapi',
  'localhost:8012': 'brazcubas',
  'localhost:8008': 'cesuca',
  'localhost:8006': 'ceunsp',
  'localhost:8003': 'cruzeiro',
  'localhost:8014': 'cruzeirovirtual',
  'localhost:8010': 'fass',
  'localhost:8011': 'fsg',
  'localhost:8009': 'modulo',
  'localhost:8007': 'udf',
  'localhost:8002': 'unicid',
  'localhost:8004': 'unifran',
  'localhost:8005': 'unipe',
  'localhost:8013': 'up',
  'localhost:8015': 'fapi',
};

const dataLayerDefineProduct = (
  tipo: string,
  curso: string
) => {
  let produto = "";

  switch (tipo) {
    case 'graduacao':
      produto = "graduacao";

      if (curso.toUpperCase().includes("FORM. PED.")) {
        produto = "formacao-de-professores";
      }

      if (curso.includes("2.0")) {
        produto = "segunda-graduacao";
      }

      break;
    case 'pos-graduacao':
      produto = "pos-graduacao";
      break;
    case 'cursos-livres':
    case 'extensao':
      produto = "cursos-livres";
      break;
    case 'cursos-tecnicos':
      produto = "cursos-tecnicos";
      break;
  }

  return produto;
}

const ambiente = ambientes[window.location.hostname] || ambientes[`${window.location.hostname}:${window.location.port}`];
const envAmbiente = window.location.hostname == 'localhost' || window.location.hostname.includes('homologa2019') ? 'dev' : 'prod';
const tipo = window.location.pathname.split("/").filter((i) => i)[0];
const nomeCursoCampo = <HTMLInputElement | null>document.querySelector('input[name=curso]');
const nomeCurso: string = nomeCursoCampo?.value || document.querySelector('.scconteudo-ncurso')?.textContent || '';
const produto = dataLayerDefineProduct(tipo, nomeCurso);

const sanitizeTexto = (texto: string) => {
  return texto
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, "")
    .split(' ')
    .join('-');
}

const createHitTimeStamp = () => {
  const now = new Date();
  const offset = -now.getTimezoneOffset();
  const offsetSign = offset >= 0 ? '+' : '-';
  const absOffset = Math.abs(offset);
  const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, '0');
  const offsetMinutes = String(absOffset % 60).padStart(2, '0');

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const date = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

const DataLayerCreateEvent = (
  eventAction: string,
  eventLabel: string
) => {
  let curso = nomeCurso ? `:${nomeCurso}` : '';
  curso = curso.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');

  let produtoCategory = produto.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
  produtoCategory = produtoCategory ? `:${produtoCategory}` : '';

  let action = eventAction.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');
  let label = eventLabel.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');

  window.dataLayer.push({
    'event': 'event',
    'eventCategory': `${ambiente}:cursos:${produto}${curso}`,
    'eventAction': action,
    'eventLabel': label,
    'data-attribute': {
      'hitTimeStamp': createHitTimeStamp(),
      'env': envAmbiente,
      'ies': ambiente,
    }
  })
}

export const DataLayerCreateVirtualPageView = (
  activeSection: string | null = null
) => {
  const produtoUrl = produto ? `${produto}/` : '';

  let secao = (activeSection != null) ? `${activeSection}/` : "";
  secao = secao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '-');

  const url = `/vpv/inscricao/${ambiente}/${produtoUrl}${secao}`;
  const cursoDataLayerPathPage: string = window.location.pathname.split('/')[2] || '';

  let cursoDataLayer = '';
  if (produto == 'Graduação') {
    let cursoDataLayerTipo = document.querySelectorAll('.informacoes-rapidas-coluna-um-item-valor')[1].textContent ||
      document.querySelector('.scs-cardinfo-resumo-tipo')?.parentElement?.querySelector('p')?.textContent || '';

    cursoDataLayer = sanitizeTexto(`${cursoDataLayerPathPage}-${cursoDataLayerTipo}`);
  } else {
    cursoDataLayer = sanitizeTexto(cursoDataLayerPathPage);
  }

  window.dataLayer.push({
    'event': 'virtualPageView',
    'pageUrl': url,
    'data-attribute': {
      'curso': cursoDataLayer,
      'produto': sanitizeTexto(produto),
      'hitTimeStamp': createHitTimeStamp(),
      'env': envAmbiente,
      'ies': ambiente,
    }
  })
}

export const DataLayerCreateCliqueBotao = (eventLabel: string) => DataLayerCreateEvent('clique:botao', eventLabel);
export const DataLayerCreateInteracaoFormulario = (eventLabel: string) => DataLayerCreateEvent('interacao:formulario', eventLabel);