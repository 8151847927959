export const DefinirQuantidadeDeInteressados = () => {
  const contadorDeInteressados = <HTMLElement>document.querySelector('.contador-de-interessados');
  const quantidadeDeViews = <HTMLElement>document.querySelector('.contador-de-interessados .destaque');

  let interessados: Array<any> = JSON.parse(sessionStorage.getItem('interessados') || '[]');
  let interessadosDaPagina = interessados.filter(item => item.page == window.location.href);
  let pageviews = parseInt(contadorDeInteressados.dataset.pageview || '0');

  if (!interessadosDaPagina[0]) {
    interessados = [
      ...interessados,
      {
        'page': window.location.href,
        'views': pageviews
      }
    ];
  }

  interessadosDaPagina = interessados.filter(item => item.page == window.location.href);
  if (
    interessadosDaPagina[0].views >= pageviews &&
    contadorDeInteressados.dataset.pageviewOrigem == 'sistema'
  ) {
    interessadosDaPagina[0].views = interessadosDaPagina[0].views + 1;
  } else {
    interessadosDaPagina[0].views = pageviews;
  }

  quantidadeDeViews.textContent = interessadosDaPagina[0].views;
  contadorDeInteressados.style.opacity = '1';
  sessionStorage.setItem('interessados', JSON.stringify(interessados));
}