export const ListaDeBeneficios = () => {
  let listaBeneficiosSlickOpcoes: JQuerySlickOptions = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  $('.lista-de-beneficios__cards').slick(listaBeneficiosSlickOpcoes);
}