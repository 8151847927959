export const StartSliderSelosMecFooter = () => {
  const sliderSelosMecOpcoes: JQuerySlickOptions = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: false,
    arrows: true,
    autoplay: false,
    swipe: true,
    swipeToSlide: true,
    touchMove: true,
    prevArrow: '<button type="button" class="selos__seta selos__seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
    nextArrow: '<button type="button" class="selos__seta selos__seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>',
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        variableWidth: true,
      }
    }],
  };

  $('.footer__selos-mec .selos').slick(sliderSelosMecOpcoes);
}