import { DataLayerCreateVirtualPageView } from "../../Utils/dataLayerCreate";

const barTabAtiva: HTMLElement = <HTMLElement>document.querySelector('.bar-tab-ativa__tab');
const iconeAbrir: string = '/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0028.svg';
const listaTabs: HTMLElement = <HTMLElement>document.querySelector('.formas-ingresso .modulo .lista-tabs__box');
const iconeElemento: HTMLImageElement = <HTMLImageElement>document.querySelector('.formas-ingresso .modulo .bar-tab-ativa .bar-tab-ativa__icone');

export const MudarTab = () => {
  const tabs: NodeListOf<HTMLElement> = document.querySelectorAll('.formas-ingresso .modulo .tab');

  if (document.location.pathname.match(/\/(graduacao|pos-graduacao|cursos-tecnicos|extensao)\/.+/g) === null) {
    DataLayerCreateVirtualPageView(tabs[0].children[0].innerHTML.replace(new RegExp('^\\s+|\\s+$', 'g'), ''));
  }

  tabs.forEach((tab: HTMLElement) => {
    tab.addEventListener('click', (event: Event) => {
      let elementoClicado: HTMLElement = <HTMLElement>event.target;
      DataLayerCreateVirtualPageView(elementoClicado.children[0].innerHTML.replace(new RegExp('^\\s+|\\s+$', 'g'), ''));

      const tabClicado: HTMLElement = <HTMLElement>event.target;
      const telas: NodeListOf<HTMLElement> = document.querySelectorAll('.formas-ingresso .modulo div[data-item]');
      const telaTabClicada: HTMLElement = <HTMLElement>document.querySelector(`.formas-ingresso .modulo div[data-item="${tabClicado.dataset.item}"]`);

      tabs.forEach((objeto: HTMLElement) => objeto.classList.remove('ativo'));
      tabClicado.classList.add('ativo');

      telas.forEach((objeto: HTMLElement) => objeto.style.display = 'none');
      telaTabClicada.style.display = 'flex';

      if (window.innerWidth <= 992) {
        iconeElemento.classList.toggle('aberto');
        iconeElemento.src = iconeAbrir;
        listaTabs.style.display = 'none';
        barTabAtiva.dataset.item = tabClicado.dataset.item;
        barTabAtiva.innerText = tabClicado.innerText.replace('\n', '');
        barTabAtiva.innerText = barTabAtiva.innerText.replace('\n', '');
      }
    });
  });
};

export const AbrirTabsMobile = () => {
  const botoes: NodeListOf<HTMLElement> = document.querySelectorAll('.formas-ingresso .modulo .bar-tab-ativa button');
  const iconeFechar: string = '/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0055.svg';
  const telas: NodeListOf<HTMLElement> = document.querySelectorAll('.formas-ingresso .modulo > div:not(.bar-tab-ativa)');

  botoes.forEach((botao: HTMLElement) =>
    botao.addEventListener('click', () => {
      let telaAtiva: HTMLElement = <HTMLElement>document.querySelector(`.formas-ingresso .modulo div[data-item="${barTabAtiva.dataset.item}"]`);

      iconeElemento.classList.toggle('aberto');
      iconeElemento.src = iconeAbrir;
      listaTabs.style.display = 'none';
      telaAtiva.style.display = 'flex';

      if (iconeElemento.classList.contains('aberto')) {
        iconeElemento.src = iconeFechar;
        telas.forEach((tela: HTMLElement) => tela.style.display = 'none');
        listaTabs.style.display = 'grid';
      }
    })
  );
};