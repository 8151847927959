export const PdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas = () => {
  if (!document.querySelector('select[name="quantidades_disciplinas"].sidebar-pdp-tela__campo-select option:checked')) return;

  let optionSelected = <HTMLSelectElement>document.querySelector('select[name="quantidades_disciplinas"].sidebar-pdp-tela__campo-select option:checked');
  if (optionSelected.value == '') return;

  let skeletonCarregamento = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--carregando');
  skeletonCarregamento.style.display = 'grid';

  let divConteudoPlanos = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--planos');
  divConteudoPlanos.style.display = 'none';

  let planoInicial = <HTMLElement>document.querySelector('.pdp-novos-carrinhos .slider-planos .plano');
  if (!planoInicial.classList.contains('slick-slide')) {
    planoInicial.style.display = 'grid';
  }

  let planos = JSON.parse(optionSelected.dataset.planos || '[]');

  planos.forEach((plano) => {
    let realBrasil = Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    let subtitulo = plano.titulo.split('-')[1];
    let valorBase = realBrasil.format(plano.valorParcela);
    let valor = optionSelected.dataset[`valor-${plano.codPlano}`] || '';
    let desconto = Math.round(100 - ((Number(valor.replace('.', '').replace(',', '.')) * 100) / plano.valorParcela));
    let valorAtraso = optionSelected.dataset[`atraso-${plano.codPlano}`] || '';

    let planoConteudoValorDe = <NodeListOf<HTMLElement>>document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-de`);
    planoConteudoValorDe.forEach(item => item.style.display = 'unset');
    planoConteudoValorDe.forEach(item => item.textContent = `de ${valorBase} por`);

    let planoConteudoValorParaTooltipDesconto = <NodeListOf<HTMLElement>>document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip-desconto`);
    planoConteudoValorParaTooltipDesconto.forEach(item => item.style.display = 'unset');

    let planoSubtitulo = <NodeListOf<HTMLElement>>document.querySelectorAll(`.plano-${plano.codPlano} .plano__subtitulo`);
    planoSubtitulo.forEach(item => item.textContent = subtitulo);

    let planoConteudoValorParaTexto = <NodeListOf<HTMLElement>>document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-texto`);
    planoConteudoValorParaTexto.forEach(item => item.textContent = `R$ ${valor}`);

    let planoConteudoValorParaTooltipDescontoText = <NodeListOf<HTMLElement>>document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip-desconto text`);
    planoConteudoValorParaTooltipDescontoText.forEach(item => item.innerHTML = `-${desconto}<tspan>%</tspan>`);

    if (Number(valor.replace('.', '').replace(',', '.')) >= plano.valorParcela) {
      planoConteudoValorDe.forEach(item => item.style.display = 'none');
      planoConteudoValorParaTooltipDesconto.forEach(item => item.style.display = 'none');
    }

    let planoConteudoValorParaTooltip = <HTMLElement>document.querySelector(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip`);
    planoConteudoValorParaTooltip.dataset.textoTootip = ` Após a data: R$${valorAtraso}`;
  });

  setTimeout(() => {
    skeletonCarregamento.style.display = 'none';
    divConteudoPlanos.style.display = 'grid';

    let listaSliders = <HTMLElement | null>document.querySelector('.pdp-novos-carrinhos .slider-planos .slick-list');
    let slideAtivo = <HTMLElement | null>document.querySelector('.pdp-novos-carrinhos .slider-planos .slick-list .slick-active');

    if (listaSliders) {
      listaSliders.style.height = `${(slideAtivo?.offsetHeight || 0)}px`;
    }
  }, 1000);
}