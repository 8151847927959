import { GetCookie } from "../../Utils/cookies";
import { CreateAjax } from "../../Utils/createAjax";
import { InteracaoOuCriacaoLead } from "../../Utils/enviaLead";

export const CalculadoraEnemCFocuInput = (modulo: HTMLElement): void => {
    const inputList = <NodeListOf<HTMLInputElement | HTMLSelectElement>>modulo.querySelectorAll('.calculadora-enem-c__step-input, .calculadora-enem-c__step-select');

    inputList.forEach((input: HTMLInputElement | HTMLSelectElement) => {
        const campo = <HTMLElement>input.parentElement;
        const label = <HTMLElement>campo.querySelector('.calculadora-enem-c__step-label');

        input.addEventListener('focus', () => {
            campo.classList.add('calculadora-enem-c__step-campo--focus');
            label.classList.add('calculadora-enem-c__step-label--focus');

            (input instanceof HTMLInputElement)
                ? input.classList.add('calculadora-enem-c__step-input--focus')
                : input.classList.add('calculadora-enem-c__step-select--focus');
        });

        input.addEventListener('focusout', () => {
            input.value = input.value.trim();
            if (!input.value) {
                campo.classList.remove('calculadora-enem-c__step-campo--focus');
                label.classList.remove('calculadora-enem-c__step-label--focus');

                (input instanceof HTMLInputElement)
                    ? input.classList.remove('calculadora-enem-c__step-input--focus')
                    : input.classList.remove('calculadora-enem-c__step-select--focus');
            }
        });
    });

    return;
}

export const camposPattern = [
    {
        'campo': 'nome',
        'pattern': '^([A-Za-záàâãéèêíïóôõúçÁÀÂÃÉÈÊÍÏÓÔÕÚÇ ]{2,40})+([A-Za-záàâãéèêíïóôõúçÁÀÂÃÉÈÊÍÏÓÔÕÚÇ ]{2,40})+$'
    },
    {
        'campo': 'email',
        'pattern': '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}'
    },
    {
        'campo': 'celular',
        'pattern': /\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}/gm
    }
];

export const ValidacaoDeCampos = (
    campos: NodeListOf<HTMLInputElement | HTMLSelectElement>
): boolean => {
    let camposValidos = true;

    campos.forEach((campo: HTMLInputElement | HTMLSelectElement) => {
        const pattern = camposPattern.find((item) => item.campo === campo.name);

        if (
            !campo.value ||
            campo.value === '' ||
            campo.checkValidity() === false ||
            (pattern && !new RegExp(pattern.pattern).test(campo.value))
        ) {
            campo.focus();
            camposValidos = false;
        }
    });

    return camposValidos;
}

export const CalculadoraEnemCMudarStep = (modulo: HTMLElement): void => {
    const botaoList = <NodeListOf<HTMLButtonElement>>modulo.querySelectorAll('.calculadora-enem-c__step-botao');
    const stepBarDesktop = <HTMLElement>document.querySelector('.calculadora-enem-c__step-bar--desktop');
    const stepBarMobile = <HTMLElement>document.querySelector('.calculadora-enem-c__step-bar--mobile');
    const inputList = <NodeListOf<HTMLInputElement>>modulo.querySelectorAll('.calculadora-enem-c__formulario input:not([type="number"])');

    inputList.forEach((input: HTMLInputElement) => {
        input.addEventListener('keypress', (evento: KeyboardEvent) => {
            const tecla = evento.key;

            if (tecla === 'Enter') {
                evento.preventDefault();
                const stepAtual = <HTMLElement>input.parentElement?.parentElement?.parentElement;
                const botaoAvancar = <HTMLButtonElement>stepAtual.querySelector('.calculadora-enem-c__step-botao--avancar');
                botaoAvancar.click();
            }
        });
    });

    const elementoFinalDesktop = stepBarDesktop.dataset.elementoFinal;
    const xElementoFinalDesktop = stepBarDesktop?.querySelector(`svg *:nth-child(${elementoFinalDesktop})`)?.getAttribute('cx') || '330.00';
    const xElementoFinalDesktopSplit = xElementoFinalDesktop.split('.');
    const novoWidthBarDesktop = +xElementoFinalDesktopSplit[0] + 30 + '.' + (xElementoFinalDesktopSplit[1] || '00');
    stepBarDesktop.innerHTML = stepBarDesktop.innerHTML.replace(/360/g, novoWidthBarDesktop);

    const elementoFinalMobile = stepBarMobile.dataset.elementoFinal;
    const xElementoFinalMobile = stepBarMobile?.querySelector(`svg *:nth-child(${elementoFinalMobile})`)?.getAttribute('cx') || '263.00';
    const xElementoFinalMobileSplit = xElementoFinalMobile.split('.');
    const novoWidthBarMobile = +xElementoFinalMobileSplit[0] + 64 + '.' + (xElementoFinalMobileSplit[1] || '00');
    stepBarMobile.innerHTML = stepBarMobile.innerHTML.replace(/327/g, novoWidthBarMobile);

    botaoList.forEach((botao: HTMLButtonElement) => {
        botao.addEventListener('click', () => {
            let numeroStepAlvo: string | number = <string>botao.dataset?.step || '1';
            numeroStepAlvo = +numeroStepAlvo;

            const stepAlvo = <HTMLElement>document.querySelector(`.calculadora-enem-c__step--${numeroStepAlvo}`);
            const stepAtual = <HTMLElement>botao.parentElement?.parentElement;
            const classListStepAtual = [...stepAtual.classList];

            let numeroStepAtual: string | number = '';
            classListStepAtual.forEach((item) => numeroStepAtual = item.startsWith('calculadora-enem-c__step--') ? item : '');
            numeroStepAtual = numeroStepAtual.replace('calculadora-enem-c__step--', '');
            numeroStepAtual = +numeroStepAtual;

            const campos = <NodeListOf<HTMLInputElement | HTMLSelectElement>>stepAtual
                .querySelectorAll('.calculadora-enem-c__step-input, .calculadora-enem-c__step-select');

            if (
                numeroStepAtual < numeroStepAlvo &&
                !ValidacaoDeCampos(campos)
            ) {
                return;
            };

            stepAtual.classList.add('calculadora-enem-c__step--desativado');
            stepAlvo.classList.remove('calculadora-enem-c__step--desativado');

            const xhr = new XMLHttpRequest();
            xhr.open("GET", `/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/progress-bar--step-${numeroStepAlvo}.svg`, false);
            xhr.overrideMimeType("image/svg+xml");
            stepBarDesktop.innerHTML = '';
            xhr.onload = () => xhr.responseXML && stepBarDesktop.appendChild(xhr.responseXML.documentElement);
            xhr.send("");

            stepBarDesktop.innerHTML = stepBarDesktop.innerHTML.replace(/#009C82/g, 'var(--cor-ies)');
            stepBarDesktop.innerHTML = stepBarDesktop.innerHTML.replace(/#0DC8A9/g, 'var(--cor-ies)');

            const elementoFinalDesktop = stepBarDesktop.dataset.elementoFinal;
            const xElementoFinalDesktop = stepBarDesktop?.querySelector(`svg *:nth-child(${elementoFinalDesktop})`)?.getAttribute('cx') || '330.00';
            const xElementoFinalDesktopSplit = xElementoFinalDesktop.split('.');
            const novoWidthBarDesktop = +xElementoFinalDesktopSplit[0] + 30 + '.' + (xElementoFinalDesktopSplit[1] || '00');
            stepBarDesktop.innerHTML = stepBarDesktop.innerHTML.replace(/360/g, novoWidthBarDesktop);

            const xhrM = new XMLHttpRequest();
            xhrM.open("GET", `/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/progress-bar-mobile--step-${numeroStepAlvo}.svg`, false);
            xhrM.overrideMimeType("image/svg+xml");
            stepBarMobile.innerHTML = '';
            xhrM.onload = () => xhrM.responseXML && stepBarMobile.appendChild(xhrM.responseXML.documentElement);
            xhrM.send("");

            stepBarMobile.innerHTML = stepBarMobile.innerHTML.replace(/#009C82/g, 'var(--cor-ies)');
            stepBarMobile.innerHTML = stepBarMobile.innerHTML.replace(/#0DC8A9/g, 'var(--cor-ies)');

            const elementoFinalMobile = stepBarMobile.dataset.elementoFinal;
            const xElementoFinalMobile = stepBarMobile?.querySelector(`svg *:nth-child(${elementoFinalMobile})`)?.getAttribute('cx') || '263.00';
            const xElementoFinalMobileSplit = xElementoFinalMobile.split('.');
            const novoWidthBarMobile = +xElementoFinalMobileSplit[0] + 64 + '.' + (xElementoFinalMobileSplit[1] || '00');
            stepBarMobile.innerHTML = stepBarMobile.innerHTML.replace(/327/g, novoWidthBarMobile);

            const inputList = <NodeListOf<HTMLInputElement | HTMLSelectElement>>modulo
                .querySelectorAll('.calculadora-enem-c__step-input, .calculadora-enem-c__step-select');

            inputList.forEach((input: HTMLInputElement | HTMLSelectElement) => {
                const campo = <HTMLElement>input.parentElement;
                const label = <HTMLElement>campo.querySelector('.calculadora-enem-c__step-label');

                input.value = input.value.trim();
                if (!input.value) {
                    campo.classList.remove('calculadora-enem-c__step-campo--focus');
                    label.classList.remove('calculadora-enem-c__step-label--focus');

                    (input instanceof HTMLInputElement)
                        ? input.classList.remove('calculadora-enem-c__step-input--focus')
                        : input.classList.remove('calculadora-enem-c__step-select--focus');
                }
            });
        });
    });

    const botaoVoltarResultado = <NodeListOf<HTMLButtonElement>>document.querySelectorAll('.calculadora-enem-c__media-botao--voltar');
    botaoVoltarResultado.forEach(botao => botao.addEventListener('click', () => {
        const resultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__resultado');
        resultado.classList.add('calculadora-enem-c__resultado--desativado');

        const formulario = <HTMLFormElement>modulo.querySelector('.calculadora-enem-c__formulario');
        formulario.classList.remove('calculadora-enem-c__formulario--desativado');
    }));

    return;
}

export const CalculadoraEnemCEnviaLead = async (dados: FormData) => {
    const dadosUrl = new URLSearchParams(location.search);
    // @ts-ignore: Unreachable code error
    const identificador = CalcEnemC.id || '';
    // @ts-ignore: Unreachable code error
    const nonceNewLead = CalcEnemC.nNewLead || '';

    const email = dados.get('email')?.toString() || '';
    const nomeCompleto = dados.get('nome')?.toString() || ''
    const nomeCompletoSplit = nomeCompleto.split(' ')
    const nome = nomeCompletoSplit[0];
    const sobrenome = nomeCompletoSplit[nomeCompletoSplit.length - 1];
    const celular = dados.get('celular')?.toString() || '';
    const campus = dados.get('campus')?.toString() || '';
    const curso = dados.get('curso')?.toString() || '';

    const periodo = dados.get('periodo')?.toString() || '';
    let periodoLead = '';

    switch (periodo) {
        case '1':
            periodoLead = 'Matutino';
            break;
        case '2':
            periodoLead = 'Vespertino';
            break;
        case '3':
            periodoLead = 'Noturno';
            break;
        default:
            break;
    }

    const quantoCurso = dados.get('quanto_curso')?.toString() || '';
    const quantoEscolaridade = dados.get('quanto_escolaridade')?.toString() || '';
    const quantoProfissional = dados.get('quanto_profissional')?.toString() || '';

    const dadosNewLead = new FormData();
    const dadosCookies = new URLSearchParams(GetCookie('parsedQS'));

    dadosNewLead.append("Conteudo_da_Campanha__c", dadosUrl.get('utm_content') || dadosCookies.get('utm_content') || '');
    dadosNewLead.append("Meio__c", dadosUrl.get('utm_medium') || dadosCookies.get('utm_medium') || '(direct)');
    dadosNewLead.append("Nome_da_Campanha__c", dadosUrl.get('utm_campaign') || dadosCookies.get('utm_campaign') || '');
    dadosNewLead.append("Origem__c", dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)');
    dadosNewLead.append("Termo_da_Campanha__c", dadosUrl.get('utm_term') || dadosCookies.get('utm_term') || '');
    dadosNewLead.append('LeadSource', dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)');
    dadosNewLead.append('GCLID__c', dadosUrl.get('gclid') || dadosCookies.get('gclid') || '');
    dadosNewLead.append("action", "newLead");
    dadosNewLead.append("nonce", nonceNewLead);
    dadosNewLead.append("Identificador__c", identificador);

    dadosNewLead.append("Email", email);
    dadosNewLead.append("FirstName", nome);
    dadosNewLead.append("LastName", sobrenome);
    dadosNewLead.append("MobilePhone", celular);
    dadosNewLead.append("Campus__c", campus);
    dadosNewLead.append("Curso_Opcao_1__c", curso);
    dadosNewLead.append("Turno__c", periodoLead);
    dadosNewLead.append("Quanto_ao_curso__c", quantoCurso);
    dadosNewLead.append("Quanto_a_escolaridade__c", quantoEscolaridade);
    dadosNewLead.append("Quanto_atividade_profissional__c", quantoProfissional);

    InteracaoOuCriacaoLead(
        identificador,
        'cadastro-desconto',
        // @ts-ignore: Unreachable code error
        LeadAction.leadByEmail,
        [
            { name: 'utm_content', value: dadosUrl.get('utm_content') || dadosCookies.get('utm_content') || '' },
            { name: 'utm_medium', value: dadosUrl.get('utm_medium') || dadosCookies.get('utm_medium') || '(direct)' },
            { name: 'utm_campaign', value: dadosUrl.get('utm_campaign') || dadosCookies.get('utm_campaign') || '' },
            { name: 'utm_source', value: dadosUrl.get('utm_source') || dadosCookies.get('utm_source') || '(direct)' },
            { name: 'utm_term', value: dadosUrl.get('utm_term') || dadosCookies.get('utm_term') || '' },
            { name: 'gclid', value: dadosUrl.get('gclid') || dadosCookies.get('gclid') || '' },
            { name: 'email', value: email },
            { name: 'nome', value: nomeCompleto },
            { name: 'telefone', value: celular },
            { name: 'campus', value: campus },
            { name: 'curso', value: curso },
            { name: 'periodo', value: periodoLead },
            { name: 'quanto_curso', value: quantoCurso },
            { name: 'quanto_escolaridade', value: quantoEscolaridade },
            { name: 'quanto_profissional', value: quantoProfissional },
        ],
        dadosNewLead
    ).then((retorno: any) => {
        console.table(retorno);
        if (retorno.LeadId) {
            sessionStorage.setItem('leadAtualId', retorno.LeadId);
            sessionStorage.setItem('leadAtualEmail', email);
        }
    });
}

export const CalculadoraEnemCBuscaResultados = async (form: HTMLFormElement, modulo: HTMLElement) => {
    const elementoNomeResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-cabecalho b');
    const elementoNome = <HTMLInputElement>modulo.querySelector('#nome');
    const nome = elementoNome.value;
    elementoNomeResultado.innerHTML = nome;

    const elementoCurso = <HTMLSelectElement>modulo.querySelector('#curso');
    const cursoValor = elementoCurso.value;
    const optionCurso = <HTMLOptionElement | null>elementoCurso.querySelector(`option[value="${cursoValor}"]`);
    const nomeCurso = optionCurso?.textContent || '';
    const elementoNomeCursoResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-nome-curso');
    elementoNomeCursoResultado.innerHTML = nomeCurso;

    const elementoCampus = <HTMLSelectElement>modulo.querySelector('#campus');
    const campusValor = elementoCampus.value;
    const optionCampus = <HTMLOptionElement | null>elementoCampus.querySelector(`option[value="${campusValor}"]`);
    const nomeCampus = optionCampus?.textContent || '';

    const elementoNomeCampusResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-nome-periodo');
    elementoNomeCampusResultado.innerHTML = nomeCampus;

    const elementoPeriodo = <HTMLSelectElement>modulo.querySelector('#periodo');
    const periodoValor = elementoPeriodo.value;
    const optionPeriodo = <HTMLOptionElement | null>elementoPeriodo.querySelector(`option[value="${periodoValor}"]`);
    const nomePeriodo = optionPeriodo?.textContent || '';

    elementoNomeCampusResultado.innerHTML == ''
        ? elementoNomeCampusResultado.innerHTML = nomePeriodo
        : elementoNomeCampusResultado.innerHTML += ` - ${nomePeriodo}`

    if (elementoNomeCampusResultado.innerHTML == '') {
        const elementoNomeCampusResultadoBox = <HTMLElement>elementoNomeCampusResultado.parentElement;
        elementoNomeCampusResultadoBox.style.display = 'none';
    }

    const formData = new FormData(form);
    const data = new FormData();

    // @ts-ignore: Unreachable code error
    const nonceGetCalculadoraEnemCResultados = CalcEnemC.nGetCalculadoraEnemCResultados || '';
    data.append('action', 'getCalculadoraEnemCResultados');
    data.append('nonce', nonceGetCalculadoraEnemCResultados);
    data.append('media-enem', formData.get('media-enem')?.toString() || '0');
    data.append('nota-linguagens', formData.get('nota-linguagens')?.toString() || '0');
    data.append('nota-matematica', formData.get('nota-matematica')?.toString() || '0');
    data.append('nota-humanas', formData.get('nota-humanas')?.toString() || '0');
    data.append('nota-natureza', formData.get('nota-natureza')?.toString() || '0');
    data.append('nota-redacao', formData.get('nota-redacao')?.toString() || '0');
    data.append('curso', formData.get('curso')?.toString() || '0');
    data.append('campus', formData.get('campus')?.toString() || '0');
    data.append('periodo', formData.get('periodo')?.toString() || '0');

    let objectData = {};
    data.forEach((value, key) => objectData[key] = value);
    let jsonData = JSON.stringify(objectData);
    localStorage.setItem('dataCalculadoraEnemC', jsonData);

    await CreateAjax(data).then(response => {
        const elementoMediaResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-resultado b');
        elementoMediaResultado.innerHTML = response.notaMedia;
        document.documentElement.style.setProperty('--progress-animation-end', `${response.porcentagem}`);

        const descontoResultado = <HTMLElement>document.querySelector('.calculadora-enem-c__desconto-resultado');
        descontoResultado.classList.remove('calculadora-enem-c__desconto-resultado--animation');
        descontoResultado.classList.add('calculadora-enem-c__desconto-resultado--animation');

        const descontoResultadoPorcentagem = <HTMLElement>document.querySelector('.calculadora-enem-c__desconto-resultado-porcentagem');
        descontoResultadoPorcentagem.innerHTML = `${response.porcentagem}%`;

        const descontoValorAntigoElemento = <HTMLElement>modulo.querySelector('.calculadora-enem-c__desconto-valor-antigo');
        const valorDe = `${response.valorDe}`;
        descontoValorAntigoElemento.innerHTML = `R$ ${valorDe.replace('.', ',')}`;

        const descontoValorElemento = <HTMLElement>modulo.querySelector('.calculadora-enem-c__desconto-valor');
        const valorPara = `${response.valorPara}`;
        descontoValorElemento.innerHTML = `R$ ${valorPara.replace('.', ',')}`;
    });
}

export const CalculadoraEnemCResultado = async (modulo: HTMLElement, form: HTMLFormElement) => {
    await CalculadoraEnemCBuscaResultados(form, modulo);

    const resultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__resultado');
    resultado.classList.remove('calculadora-enem-c__resultado--desativado');

    const formulario = <HTMLFormElement>modulo.querySelector('.calculadora-enem-c__formulario');
    formulario.classList.add('calculadora-enem-c__formulario--desativado');

    const selectorCurso = <HTMLElement>modulo.querySelector('.calculadora-enem-c__step-select#curso');
    const link = selectorCurso.dataset.link || '';

    const botaoInscrevaSe = <HTMLAnchorElement>modulo.querySelector('.calculadora-enem-c__desconto-inscreva-se');
    const linkPainel = botaoInscrevaSe.dataset.linkPainel || '';

    if (linkPainel == '' && link != '') {
        const parsedQS = document.cookie.split('parsedQS=')[1] ? document.cookie.split('parsedQS=')[1].split(';')[0] : '';
        botaoInscrevaSe.href = link + '?' + parsedQS;
    }
}

export const CalculadoraEnemCSubmit = (modulo: HTMLElement): void => {
    const formulario = <HTMLFormElement>modulo.querySelector('.calculadora-enem-c__formulario');

    formulario.addEventListener('submit', (evento: Event) => {
        evento.preventDefault();

        CalculadoraEnemCResultado(modulo, formulario);

        const dados = new FormData(formulario);
        CalculadoraEnemCEnviaLead(dados);
    });
}

export const CalculadoraEnemCTrocaCurso = (modulo: HTMLElement) => {
    const resultado = <HTMLDivElement>modulo.querySelector('.calculadora-enem-c__resultado');
    const trocaCurso = <HTMLDivElement>modulo.querySelector('.calculadora-enem-c__troca-curso');
    const botao = <HTMLButtonElement>modulo.querySelector('.calculadora-enem-c__media-troca-curso');
    const fechar = <HTMLButtonElement>modulo.querySelector('.calculadora-enem-c__troca-curso-fechar');
    const botaoTroca = <HTMLButtonElement>modulo.querySelector('.calculadora-enem-c__troca-curso-botao');

    botao.addEventListener('click', () => {
        resultado.classList.add('calculadora-enem-c__resultado--desativado');
        trocaCurso.classList.remove('calculadora-enem-c__troca-curso--desativado');

        const inputCampus1 = document.querySelector('.calculadora-enem-c__formulario #campus');
        const inputCampus2 = document.querySelector('.calculadora-enem-c__troca-curso #campus')?.parentElement;
        if (inputCampus1 == null) {
            inputCampus2?.remove();
        }

        const inputCurso1 = document.querySelector('.calculadora-enem-c__formulario #curso');
        const inputCurso2 = document.querySelector('.calculadora-enem-c__troca-curso #curso')?.parentElement;
        if (inputCurso1 == null) {
            inputCurso2?.remove();
        }

        const inputPeriodo1 = document.querySelector('.calculadora-enem-c__formulario #periodo');;
        const inputPeriodo2 = document.querySelector('.calculadora-enem-c__troca-curso #periodo')?.parentElement;
        if (inputPeriodo1 == null) {
            inputPeriodo2?.remove();
        }
    });

    fechar.addEventListener('click', () => {
        resultado.classList.remove('calculadora-enem-c__resultado--desativado');
        trocaCurso.classList.add('calculadora-enem-c__troca-curso--desativado');
    });

    botaoTroca.addEventListener('click', async () => {

        const dataCalculadoraEnemC = localStorage.getItem('dataCalculadoraEnemC');
        const data = JSON.parse(dataCalculadoraEnemC || '{}');

        const dadosTrocaCursoElemento = <HTMLElement>modulo.querySelector('.calculadora-enem-c__troca-curso-campos');

        const campus = <HTMLSelectElement | null>dadosTrocaCursoElemento.querySelector('#campus');
        const curso = <HTMLSelectElement | null>dadosTrocaCursoElemento.querySelector('#curso');
        const periodo = <HTMLSelectElement | null>dadosTrocaCursoElemento.querySelector('#periodo');

        data.campus = campus?.value || 0;
        data.curso = curso?.value || 0;
        data.periodo = periodo?.value || 0;

        const dataForm = new FormData();
        for (const key in data) {
            dataForm.append(key, data[key]);
        }

        const elementoCurso = curso;
        const cursoValor = elementoCurso?.value;
        const optionCurso = <HTMLOptionElement | null>elementoCurso?.querySelector(`option[value="${cursoValor}"]`);
        const nomeCurso = optionCurso?.textContent || '';
        const elementoNomeCursoResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-nome-curso');
        elementoNomeCursoResultado.innerHTML = nomeCurso;

        const elementoCampus = campus;
        const campusValor = elementoCampus?.value;
        const optionCampus = <HTMLOptionElement | null>elementoCampus?.querySelector(`option[value="${campusValor}"]`);
        const nomeCampus = optionCampus?.textContent || '';

        const elementoNomeCampusResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-nome-periodo');
        elementoNomeCampusResultado.innerHTML = nomeCampus;

        const elementoPeriodo = periodo;
        const periodoValor = elementoPeriodo?.value;
        const optionPeriodo = <HTMLOptionElement | null>elementoPeriodo?.querySelector(`option[value="${periodoValor}"]`);
        const nomePeriodo = optionPeriodo?.textContent || '';

        elementoNomeCampusResultado.innerHTML == ''
            ? elementoNomeCampusResultado.innerHTML = nomePeriodo
            : elementoNomeCampusResultado.innerHTML += ` - ${nomePeriodo}`

        if (elementoNomeCampusResultado.innerHTML == '') {
            const elementoNomeCampusResultadoBox = <HTMLElement>elementoNomeCampusResultado.parentElement;
            elementoNomeCampusResultadoBox.style.display = 'none';
        }

        await CreateAjax(dataForm).then(response => {
            const elementoMediaResultado = <HTMLElement>modulo.querySelector('.calculadora-enem-c__media-resultado b');
            elementoMediaResultado.innerHTML = response.notaMedia;
            document.documentElement.style.setProperty('--progress-animation-end', `${response.porcentagem}`);

            const descontoResultado = <HTMLElement>document.querySelector('.calculadora-enem-c__desconto-resultado');
            descontoResultado.classList.remove('calculadora-enem-c__desconto-resultado--animation');
            descontoResultado.classList.add('calculadora-enem-c__desconto-resultado--animation');

            const descontoResultadoPorcentagem = <HTMLElement>document.querySelector('.calculadora-enem-c__desconto-resultado-porcentagem');
            descontoResultadoPorcentagem.innerHTML = `${response.porcentagem}%`;

            const descontoValorAntigoElemento = <HTMLElement>modulo.querySelector('.calculadora-enem-c__desconto-valor-antigo');
            const valorDe = `${response.valorDe}`;
            descontoValorAntigoElemento.innerHTML = `R$ ${valorDe.replace('.', ',')}`;

            const descontoValorElemento = <HTMLElement>modulo.querySelector('.calculadora-enem-c__desconto-valor');
            const valorPara = `${response.valorPara}`;
            descontoValorElemento.innerHTML = `R$ ${valorPara.replace('.', ',')}`;
        });

        const link = curso?.dataset.link || '';

        const botaoInscrevaSe = <HTMLAnchorElement>modulo.querySelector('.calculadora-enem-c__desconto-inscreva-se');
        const linkPainel = botaoInscrevaSe.dataset.linkPainel || '';

        if (linkPainel == '' && link != '') {
            const parsedQS = document.cookie.split('parsedQS=')[1] ? document.cookie.split('parsedQS=')[1].split(';')[0] : '';
            botaoInscrevaSe.href = link + '?' + parsedQS;
        }

        resultado.classList.remove('calculadora-enem-c__resultado--desativado');
        trocaCurso.classList.add('calculadora-enem-c__troca-curso--desativado');
    });
}

export const CalculadoraEnemCBuscaCursosAcao = (
    seletor: HTMLSelectElement | null,
    seletorCurso: HTMLSelectElement,
    seletorCursoLabel: HTMLElement
) => {
    seletor?.addEventListener('change', async () => {
        seletorCurso.innerHTML = '<option disabled="" selected="" hidden="">Carregando...</option>';
        seletorCursoLabel.classList.add('calculadora-enem-c__step-label--focus');

        const valor = seletor.value || '';

        // @ts-ignore: Unreachable code error
        const nonce = CalcEnemC.nGetCursosCampus || '';

        const data = new FormData();
        data.append('action', 'getCursosCampus');
        data.append('nonce', nonce);
        data.append('id', valor);

        await CreateAjax(data)
            .then(response => {
                const retornoCursos = response.filter((curso) => !isNaN(curso.codCurs));

                if (retornoCursos.length == 0) {
                    seletorCurso.innerHTML = '<option disabled="" selected="" hidden="">Nenhum curso encontrado</option>';
                    return;
                }

                seletorCurso.innerHTML = '<option disabled="" selected="" hidden=""></option>';
                seletorCursoLabel.classList.remove('calculadora-enem-c__step-label--focus');

                retornoCursos.forEach((curso: any) => {
                    if (!isNaN(curso.codCurs)) {
                        const option = document.createElement('option');
                        option.value = curso.codCurs;
                        option.textContent = curso.descMkt;
                        option.dataset.link = curso.link;
                        seletorCurso.appendChild(option);
                    }
                })
            });
    })
}

export const CalculadoraEnemCBuscaCursos = (modulo: HTMLElement) => {
    const formulario = <HTMLElement>modulo.querySelector('.calculadora-enem-c__formulario');
    const fSeletor = <HTMLSelectElement | null>formulario.querySelector('.calculadora-enem-c__step-select#campus');
    const fSeletorCurso = <HTMLSelectElement>formulario.querySelector('.calculadora-enem-c__step-select#curso');
    const fSeletorCursoLabel = <HTMLElement>fSeletorCurso?.parentElement?.querySelector('label');

    CalculadoraEnemCBuscaCursosAcao(fSeletor, fSeletorCurso, fSeletorCursoLabel);

    const trocaCurso = <HTMLElement>modulo.querySelector('.calculadora-enem-c__troca-curso');
    const tSeletor = <HTMLSelectElement | null>trocaCurso.querySelector('.calculadora-enem-c__step-select#campus');
    const tSeletorCurso = <HTMLSelectElement>trocaCurso.querySelector('.calculadora-enem-c__step-select#curso');
    const tSeletorCursoLabel = <HTMLElement>tSeletorCurso?.parentElement?.querySelector('label');

    CalculadoraEnemCBuscaCursosAcao(tSeletor, tSeletorCurso, tSeletorCursoLabel);
}

export const CalculadoraEnemCBuscaPeriodoAcao = (
    seletorCampus: HTMLSelectElement | null,
    seletorPeriodo: HTMLSelectElement | null,
    seletorPeriodoLabel: HTMLElement | null,
    seletor: HTMLSelectElement
) => {
    seletor.addEventListener('change', async () => {
        seletorPeriodo && (seletorPeriodo.innerHTML = '<option disabled="" selected="" hidden="">Carregando...</option>');
        seletorPeriodoLabel?.classList.add('calculadora-enem-c__step-label--focus');

        const valor = seletor.value || '';
        const campus = seletorCampus?.value || '';
        const option = <HTMLElement>seletor.querySelector(`option[value="${valor}"]`);
        const link = option?.dataset.link || '';
        seletor.dataset.link = link;

        // @ts-ignore: Unreachable code error
        const nonce = CalcEnemC.nGetPeriodoCurso || '';

        const data = new FormData();
        data.append('action', 'getPeriodoCurso');
        data.append('nonce', nonce);
        data.append('codCurso', valor);
        data.append('campus', campus);

        await CreateAjax(data)
            .then(response => {
                if (response.length == 0) {
                    seletorPeriodo && (seletorPeriodo.innerHTML = '<option disabled="" selected="" hidden="">Nenhum periodo encontrado</option>');
                    return;
                }

                seletorPeriodo && (seletorPeriodo.innerHTML = '<option disabled="" selected="" hidden=""></option>');
                seletorPeriodoLabel?.classList.remove('calculadora-enem-c__step-label--focus');

                response.forEach((periodo: any) => {
                    const option = document.createElement('option');
                    option.textContent = periodo;

                    switch (periodo) {
                        case 'Matutino':
                            option.value = '1';
                            break;
                        case 'Vespertino':
                            option.value = '2';
                            break;
                        case 'Noturno':
                            option.value = '3';
                            break;
                    }
                    seletorPeriodo?.appendChild(option);
                })
            });
    })
}

export const CalculadoraEnemCBuscaPeriodo = (modulo: HTMLElement) => {
    const formulario = <HTMLElement>modulo.querySelector('.calculadora-enem-c__formulario');
    const fSeletorCampus = <HTMLSelectElement | null>formulario.querySelector('.calculadora-enem-c__step-select#campus');
    const fSeletorPeriodo = <HTMLSelectElement | null>formulario.querySelector('.calculadora-enem-c__step-select#periodo');
    const fSeletorPeriodoLabel = <HTMLElement | null>fSeletorPeriodo?.parentElement?.querySelector('label');
    const fSeletor = <HTMLSelectElement>formulario.querySelector('.calculadora-enem-c__step-select#curso');

    CalculadoraEnemCBuscaPeriodoAcao(fSeletorCampus, fSeletorPeriodo, fSeletorPeriodoLabel, fSeletor);

    const trocaCurso = <HTMLElement>modulo.querySelector('.calculadora-enem-c__troca-curso');
    const tSeletorCampus = <HTMLSelectElement | null>trocaCurso.querySelector('.calculadora-enem-c__step-select#campus');
    const tSeletorPeriodo = <HTMLSelectElement | null>trocaCurso.querySelector('.calculadora-enem-c__step-select#periodo');
    const tSeletorPeriodoLabel = <HTMLElement | null>tSeletorPeriodo?.parentElement?.querySelector('label');
    const tSeletor = <HTMLSelectElement>trocaCurso.querySelector('.calculadora-enem-c__step-select#curso');

    CalculadoraEnemCBuscaPeriodoAcao(tSeletorCampus, tSeletorPeriodo, tSeletorPeriodoLabel, tSeletor);
}

const CalculadoraEnemC = (modulo: HTMLElement) => {
    CalculadoraEnemCFocuInput(modulo);
    CalculadoraEnemCMudarStep(modulo);
    CalculadoraEnemCSubmit(modulo);
    CalculadoraEnemCTrocaCurso(modulo);
    CalculadoraEnemCBuscaCursos(modulo);
    CalculadoraEnemCBuscaPeriodo(modulo);
}

export default CalculadoraEnemC;