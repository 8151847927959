let sliderOptions: JQuerySlickOptions = {
  fade: true,
  cssEase: 'linear',
  speed: 500,
  adaptiveHeight: true,
  dots: false,
  infinite: false,
  prevArrow: '<button type="button" class="informacoes-rapidas-coluna-dois-item-conteudos-slider__seta informacoes-rapidas-coluna-dois-item-conteudos-slider__seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
  nextArrow: '<button type="button" class="informacoes-rapidas-coluna-dois-item-conteudos-slider__seta informacoes-rapidas-coluna-dois-item-conteudos-slider__seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>'
};

export const PdpInformacoesRapidasCursosGraduacaoBase = () => {
  let sliders = <NodeListOf<HTMLElement>>document.querySelectorAll('.informacoes-rapidas-coluna-dois .informacoes-rapidas-coluna-dois-item-conteudos-slider .informacoes-rapidas-coluna-dois-item-conteudos');

  if (sliders.length > 1) {
    $('.informacoes-rapidas-coluna-dois-item-conteudos-slider').slick(sliderOptions);
  }

  $('.informacoes-rapidas-coluna-dois-item-conteudos-slider')
    .on('afterChange', () => {
      let sliderAtivo: HTMLElement = screen.width > 992
        ? <HTMLElement>document.querySelector('.informacoes-rapidas-coluna-dois-item-conteudos.slick-active')
        : <HTMLElement>document.querySelector('.sidebar-pdp-conteudo .informacoes-rapidas-coluna-dois-item-conteudos.slick-active');

      let turno = sliderAtivo.dataset.turno || '';
      let elementoTurnos = sliderAtivo?.parentElement?.parentElement?.parentElement?.parentElement
        ?.parentElement?.nextElementSibling?.children[1]?.children[1] || null;

      elementoTurnos && (elementoTurnos.innerHTML = turno);
    })
}