export const BuscaPorPolosCarregandoMapa = () => {
  jQuery(() => $('.busca-por-polos__mapa')
    .vectorMap({
      map: 'brazil',
      zoomButtons: false,
      zoomOnScroll: false,
      regionStyle: {
        initial: {
          fill: '#009CDE',
          'fill-opacity': 1,
          stroke: '#FFF',
          'stroke-width': 250,
          'stroke-opacity': 1
        },
        hover: {
          fill: '#003B71',
          "fill-opacity": 1,
          cursor: 'pointer'
        }
      },
      backgroundColor: 'none',
      onRegionOver: function (evento: Event, code: string) {
        let regiao = <any>document.querySelector(`.jvectormap-container svg g path[data-code="${code}"]`);
        let dadosRegiao = <any>regiao.getBoundingClientRect();
        let ancora = <HTMLElement>document.querySelector('.busca-por-polos__mapa-tooltip-ancora');
        let tooltip = <HTMLElement>document.querySelector('.busca-por-polos__mapa-tooltip');

        regiao.dataset.ativo = 'true';
        ancora.style.top = `${dadosRegiao.top}px`;
        ancora.style.left = `${dadosRegiao.left}px`;
        ancora.style.height = `${dadosRegiao.height}px`;
        ancora.style.width = `${dadosRegiao.width}px`;

        if (window.innerWidth > 992) {
          ancora.style.display = 'flex';
        } else {
          tooltip.style.transform = '';
          tooltip.style.borderRadius = '';

          if ((dadosRegiao.left + 160) >= window.innerWidth) {
            tooltip.style.transform = 'translate(-100%, 0)';
            tooltip.style.borderRadius = '5px 5px 0 5px';
          } else {
            tooltip.style.transform = 'translate(0, 0)';
            tooltip.style.borderRadius = '5px 5px 5px 0';
          }

          let display = setTimeout(() => {
            ancora.style.display = 'flex';
            clearTimeout(display);
          }, 2);
        }

        let nomeEstadoElemento = <HTMLElement>document.querySelector('.mapa-tooltip__titulo');
        let quantidadePolosSpan = <HTMLSpanElement>document.querySelector('.mapa-tooltip__quantidade-polos');

        nomeEstadoElemento.textContent = regiao.dataset.nomeEstado;
        quantidadePolosSpan.textContent = regiao.dataset.quantidadePolos;
      },
      onRegionOut: function () {
        let ancora = <HTMLElement>document.querySelector('.busca-por-polos__mapa-tooltip-ancora');
        let regiao = <any>document.querySelector(`.jvectormap-container svg g path[data-ativo="true"]`);
        regiao.dataset.ativo = 'false';

        if (window.innerWidth > 992) {
          ancora.style.display = 'none';
        } else {
          let acao = setTimeout(() => {
            ancora.style.display = 'none';
            clearTimeout(acao);
          }, 1);
        }
      },
      onRegionTipShow: function (evento: Event, elemento: any, code: string) {
        elemento.style.display = 'none';
      }
    })
  );
}