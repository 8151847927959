export const StartSliderNossasUnidadesFooter = () => {
  const sliderNossasUnidadesOptions: JQuerySlickOptions = {
    infinite: false,
    slidesToShow: 3.34,
    slidesToScroll: .66,
    variableWidth: false,
    dots: false,
    arrows: true,
    autoplay: false,
    swipe: true,
    swipeToSlide: true,
    touchMove: true,
    prevArrow: '<button type="button" class="itens__seta itens__seta--anterior"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0007.svg" alt="Anterior" /></button>',
    nextArrow: '<button type="button" class="itens__seta itens__seta--proximo"><img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0006.svg" alt="Proximo" /></button>',
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: .8
      }
    }],
  };

  let quantidadeItens = <Number>document.querySelectorAll('.footer__nossas-unidades .itens .unidade').length;

  if (
    quantidadeItens > 2 ||
    (
      quantidadeItens > 1 &&
      screen.width <= 992
    )
  ) {
    $('.footer__nossas-unidades .itens').slick(sliderNossasUnidadesOptions);
  }
}