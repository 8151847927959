const AtivarTopico = () => {
  let tabs = <NodeListOf<HTMLElement>>document
    .querySelectorAll('#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-sbtn');

  const Acao = (evento: Event) => {
    let tabClicada = <HTMLElement>evento.target;
    let index = tabClicada.dataset.index || '0';

    let botaoItemAtivo = <HTMLElement>document
      .querySelector(`#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-s${index}-btn`);

    let itensAll = <NodeListOf<HTMLElement>>document
      .querySelectorAll('#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-itensall');

    let itemAtivo = <HTMLElement>document
      .querySelector(`#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-item${index}`);

    tabs.forEach(tab => tab.classList.remove('active'));
    botaoItemAtivo.classList.add('active');
    itensAll.forEach(item => item.classList.remove('active'));
    itensAll.forEach(item => item.classList.add('inactive'));
    itemAtivo.classList.remove('inactive');
    itemAtivo.classList.add('active');
  }

  tabs.forEach(tab => tab.addEventListener(
    'click',
    (evento: Event) => Acao(evento)
  ));
}

const AtivarDuvida = () => {
  let duvidas = <NodeListOf<HTMLElement>>document
    .querySelectorAll("#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-ibtn")

  const Acao = (evento: Event) => {
    let duvidaClicada = <HTMLElement>evento.target;
    let tab = duvidaClicada.dataset.tab || '0';
    let duvida = duvidaClicada.dataset.duvida || '0';

    let duvidaBotao = <HTMLElement>document
      .querySelector(`#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-i${tab}d${duvida}btn`);

    let duvidaConteudo = <HTMLElement>document
      .querySelector(`#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-i${tab}d${duvida}conteudo`);

    duvidaBotao.classList.toggle('active');
    duvidaConteudo.classList.toggle('active');
  }

  duvidas.forEach(duvida => duvida.addEventListener(
    'click',
    (evento: Event) => Acao(evento)
  ));
}

const ColsSet = () => {
  let todosOsItens = <NodeListOf<HTMLElement>>document.querySelectorAll('#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-itensall');
  let primeiroItem = <HTMLElement>document.querySelector('#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-item0');
  let botaoPrimeiroItem = <HTMLElement | null>document.querySelector('#PrincipaisDuvidas.duvidasPdp .pduvidas-colduvidas-s0-btn');

  if (todosOsItens.length) {
    todosOsItens.forEach(item => item.classList.add('inactive'));
    primeiroItem.classList.remove('inactive');
    primeiroItem.classList.add('active');
    botaoPrimeiroItem?.classList.add('active');
  }
}

export const DuvidasPdp = () => {
  ColsSet();
  AtivarTopico();
  AtivarDuvida();
}