import { BuscaPorPolosCarregandoMapa } from "./buscaPorPolosCarregandoMapa";
import { BuscaPorPoloFormulario } from "./buscaPorPolosFormulario";
import { BuscaPorPolosScrollTooltip } from "./buscaPorPolosScrollTooltip";
import { BuscaPorPolosSetClick } from "./buscaPorPolosSetClick";

export const BuscaPorPolos = () => {
  BuscaPorPolosCarregandoMapa();
  BuscaPorPolosSetClick();
  BuscaPorPoloFormulario();
  BuscaPorPolosScrollTooltip();
}
