import { CreateAjax } from "../../Utils/createAjax";

/**
 * Inclui parâmetros de UTM nas urls de links
 * @param {string} url - url para incluir os parâmetros de UTM
 * @returns {string} url com os parâmetros de UTM incluídos
 */
const incluirUtmNasUrls = (url: string) => {
  // UtmUrlParameters é criado pelo setUtm.js
  let utmsDaPagina = decodeURIComponent(window.UtmUrlParameters);

  url += url.includes("?") ? '' : '?';
  url += ((url.slice(-1) == '?') ? '' : '&') + utmsDaPagina;

  return url;
}

/**
 * Gera as estrelas de conceito ENADE com base nos dados informados
 * @param {string} dadosEnade - Dados de ENADE em forma de string JSON
 * @returns {string} HTML com as estrelas de conceito ENADE
 */
const estrelasEnade = (dadosEnade) => {
  if (!dadosEnade) {
    return '';
  }

  let conceitoEnade = 0;
  let notasEnade = JSON.parse(dadosEnade);
  let notaMec = '';

  notasEnade.forEach((enade) => {
    let tmpConceitoEnade = enade.cpcEnad > enade.infCglo ? enade.cpcEnad : enade.infCglo;
    conceitoEnade = tmpConceitoEnade > conceitoEnade ? tmpConceitoEnade : conceitoEnade;
  })

  if (conceitoEnade == 3) {
    notaMec = '<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 34 9.6" xml:space="preserve"><path class="st0" d="M5.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6L7.8 6c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4L5.2 8.2c-.1-.1-.2-.1-.3 0L2.3 9.5c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3L.1 4c-.2-.2-.1-.6.2-.6L3.2 3c.1 0 .2-.1.3-.2L4.8.2c0-.2.4-.2.5 0zM17.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L16.8.2c0-.2.4-.2.5 0zM29.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L28.8.2c0-.2.4-.2.5 0z"/></svg><p class="conceito-mec-texto">Conceito 3 na classificação do MEC</p>';
  } else if (conceitoEnade == 4) {
    notaMec = '<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 46 9.6" xml:space="preserve"><path class="st0" d="M5.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6L7.8 6c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4L5.2 8.2c-.1-.1-.2-.1-.3 0L2.3 9.5c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3L.1 4c-.2-.2-.1-.6.2-.6L3.2 3c.1 0 .2-.1.3-.2L4.8.2c0-.2.4-.2.5 0zM17.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L16.8.2c0-.2.4-.2.5 0zM29.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L28.8.2c0-.2.4-.2.5 0zM41.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L40.8.2c0-.2.4-.2.5 0z"/></svg><p class="conceito-mec-texto">Conceito 4 na classificação do MEC</p>';
  } else if (conceitoEnade == 5) {
    notaMec = '<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 58 9.6" xml:space="preserve"><path class="st0" d="M5.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6L7.8 6c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4L5.2 8.2c-.1-.1-.2-.1-.3 0L2.3 9.5c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3L.1 4c-.2-.2-.1-.6.2-.6L3.2 3c.1 0 .2-.1.3-.2L4.8.2c0-.2.4-.2.5 0zM17.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L16.8.2c0-.2.4-.2.5 0zM29.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L28.8.2c0-.2.4-.2.5 0zM41.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L40.8.2c0-.2.4-.2.5 0zM53.3.2l1.3 2.6c0 .1.1.2.2.2l2.9.4c.3 0 .4.4.2.6l-2.1 2c-.1.1-.1.2-.1.3l.5 2.9c0 .3-.2.5-.5.4l-2.5-1.4c-.1-.1-.2-.1-.3 0l-2.6 1.3c-.3.1-.6-.1-.5-.3l.5-2.9c0-.1 0-.2-.1-.3l-2.1-2c-.2-.2-.1-.6.2-.6l2.9-.4c.1 0 .2-.1.3-.2L52.8.2c0-.2.4-.2.5 0z"/></svg><p class="conceito-mec-texto">Conceito 5 na classificação do MEC</p>';
  } else {
    notaMec = '<img src="/wp-content/themes/cruzeiroportais2019/assets/imagens/estrela_vazia.png">';
  }

  return notaMec;
}

/**
 * Carrega o slider da vitrine de cursos.
 * 
 * @param {HTMLElement} modulo O módulo que contém a vitrine de cursos.
 */
const carregarSlider = (modulo: HTMLElement) => {
  const vitrine: HTMLElement | null = modulo.querySelector('.vitrine-encontre-o-curso');

  if (vitrine) {
    $(vitrine).slick({
      infinite: true,
      slidesToScroll: 1,
      adaptiveHeight: true,
      variableWidth: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
    });
  }
}

/**
 * Carrega a visualização do curso ao clicar no botão de visualização de curso.
 * 
 * @param {HTMLElement} modulo 
 * @returns {void}
 */
const visualizarCurso = (modulo: HTMLElement) => {
  const botaoVisualizarCurso: HTMLButtonElement | null = modulo.querySelector('.sidebar > .formulario-busca-curso > .formulario-botao');
  if (!botaoVisualizarCurso) {
    return;
  }

  /**
   * Event listener de click da visualização do curso
   */
  botaoVisualizarCurso.addEventListener('click', () => {
    const textoBotaoVisualizarCurso: HTMLElement | null = botaoVisualizarCurso.querySelector('.texto');
    const curso: HTMLSelectElement | null = document.querySelector('select[name="selecione-o-curso"].formulario-item-campo');
    if (!textoBotaoVisualizarCurso || !curso) return;

    botaoVisualizarCurso.classList.add('form-btnloading');
    botaoVisualizarCurso.disabled = true;
    textoBotaoVisualizarCurso.textContent = 'Aguarde...';

    const [codCurso, slugCurso] = curso.value.split('|');
    let dados = new FormData();
    dados.append('action', 'getDadosCurso');
    dados.append('codCurso', codCurso);
    dados.append('slugCurso', slugCurso);

    CreateAjax(dados)
      .then(retorno => {
        const curso = retorno[0];
        const titulo: HTMLElement | null = modulo.querySelector('.resultado > .conteudo > .header > .titulo');
        const texto: HTMLElement | null = modulo.querySelector('.resultado > .conteudo > .texto');
        if (!titulo || !texto) return;

        botaoVisualizarCurso.classList.remove('form-btnloading');
        botaoVisualizarCurso.disabled = false;
        textoBotaoVisualizarCurso.textContent = 'Visualizar curso';

        titulo.textContent = curso.descMkt;
        texto.innerHTML = `${curso.sobreCurso.slice(0, 520)}...`;

        const cardInfo: HTMLElement | null = modulo.querySelector('.resultado > .sidebar > .card-info');
        if (!cardInfo) return;

        const modalidade: HTMLElement | null = cardInfo?.querySelector('.conteudo > .infos > .item:first-child .texto');
        const duracaoCurso: HTMLElement | null = cardInfo?.querySelector('.conteudo > .infos > .item:last-child .texto');
        const imagem: HTMLImageElement | null = modulo.querySelector('.resultado > .sidebar > .card-info > .midia > .imagem');
        const notaMec: HTMLElement | null = modulo.querySelector('.resultado > .conteudo > .header > .conceito-mec');
        if (!modalidade || !duracaoCurso || !imagem || !notaMec) return;

        modalidade.textContent = curso.nomeModalidade;
        duracaoCurso.textContent = `${curso.duracaoCurso} semestres`;
        notaMec.innerHTML = estrelasEnade(curso.enade);

        if (curso.imagem || curso.eadimgurl || curso.semiimgurl || curso.aovivoimgurl) {
          imagem.src = curso.imagem || curso.eadimgurl || curso.semiimgurl || curso.aovivoimgurl;
        }

        const campoModalidade: HTMLSelectElement | null = modulo.querySelector('select[name="selecione-a-modalidade"].formulario-item-campo');
        if (!campoModalidade) return;

        const modalidadeCurso: HTMLElement | null = cardInfo.querySelector('.conteudo > .infos > .item:nth-child(2) .texto');
        const botaoSaibaMaisCurso: HTMLAnchorElement | null = modulo.querySelector('.resultado > .conteudo > .botao');
        const botaoInscrevaSeCurso: HTMLAnchorElement | null = cardInfo.querySelector('.conteudo > .botoes > .botao:first-child');
        const botaoVerInvestimentoCurso: HTMLAnchorElement | null = cardInfo.querySelector('.conteudo > .botoes > .botao:last-child');
        if (!modalidadeCurso || !botaoSaibaMaisCurso || !botaoInscrevaSeCurso || !botaoVerInvestimentoCurso) return;

        if (campoModalidade.value == 'Presencial') {
          modalidadeCurso.textContent = 'Presencial';

          // UtmUrlParameters é criado pelo setUtm.js
          let utmsDaPagina = decodeURIComponent(window.UtmUrlParameters);

          botaoSaibaMaisCurso?.setAttribute('href', `/graduacao/${curso.post_name}/?${utmsDaPagina}`);
          botaoInscrevaSeCurso?.setAttribute('href', `/graduacao/${curso.post_name}/?${utmsDaPagina}`);
          botaoVerInvestimentoCurso?.setAttribute('href', `/graduacao/${curso.post_name}/?${utmsDaPagina}`);
        }

        if (campoModalidade.value == 'EAD' || campoModalidade.value == 'EAD Digital') {
          let url: string = incluirUtmNasUrls(curso.eadurl ?? '');

          modalidadeCurso.textContent = 'EAD Digital';
          botaoSaibaMaisCurso.setAttribute('href', url);
          botaoInscrevaSeCurso.setAttribute('href', url);
          botaoVerInvestimentoCurso.setAttribute('href', url);
        }

        if (campoModalidade.value == 'Semipresencial EAD 4.0' || campoModalidade.value == 'EAD Semipresencial') {
          let url: string = incluirUtmNasUrls(curso.semiurl ?? '');

          modalidadeCurso.innerHTML = 'EAD Semipresencial';
          botaoSaibaMaisCurso.setAttribute('href', url);
          botaoInscrevaSeCurso.setAttribute('href', url);
          botaoVerInvestimentoCurso.setAttribute('href', url);
        }

        if (campoModalidade.value == 'Semipresencial EAD 4.0i' || campoModalidade.value == 'EAD com aulas ao vivo') {
          let url: string = incluirUtmNasUrls(curso.aovivourl ?? '');

          modalidadeCurso.innerHTML = 'EAD com aulas ao vivo';
          botaoSaibaMaisCurso.href = url;
          botaoInscrevaSeCurso.href = url;
          botaoVerInvestimentoCurso.href = url;
        }

        const vitrine: HTMLElement | null = modulo.querySelector('.vitrine-encontre-o-curso');
        const resultado: HTMLElement | null = modulo.querySelector('article.resultado');
        if (!vitrine || !resultado) return;

        vitrine.style.display = 'none';
        resultado.style.display = 'grid';
      });
  });
}

/**
 * Responsável por adicionar os event listeners para todos os selects, que buscam informação do próximo select ao mudar o select disponível.
 * 
 * @param {HTMLElement}modulo 
 * @returns {void}
 */
const listenersSelects = (modulo: HTMLElement) => {
  const campoSelecaoTurno: HTMLSelectElement | null = modulo.querySelector('select[name="selecione-o-turno"].formulario-item-campo');
  const campoSelecaoUnidade: HTMLSelectElement | null = modulo.querySelector('select[name="selecione-a-unidade"].formulario-item-campo');
  const campoSelecaoCurso: HTMLSelectElement | null = modulo.querySelector('select[name="selecione-o-curso"].formulario-item-campo');
  const campoSelecaoModalidade: HTMLSelectElement | null = modulo.querySelector('select[name="selecione-a-modalidade"].formulario-item-campo');
  if (!campoSelecaoTurno || !campoSelecaoUnidade || !campoSelecaoCurso || !campoSelecaoModalidade) return;

  campoSelecaoTurno.addEventListener('change', () => {
    const botao: HTMLButtonElement | null = modulo.querySelector('.sidebar > .formulario-busca-curso > .formulario-botao');
    if (!botao) return;

    botao.style.opacity = '1';
    botao.disabled = false;
  });

  campoSelecaoUnidade.addEventListener('change', (event) => {
    const divSelecaoTurno: HTMLElement | null = campoSelecaoTurno.parentElement;
    if (!divSelecaoTurno) return;

    if (campoSelecaoUnidade.value == 'virtual') {
      const botao: HTMLButtonElement | null = modulo.querySelector('.sidebar > .formulario-busca-curso > .formulario-botao');
      if (!botao) return;

      divSelecaoTurno.style.display = 'none';
      botao.style.opacity = '1';
      botao.disabled = false;
      return;
    }

    divSelecaoTurno.style.display = 'grid';
    divSelecaoTurno.style.opacity = '1';
    campoSelecaoTurno.disabled = false;

    let curso = campoSelecaoCurso.value.split('|')[0] ?? 0;
    const nonceCampo: HTMLInputElement | null = document.querySelector('#wp_nonce_get_periodo_curso');
    if (!nonceCampo) return;
    const nonce = nonceCampo.value;

    let dados = new FormData();
    dados.append('action', 'getPeriodoCurso');
    dados.append('codCurso', curso);
    dados.append('nonce', nonce);

    CreateAjax(dados)
      .then((retorno: string[]) => {
        campoSelecaoTurno.innerHTML = '';
        let option = document.createElement('option');
        option.setAttribute('value', '');
        option.setAttribute('disabled', '');
        option.setAttribute('selected', 'true');
        option.setAttribute('hidden', '');
        campoSelecaoTurno.appendChild(option);

        retorno.forEach(elemento => {
          let option = document.createElement('option');
          option.setAttribute('value', elemento);
          option.textContent = elemento;
          campoSelecaoTurno.appendChild(option);
        });
      });
  });

  campoSelecaoCurso.addEventListener('change', (event) => {
    campoSelecaoUnidade.innerHTML = '';

    let curso = event.target.value.split('|')[0] ?? 0;

    let option = document.createElement('option');
    option.setAttribute('value', '');
    option.setAttribute('disabled', '');
    option.setAttribute('selected', 'true');
    option.setAttribute('hidden', '');
    campoSelecaoUnidade.appendChild(option);

    if (campoSelecaoModalidade.value == 'EAD' || campoSelecaoModalidade.value == 'EAD Digital') {
      let option = document.createElement('option');
      option.setAttribute('value', 'virtual');
      option.textContent = 'Cruzeiro do Sul Virtual';
      campoSelecaoUnidade.appendChild(option);

      const divSelecaoUnidade: HTMLElement | null = campoSelecaoUnidade.parentElement;
      if (!divSelecaoUnidade) return;

      divSelecaoUnidade.style.opacity = '1';
      campoSelecaoUnidade.disabled = false;
      return;
    }

    let dados = new FormData();
    dados.append('action', 'getUnidadeCurso');
    dados.append('curso', curso);

    CreateAjax(dados)
      .then((retorno) => {
        const divSelecaoUnidade: HTMLElement | null = campoSelecaoUnidade.parentElement;
        if (!divSelecaoUnidade) return;

        if (!retorno) {
          let option = document.createElement('option');
          option.setAttribute('value', 'virtual');
          option.textContent = 'Cruzeiro do Sul Virtual';
          campoSelecaoUnidade.appendChild(option);

          divSelecaoUnidade.style.opacity = '1';
          campoSelecaoUnidade.disabled = false;
          return;
        }

        retorno.forEach((elemento) => {
          let option = document.createElement('option');
          option.setAttribute('value', elemento.codEstudo);
          option.textContent = elemento.campusEstudo;
          campoSelecaoUnidade.appendChild(option);

          divSelecaoUnidade.style.opacity = '1';
          campoSelecaoUnidade.disabled = false;
        });
      });
  });

  campoSelecaoModalidade.addEventListener('change', (event) => {
    let dados = new FormData();
    dados.append('action', 'getCursosModalidade');
    dados.append('modalidade', campoSelecaoModalidade.value);

    CreateAjax(dados)
      .then(retorno => {
        const divSelecaoCurso: HTMLElement | null = campoSelecaoCurso.parentElement;
        if (!divSelecaoCurso) return;

        divSelecaoCurso.style.opacity = '1';
        campoSelecaoCurso.disabled = false;

        campoSelecaoCurso.innerHTML = '';
        let option = document.createElement('option');
        option.setAttribute('value', '');
        option.setAttribute('disabled', 'true');
        option.setAttribute('selected', '');
        option.setAttribute('hidden', '');
        campoSelecaoCurso.append(option);

        retorno.forEach((elemento) => {
          let modalidade = elemento.modalidade.toUpperCase().slice(0, 1) + elemento.modalidade.toLowerCase().slice(1);

          let option = document.createElement('option');
          option.setAttribute('value', `${elemento.codCurs}|${elemento.post_name}`);
          option.textContent = `${elemento.descMkt} - ${modalidade}`;
          campoSelecaoCurso.appendChild(option);
        })
      });
  });
}


export const EncontreOCurso = (modulo: HTMLElement) => {
  carregarSlider(modulo);
  visualizarCurso(modulo);
  listenersSelects(modulo);
}