import { VisibilidadeElementoScroll } from "../../Utils/visibilidadeElementoScroll";

/**
 * Responsável por controlar a exibicao do bot o flutuante
 * com base na posicao do scroll.
 * @function
 */
export const BotaoFlutuante = () => {
  const Acao = () => {
    let btnFloat = <HTMLElement>document.querySelector('.div-btn-floating');

    if (window.innerWidth <= 992) {
      VisibilidadeElementoScroll(btnFloat, 500, 'div-btn-floation--active');
    } else {
      VisibilidadeElementoScroll(btnFloat, 785, 'div-btn-floation--active');
    }
  }

  window.addEventListener('scroll', () => Acao());
}