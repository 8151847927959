import { TesteVocacionalTelasSegundoFormulario } from "./testeVocacionalTelas";

const TesteVocacionalResultados = (modulo: HTMLElement) => {
  // @ts-ignore: Unreachable code error
  const textoResultado = JSON.parse(TesteVocacional.resultado);
  const botaoVerResultado = <HTMLButtonElement>modulo.querySelector('.tv-pagina:last-child .tv-botao-proxima');

  botaoVerResultado?.addEventListener('click', () => {
    const campoUf = <HTMLSelectElement | null>modulo.querySelector('.formulario #uf');
    const campoCidade = <HTMLSelectElement | null>modulo.querySelector('.formulario #cidade');
    (campoUf != null && campoCidade != null) && TesteVocacionalTelasSegundoFormulario(modulo);

    while (!document.querySelector('#tv-resultado')) { } // Travando a execução do script ate a criação dinamica do elemento, quando ele estiver no DOM segue com a execução.

    if (textoResultado.titulo.trim() !== '') {
      // @ts-ignore: Unreachable code error
      document.querySelector('.tv-resultado-texto-destaque').innerHTML = textoResultado.titulo;
    }

    if (textoResultado.subtitulo.trim() !== '') {
      // @ts-ignore: Unreachable code error
      document.querySelector('.tv-resultado-texto').innerHTML = textoResultado.subtitulo;
    }

    if (textoResultado.chamada.trim() !== '') {
      // @ts-ignore: Unreachable code error
      document.querySelector('.tv-resultado-conheca-cursos a').innerHTML = textoResultado.chamada;
    }

    if (textoResultado.detalhes.trim() !== '') {
      // @ts-ignore: Unreachable code error
      document.querySelector('.tv-resultado-texto a').innerHTML = textoResultado.detalhes;
    }

    if (textoResultado.botaoTexto.trim() !== '') {
      // @ts-ignore: Unreachable code error
      document.querySelector('.tv-resultado-texto a').dataset.botaoTexto = textoResultado.botaoTexto;
    }

    const pesquisa = <HTMLElement>modulo.querySelector('.perguntas ');
    const idVitrine = pesquisa?.dataset?.idVitrine || '';
    if (idVitrine !== '') {
      document.querySelector('.tv-resultado-texto a')?.setAttribute('id', idVitrine);
    }

    if (
      textoResultado.botaoLink.trim() !== '' &&
      textoResultado.botaoLink.trim() !== '""'
    ) {
      const link = JSON.parse(textoResultado.botaoLink);

      // @ts-ignore: Unreachable code error
      (link.target !== undefined) && document.querySelector('.tv-resultado-texto a').setAttribute('target', link.target);
      // @ts-ignore: Unreachable code error
      (link.url !== undefined) && document.querySelector('.tv-resultado-texto a').setAttribute('href', link.url);
    }

    let parsedQS = document.cookie.split('parsedQS=')[1].split(';')[0];

    let botaoVerCursos = document.querySelector('.tv-resultado-texto a');
    let linkBotaoVerCursos = botaoVerCursos?.getAttribute('href');
    botaoVerCursos?.setAttribute('href', linkBotaoVerCursos + '&' + encodeURIComponent(parsedQS));

    let botaoVerCursosTexto = document.querySelector('.tv-resultado-conheca-cursos a');
    let linkBotaoVerCursosTexto = botaoVerCursosTexto?.getAttribute('href');
    botaoVerCursosTexto?.setAttribute('href', linkBotaoVerCursosTexto + '&' + encodeURIComponent(parsedQS));

    if (window.location !== window.parent.location) {
      // @ts-ignore: Unreachable code error
      document.querySelector('.tv-resultado-texto a').setAttribute('target', '_blank')
    }
  });
}

export default TesteVocacionalResultados;