import { SetCookie } from "../../Utils/cookies";
import { DadoDeLead, EnviaLead } from "../../Utils/enviaLead";
import { SetFormaIngresso } from "../../Utils/formaDeIngressoSf";
import { verificaEmailSalesforce } from "../../Utils/verificaEmailSalesforce";
import { ModalCalculadoraEnemVerificarCamposTipoRegex } from "../ModalCalculadoraEnemNovo/ModalCalculadoraEnemVerificarCampos";
import { CalculadoraEnemAtualizaDots } from "./CalculadoraEnemAtualizaDots";
import { CalculadoraEnemValidar } from "./CalculadoraEnemValidar";

export const CalculadoraEnemMudaPassos = (orientacao) => {
    SetFormaIngresso('ENEM');

    let formulario = <HTMLElement>document.querySelector('.calculadora-enem__formulario');
    let passoAtual = parseInt(formulario.dataset.passoAtual ?? '0');
    let quantidadePassos = document.querySelectorAll('.calculadora-enem__passo').length;
    let identificador = <HTMLInputElement>document.querySelector('#calculadora-enem__identificador');

    let numeroProximoPasso = passoAtual >= 3 ? 3 : passoAtual + 1;
    if (orientacao == 'voltar') {
        numeroProximoPasso = passoAtual <= 0 ? 0 : passoAtual - 1;
    }

    let textoBotaoAvancar = document.querySelector('.calculadora-enem__botao--avancar')?.textContent;
    if (textoBotaoAvancar == 'Alterar') {
        numeroProximoPasso = 3;
        let botaoAvancar = <HTMLElement>document.querySelector('.calculadora-enem__botao--avancar');
        botaoAvancar.textContent = 'Avançar';
    }

    let elementoPassoAtual = document.
        querySelector(`.calculadora-enem__passo[data-numero-passo="${passoAtual}"]`);
    let proximoPasso = document.
        querySelector(`.calculadora-enem__passo[data-numero-passo="${numeroProximoPasso}"]`);

    // altera visibilidade dos passos
    elementoPassoAtual?.classList.remove('calculadora-enem__passo--ativo');
    proximoPasso?.classList.add('calculadora-enem__passo--ativo');

    // define numero do passo no dataset do form
    formulario.dataset.passoAtual = String(numeroProximoPasso);

    // desativa botão de avançar
    document.querySelector('.calculadora-enem__botao--avancar')?.
        classList.add('calculadora-enem__botao--inativo');

    // Atualização dos dots
    CalculadoraEnemAtualizaDots(numeroProximoPasso, quantidadePassos);

    // habilita botão de avançar se tudo estiver preenchido corretamente ao voltar no passo
    CalculadoraEnemValidar(numeroProximoPasso, false);

    // Atualizacao do titulo do passo
    document.querySelector(`.calculadora-enem__titulo--etapa[data-numero-passo="${passoAtual}"]`)?.
        classList.remove('calculadora-enem__titulo--etapa--ativo');
    document.querySelector(`.calculadora-enem__titulo--etapa[data-numero-passo="${numeroProximoPasso}"]`)?.
        classList.add('calculadora-enem__titulo--etapa--ativo');

    if (passoAtual == 0 && numeroProximoPasso == 1) {
        let emailCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__input--email')
        let nonceLeadByEmail = <HTMLInputElement>document.querySelector('#wp_nonce_lead_by_email')

        verificaEmailSalesforce(emailCampo.value, nonceLeadByEmail.value).then((retorno) => {
            if (retorno.Id) {
                sessionStorage.setItem('leadAtualId', retorno.Id);

                return;
            }

            sessionStorage.setItem('leadAtualId', '');

            let nomeCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__input--nome');
            let telefoneCampo = <HTMLInputElement>document.querySelector('#calculadora-enem__input--celular');

            let dadosLead = Array();

            if (!ModalCalculadoraEnemVerificarCamposTipoRegex[nomeCampo.type].test(nomeCampo.value.trim())) {
                return 'Nome inválido. Por favor, tente novamente.';
            }

            dadosLead.push({
                name: 'nome',
                value: nomeCampo.value
            });

            if (!ModalCalculadoraEnemVerificarCamposTipoRegex[telefoneCampo.type].test(telefoneCampo.value.trim())) {
                return 'Celular inválido. Por favor, tente novamente.'
            }

            dadosLead.push({
                name: 'telefone',
                value: telefoneCampo.value
            });

            if (!ModalCalculadoraEnemVerificarCamposTipoRegex[emailCampo.type].test(emailCampo.value.trim())) {
                return 'Email inválido. Por favor, tente novamente.'
            }

            dadosLead.push({
                name: 'email',
                value: emailCampo.value
            });

            EnviaLead(
                identificador?.value,
                'cadastro-desconto',
                dadosLead
            ).then((retorno) => {
                if (retorno.LeadId) {
                    sessionStorage.setItem('leadAtualId', retorno.LeadId);
                }
            });
        });
    }

    if (numeroProximoPasso == 3) {
        document.querySelector('.calculadora-enem__botoes')?.
            classList.add('calculadora-enem__botoes--oculto');
        let campos = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
            .querySelectorAll('.calculadora-enem__passos input, .calculadora-enem__passos select');

        let leadAtual = sessionStorage.getItem('leadAtual') || '[]';
        let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

        campos.forEach((elemento) => {
            let elementoExistente = dadosLead.find(dado => dado.name == elemento.name);

            if (elementoExistente) {
                elementoExistente.value = elemento.value;
            }

            if (!elementoExistente) {
                let dadoLead: DadoDeLead = {
                    name: elemento.name,
                    value: elemento.value
                }
                dadosLead.push(dadoLead);
            }
        });

        sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
        SetCookie('leadAtual', JSON.stringify(dadosLead), 1);

        if (passoAtual == 2) {
            EnviaLead(
                identificador?.value,
                'cadastro-desconto',
                dadosLead
            ).then((retorno) => {
                if (retorno['LeadId']) {
                    localStorage.setItem('idLead', retorno['LeadId']);
                }
            });
        }
    } else {
        document.querySelector('.calculadora-enem__botoes')?.
            classList.remove('calculadora-enem__botoes--oculto');
    }
}