import { CreateAjax } from "../../Utils/createAjax";

const conteudoDatas = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-datas');

const boxAcaoBack = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-box-acao-back');

const conteudoItems = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-items');

const conteudo = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo');

const conteudoItemsPorAno = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-items-por-ano');

const botaoItemsPorAnoConteudo = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-items-por-ano-botao');

const botaoDatasConteudo = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-datas-botao');

const conteudoItemsPorAnoLista = <HTMLElement>document
  .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-items-por-ano-lista');

const BotaoBox = () => {
  const botaoBox = <HTMLElement>document
    .querySelector('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-box-acao-botao');

  const AcaoBotaoBox = () => {
    conteudoDatas.style.display = 'grid';
    boxAcaoBack.style.display = 'none';
    conteudoItems.style.display = 'none';
    conteudo.style.borderRadius = '8px';

    conteudo.style.padding = window.innerWidth <= 992
      ? '30px'
      : '30px 60px';
  }

  botaoBox.addEventListener('click', () => AcaoBotaoBox());
}

const BotaoDatasConteudo = () => {
  const AcaoBotaoDatasConteudo = () => {
    conteudoDatas.style.display = 'none';
    boxAcaoBack.style.display = 'block';
    conteudoItems.style.display = 'grid';
    conteudo.style.borderRadius = '8px 8px 0 0';

    conteudo.style.padding = window.innerWidth <= 992
      ? '30px 30px 0'
      : '30px 60px 0';
  }

  botaoDatasConteudo.addEventListener('click', () => AcaoBotaoDatasConteudo());
}

const BotaoItemsPorAnoConteudo = () => {
  const AcaoBotaoItemsPorAnoConteudo = () => {
    conteudoItemsPorAno.style.display = 'none';
    conteudoDatas.style.display = 'grid';
    conteudoItemsPorAnoLista.innerHTML = '';
  }

  botaoItemsPorAnoConteudo.addEventListener('click', () => AcaoBotaoItemsPorAnoConteudo());
}

const CriarElementoDeEdital = (edital: any) => {
  let item = document.createElement("li");
  let link = document.createElement("a");
  let imagem = document.createElement("img");
  let texto = document.createElement("p");

  texto.appendChild(document.createTextNode(edital.editais_regulamentos_item_nome));
  texto.classList.add('modulo-mosaico-informacoes-editais-conteudo-items-por-ano-lista-item-nome');

  imagem.classList.add('modulo-mosaico-informacoes-editais-conteudo-items-por-ano-lista-item-img');
  imagem.src = '/wp-content/themes/cruzeiroportais2019/assets/imagens/pdf_white.svg';

  link.href = edital.editais_regulamentos_lista_item_arquivo_interno;
  if (edital.editais_regulamentos_lista_item_arquivo_interno == false) {
    link.href = edital.editais_regulamentos_lista_item_arquivo_externo;
  }

  link.classList.add('modulo-mosaico-informacoes-editais-conteudo-items-por-ano-lista-item-link');
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.appendChild(imagem);
  link.appendChild(texto);

  item.classList.add('modulo-mosaico-informacoes-editais-conteudo-items-por-ano-lista-item');
  item.appendChild(link);

  conteudoItemsPorAnoLista.appendChild(item);
}

const GetRegulamentosComAno = () => {
  const itensDatas = <NodeListOf<HTMLElement>>document.querySelectorAll('.modulo-mosaico-informacoes .modulo-mosaico-informacoes-editais-conteudo-datas-item');
  const nonce = <HTMLInputElement>document.querySelector('#wp_nonce_get_editais_regulamentos');
  const categoriasElemento = <HTMLInputElement>document.querySelector('#modulo-mosaico-informacoes-categorias');

  const nonceValue: string = nonce.value;
  const categorias = categoriasElemento.value;

  const AcaoGetRegulamentosComAno = (evento: Event) => {
    let elementoClicado = <HTMLElement>evento.target;
    let anoDoEdital: string = elementoClicado.dataset.ano || '';
    let dados = new FormData();

    elementoClicado.innerHTML = '';
    elementoClicado.classList.toggle('form-btnloading');

    dados.append('action', 'getEditaisRegulamentosPorAno');
    dados.append('nonce', nonceValue);
    dados.append('ano', anoDoEdital);
    dados.append('categorias', categorias);

    CreateAjax(dados).then(retorno => {
      elementoClicado.innerHTML = anoDoEdital;
      elementoClicado.classList.toggle('form-btnloading');

      conteudoItemsPorAno.style.display = 'grid';
      conteudoDatas.style.display = 'none';
      botaoItemsPorAnoConteudo.innerHTML = botaoDatasConteudo.innerHTML;

      retorno.forEach((edital: any) => CriarElementoDeEdital(edital));
    });
  }

  itensDatas.forEach(itensDatas => itensDatas
    .addEventListener(
      'click',
      (evento) => AcaoGetRegulamentosComAno(evento)
    ));
}

export const MosaicoInformacoes = () => {
  BotaoBox();
  BotaoDatasConteudo();
  BotaoItemsPorAnoConteudo();
  GetRegulamentosComAno();
}