export const SliderBoxesImagens = () => {
  const pictures = <NodeListOf<HTMLPictureElement>>document.querySelectorAll('.slider-boxes__imagem');

  pictures.forEach((picture: HTMLPictureElement) => picture
    .querySelector('img')?.addEventListener('error', () => {
      const sourceMobile = <HTMLSourceElement>picture.querySelector('source[data-layout="mobile"]');
      const sourceDesktop = <HTMLSourceElement>picture.querySelector('source[data-layout="desktop"]');

      sourceMobile.src = sourceMobile.dataset.imagem || '';
      sourceMobile.srcset = sourceMobile.dataset.imagens || '';

      sourceDesktop.src = sourceDesktop.dataset.imagem || '';
      sourceDesktop.srcset = sourceDesktop.dataset.imagens || '';
    }, {
      once: true
    })
  )
}