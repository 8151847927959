export const BuscaPorPolosScrollTooltip = () => {
  const Acao = () => {
    let regiao = <any | undefined>document.querySelector('.jvectormap-container svg g path[data-ativo="true"]');
    let dadosRegiao = <any | undefined>regiao?.getBoundingClientRect();
    let ancora = <HTMLElement>document.querySelector('.busca-por-polos__mapa-tooltip-ancora');

    if (dadosRegiao) {
      ancora.style.top = `${dadosRegiao.top}px`;
      ancora.style.left = `${dadosRegiao.left}px`;
    }
  }

  document.addEventListener("scroll", () => Acao());
  document.body.addEventListener("scroll", () => Acao());
}