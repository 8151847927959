const containerConteudo: HTMLElement = <HTMLElement>document.querySelector('.container-conteudo-pdp');

const slideOptions: JQuerySlickOptions = {
  variableWidth: true,
  swipeToSlide: true,
  slidesToShow: 1,
  touchMove: true,
  infinite: true,
  dots: false,
  swipe: true
};

export const IniciarSliderCampus = () => {
  $('.pdp-sessao__campus .slider').slick(slideOptions);
}

export const AbrirArquivoCampus = () => {
  document.querySelectorAll('.pdp-sessao__campus .slide')
    .forEach(slide => slide.addEventListener('click', (evento: Event) => {
      let slideClicado: HTMLElement = <HTMLElement>evento.target;

      if (slideClicado.dataset.tipo == 'descricao') {
        return;
      }

      if (slideClicado.dataset.tipo == 'video') {
        let videoSource: any = <any>document.querySelector('.modal__video-campus .video video source');
        let video: any = <any>document.querySelector('.modal__video-campus .video video');

        document.querySelector('.modal__video-campus .video')?.classList.toggle('ativo');
        videoSource.src = slideClicado.dataset.arquivo;
        video.load();
      }

      if (slideClicado.dataset.tipo == 'image' || slideClicado.dataset.tipo == 'imagem') {
        let imagem: any = <any>document.querySelector('.modal__video-campus .imagem img');
        document.querySelector('.modal__video-campus .imagem')?.classList.toggle('ativo');
        imagem.src = slideClicado.dataset.arquivo;
        imagem.alt = slideClicado.dataset.arquivoAlt;
      }

      document.body.style.overflowY = document.body.style.overflowY == 'hidden' ? '' : 'hidden';
      containerConteudo.style.zIndex = (containerConteudo.style.zIndex == '1' || containerConteudo.style.zIndex == '') ? '9999' : '1';
      document.querySelector('.modal__video-campus')?.classList.toggle('ativo');
    }));

  document.querySelector('.modal__video-campus')
    ?.addEventListener('click', () => {
      document.body.style.overflowY = document.body.style.overflowY == 'hidden' ? '' : 'hidden';
      containerConteudo.style.zIndex = (containerConteudo.style.zIndex == '1' || containerConteudo.style.zIndex == '') ? '9999' : '1';
      document.querySelector('.modal__video-campus')?.classList.toggle('ativo');
      document.querySelector('.modal__video-campus .video')?.classList.remove('ativo');
      document.querySelector('.modal__video-campus .imagem')?.classList.remove('ativo');
    });
};
