
const PegarParametrosUrl = () => {
  let opcoesFiltrosUrl = decodeURI(location.search.slice(1)).split('&');
  let opcoesFiltros = [];

  opcoesFiltrosUrl.forEach((opcao) => {
    let parametro = opcao.split('=');

    if (parametro[1]) {
      opcoesFiltros.push({
        'nome': parametro[0],
        'valores': parametro[1].split('!').filter(param => param != ''),
      });
    }
  });

  return opcoesFiltros;
}

const AtivarItensFiltros = (parametros) => {
  parametros.forEach((parametro) => {
    if (parametro.nome == 'modalidade') {
      parametro.valores.forEach((filtro) => {
        if (filtro == 'tem_opcao_presencial') {
          document.querySelector('.btnPresencial').checked = true;
        } else if (filtro == 'tem_opcao_ead_4_0') {
          document.querySelector('.btnEad40').checked = true;
        } else if (filtro == 'tem_opcao_ead_4_0_i') {
          document.querySelector('.btnEad40i').checked = true;
        } else if (filtro == 'tem_opcao_online') {
          document.querySelector('.btnEad').checked = true;
        }
      });
    } else if (parametro.nome == 'tipo') {
      parametro.valores.forEach((filtro) => {
        filtro = filtro.replace('+', ' ');

        if (filtro == 'Bacharelado') {
          document.querySelector('input[value="Bacharelado"]').checked = true;
        } else if (filtro == 'Licenciatura') {
          document.querySelector('input[value="Licenciatura"]').checked = true;
        } else if (filtro == 'Pedagogia para Licenciados') {
          document.querySelector('input[value="Pedagogia para Licenciados"]').checked = true;
        } else if (filtro == 'Tecnológico') {
          document.querySelector('input[value="Tecnológico"]').checked = true;
        } else if (filtro == 'Especialização') {
          document.querySelector('input[value="Especialização"]').checked = true;
        } else if (filtro == 'Formação de Professores R2') {
          document.querySelector('input[value="Formação de Professores R2"]').checked = true;
        } else if (filtro == 'Idiomas') {
          document.querySelector('input[value="Idiomas"]').checked = true;
        } else if (filtro == 'Preparatório') {
          document.querySelector('input[value="Preparatório"]').checked = true;
        } else if (filtro == 'MBA') {
          document.querySelector('input[value="MBA"]').checked = true;
        } else if (filtro == 'Tecnologico') {
          document.querySelector('input[value="Tecnologico"]').checked = true;
        } else if (filtro == 'Cursos Livres') {
          document.querySelector('input[value="Cursos Livres"]').checked = true;
        }
      });
    } else if (parametro.nome == 'tipo-de-curso') {
      parametro.valores.forEach((filtro) => {
        filtro = filtro.replace('+', ' ');

        if (filtro == 'Graduação') {
          document.querySelector('input[value="Graduação"]').checked = true;
        } else if (filtro == 'Pós-graduação') {
          document.querySelector('input[value="Pós-graduação"]').checked = true;
        } else if (filtro == 'Cursos Técnicos') {
          document.querySelector('input[value="Cursos Técnicos"]').checked = true;
        } else if (filtro == 'Cursos de Idiomas') {
          document.querySelector('input[value="Cursos de Idiomas"]').checked = true;
        } else if (filtro == 'Cursos Preparatórios') {
          document.querySelector('input[value="Cursos Preparatórios"]').checked = true;
        } else if (filtro == 'Cursos Livres') {
          document.querySelector('input[value="Cursos Livres"]').checked = true;
        }
      });
    } else if (parametro.nome == 'area') {
      parametro.valores.forEach((filtro) => {
        if (filtro) {
          document.querySelector('select.select-grad-mob').value = filtro;
        }
      });
    }
  });
}

export const vitrineOnLoad = () => {
  AtivarItensFiltros(PegarParametrosUrl());
}