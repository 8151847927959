import { SetFormaIngresso } from "../../Utils/formaDeIngressoSf";
import { StartSliderCondicoesCopyrightFooter } from "./footerCondicoesCopyright";
import { ScriptsGlobais } from "./footerGlobal";
import { ViewModalCondicoes } from "./footerModal";
import { StartSliderNossasUnidadesFooter } from "./footerNossasUnidades";
import { VerMaisCursosGraduacaoFooter } from "./footerPrincipaisCursosGraduacao";
import { StartSliderSelosMecFooter } from "./footerSelosMec";
import { AbrirFecharItemSiteMap } from "./footerSiteMap";

export const Footer = () => {
  SetFormaIngresso();
  ScriptsGlobais();
  StartSliderNossasUnidadesFooter();
  StartSliderSelosMecFooter();
  StartSliderCondicoesCopyrightFooter();
  ViewModalCondicoes();
  AbrirFecharItemSiteMap();
  VerMaisCursosGraduacaoFooter();
};