/**
 * Inicializa e gerencia a abertura dos modais da calculadora ENEM ao clicar no botão.
 *
 * Esta função seleciona todos os elementos com a classe 'abrir-modal-calculadora-enem'
 * e adiciona eventos de clique a eles. Quando um botão é clicado, tenta
 * abrir o modal correspondente chamando a função `AtivarModal` com o ID do modal.
 *
 * A função inclui lógica para lidar com casos especiais, como abrir links em novas abas
 * ou alternar modais específicos com base em certas condições.
 *
 * Ela garante que, quando um modal é ativado, o overflow do corpo é oculto para evitar
 * a rolagem de fundo.
 */
export const AbrirModalCalculadoraEnem = () => {
  let botoes = <NodeListOf<HTMLElement>>document.querySelectorAll('.abrir-modal-calculadora-enem');

  const AtivarModal = (modalId: string): Element | null => {
    modalId = modalId.replace('#', '');

    let modal: Element | null = null;

    modal = document.querySelector(`#${modalId}.calculadora-enem-modal`);

    let ativo = false;

    if (modal !== null) {
      modal.classList.toggle("modal-desativado");
      ativo = !modal.classList.contains("modal-desativado");
    } else {
      let elemento = document.getElementById(modalId);
      let isModal: boolean = false;
      elemento?.classList.forEach(item => item.includes('modal') && (isModal = true));

      if (isModal) {
        modal = elemento;
        modal?.classList.toggle('disable-modaldefinitivo');
        document.querySelector('.bg_lightbox')?.classList.toggle('disable-modaldefinitivo');
        ativo = !modal?.classList.contains('disable-modal');
      }
    }

    if (ativo) {
      document.body.style.overflow = 'hidden';
    }

    return modal;
  }

  const Acao = (evento: Event) => {
    evento.preventDefault();

    let botaoClicado = <HTMLAnchorElement>evento.currentTarget;
    let modalId = botaoClicado.dataset.modal || '';
    modalId = modalId.replace('#', '');

    let modal: Element | null = AtivarModal(modalId);

    if (
      !modal &&
      !botaoClicado.href.includes('aviseMe')
    ) {
      let url = new URL(botaoClicado.href);

      if (botaoClicado.target === '_blank') {
        window.open(url.href, '_blank');
      } else {
        window.open(url.href, '_self');
      }
    }

    if (botaoClicado.href.includes('aviseMe')) {
      document.querySelector('.modal-avise')?.classList.remove('disable-modal');
      document.querySelector('.bg_lightbox')?.classList.remove('disable-modal');
      document.querySelector('body')?.classList.add('blockBody');
    }
  }

  botoes.forEach((botao: HTMLElement, index: number) => {
    if (index === 0 && window.location.hash === '#calculadora-enem') {
      let modalId = botao.dataset.modal || '';
      modalId = modalId.replace('#', '');
      AtivarModal(modalId);
    }

    botao.addEventListener('click', (evento: Event) => Acao(evento));
  });
}