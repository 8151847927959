import { ModalCalculadoraEnemVerificarCamposTipoRegex } from "../ModalCalculadoraEnemNovo/ModalCalculadoraEnemVerificarCampos";
import { CalculadoraEnemBuscarNota } from "./CalculadoraEnemBuscarNota";
import { CalculadoraEnemMudaPassos } from "./CalculadoraEnemMudaPassos";
import { CalculadoraEnemValidar } from "./CalculadoraEnemValidar";


export const CalculadoraEnemBotoes = () => {
    document.querySelector('.calculadora-enem__botao--avancar')?.addEventListener('click', () => {
        let passoAtual = document.querySelector('.calculadora-enem__formulario').dataset.passoAtual;
        let textoBotaoAvancar = document.querySelector('.calculadora-enem__botao--avancar').textContent;
        let validacao = CalculadoraEnemValidar(passoAtual);
        let validacaoDupla = true;

        document.querySelector('.calculadora-enem__formulario .calculadora-enem__passo--ativo')
            ?.querySelectorAll('input, select')
            .forEach((item: any) => {
                item.parentElement?.classList.remove('calculadora-enem__grupo-campo--invalido');

                if (
                    item.type !== 'hidden' &&
                    item.required &&
                    !ModalCalculadoraEnemVerificarCamposTipoRegex[item.type].test(item.value.trim())
                ) {
                    item.parentElement?.classList.add('calculadora-enem__grupo-campo--invalido');

                    item.addEventListener('input', () => {
                        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[item.type].test(item.value.trim())) {
                            item.parentElement?.classList.remove('calculadora-enem__grupo-campo--invalido');
                        }
                    });

                    item.addEventListener('change', () => {
                        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[item.type].test(item.value.trim())) {
                            item.parentElement?.classList.remove('calculadora-enem__grupo-campo--invalido');
                        }
                    });

                    validacaoDupla = false;
                }
            })

        if (validacao && validacaoDupla) {
            if (passoAtual == 2 || textoBotaoAvancar == 'Alterar') {
                let elementoCurso = document.querySelector('#calculadora-enem__select--curso');
                let curso = elementoCurso.options[elementoCurso.selectedIndex].text;

                if (
                    document.querySelector('#calculadora-enem__select--pais')
                    && document.querySelector('#calculadora-enem__select--estado')
                    && document.querySelector('#calculadora-enem__select--cidade')
                    && document.querySelector('#calculadora-enem__select--polo')
                ) {
                    let elementoPolo = document.querySelector('#calculadora-enem__select--polo');
                    let polo = elementoPolo.options[elementoPolo.selectedIndex].text;

                    document.querySelector('.calculadora-enem__escolhas').textContent = curso + ', ' + polo;

                    document.querySelector('.calculadora-enem__botoes').classList.add('calculadora-enem__botoes--oculto');

                    CalculadoraEnemBuscarNota();
                    CalculadoraEnemMudaPassos('avancar');

                    return;
                }

                let elementoCampus = document.querySelector('#calculadora-enem__select--campus');
                let campus = elementoCampus.options[elementoCampus.selectedIndex].text;

                let periodo = '';
                if (document.querySelector('#calculadora-enem__select--periodo')) {
                    let elementoPeriodo = document.querySelector('#calculadora-enem__select--periodo');
                    periodo = elementoPeriodo.options[elementoPeriodo.selectedIndex].text;
                }

                document.querySelector('.calculadora-enem__escolhas').textContent = campus + ', ' + curso + ', ' + periodo;

                CalculadoraEnemBuscarNota();
                CalculadoraEnemMudaPassos('avancar');
            }

            document.querySelector('.calculadora-enem__botoes').classList.remove('calculadora-enem__botoes--oculto');

            CalculadoraEnemMudaPassos('avancar');
        }

    });

    document.querySelector('.calculadora-enem__botao--voltar')?.addEventListener('click', () => {
        CalculadoraEnemMudaPassos('voltar');
    });
}