import { BotoesDataLayer } from "./formasIngressoBotoes";
import { StartSlider } from "./formasIngressoListaCards";
import { AbrirTabsMobile, MudarTab } from "./formasIngressoListaTabs";
import { ModalAviseMe } from "./formasIngressoTelas";

const FormasIngresso = () => {
  StartSlider();
  MudarTab();
  AbrirTabsMobile();
  ModalAviseMe();
  BotoesDataLayer();
}

export default FormasIngresso;