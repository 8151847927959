import { EnviaLead } from "../../Utils/enviaLead";
import { SetFormaIngresso } from "../../Utils/formaDeIngressoSf";

export const CalculadoraEnemNovoEnviarLead = () => {
    let identificador = 'Calcule Desconto';
    let formulario = <HTMLFormElement>document.querySelector('.calculadora-enem__formulario');
    let dadosFormulario = new FormData(formulario);
    let dados = Array();

    dadosFormulario.append('action', 'processFields');
    // @ts-ignore: Unreachable code error
    dadosFormulario.append('nonce', ProcessFields.nonce);

    for (let campo of dadosFormulario.entries()) {
        let campoEncontrado = dados.find(dado => dado.name == campo[0]);

        if (campoEncontrado) {
            campoEncontrado.value = campo[1];

            continue;
        }

        dados.push({
            name: campo[0],
            value: campo[1]
        });
    }

    SetFormaIngresso('ENEM');
    dados.push({ name: 'forma-ingresso', value: 'ENEM' });

    EnviaLead(
        identificador,
        'cadastro-desconto',
        dados
    ).then((retorno) => {
        if (retorno.LeadId) {
            sessionStorage.setItem('leadAtualId', retorno.LeadId);
        }
    });
}