import { ModalCalculadoraEnemNovoApresentarValores } from "./ModalCalculadoraEnemNovoApresentarValores";
import { CalculadoraEnemNovoEnviarLead } from "./ModalCalculadoraEnemNovoEnviarLead";
import { ModalCalculadoraEnemNovoValidarFormulario } from "./ModalCalculadoraEnemNovoValidarFormulario";

export const ModalCalculadoraEnemNovoAlteracoesNaEtapaEnem = (proximoPasso) => {
    let passoAtual = proximoPasso - 1;

    if (proximoPasso == 3 && ModalCalculadoraEnemNovoValidarFormulario(passoAtual, false)) {
        let notaEnem = <HTMLInputElement>document.querySelector('#calculadora-enem__input--nota-enem');

        if (notaEnem.value.length >= 3 && notaEnem.value.length <= 4) {
            ModalCalculadoraEnemNovoApresentarValores(notaEnem.value);
        }

        if (window.screen.width <= 992) {
            document.querySelector('.modal-calculadora-enem__box:last-of-type')?.classList.add('modal-calculadora-enem__box--ativo');
        }
        
        document.querySelector('.calculadora-enem__dots')?.classList.add('calculadora-enem__dots--inativo');
        document.querySelector('.calculadora-enem__botoes')?.classList.add('calculadora-enem__botoes--oculto');
        document.querySelector('.calculadora-enem__informacoes-escolha')?.classList.add('calculadora-enem__informacoes-escolha--ativo');
        document.querySelector('.modal-calculadora-enem__resultado')?.classList.remove('modal-calculadora-enem__resultado--opaco');

        CalculadoraEnemNovoEnviarLead();

        return;
    }

    document.querySelector('.calculadora-enem__botoes')?.classList.remove('calculadora-enem__botoes--oculto');
    document.querySelector('.calculadora-enem__dots')?.classList.remove('calculadora-enem__dots--inativo');
    document.querySelector('.calculadora-enem__informacoes-escolha')?.classList.remove('calculadora-enem__informacoes-escolha--ativo');
    document.querySelector('.modal-calculadora-enem__resultado')?.classList.add('modal-calculadora-enem__resultado--opaco');
}