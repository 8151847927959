import { DataLayerCreateCliqueBotao, DataLayerCreateInteracaoFormulario } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerTelaFormasIngresso = () => {
  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  let fluxoEventLabel = 'ver-investimento';

  if (fluxo == 'InscrevaSe') {
    fluxoEventLabel = 'inscricao';
  }

  const seletores = <NodeListOf<HTMLSelectElement>>document
    .querySelectorAll('.sidebar-pdp-tela-formas-ingresso .sidebar-pdp-tela__campo-select');

  const botaoEnviar = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso .sidebar-pdp-tela__proximo');

  const AcaoFormulario = (evento: Event) => {
    const elemento = <HTMLSelectElement>evento.target;
    DataLayerCreateInteracaoFormulario(`${fluxoEventLabel}:${elemento.name}`);
  }

  const AcaoEnvio = (evento) => {
    const elemento = <HTMLAnchorElement>evento.target;
    let status = 'inscricao';

    if (elemento.href.includes('#aviseMe')) {
      status = 'avise-me';
    }

    DataLayerCreateCliqueBotao(`formulario:${fluxoEventLabel}:${status}`);
  }

  seletores.forEach((elemento: HTMLElement) => elemento
    .addEventListener('change', (evento: Event) => AcaoFormulario(evento)));

  botaoEnviar.addEventListener('click', (evento: Event) => AcaoEnvio(evento));
}