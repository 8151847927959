export const ModalAviseMe = () => {
  let botoes: NodeListOf<HTMLAnchorElement> = document
    .querySelectorAll(
      '.formas-ingresso .botao--primario, .sidebar-pdp-tela a[data-status="aviseMe"]'
    );

  botoes.forEach((botao: HTMLAnchorElement) => {
    if (
      botao.id.match('avise-me') ||
      botao.dataset.status == 'aviseMe'
    ) {
      botao.addEventListener('click', (evento) => {
        evento.preventDefault();
        document.querySelector('.modal-avise')?.classList.remove('disable-modal');
        document.querySelector('.bg_lightbox')?.classList.remove('disable-modal');
        document.querySelector('body')?.classList.add('blockBody');

        $("#modal-estude").remove();
      })
    };
  })
};