const ModalApoio = () => {
  window.addEventListener('load', () => {
    // Slider
    $('.modal-apoio__slider').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: false,
      initialSlide: 1,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        }
      }],
    });

    // Botão fechamento
    document.querySelectorAll('.modal-apoio__botao-fechamento').forEach((elemento) => {
      elemento.addEventListener(
        'click',
        () => {
          elemento.parentNode.parentNode.parentNode.parentNode.parentNode.classList.toggle('modal-apoio--ativo');
          document.querySelector('body').classList.toggle('modal-apoio--body-ativo');
        }
      );
    });

    // Tooltip
    document.querySelectorAll('.tooltip:not([id=""])').forEach((elemento) => {
      elemento.addEventListener('click',
        () => {
          document.querySelector('#' + elemento.id + '-modal').classList.toggle('modal-apoio--ativo');
          $('#' + elemento.id + '-modal .modal-apoio__slider').slick('setPosition');
          document.querySelector('body').classList.toggle('modal-apoio--body-ativo');
        }
      )
    });
    
  });
}

export default ModalApoio;