export const ModalCalculadoraEnemVerificarCamposTipoRegex = {
  'text': /^[a-zA-Z0-9_ ]{2,40}$/,
  'email': /^([a-zA-Z0-9]{1,})+([a-zA-Z0-9._%+-]?)+([a-zA-Z0-9]{1,})@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  'tel': /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/,
  'select-one': /^(?!\s*$).+/
};

export default function ModalCalculadoraEnemVerificarCampos(divPassoAtivo: HTMLDivElement): boolean {
  let statusDosCampos = true;

  divPassoAtivo.querySelectorAll('input').forEach((input) => {
    input.parentElement?.classList.remove('calculadora-enem__grupo-campo--invalido');

    if (
      input.type !== 'hidden' &&
      input.required &&
      !ModalCalculadoraEnemVerificarCamposTipoRegex[input.type].test(input.value.trim())
    ) {
      input.parentElement?.classList.add('calculadora-enem__grupo-campo--invalido');

      input.addEventListener('input', () => {
        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[input.type].test(input.value.trim())) {
          input.parentElement?.classList.remove('calculadora-enem__grupo-campo--invalido');
        }
      });

      input.addEventListener('change', () => {
        if (!ModalCalculadoraEnemVerificarCamposTipoRegex[input.type].test(input.value.trim())) {
          input.parentElement?.classList.remove('calculadora-enem__grupo-campo--invalido');
        }
      })

      statusDosCampos = false;
    }
  });

  return statusDosCampos;
}