import { DataLayerCreateCliqueBotao } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerTelaSeis = () => {
  let fluxoEventLabel = 'ver-investimento';

  const botoesInscrevaSe = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-seis .sidebar-pdp-tela__inscreva-se');

  botoesInscrevaSe
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao(`formulario:${fluxoEventLabel}:inscricao`)));
}