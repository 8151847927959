import { CreateAjax } from "../../Utils/createAjax";

export const ModalCalculadoraEnemNovoApresentarValores = (nota) => {
  if (ModalCalculadoraEnemNovoVerificarSeNotaEDiferente(nota)) {
    ModalCalculadoraNovoEnemAtivarLoader();
    ModalCalculadoraEnemNovoBuscarDadosEPreencherValor(nota);
  }
}

const ModalCalculadoraEnemNovoVerificarSeNotaEDiferente = (nota) => {
  let notaStorage = sessionStorage.getItem('notaEnem');

  if (!sessionStorage.getItem('notaEnem') || notaStorage?.length == 0) {
    sessionStorage.setItem('notaEnem', nota);
    return true;
  }

  if (nota == notaStorage) {
    return false;
  }

  sessionStorage.setItem('notaEnem', nota);
  return true;
}

const ModalCalculadoraNovoEnemAtivarLoader = () => {
  document.querySelector('.calculadora-enem__loader--nota')?.classList.add('calculadora-enem__loader--ativo');

  document.querySelector('.calculadora-enem__titulo--nota')?.classList.remove('calculadora-enem__titulo--nota-inativa');
  document.querySelector('.calculadora-enem__titulo--nota')?.classList.add('calculadora-enem__titulo--nota-oculto');
  document.querySelector('.calculadora-enem__informacao--nota')?.classList.remove('calculadora-enem__informacao--nota-ativa');

  document.querySelector('.calculadora-enem__valores')?.classList.remove('calculadora-enem__valores--ativo');
}

const ModalCalculadoraEnemNovoApresentarValor = (porcentagem) => {
  let valorDe = <HTMLDivElement>document.querySelector('.calculadora-enem__valor-de');
  let porcentagemDiv = <HTMLDivElement>document.querySelector('.calculadora-enem__porcentagem');

  valorDe?.classList.remove('calculadora-enem__valor-de--oculto');
  porcentagemDiv?.classList.remove('calculadora-enem__porcentagem--oculto');
  if (porcentagem === 0) {
    valorDe?.classList.add('calculadora-enem__valor-de--oculto');
    porcentagemDiv?.classList.add('calculadora-enem__porcentagem--oculto');
  }

  setTimeout(() => {
    document.querySelector('.calculadora-enem__loader--nota')?.classList.remove('calculadora-enem__loader--ativo');

    document.querySelector('.calculadora-enem__titulo--nota')?.classList.remove('calculadora-enem__titulo--nota-oculto');
    document.querySelector('.calculadora-enem__valores')?.classList.add('calculadora-enem__valores--ativo');
  }, 1000);
}

const ModalCalculadoraEnemNovoBuscarDadosEPreencherValor = (nota) => {
  let campusCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--campus');
  let periodoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--periodo');
  let cursoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--curso');
  let nonceCampo = <HTMLInputElement>document.querySelector('#modal-calculadora-enem__nonce--add-valor-desconto');

  let dados = new FormData();
  dados.append('action', 'addValorDesconto');
  dados.append('nota', nota);
  dados.append('periodo', periodoCampo?.value ?? 'EAD');
  dados.append('campus', campusCampo?.value ?? '');
  dados.append('curso', cursoCampo?.value ?? '');
  dados.append('nonce', nonceCampo?.value ?? '');
  dados.append('idLead', sessionStorage.getItem('leadAtualId') ?? '');

  CreateAjax(dados).then((retorno) => {
    ModalCalculadoraEnemNovoPreencherValor(retorno);
    ModalCalculadoraEnemNovoApresentarValor(retorno.porcentagem);
  });
}

const ModalCalculadoraEnemNovoPreencherValor = (dados) => {
  let valorDeCampo = <HTMLSpanElement>document.querySelector('.calculadora-enem__valor-de--span');
  let valorParaCampo = <HTMLSpanElement>document.querySelector('.calculadora-enem__valor-para--span');
  let porcentagemCampo = <HTMLSpanElement>document.querySelector('.calculadora-enem__porcentagem-valor');
  let botaoInscrevaSe = document.querySelector('.calculadora-enem__valores .calculadora-enem__botao--primario');

  valorDeCampo.textContent = 'R$ ' + dados.valorCheio;
  valorParaCampo.textContent = 'R$ ' + dados.desconto;
  porcentagemCampo.textContent = dados.porcentagem + '%';
  botaoInscrevaSe?.setAttribute('href', dados.formasIngresso.url ?? '');
  botaoInscrevaSe?.setAttribute('target', dados.formasIngresso.urlTarget ?? '');
}