import { CreateAjax } from "../../Utils/createAjax";

export const BuscaPorPolosSetClick = () => {
  let carregarDados = setInterval(() => {
    if (document.querySelector('.jvectormap-container')) {
      clearInterval(carregarDados);
    }

    document.querySelectorAll('.jvectormap-region').forEach((elemento) => {
      elemento.addEventListener('click', () => {
        let uf = elemento.getAttribute('data-code');

        if (window.innerWidth > 992) {
          window.location.href = '/nossos-polos/?pais=BRASIL&uf=' + uf;
        } else {
          let botao = <HTMLAnchorElement>document.querySelector('.busca-por-polos__mapa-tooltip-botao');
          botao.href = `/nossos-polos/?pais=BRASIL&uf=${uf}`;
        }
      });
    });

    let dados = new FormData();
    dados.append('action', 'getQuantidadesPolos');

    CreateAjax(dados).then((retorno) => {
      retorno.forEach((item) => {
        let uf = <HTMLElement>document.querySelector('.busca-por-polos__mapa svg path[data-code="' + item.uf + '"]');

        uf.dataset.quantidadePolos = item.quantidadePolos;
        uf.dataset.nomeEstado = item.estado;
      });
    });
  }, 50);
}