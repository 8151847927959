export const CalculadoraEnemAtualizaDots = (passoAtual, quantidadePassos) => {
    document.querySelector(`.calculadora-enem__dot--ativo`)?.classList.toggle('calculadora-enem__dot--ativo');

    let dotPassoAtual = document.querySelector(`.calculadora-enem__dot[data-numero-passo="${passoAtual}"]`);

    if (dotPassoAtual) {
        document.querySelector('.calculadora-enem__dots')?.classList.remove('calculadora-enem__dots--inativo');
        dotPassoAtual.classList.toggle('calculadora-enem__dot--ativo');
    } else {
        document.querySelector('.calculadora-enem__dots')?.classList.add('calculadora-enem__dots--inativo');
    }

    for (let i = passoAtual; i >= 0; i--) {
        document.querySelector(`.calculadora-enem__dot[data-numero-passo="${i}"]`)?.
            classList.add('calculadora-enem__dot--concluido');
    }

    for (let i = passoAtual; i <= quantidadePassos; i++) {
        document.querySelector(`.calculadora-enem__dot[data-numero-passo="${i}"]`)?.
            classList.remove('calculadora-enem__dot--concluido');
    }
}