export const CxIntervencao = () => {
  const Acao = () => {
    let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;
    let botao = <HTMLElement>document.querySelector('#btnIntervencao-open');

    if (screen.width <= 992) {
      botao.classList.remove('btnIntervencao-open--ativo');

      if (valorScroll >= 400) {
        botao.classList.add('btnIntervencao-open--ativo');
      }
    }
  }

  const containerIntervencao = <HTMLElement>document.querySelector('.containerIntervencao');
  const containerIntervencaoBotaoFechar = <HTMLElement>document.querySelector('.containerIntervencao-btnclose');
  const containerIntervencaoBotaoAbrir = <HTMLElement>document.querySelector('#btnIntervencao-open');

  containerIntervencaoBotaoFechar.addEventListener('click', (evento) => {
    evento.preventDefault();

    containerIntervencao.style.opacity = '0';
    setTimeout(() => {
      containerIntervencao.style.display = 'none';
    }, 150);
    
  });

  containerIntervencaoBotaoAbrir.addEventListener('click', (evento) => {
    evento.preventDefault();

    containerIntervencao.style.opacity = '1';
    setTimeout(() => {
      containerIntervencao.style.display = 'block';
    }, 150);
  });

  document.addEventListener("scroll", () => Acao());
  document.body.addEventListener("scroll", () => Acao());
}