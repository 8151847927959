/**
 * Envia a altura de um elemento para o parent (se ele existir, ou seja, se
 * estiver dentro de um iframe). Utiliza o scrollHeight se o elemento for
 * instanceof HTMLElement, e height se for instanceof DOMRectReadOnly.
 *
 * @param {HTMLElement | DOMRectReadOnly} elemento - O elemento cuja altura
 *   deve ser enviada.
 */
export const mensagemHeight = (
  elemento: HTMLElement | DOMRectReadOnly
) => window.parent.postMessage(
  elemento instanceof HTMLElement
    ? elemento.scrollHeight
    : elemento.height,
  '*'
);

/**
 * Envia a altura do elemento com classe "embed-main" para o parent (se ele
 * existir, ou seja, se estiver dentro de um iframe) ao renderizar o
 * componente e tambem quando o elemento muda de tamanho. Utiliza o
 * scrollHeight ou height respectivamente.
 *
 * @param {HTMLElement} elementoEmbedMain - O elemento com classe
 *   "embed-main" cuja altura deve ser enviada.
 *
 * @returns {void}
 */
const EmbedContentSize = (elementoEmbedMain: HTMLElement) => {
  const embedMain = elementoEmbedMain;
  mensagemHeight(embedMain);

  const resizeObserver = new ResizeObserver(elementos => {
    for (let elemento of elementos) {
      mensagemHeight(elemento.contentRect);
    }
  });

  resizeObserver.observe(embedMain);
};

export default EmbedContentSize;