export const AtivarHeaderFixo = () => {
  const conteudo = <HTMLElement>document.querySelector('#container');
  const header = <HTMLElement>document.querySelector('.header');
  const barraRotacional = <HTMLElement | null>document.querySelector('.n-barra-rotacional');

  const Acao = () => {
    conteudo.style.paddingTop = '';
    header.classList.remove('fixed');
    barraRotacional?.classList.remove('menu-fixo');
    $('.n-barra-rotacional-container')?.slick('slickNext');

    const altureHeader = header.offsetHeight;
    const addAltura = getComputedStyle(header).position !== 'absolute';
    let valorScroll = screen.width <= 992 ? document.body.scrollTop : window.scrollY;

    if (valorScroll >= 500) {
      conteudo.style.paddingTop = addAltura ? `${altureHeader}px` : '';
      barraRotacional?.classList.add('menu-fixo')
      header.classList.add('fixed');
    }
  }

  document.addEventListener("scroll", () => Acao());
  document.body.addEventListener("scroll", () => Acao());
}