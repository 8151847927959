export const ModalCalculadoraEnemNovoPreencherInformacoesDeTrocaDeCurso = () => {
    let informacoesEscolhaCursoSpan = <HTMLSpanElement>document.querySelector('.calculadora-enem__escolhas');
    let cursoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--curso');

    if (document.querySelector('#calculadora-enem__action--get-polos')) {
        let estadoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--estado');
        let cidadeCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--cidade');
        let poloCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--polo');

        let localizacao = estadoCampo[estadoCampo.selectedIndex].text + ' - ' + cidadeCampo[cidadeCampo.selectedIndex].text + ' - ' + poloCampo[poloCampo.selectedIndex].text.split(' - ')[0];
        informacoesEscolhaCursoSpan.textContent = cursoCampo[cursoCampo.selectedIndex].text + ', ' + localizacao;

        return;
    }

    let campusCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--campus');
    let periodoCampo = <HTMLSelectElement>document.querySelector('#calculadora-enem__select--periodo');

    informacoesEscolhaCursoSpan.textContent = campusCampo[campusCampo.selectedIndex].text + ', ' + cursoCampo[cursoCampo.selectedIndex].text + ', ' + periodoCampo[periodoCampo.selectedIndex].text;
}