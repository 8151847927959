export const TooltipTelaUm = () => {
  let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
  let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
  let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
  let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');
  let body = <HTMLElement>document.querySelector('body');

  body.style.overflowY = '';

  conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
  conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;

  tooltipTelaUm.addEventListener('click', () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;

    if (screen.width <= 992) {
      document.body.style.overflowY = '';
      conteudoTooltipTelaUm.style.display = 'block';
    }
  });

  tooltipTelaUm.addEventListener('mouseover', () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;

    conteudoTooltipTelaUm.style.display = 'block';
  });

  tooltipTelaUm.addEventListener('mouseout', () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;

    conteudoTooltipTelaUm.style.display = 'none';
  });

  document.addEventListener("scroll", () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;
  });

  document.addEventListener("touchmove", () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;
  });

  document.querySelector('.sidebar-pdp-conteudo')?.addEventListener('scroll', () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;
  });

  document.querySelector('.sidebar-pdp-conteudo')?.addEventListener('touchmove', () => {
    let tooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip');
    let tooltipTelaUmY = tooltipTelaUm.getBoundingClientRect().y;
    let tooltipTelaUmX = tooltipTelaUm.getBoundingClientRect().x;
    let conteudoTooltipTelaUm = <HTMLElement>document.querySelector('.sidebar-pdp-tela-um-acoes-botao-tooltip-conteudo');

    conteudoTooltipTelaUm.style.top = `${tooltipTelaUmY}px`;
    conteudoTooltipTelaUm.style.left = `${tooltipTelaUmX}px`;
  });
}