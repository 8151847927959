import { CreateAjax } from "./createAjax";
import { getCookie } from "./getCookie";
import { verificaEmailSalesforce } from "./verificaEmailSalesforce";

export interface DadoDeLead {
  name: string;
  value: string;
}

const AdicionarCampoUrlOuCookies = (
  dados: DadoDeLead[],
  nomeCampo: string,
  valorDefault: string
) => {
  const dadosUrl = new URLSearchParams(location.search);
  const dadosCookies = new URLSearchParams(getCookie('parsedQS'));

  let itemExiste = dados.find(item => item.name == nomeCampo);
  if (itemExiste == undefined) {
    dados.push({
      name: nomeCampo,
      value: dadosUrl.get(nomeCampo) || dadosCookies.get(nomeCampo) || valorDefault
    });
  } else {
    itemExiste.value = dadosUrl.get(nomeCampo) || dadosCookies.get(nomeCampo) || valorDefault;
  }

  return dados;
};

export const EnviaLead = async (
  identificador: string,
  tipoformulario: string,
  dados: DadoDeLead[]
) => {
  let identificadorTratado = decodeURI(identificador);

  let dadosLead: String[] = [
    "action=processFields",
    // @ts-ignore: Unreachable code error
    `nonce=${ProcessFields.nonce}`,
    `tipoformulario=${tipoformulario}`,
    `identificador=${identificadorTratado}`,
    // @ts-ignore: Unreachable code error
    `idAnalytics=${window.idAnalyticsValue}`
  ]

  const camposUrleCookies = {
    'utm_content': '',
    'utm_medium': '(direct)',
    'utm_campaign': '',
    'utm_source': '(direct)',
    'utm_term': '',
    'gclid': ''
  };

  let novosDados: any = [];
  dados.forEach((dado: DadoDeLead) => {
    let elementoExistente = novosDados == []
      ? false
      : novosDados.find(item => item.name == dado.name);

    if (elementoExistente) {
      elementoExistente.value = dado.value;
    }

    if (!elementoExistente) {
      novosDados.push(dado);
    }
  });

  for (const item of Object.entries(camposUrleCookies)) {
    novosDados = AdicionarCampoUrlOuCookies(novosDados, item[0], item[1]);
  }

  novosDados.forEach(dado => dadosLead
    .push(`inputs[${dado.name}]=${dado.value}`));

  const bodyData = encodeURI(dadosLead.join('&').replace(/ /g, '+'));

  const retornoAjax = await fetch(
    // @ts-ignore: Unreachable code error
    WpObj.ajaxurl,
    {
      "headers": {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "x-requested-with": "XMLHttpRequest"
      },
      "method": "POST",
      "body": bodyData
    }
  );

  return await retornoAjax.json();
}

export const EnviaNewLead = async (dados: FormData) => await CreateAjax(dados);

export const InteracaoOuCriacaoLead = async (
  identificador: string,
  tipoFormulario: string,
  nonceLeadByEmail: string,
  dadosEnviaLead: DadoDeLead[],
  dadosNewLead: FormData
) => {
  const email = dadosEnviaLead.find(item => item.name === 'email')?.value || '';

  await verificaEmailSalesforce(email, nonceLeadByEmail)
    .then(async (retorno: any) => {
      if (retorno.Id) {
        await EnviaLead(identificador, tipoFormulario, dadosEnviaLead);
        return;
      }

      await EnviaNewLead(dadosNewLead).then((retorno) => console.table(retorno));
      return;
    });
}