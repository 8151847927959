import { DataLayerCreateCliqueBotao } from "../../../Utils/dataLayerCreate";

export const PdpDataLayerTelaQuaseLa = () => {
  let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
  let fluxoEventLabel = 'ver-investimento';

  if (fluxo == 'InscrevaSe') {
    fluxoEventLabel = 'inscricao';
  }

  const botoesInscrevaSe = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela-quase-la .sidebar-pdp-tela-quase-la-form-acoes-proximo');

  botoesInscrevaSe
    .forEach((elemento: HTMLElement) => elemento
      .addEventListener('click', () => DataLayerCreateCliqueBotao(`formulario:${fluxoEventLabel}:inscricao`)));
}