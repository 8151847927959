export const IncluirOptionSelect = (select, lista, campoValueOption, campoTextoOption, classeDesabilitado = '') => {
    select.innerHTML = '';
    let elementoOption = document.createElement('option');
    elementoOption.setAttribute('value', '');
    elementoOption.setAttribute('selected', '');
    elementoOption.setAttribute('disabled', '');

    select.appendChild(elementoOption);

    lista.forEach((item) => {
        let elementoOption = document.createElement('option');
        elementoOption.setAttribute('value', item[campoValueOption]);
        elementoOption.textContent = item[campoTextoOption];

        select.appendChild(elementoOption);
    });

    if (select.classList.contains(classeDesabilitado)) {
        select.classList.remove(classeDesabilitado);
    } else if (select.parentElement.classList.contains(classeDesabilitado)) {
        select.parentElement.classList.remove(classeDesabilitado);
    }
}