const triggerHasModal = () => {
  let hashText = window.location.hash.split('?')[0];
  let hashTextFull = window.location.hash;

  if (hashText.length > 0 && $(hashText + '.modal-de-botoes').length > 0) {
    $(hashText + '.modal-de-botoes').removeClass('disable-modaldefinitivo');
    $('.modal-' + hashText.substr(1) + '-bg').removeClass('disable-modaldefinitivo');

    if ($('a[href="' + hashTextFull + '"]').length > 0 && !$('a[href="' + hashTextFull + '"]').hasClass('ready')) {

      $('a[href="' + hashTextFull + '"]').on('click', function (e) {
        e.preventDefault();
        triggerHasModal();
      });

      $('a[href="' + hashTextFull + '"]').addClass('ready');
    }
  }
}

export const CxModalBotoes = () => {
  window.addEventListener('hashchange', () => {
    triggerHasModal();
  });
}

export const CxModalBotoesOnLoad = () => {
  triggerHasModal();
}