import 'flexslider';
import 'jquery-ui/ui/widgets/slider';

export const CxDestaquePage = () => {
  if ($("#historia").length) {
    $('#historia').flexslider({
      animation: "slide",
      animationLoop: false,
      after: function () {
        let flexindex = $('#historia').data('flexslider').currentSlide;
        $("#bar").slider({
          value: flexindex
        });
      }
    });
  }

  if (document.querySelectorAll('.nossahistoria a.flex-next, .nossahistoria .flex-prev').length) {
    document.querySelectorAll('.nossahistoria a.flex-next, .nossahistoria .flex-prev').forEach((elemento) => {
      elemento.removeAttribute('href');
    });
  }

  if (document.querySelectorAll('#instituicoes a.flex-next, #instituicoes .flex-prev').length) {
    document.querySelectorAll('#instituicoes a.flex-next, #instituicoes .flex-prev').forEach((elemento) => {
      elemento.removeAttribute('href');
    });
  }
}