const Tooltip = () => {
    document.querySelectorAll('.tooltip').forEach((item) => {
        item.addEventListener('mouseover', () => {
            item.style.setProperty(
                '--posicao-x-texto-tooltip',
                `${item.getBoundingClientRect().x + 7.5}px`
            );

            item.style.setProperty(
                '--border-radius-texto-tooltip',
                '0px 10px 10px 10px'
            );

            if (window.screen.width <= 992) {
                let posicaoTooltip = ((item.getBoundingClientRect().x - window.screen.width) * -1) - 32.5;

                if (posicaoTooltip < 125) item.style.setProperty(
                    '--border-radius-texto-tooltip',
                    '10px 0px 10px 10px'
                );

                item.style.setProperty(
                    '--posicao-x-texto-tooltip',
                    posicaoTooltip < 125 ? `${posicaoTooltip}px` : 'unset'
                );
            }
        })
    })
}

export default Tooltip;