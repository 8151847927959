import { ModalCalculadoraEnemVerificarCamposTipoRegex } from "../../ModalCalculadoraEnemNovo/ModalCalculadoraEnemVerificarCampos";

export const PdpSidebarValidarCampos = (
  tela: string, apresentarErro = true
): boolean => {
  let botaoAvancar = <HTMLElement | null>document.querySelector(`.sidebar-pdp-tela-${tela} .sidebar-pdp-tela__proximo`);
  let camposTela = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
    .querySelectorAll(`
      .sidebar-pdp-tela-${tela} .sidebar-pdp-tela__campos-formulario input,
      .sidebar-pdp-tela-${tela} .sidebar-pdp-tela__campos-formulario select
    `);

  botaoAvancar?.classList.remove('sidebar-pdp-tela__proximo--ativo');
  if ([...camposTela].length = 0) { return true; }
  let listaItemStatus = [...camposTela].map((item) => {
    let valido = item.checkValidity();

    if (valido) {
      valido = ModalCalculadoraEnemVerificarCamposTipoRegex[item.type].test(item.value.trim());
    }

    return valido;
  });

  if (!listaItemStatus.includes(false)) {
    botaoAvancar?.classList.add('sidebar-pdp-tela__proximo--ativo');
    return true;
  }

  listaItemStatus.forEach((
    status: boolean,
    index: number
  ) => {
    let elemento = camposTela[index];

    if (status == true) {
      elemento.classList.remove('sidebar-pdp-tela__campo--invalido');
    }

    if (status == false) {
      if (apresentarErro == true) {
        if (elemento.nodeName == 'SELECT' && elemento.value == '') {
          elemento.classList.add('sidebar-pdp-tela__campo--invalido');
        }

        elemento.classList.add('sidebar-pdp-tela__campo--invalido');
      }

      botaoAvancar?.classList.remove('sidebar-pdp-tela__proximo--ativo');
    }
  });

  return false;
}