const TesteVocacionalSlider = () => {
  const sliderOptions = {
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    arrows: false,
    swipe: false,
    slidesToShow: 4,
    cssEase: 'linear',
    pauseOnFocus: false,
    pauseOnHover: false,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    }]
  };

  $('.modulo-teste-vocacional  .apresentacao .slider').slick(sliderOptions);
}

export default TesteVocacionalSlider;