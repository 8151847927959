export const VerMaisCursosGraduacaoFooter = () => {
  const botaoVerMais: HTMLElement = <HTMLElement>document.querySelector('.footer__principais-cursos-graduacao .ver-mais');
  const textoBotaoVerMais: HTMLElement = <HTMLElement>document.querySelector('.footer__principais-cursos-graduacao .ver-mais__texto');
  const iconeBotaoVerMais: HTMLImageElement = <HTMLImageElement>document.querySelector('.footer__principais-cursos-graduacao .ver-mais__icone');
  const listaCursos: HTMLElement = <HTMLElement>document.querySelector('.footer__principais-cursos-graduacao .itens__lista');
  const listaCursosBox: HTMLElement = <HTMLElement>document.querySelector('.footer__principais-cursos-graduacao .itens');
  const listaCursosDegrade: HTMLElement = <HTMLElement>document.querySelector('.footer__principais-cursos-graduacao .itens__degrade');
  const body: HTMLElement = <HTMLElement>document.querySelector('body');
  const botaoFechar: HTMLElement = <HTMLElement>document.querySelector('.footer__modal--principais-cursos .conteudo__fechar');

  botaoFechar.addEventListener(
    'click',
    () => body.classList.toggle('ativar-modal-footer--principais-cursos')
  );

  botaoVerMais?.addEventListener('click', () => {
    let tela = window.innerWidth;
    if (tela <= 992) {
      body.classList.toggle('ativar-modal-footer--principais-cursos');
      return;
    }

    let tamalhoListaCursos: number = listaCursos.scrollHeight || 56;

    if (listaCursosBox.style.height === '') {
      listaCursosBox.style.height = `${tamalhoListaCursos}px`;
      listaCursosDegrade.style.transform = `translateY(${tamalhoListaCursos}px)`;
      textoBotaoVerMais.textContent = botaoVerMais.dataset.textoAberto || 'ocultar os principais cursos';
      iconeBotaoVerMais.src = '/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0064B.svg';
    } else {
      listaCursosBox.style.height = '';
      listaCursosDegrade.style.transform = `translateY(0)`;
      textoBotaoVerMais.textContent = botaoVerMais.dataset.textoFechado || 'explorar os principais cursos';
      iconeBotaoVerMais.src = '/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0064.svg';
    }
  });
};

