export const DocumentReady = () => {
  if ($('#ModalVideoSobre').length > 0) {
    let modalVideoSobre = document.getElementById("ModalVideoSobre");
    let btnVideoSobre = document.getElementById("scs-cardinfo-midia-video-btn");
    let btnVideoSobreMobile = document.getElementById("scs-cardinfo-midia-video-btnmobile");
    let spanVideoSobre = document.getElementsByClassName("scs-cardinfo-mvideo-mconteudo-fechar")[0];
    let bodystop = document.getElementsByClassName("single")[0];

    if (btnVideoSobre) {
      btnVideoSobre.onclick = function () {
        modalVideoSobre.style.display = "block";
        bodystop.style.position = "fixed";
        bodystop.style.height = "100vh";
      }
    }

    if (btnVideoSobreMobile) {
      btnVideoSobreMobile.onclick = function () {
        modalVideoSobre.style.display = "block";
        bodystop.style.position = "fixed";
        bodystop.style.height = "100vh";
      }
    }

    if (spanVideoSobre) {
      spanVideoSobre.onclick = function () {
        modalVideoSobre.style.display = "none";
        bodystop.style.position = "initial";
        bodystop.style.height = "initial";
      }
    }

    $('#scs-cardinfo-midia-video-btn').on('click', function (e) {
      e.preventDefault();
      $('body').addClass('blockBody');
    });

    $('#scs-cardinfo-midia-video-btnmobile').on('click', function (e) {
      e.preventDefault();
      $('body').addClass('blockBody');
    });

    $('.scs-cardinfo-mvideo-mconteudo-fechar').on('click', function (e) {
      e.preventDefault();
      $('body').removeClass('blockBody');
    });

  }

  if ($('.topo-conteudo-vitrine-graduacao .nenhum-curso').length) {
    $('.topo-conteudo-vitrine-graduacao').hide();
    $('.vitrine-cursos-filtros').css('margin-top', '0');
  }

  if (window.innerWidth <= 991) {

    $('.pdp-veja-valor-infobtn').on('click', function () {
      $('.pdp-veja-valor-info').toggle();
    });

    $('.btnconhecamobile').on('click', function () {
      $('.pdp-veja-valor-info').hide();
    });

    $('.pdp-veja-valor.btn-padrao').on('click', function () {
      $('.pdp-veja-valor-info').hide();
    });

  }

  if (window.innerWidth >= 992) {

    $('.pdp-veja-valor-infobtn').on('mouseenter', function () {
      $('.sobre-curso .scsidebar-card-info').addClass("pdp-vejavalor-infoopen");
      $('.pdp-veja-valor-info').show();
    });

    $('.pdp-veja-valor-infobtn').on('mouseout', function () {
      $('.sobre-curso .scsidebar-card-info').removeClass("pdp-vejavalor-infoopen");
      $('.pdp-veja-valor-info').hide();
    });

  }

  $('input[value="GESTÃO/MBA"]').parent().remove();

  // Oculta campo de Escolaridade do popup de saida em Pós/Livres
  if (window.location.href.indexOf("/pos-graduacao") > -1 || window.location.href.indexOf("/extensao") > -1 || window.location.href.indexOf("/cursos-livres") > -1) {
    $('.n-modal-saida.deixe-modal .mdeixe-quantoescolaridade').addClass('mdeixes-disable');
  }
  // END - Oculta campo de Escolaridade do popup de saida em Pós/Livres

  // Paliativo filtros das vitrines Pós/Livres/Técnico antiga
  if ($('.opensidebar-filtros').length > 0) {

    $('.opensidebar-filtros').click(function (e) {
      e.preventDefault();
      $('.vitrine .produtos .sidebar .sidebar-filtros').addClass('sidebar-filtrosopen');
      $('body').addClass('blockBody');
      $('.opensidebar-filtros-bg').addClass('opensidebar-filtros-bgon');
      $('.vitrine .produtos .sidebar').addClass('filtrosm-on');

    });

    $('.closesidebar-filtros').click(function (e) {
      e.preventDefault();
      $('.vitrine .produtos .sidebar .sidebar-filtros').removeClass('sidebar-filtrosopen');
      $('body').removeClass('blockBody');
      $('.opensidebar-filtros-bg').removeClass('opensidebar-filtros-bgon');
      $('.vitrine .produtos .sidebar').removeClass('filtrosm-on');
    });

  }
  // END - Paliativo filtros das vitrines Pós/Livres/Técnico antiga

  // Footer Collapse Cursos //
  $('#showMoreFooter').on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass('showMoreFooterActive');
    if ($(this).html() == 'Ver mais') {
      $(this).html('Ver menos');
    } else {
      $(this).html('Ver mais');
    }
    $('.hiddenFooter').toggleClass('showFooter');
  });
  // END - Footer Collapse Cursos //

  // Menu Footer Collapse Campis //
  if (window.innerWidth >= 993) {
    $('.btnVerMaisCampus').click(function (e) {
      $(this).toggleClass("btnVerMaisCampus-open");
      e.preventDefault();
      if ($(this).html() == 'Ver todos') {
        $(this).html('Ver menos');
      } else {
        $(this).html('Ver todos');
      }
      $('.moreContainer').slideToggle("300");
    });
  }
  // END - Menu Footer Collapse Campis //

  // Menu Footer Collapse Geral //
  if (window.innerWidth <= 992) {
    $('.menuf-toggle .menuf-title').click(function () {
      $(this).toggleClass("menuf-open");
      $(this).parent().find('.menuf-content').slideToggle("300");
    });
  }
  // END - Menu Footer Collapse //

  // Menu Mestrado e Doutorado Collapse Geral //
  if (window.innerWidth <= 992) {
    $('.menuLateral .menuLateral-titulo').on("click", function () {
      $(this).toggleClass("menuLateral-open");
      $(this).parent().find('.menuLateral-itens').slideToggle("300");
    });
  }
  // END - Menu Mestrado e Doutorado Collapse Geral //

  if ($('#btnFecharFiltroMob').length) {
    $('#btnFecharFiltroMob').click(function (e) {
      e.preventDefault();
      $('.mlateral-filtros').hide();
      $('.loadContainer').removeClass('loadContainerOpacity');
      $('body').removeClass('overflowDisable');
    });
  }

  if (window.innerWidth <= 992) {
    if ($('.filtro-mobl').length) {
      $('.filtro-mobl').click(function (e) {
        e.preventDefault();
        $('.mlateral-filtros').show();
        $('.loadContainer').addClass('loadContainerOpacity');
        $('body').addClass('overflowDisable');
      });
    }
  }

  // Mostra e esconde filtros na Vitrine Presencial mobile
  $('.container-filtro-mobile').click(function (e) {
    if ($('.container-filtro-mobile input:checkbox:checked').length > 0) {
      $('.btnFiltrar').addClass('btnFadeIn');
      $('.btnLimpar').addClass('btnFadeIn');
    } else {
      $('.btnFiltrar').removeClass('btnFadeIn');
      $('.btnLimpar').removeClass('btnFadeIn');
    }
  });

  if ($('#btnFecharFiltroMob').length) {
    $('#btnFecharFiltroMob').click(function (e) {
      e.preventDefault();
      $('.container-filtro-mobile').hide();
      $('.loadContainer').removeClass('loadContainerOpacity');
      $('body').removeClass('overflowDisable');
    });
  }

  if ($('.filtro-mobl').length) {
    $('.filtro-mobl').click(function (e) {
      e.preventDefault();
      $('.container-filtro-mobile').show();
      $('.loadContainer').addClass('loadContainerOpacity');
      $('body').addClass('overflowDisable');
    });
  }

  $(".audio_controls").click(function (e) {
    e.stopPropagation();
    var itemVideoDesktop = document.getElementById("videoDesktop" + $(this).children().data('banner'));
    var itemVideoMobile = document.getElementById("videoMobile" + $(this).children().data('banner'));
    if ($(this).children().children().hasClass('fa-volume-mute')) {
      $(this).children().children().removeClass('fa-volume-mute');
      $(this).children().children().addClass('fa-volume-up');
      if (window.innerWidth <= 960) {
        itemVideoMobile.muted = false;
      } else {
        itemVideoDesktop.muted = false;
      }
    } else {
      $(this).children().children().removeClass('fa-volume-up');
      $(this).children().children().addClass('fa-volume-mute');
      if (window.innerWidth <= 960) {
        itemVideoMobile.muted = true;
      } else {
        itemVideoDesktop.muted = true;
      }
    }

  });

  //Define a altura dos botão de mute quando a barra rotacional esta ativa
  if ($('.n-barra-rotacional').length > 0) {
    $('.audio_controls').addClass('comrotacional');
  } else {
    $('.audio_controls').removeClass('comrotacional');
  }

  if ($('input[name="identificador"]').length) {
    if ($('input[name="identificador"]').val() == 'Na prática') {
      $('#nossosCursosOferecidosAjudaConsultores').remove();
      $('#cursoDesejadoAjudaConsultor').remove();
      $('.modulo-form textarea').remove();
    }
  }

  if ($('.boxSobreCampusVirtual').length) {
    $('.turmas').hide();
  }

  $('body:not(.single-graduacao) .conceito-mec-texto').mouseenter(function () {
    $('.conceito-mec-box').show();
  });

  $('body:not(.single-graduacao) .conceito-mec-texto').mouseout(function () {
    $('.conceito-mec-box').hide();
  });

  if ($('#cpf-retorne-curso').length) {
    $('#cpf-retorne-curso').mask('000.000.000-00');
  }

  // Veja o valor PDP EAD
  $(".pdp-veja-valor").on("click", function () {
    $(".pdp-inputs-valor").removeClass("disable-pdp-ead");
    $(".cta-ead-pdp-desk").addClass("disable-pdp-ead");

    if (getCookie('sendFormPricePDP')) {
      $('.pdp-inputs-valor .titulo-ead-pdp').hide();
      $('.pdp-inputs-valor > p').hide();
      $('.pdp-inputs-valor .step-1').hide();
      $(".pdp-inputs-valor").hide();
      $('.ead-pdp-card-valor').removeClass("disable-pdp-ead");
    }

  });

  $(".pdp-fechar-valor").on("click", function () {
    $(".cta-ead-pdp-desk").removeClass("disable-pdp-ead");
    $(".pdp-inputs-inscrevase").addClass("disable-pdp-ead");
    $(".pdp-inputs-valor").addClass("disable-pdp-ead");
    $(".ead-pdp-card-valor").addClass("disable-pdp-ead");
  });
  // END - Veja o valor PDP EAD

  $(".pdp-enviar-valor-desktop").on("click", function (e) {
    let formCurso = document.querySelector(".pdp-inputs-valor-desktop");
    let geolocalizacao = document.querySelector(".pdp-inputs-valor-desktop .step-2 .switch-geolocal-onoff");

    // Retirando validação de obrigatorio em campos caso geolocalização esteja ativada
    if (geolocalizacao.checked) {
      formCurso.Pais.required = false;
      formCurso.estado.required = false;
      formCurso.cidade.required = false;
    }

    if (getCookie('ehPoloInCompany') === '1') {
      formCurso.Pais.required = false;
      formCurso.estado.required = false;
      formCurso.cidade.required = false;
      formCurso.polo.required = false;
    }

    // Ativando validação HTML5 em campos requeridos do formulário
    if (formCurso.checkValidity()) {
      e.preventDefault();
    } else {
      formCurso.reportValidity();
      return;
    }

    let buttonSubmit = document.querySelector(".pdp-enviar-valor-desktop");
    $(buttonSubmit).prop('disabled', true);
    $(buttonSubmit).html('Aguarde...');
    $(buttonSubmit).addClass('form-btnloading');

    var form = $('.pdp-inputs-valor-desktop');
    var inputs = $("input,textarea,select", form);
    var input = null;
    var fields = {};
    for (var i = 0, len = inputs.length; i < len; i++) {
      input = inputs[i];
      var name = $(input).attr("name");
      if (getCookie('ehPoloInCompany') === '1') {
        switch (name) {
          case 'Pais': fields[name] = localStorage.getItem('polo_pais');
            break;
          case 'estado': fields[name] = localStorage.getItem('polo_estado');
            break;
          case 'cidade': fields[name] = localStorage.getItem('polo_cidade');
            break;
          case 'polo': fields[name] = localStorage.getItem('polo_nome');
            fields['polo_origem'] = localStorage.getItem('polo_id') + 'POLO';
            break;
          default: if (name !== undefined && name !== "tipoformulario" && name !== "identificador") {
            fields[name] = input.value;
          }
        }
      } else {
        if (name == 'polo') {
          fields[name] = $('option:selected', input).html();
          fields['polo_origem'] = input.value + 'POLO';
        }
        if (name !== undefined && name !== "tipoformulario" && name !== "identificador") {
          fields[name] = input.value;
        }
      }
    }

    if (window.location.href.indexOf("cruzeirodosulvirtual.com.br") != -1 || window.location.href.indexOf(":8014") != -1) {
      fields['modalidade'] = 'EAD Digital';
    } else if (window.location.href.indexOf("semipresencial") != -1) {
      fields['modalidade'] = 'EAD Semipresencial';
    } else {
      fields['modalidade'] = 'Presencial';
    }

    $.ajax({
      url: WpObj.ajaxurl,
      method: "POST",
      data: {
        action: "processFields",
        nonce: ProcessFields.nonce,
        tipoformulario: decodeURI($(form).find('input[name="tipoformulario"]').val()),
        identificador: decodeURI($(form).find('input[name="identificador"]').val()),
        idAnalytics: idAnalyticsValue,
        inputs: fields,
        stat: 'sendFormPricePDP'
      }
    }).done(function (data) {
      $(".ead-pdp-card-valor").removeClass("disable-pdp-ead");
      $(".pdp-inputs-valor").addClass("disable-pdp-ead");
      $(".pdp-enviar-valor").removeClass('form-btnloading');
      sessionStorage.setItem('idLeadSessionSF', data.LeadId);
    });

  });

  $(".pdp-enviar-valor-mobile").on("click", function (e) {
    formCurso = document.querySelector(".pdp-inputs-valor-mobile");
    let geolocalizacao = document.querySelector(".pdp-inputs-valor-mobile .step-2 .switch-geolocal-onoff");

    // Retirando validação de obrigatorio em campos caso geolocalização esteja ativada
    if (geolocalizacao.checked) {
      formCurso.Pais.required = false;
      formCurso.estado.required = false;
      formCurso.cidade.required = false;
    }

    if (getCookie('ehPoloInCompany') === '1') {
      formCurso.Pais.required = false;
      formCurso.estado.required = false;
      formCurso.cidade.required = false;
      formCurso.polo.required = false;
    }

    if (formCurso.checkValidity()) {
      e.preventDefault();
    } else {
      formCurso.reportValidity();
      return;
    }

    let buttonSubmit = document.querySelector(".pdp-enviar-valor-mobile");
    $(buttonSubmit).prop('disabled', true);
    $(buttonSubmit).html('Aguarde...');
    $(buttonSubmit).addClass('form-btnloading');

    var form = $('.pdp-inputs-valor-mobile');
    var inputs = $("input,textarea,select", form);
    var input = null;
    var fields = {};

    for (var i = 0, len = inputs.length; i < len; i++) {
      input = inputs[i];
      var name = $(input).attr("name");
      if (getCookie('ehPoloInCompany') === '1') {
        switch (name) {
          case 'Pais': fields[name] = localStorage.getItem('polo_pais');
            break;
          case 'estado': fields[name] = localStorage.getItem('polo_estado');
            break;
          case 'cidade': fields[name] = localStorage.getItem('polo_cidade');
            break;
          case 'polo': fields[name] = localStorage.getItem('polo_nome');
            fields['polo_origem'] = localStorage.getItem('polo_id') + 'POLO';
            break;
          default: if (name !== undefined && name !== "tipoformulario" && name !== "identificador") {
            fields[name] = input.value;
          }
        }
      } else {
        if (name == 'polo') {
          fields[name] = $('option:selected', input).html();
          fields['polo_origem'] = input.value + 'POLO';
        }
        if (name !== undefined && name !== "tipoformulario" && name !== "identificador") {
          fields[name] = input.value;
        }
      }
    }

    if (window.location.href.indexOf("cruzeirodosulvirtual.com.br") != -1 || window.location.href.indexOf(":8014") != -1) {
      fields['modalidade'] = 'EAD Digital';
    } else if (window.location.href.indexOf("semipresencial") != -1) {
      fields['modalidade'] = 'EAD Semipresencial';
    } else {
      fields['modalidade'] = 'Presencial';
    }

    $.ajax({
      url: WpObj.ajaxurl,
      method: "POST",
      data: {
        action: "processFields",
        nonce: ProcessFields.nonce,
        tipoformulario: decodeURI($(form).find('input[name="tipoformulario"]').val()),
        identificador: decodeURI($(form).find('input[name="identificador"]').val()),
        idAnalytics: idAnalyticsValue,
        inputs: fields,
        stat: 'sendFormPricePDP'
      }
    }).done(function (data) {
      $(".ead-pdp-card-valor").removeClass("disable-pdp-ead");
      $(".pdp-inputs-valor").addClass("disable-pdp-ead");
      $(".pdp-enviar-valor").removeClass('form-btnloading');
      let dados = JSON.parse(data);
      sessionStorage.setItem('idLeadSessionSF', dados.message);
    });

  });

  $('.pdp-inputs-valor').on("submit", function (e) {
    e.preventDefault();
  });

  // INSCREVA-SE

  $(".pdp-inscreva-se").on("click", function (e) {
    e.preventDefault();

    let tipoCurso = document.querySelector('#tipo_curso').value;
    let nonceValue = document.querySelector('#wp_nonce_get_dados_polo').value;
    if ($(this).attr('data-action') == 'inscrevase') {
      if (getCookie('sendFormPricePDP')) {
        $(".pdp-inscreva-se").html('Aguarde...');
        $(".pdp-inscreva-se").addClass('form-btnloading');
        $.ajax({
          url: WpObj.ajaxurl,
          method: 'POST',
          data: {
            action: 'getDadosPolo',
            poloSelecionado: getCookie('poloSelecionado'),
            postid: $('body').attr('class').match(/postid-([0-9]+)/)[1],
            cursoid: $('.cursoid').val(),
            nonce: nonceValue,
            tipoCurso: tipoCurso,
            idLead: sessionStorage.getItem('idLeadSessionSF'),
          },
        }).done(function (data) {
          var obj = JSON.parse(data);
          $(".postid").after("<input type='hidden' class='urlProvaOnline' value='" + obj['urlProvaOnline'] + "'>");
          $(".postid").after("<input type='hidden' class='urlAgendadaDigital' value='" + obj['urlAgendadaDigital'] + "'>");
          $(".postid").after("<input type='hidden' class='urlIngressoEnem' value='" + obj['urlIngressoEnem'] + "'>");
          $(".postid").after("<input type='hidden' class='urlSegundaGraduacao' value='" + obj['urlSegundaGraduacao'] + "'>");
          $(".postid").after("<input type='hidden' class='urlSegundaGraduacao20' value='" + obj['urlSegundaGraduacao20'] + "'>");
          $(".postid").after("<input type='hidden' class='urlTransferencia' value='" + obj['urlTransferencia'] + "'>");
          $(".postid").after("<input type='hidden' class='urlPedagogiaLicenciados' value='" + obj['urlPedagogiaLicenciados'] + "'>");
          $(".postid").after("<input type='hidden' class='urlR2' value='" + obj['urlR2'] + "'>");
          $(".postid").after("<input type='hidden' class='urlRetorneCurso' value='" + obj['urlRetorneCurso'] + "'>");
          $('.pdp-inputs-inscrevase .titulo-ead-pdp').hide();
          $('.pdp-inputs-inscrevase > p').hide();
          $('.pdp-inputs-inscrevase .step-1').hide();
          $('.pdp-inputs-inscrevase .mlateral-filtros-geolocal').hide();
          $('.container-data-formas').removeClass('hidden-item-step');
          $('.container-data-formas .titulo-ead-pdp').show();
          $(".pdp-inscreva-se").html('Inscreva-se');
          $(".pdp-inscreva-se").removeClass('form-btnloading');
          $(".pdp-inputs-inscrevase").removeClass("disable-pdp-ead");
          $(".cta-ead-pdp-desk").addClass("disable-pdp-ead");
        });
      } else {
        $(".pdp-inputs-inscrevase").removeClass("disable-pdp-ead");
        $(".cta-ead-pdp-desk").addClass("disable-pdp-ead");
      }
    } else {
      var curso = this.getAttribute('data-curso');
      $(".modal-avise").removeClass('disable-modal');
      $(".bg_lightbox").removeClass('disable-modal');
      $('#cursoAvise').val(curso);
    }
  });

  $(".pdp-inscreva-se-card").on("click", function () {
    if (getCookie('sendFormPricePDP')) {
      $(".pdp-inscreva-se-card").html('Aguarde...');
      $(".pdp-inscreva-se-card").addClass('form-btnloading');
      let tipoCurso = document.querySelector('#tipo_curso').value;
      let nonceValue = document.querySelector('#wp_nonce_get_dados_polo').value;
      $.ajax({
        url: WpObj.ajaxurl,
        method: 'POST',
        data: {
          action: 'getDadosPolo',
          poloSelecionado: getCookie('poloSelecionado'),
          postid: $('body').attr('class').match(/postid-([0-9]+)/)[1],
          cursoid: $('.cursoid').val(),
          nonce: nonceValue,
          tipoCurso: tipoCurso,
          idLead: sessionStorage.getItem('idLeadSessionSF'),
        },
      }).done(function (data) {
        var obj = JSON.parse(data);
        $(".postid").after("<input type='hidden' class='urlProvaOnline' value='" + obj['urlProvaOnline'] + "'>");
        $(".postid").after("<input type='hidden' class='urlAgendadaDigital' value='" + obj['urlAgendadaDigital'] + "'>");
        $(".postid").after("<input type='hidden' class='urlIngressoEnem' value='" + obj['urlIngressoEnem'] + "'>");
        $(".postid").after("<input type='hidden' class='urlSegundaGraduacao' value='" + obj['urlSegundaGraduacao'] + "'>");
        $(".postid").after("<input type='hidden' class='urlSegundaGraduacao20' value='" + obj['urlSegundaGraduacao20'] + "'>");
        $(".postid").after("<input type='hidden' class='urlTransferencia' value='" + obj['urlTransferencia'] + "'>");
        $(".postid").after("<input type='hidden' class='urlPedagogiaLicenciados' value='" + obj['urlPedagogiaLicenciados'] + "'>");
        $(".postid").after("<input type='hidden' class='urlR2' value='" + obj['urlR2'] + "'>");
        $(".postid").after("<input type='hidden' class='urlRetorneCurso' value='" + obj['urlRetorneCurso'] + "'>");
        $(".pdp-inscreva-se-card").html('Inscreva-se');
        $(".pdp-inscreva-se-card").removeClass('form-btnloading');
        $(".pdp-inputs-inscrevase").removeClass("disable-pdp-ead");
        $(".cta-ead-pdp-desk").addClass("disable-pdp-ead");
        $(".ead-pdp-card-valor").addClass("disable-pdp-ead");

        $('.pdp-inputs-inscrevase .titulo-ead-pdp').hide();
        $('.pdp-inputs-inscrevase > p').hide();
        $('.pdp-inputs-inscrevase .step-1').hide();
        $('.mlateral-filtros-geolocal').hide();
        $('.pdp-inputs-inscrevase .container-data-formas').removeClass('hidden-item-step');
      });
    } else {
      $(".pdp-inputs-inscrevase").removeClass("disable-pdp-ead");
      $(".cta-ead-pdp-desk").addClass("disable-pdp-ead");
      $(".ead-pdp-card-valor").addClass("disable-pdp-ead");
    }
  });

  $(".select-ead-pdp").change(function () {
    $(".pdp-inputs-inscrevase").removeClass("pdp-inputs-inscrevase-hidden");
  });

  if (window.location.href.indexOf("/aluno") > 0) {
    $('#btnAlunoNew').html('Login');
    $('#btnAlunoNew').attr('href', 'https://novoportal.cruzeirodosul.edu.br/?empresa=' + coEmpresa + '&blackboard=false');
    $('body').addClass('home-do-aluno');
  }

  $('.show-text').click(function (e) {
    e.preventDefault();
    $(this).prev().toggle();
    if ($(this).text() == 'ver menos') $(this).html('ver mais');
    else $(this).html('ver menos')
  });

  $('.parcelas_forma_pagamento').on('change', function () {
    var valor = $("option:selected", this).data('valor');
    var valor_8 = $("option:selected", this).data('valor-8');
    var valor_9 = $("option:selected", this).data('valor-9');
    var atraso = $("option:selected", this).data('atraso');
    var atraso_8 = $("option:selected", this).data('atraso-8');
    var atraso_9 = $("option:selected", this).data('atraso-9');
    var pravaler = $("option:selected", this).data('pravaler');
    var pravaler_8 = $("option:selected", this).data('pravaler-8');
    var pravaler_9 = $("option:selected", this).data('pravaler-9');
    var disciplinas = $("option:selected", this).val();
    var texto_anterior = $('.obs').html();
    $('.qtd_disciplinas').html(disciplinas + ' disciplinas,');
    $('.valor_parcela').html('R$ ' + valor + (valor.includes(',') ? '*' : ',00*'));
    $('.obs').html(' Após a data: R$ ' + atraso + (atraso.includes(',') ? '' : ',00'));
    $('.box_pravaler').html('R$ ' + pravaler + (pravaler.includes(',') ? ' com o PRAVALER' : ',00 com o PRAVALER'));
    if ($('.plano_7')) {
      $('.plano_7 .valor_parcela').html('R$ ' + valor_8 + (valor_8.includes(',') ? '*' : ',00*'));
      $('.plano_7 .obs').html(texto_anterior + ' Após a data: R$ ' + atraso_8 + (atraso_8.includes(',') ? '' : ',00'));
      $('.plano_7 .box_pravaler').html('R$ ' + pravaler_8 + (pravaler_8.includes(',') ? ' com o PRAVALER' : ',00 com o PRAVALER'));
    }
    //Configurações de valor plano 9
    if ($('.plano_9')) {
      $('.plano_9 .valor_parcela').html('R$ ' + valor_9 + (valor_9.includes(',') ? '*' : ',00*'));
      $('.plano_9 .obs').html(' Após a data: R$ ' + atraso_9 + (atraso_9.includes(',') ? '' : ',00'));
      $('.plano_9 .box_pravaler').html('R$ ' + pravaler_9 + (pravaler_9.includes(',') ? ' com o PRAVALER' : ',00 com o PRAVALER'));
    }

    $('.hidden_plano_pagamento').show();
  });

  pathname = window.location.pathname;
  if (pathname == '/graduacao/medicina/') {
    $('#formas-ingresso').hide();
  }
  $('.btninscrevase:not(.aviseme)').on('click', function (event) {
    event.preventDefault();

    $(".pdppres-inscreva-se").addClass("disable-btns-pdppres");
    $(".pdpres-veja-valor").addClass("disable-btns-pdppres");
    var local = window.location.origin;
    if (pathname == '/graduacao/medicina/') {
      window.location.assign(
        local + '/processo-seletivo/graduacao-presencial/?link=bMedicina'
      );
    } else {
      $('html, body').animate({
        scrollTop: $('#FormasIngresso').offset().top - 50,
      }, 1000);
    }
  });
  $('.aviseme').on('click', function (event) {
    event.preventDefault();
    var curso = this.getAttribute('data-curso');
    $(".modal-avise").removeClass('disable-modal');
    $(".bg_lightbox").removeClass('disable-modal');
    $('#cursoAvise').val(curso);
  });

  $('#btnAvisePos').click(function (e) {
    e.preventDefault();
    var curso = this.getAttribute('data-curso');
    $(".modal-avise").removeClass('disable-modal');
    $(".bg_lightbox").removeClass('disable-modal');
    $('#cursoAvise').val(curso);
  });

  $('.btnconheca').on('click', function (e) {
    $(".pdppres-inscreva-se").addClass("disable-btns-pdppres");
    $(".pdpres-veja-valor").addClass("disable-btns-pdppres");
    $(this).next().show();
  });

  $('.btnconhecamobile').on('click', function (e) {
    $('.pdpres-vejavalor-conteudo').show();
  });

  if ($('#cursos-slider').length) {
    $('#cursos-slider').slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      adaptiveHeight: true
    });
    $('.flex-control-nav a').click(function (e) {
      e.preventDefault();
      var slide = $(this).attr('data-slider');
      $('#cursos-slider').slick('slickGoTo', slide);
      $('.flex-control-nav a').removeClass('flex-active');
      $(this).addClass('flex-active');
    });
  }

  if ($('#cursos-slidermobile').length) {
    $('#cursos-slidermobile').slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      adaptiveHeight: true
    });
    $('.flex-control-nav a').click(function (e) {
      e.preventDefault();
      var slide = $(this).attr('data-slider');
      $('#cursos-slidermobile').slick('slickGoTo', slide);
      $('.flex-control-nav a').removeClass('flex-active');
      $(this).addClass('flex-active');
    });
  }

  $('.input-text-valor').on('keyup', function () {
    $(this).next().removeClass('step-hidden-valor');
  });

  $('.email-ead-pdp-input').on('keyup', function () {
    if (getCookie('ehPoloInCompany') === '1') {
      $('.step-3.step-hidden-valor').removeClass('step-hidden-valor');
    } else {
      $('.step-2.step-hidden-valor').removeClass('step-hidden-valor');
    }
  });

  $('.posduracao-range').change(function () {
    var valorSelecionado = $(this).val();
    var params = new URLSearchParams(window.location.search);
    params.set('duracao', valorSelecionado);
    window.location.search = params.toString();
  });

  $('#grad-iess').change(function () {
    var valorSelecionado = $(this).val();
    var area = valorSelecionado + "!";
    var params = new URLSearchParams(window.location.search);
    params.set('area', area);
    params.set('pagina', '');
    if ($('.vitrine-cursos-geralpolo').length) {
      params.set('ancora', 'CursosPolo');
    }
    window.location.search = params.toString();
  });

  $('.filtro-tipos-virtual').click(function () {
    var valorSelecionado = $(this).val();
    var tipoUrl = new URLSearchParams(window.location.search).get('tipo');
    if (tipoUrl && tipoUrl.indexOf(valorSelecionado) !== -1) {
      var tipo = tipoUrl.replace(valorSelecionado + "!", '');
    } else if (tipoUrl) {
      var tipo = tipoUrl + valorSelecionado + "!";
    } else {
      var tipo = valorSelecionado + "!";
    }
    var params = new URLSearchParams(window.location.search);
    params.set('tipo', tipo);
    if ($('.vitrine-cursos-geralpolo').length) {
      params.set('ancora', 'CursosPolo');
    }
    window.location.search = params.toString();
  });

  $('.filtro-tipos-de-curso-virtual').click(function () {
    var valorSelecionado = $(this).val();
    var tipoCursoUrl = new URLSearchParams(window.location.search).get('tipo-de-curso');
    if (tipoCursoUrl && tipoCursoUrl.indexOf(valorSelecionado) !== -1) {
      var tipoCurso = tipoCursoUrl.replace(valorSelecionado + "!", '');
    } else if (tipoCursoUrl) {
      var tipoCurso = tipoCursoUrl + valorSelecionado + "!";
    } else {
      var tipoCurso = valorSelecionado + "!";
    }
    var params = new URLSearchParams(window.location.search);
    params.set('tipo-de-curso', tipoCurso);
    if ($('.vitrine-cursos-geralpolo').length) {
      params.set('ancora', 'CursosPolo');
    }
    window.location.search = params.toString();
  });

  $('#form-search-curso-polo').submit(function (e) {
    e.preventDefault();
    var valorSelecionado = $('#nome-curso-polo').val();
    var params = new URLSearchParams(window.location.search);
    params.set('nome', valorSelecionado);
    if ($('.vitrine-cursos-geralpolo').length) {
      params.set('ancora', 'CursosPolo');
    }
    window.location.search = params.toString();
  });

  if ($("#orgLista").length) {
    $("#orgLista").click(function () {
      $(this).css('opacity', '1');
      $('#orgGaleria').css('opacity', '.3');
      $('.vitrine-graduacao-cards').removeClass('vitrine-graduacao-cards-org-galeria');
      $('.vitrine-graduacao-cards').addClass('vitrine-graduacao-cards-org-lista');
      return false;
    });
  }

  if ($("#orgGaleria").length) {
    $("#orgGaleria").click(function () {
      $(this).css('opacity', '1');
      $('#orgLista').css('opacity', '.3');
      $('.vitrine-graduacao-cards').removeClass('vitrine-graduacao-cards-org-lista');
      $('.vitrine-graduacao-cards').addClass('vitrine-graduacao-cards-org-galeria');
      return false;
    });
  }

  if ($('.btnLimpar').length) {
    $('.btnLimpar').on('click', function (e) {
      e.preventDefault();
      var url = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
      window.location.replace(url);
    });
  }

  $('.btnOpenVest').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('clickedButton');
    $('.contentVest').toggleClass('openContent');
  });

  $('#btnOpenEnem').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('clickedButton');
    $('.contentEnem').toggleClass('openContent');
  });

  $('#btnOpenTransf').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('clickedButton');
    $('.contentTransf').toggleClass('openContent');
  });

  $('#resultadosMedVest').change(function () {
    window.location.replace($(this).val());
  });

  $('#resultadosMedEnem').change(function () {
    window.location.replace($(this).val());
  });

  $('.btnRecolherMedicina').click(function (e) {
    e.preventDefault();
    $('.containerRecolherMedicina').slideToggle(600);
    $('.btnRecolherMedicina > span > i').toggleClass(
      'fa-angle-up',
      'fa-angle-down'
    );
  });

  $('.btnRecolherMedicinaTransferencia').click(function (e) {
    e.preventDefault();
    $('.containerRecolherMedicinaTransferencia').slideToggle(600);
    $('.btnRecolherMedicinaTransferencia > span > i').toggleClass(
      'fa-angle-up',
      'fa-angle-down'
    );
  });

  if ($('.ico-svg-mais, .aba-menu-mais').length) {
    $('.ico-svg-mais, .aba-menu-mais').mouseover(function () {
      $('.aba-menu-mais').stop().slideDown(400, 'linear');
    });
  }

  if ($('.aba-menu-mais').length) {
    $('.aba-menu-mais').mouseout(function () {
      $('.aba-menu-mais').stop().slideUp(400, 'linear');
    });
  }

  if ($('.ico-svg-fechar').length) {
    $('.ico-svg-fechar').click(function (e) {
      e.preventDefault();
      $('.n-barra-alerta').fadeOut();
    });
  }

  if ($('#download').length) {
    jQuery('#download').flexslider({
      animation: 'slide',
      animationLoop: true,
      itemWidth: 220,
      minItems: 1,
      maxItems: 6,
    });
  }

  AOS.init();

  $('#btnReadMore').click(function (e) {
    e.preventDefault();
    var dots = document.getElementById('dots');
    var moreText = document.getElementById('more');
    var btnText = document.getElementById('btnReadMore');

    if (dots.style.display === 'none') {
      dots.style.display = 'inline';
      btnText.innerHTML = 'Leia mais...';
      moreText.style.display = 'none';
    } else {
      dots.style.display = 'none';
      btnText.innerHTML = 'Ler menos...';
      moreText.style.display = 'inline';
    }
  });

  $('.modal-retorne .modal-retorne-fechar').click(function (e) {
    e.preventDefault();
    $('.modal-retorne').removeClass('showModalRetorne');
    $('body').removeClass('blockBody');
  });

  $('.modal-retorne .fechar-modal-topo').click(function (e) {
    e.preventDefault();
    $('.modal-retorne').removeClass('showModalRetorne');
    $('body').removeClass('blockBody');
  });

  $('.modal-enem-antecipado .fechar-modal-topo').click(function (e) {
    e.preventDefault();
    $('.modal-enem-antecipado').removeClass('showModalRetorne');
    $('body').removeClass('blockBody');
  });

  $('a:not(.download-button)').on('click', function (e) {
    var href = $(this).attr('href');
    url = window.location.href;
    if (url.match(/cruzeirodosulvirtual.com.br/) || url.match(/localhost:8014/)) {
      coEmpresa = "12";
    }
    if (typeof href !== 'undefined') {
      if (href.match(/retorneaocurso/)) {
        e.preventDefault();
        $('.modal-retorne').addClass('showModalRetorne');
        $('body').addClass('blockBody');
      } else if (coEmpresa == 12 && href.match(/retorneaocurso/)) {
        e.preventDefault();
        $('.modal-retorne').addClass('showModalRetorne');
        $('body').addClass('blockBody');
      } else if (href.match(/enemantecipado/)) {
        e.preventDefault();
        $('.modal-enem-antecipado').addClass('showModalRetorne');
        $('body').addClass('blockBody');
      } else if (href.match(/provaagendadamodal/)) {
        e.preventDefault();
        $('.modal-prova-agendada').addClass('showModalRetorne');
        $('body').addClass('blockBody');
      }
    } else {
      return false;
    }
  });

  $(".modal-retorne .modal-retorne-avancar").click(function (e) {
    e.preventDefault();
    url = window.location.href;
    if (url.match(/unicid.edu.br/) || url.match(/localhost:8002/)) {
      coEmpresa = "8";
    } else if (url.match(/cruzeirodosulvirtual.com.br/) || url.match(/localhost:8014/)) {
      coEmpresa = "12";
    } else if (url.match(/cruzeirodosul.edu.br/) || url.match(/localhost:8003/)) {
      coEmpresa = "1";
    } else if (url.match(/unifran.edu.br/) || url.match(/localhost:8004/)) {
      coEmpresa = "14";
    } else if (url.match(/ceunsp.edu.br/) || url.match(/localhost:8006/)) {
      coEmpresa = "29";
    } else if (url.match(/unipe.edu.br/) || url.match(/localhost:8005/)) {
      coEmpresa = "38";
    } else if (url.match(/udf.edu.br/) || url.match(/localhost:8007/)) {
      coEmpresa = "6";
    } else if (url.match(/cesuca.edu.br/) || url.match(/localhost:8008/)) {
      coEmpresa = "35";
    } else if (url.match(/modulo.edu.br/) || url.match(/localhost:8009/)) {
      coEmpresa = "4";
    } else if (url.match(/fass.edu.br/) || url.match(/localhost:8010/)) {
      coEmpresa = "25";
    } else if (url.match(/fsg.edu.br/) || url.match(/localhost:8011/)) {
      coEmpresa = "34";
    } else if (url.match(/brazcubas.edu.br/) || url.match(/localhost:8012/)) {
      coEmpresa = "42";
    } else if (url.match(/up.edu.br/) || url.match(/localhost:8013/)) {
      coEmpresa = "47";
    } else if (url.match(/fapipinhais.edu.br/) || url.match(/localhost:8015/)) {
      coEmpresa = "82";
    } else {
      coEmpresa = "8";
    }
    var cpf = $("#cpf-retorne-curso").val().replace('.', '').replace('.', '').replace('-', '');
    var url =
      "https://siaa.cruzeirodosul.edu.br/vestibular-inscricao/inscricao/cadastro.jsf?inicio=1&codigoEmpresa=" +
      coEmpresa +
      "&cpfCandidato=" +
      cpf +
      "&tipoVestibular=26";
    window.open(url, '_blank');
  });

  if ($('#curso').length) {
    $('#curso:not(.calculadora-enem-c__step-select)').change(function () {
      cocCurso = $('#curso option:selected').val();
      let nonceValue = document.querySelector('#wp_nonce_get_periodo_curso').value;
      $.ajax({
        url: WpObj.ajaxurl,
        method: 'POST',
        data: {
          action: 'getPeriodoCurso',
          codCurso: cocCurso,
          nonce: nonceValue,
        },
      }).done(function (data) {
        let obj = JSON.parse(data);

        $('#periodo').html('<option value="">Períodos</option>');
        obj.forEach(function (o, index) {
          periodoTexto = o.charAt(0).toUpperCase() + o.slice(1);
          $('#periodo').append(
            '<option value="' +
            o +
            '">' +
            periodoTexto +
            '</option>'
          );
        });

        $('#periodo').prop('disabled', false);
      });
    });
  }

  // BIBLIOTECA
  $('.formDuvidas #cursoDesejadoAjudaConsultor').css('display', 'none'); // incialmente oculta esse campo no form dúvidas com os consultores
  if ($('#form-pesquisa-biblioteca').length) {
    $('#form-pesquisa-biblioteca').tabs();

    document.querySelector('.renove-biblioteca').addEventListener('click', () => {
      let link = document.querySelector('#form-pesquisa-biblioteca .renove-biblioteca');

      window.location.href = link;
    });
  }

  // FECHA BIBLIOTECA

  // PDP  ***** MEDICINA  //

  $('#btnAviseMeMobile').click(function () {
    event.preventDefault();
    $(".modal-avise").removeClass('disable-modal');
    $(".bg_lightbox").removeClass('disable-modal');
  });

  $('.botao-valor-mobile').click(function () {
    event.preventDefault();
    $('.botao-valor-mobile').hide();
    $('.form-mobile').show();
  });

  // FECHA PDP MEDICINA //

  if (screen.width < 991) {
    url = location.href;
    if (url.indexOf('link') != -1) {
      let query = location.search.slice(1);
      let partes = query.split('&');
      let data = {};
      partes.forEach(function (parte) {
        let chaveValor = parte.split('=');
        let chave = chaveValor[0];
        let valor = chaveValor[1];
        data[chave] = valor;
      });
      area = data['link'];
      $('#' + area).show();
      $('html, body').animate(
        {
          scrollTop: $('#' + area).offset().top
        },
        1000
      );
    }
  }
  jQuery('#select-perfil').change(function () {
    url = window.location.href;
    if (url.match(/unicid.edu.br/) || url.match(/localhost:8002/)) {
      coEmpresa = 'unicid';
    } else if (url.match(/cruzeirodosulvirtual.com.br/) || url.match(/localhost:8014/)) {
      coEmpresa = 'cruzeirovirtual';
    } else if (url.match(/cruzeirodosul.edu.br/) || url.match(/localhost:8003/)) {
      coEmpresa = 'cruzeiro';
    } else if (url.match(/unifran.edu.br/) || url.match(/localhost:8004/)) {
      coEmpresa = 'unifran';
    } else if (url.match(/ceunsp.edu.br/) || url.match(/localhost:8006/)) {
      coEmpresa = 'ceunsp';
    } else if (url.match(/unipe.edu.br/) || url.match(/localhost:8005/)) {
      coEmpresa = 'unipe';
    } else if (url.match(/udf.edu.br/) || url.match(/localhost:8007/)) {
      coEmpresa = 'udf';
    } else if (url.match(/cesuca.edu.br/) || url.match(/localhost:8008/)) {
      coEmpresa = 'cesuca';
    } else if (url.match(/modulo.edu.br/) || url.match(/localhost:8009/)) {
      coEmpresa = 'modulo';
    } else if (url.match(/fass.edu.br/) || url.match(/localhost:8010/)) {
      coEmpresa = 'fass';
    } else if (url.match(/fsg.edu.br/) || url.match(/localhost:8011/)) {
      coEmpresa = 'fsg';
    } else if (url.match(/brazcubas.edu.br/) || url.match(/localhost:8012/)) {
      coEmpresa = 'brazcubas';
    } else if (url.match(/up.edu.br/) || url.match(/localhost:8013/)) {
      coEmpresa = "up";
    } else if (url.match(/fapipinhais.edu.br/) || url.match(/localhost:8015/)) {
      coEmpresa = "fapi";
    } else {
      coEmpresa = 'unicid';
    }
    $('#select-perfil option:selected').each(function () {
      link = $(this).val();

      Cookies.set(coEmpresa + 'PerifilVisita', link, {
        path: '/',
        expires: 365,
      });
      home = window.location.protocol + '//' + window.location.host + '/';

      if (link == home) {
        Cookies.remove(coEmpresa + 'PerifilVisita');
      }

      window.location.assign(link);
    });
  });

  jQuery('.resultado-tradicional').change(function () {
    link = $(this).find('option:selected').val();
    if (link != '') {
      window.open(link);
    } else {
      return false;
    }
  });

  var url = window.location.href;

  $('#select-perfil option').each(function () {
    if (url == $(this).val()) {
      $(this).prop('selected', true);
    }
  });

  if (coEmpresa == 'cruzeirovirtual') {
    $('.item-campus-pdp').hide();
  }

  $('#menuEstude').addClass('menu-estude-' + coEmpresa);
  $('.medicinaEnem').addClass('column-' + coEmpresa);
  $('#bMedicinaTransferencia').addClass('column-med-transf-' + coEmpresa);
  $('.bolsaMeritoEnem').addClass('enem-' + coEmpresa);
  $('.vitrine-graduacao').addClass('vitrine-graduacao-' + coEmpresa);

  $('.select-inscreva-pais').change(function (event) {
    var valorSelecionado = $(this).val();
    let nonceValue = document.querySelector('#wp_nonce_get_estados').value;

    let ldsring = document.createElement("div");
    let ldsringDiv1 = document.createElement("div");
    let ldsringDiv2 = document.createElement("div");
    let ldsringDiv3 = document.createElement("div");
    let ldsringDiv4 = document.createElement("div");
    let ldsringDiv5 = document.createElement("div");
    ldsring.classList.add('lds-ring');
    ldsring.appendChild(ldsringDiv1);
    ldsring.appendChild(ldsringDiv2);
    ldsring.appendChild(ldsringDiv3);
    ldsring.appendChild(ldsringDiv4);
    ldsring.appendChild(ldsringDiv5);
    event.target.after(ldsring);

    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getEstados',
        pais: valorSelecionado,
        nonce: nonceValue,
      },
    }).done(function (data) {

      var obj = JSON.parse(data);
      $('.select-inscreva-estado').html('<option value="" disabled selected hidden>Selecione um estado</option>');
      event.target.nextElementSibling.remove();

      obj.forEach(function (o, index) {
        $('.select-inscreva-estado').append('<option value="' + o.sigla + '">' + o.nome + '</option>');
      });

      $('.select-inscreva-estado').removeClass('hidden-item-step');
    });
  });

  $('#campusPais').change(function () {
    var valorSelecionado = $(this).val();
    let nonceValue = document.querySelector("#wp_nonce_get_estados_calcule_desconto").value;
    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getEstados',
        pais: valorSelecionado,
        nonce: nonceValue,
      },
    }).done(function (data) {
      $('#campusUf').html('<option disabled selected>Selecione um estado</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        $('#campusUf').append('<option value="' + o.sigla + '">' + o.nome + '</option>');
      });
      $('#campusUf').prop('disabled', false);
    });
  });

  $('.select-pais-valor').change(function (event) {
    var valorSelecionado = $(this).val();
    let nonceValue = document.querySelector("#wp_nonce_get_estados").value;

    let ldsring = document.createElement("div");
    let ldsringDiv1 = document.createElement("div");
    let ldsringDiv2 = document.createElement("div");
    let ldsringDiv3 = document.createElement("div");
    let ldsringDiv4 = document.createElement("div");
    let ldsringDiv5 = document.createElement("div");
    ldsring.classList.add('lds-ring');
    ldsring.appendChild(ldsringDiv1);
    ldsring.appendChild(ldsringDiv2);
    ldsring.appendChild(ldsringDiv3);
    ldsring.appendChild(ldsringDiv4);
    ldsring.appendChild(ldsringDiv5);
    event.target.after(ldsring);

    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getEstados',
        pais: valorSelecionado,
        nonce: nonceValue,
      },
    }).done(function (data) {
      event.target.nextElementSibling.remove();
      $('.select-estado-valor').html('<option value="" disabled selected hidden>Selecione um estado</option>');
      var obj = JSON.parse(data);

      obj.forEach(function (o, index) {
        $('.select-estado-valor').append('<option value="' + o.sigla + '">' + o.nome + '</option>');
      });

      $('.select-estado-valor').removeClass('step-hidden-valor');
    });
  });

  $('.select-inscreva-estado').change(function (event) {
    let nonceValue = document.querySelector('#wp_nonce_get_cidades').value
    var valorSelecionado = $(this).val();

    let ldsring = document.createElement("div");
    let ldsringDiv1 = document.createElement("div");
    let ldsringDiv2 = document.createElement("div");
    let ldsringDiv3 = document.createElement("div");
    let ldsringDiv4 = document.createElement("div");
    let ldsringDiv5 = document.createElement("div");
    ldsring.classList.add('lds-ring');
    ldsring.appendChild(ldsringDiv1);
    ldsring.appendChild(ldsringDiv2);
    ldsring.appendChild(ldsringDiv3);
    ldsring.appendChild(ldsringDiv4);
    ldsring.appendChild(ldsringDiv5);
    event.target.after(ldsring);

    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getCidades',
        estado: valorSelecionado,
        nonce: nonceValue
      },
    }).done(function (data) {
      event.target.nextElementSibling.remove();
      $('.select-inscreva-cidade').html('<option value="" disabled selected hidden>Selecione uma cidade</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        $('.select-inscreva-cidade').append('<option value="' + o.coordenadas + '">' + o.nome + '</option>');
      });
      $('.select-inscreva-cidade').removeClass('hidden-item-step');
    });
  });

  $('.select-estado-valor').change(function (event) {
    let nonceValue = document.querySelector('#wp_nonce_get_cidades').value
    var valorSelecionado = $(this).val();

    let ldsring = document.createElement("div");
    let ldsringDiv1 = document.createElement("div");
    let ldsringDiv2 = document.createElement("div");
    let ldsringDiv3 = document.createElement("div");
    let ldsringDiv4 = document.createElement("div");
    let ldsringDiv5 = document.createElement("div");
    ldsring.classList.add('lds-ring');
    ldsring.appendChild(ldsringDiv1);
    ldsring.appendChild(ldsringDiv2);
    ldsring.appendChild(ldsringDiv3);
    ldsring.appendChild(ldsringDiv4);
    ldsring.appendChild(ldsringDiv5);
    event.target.after(ldsring);

    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getCidades',
        estado: valorSelecionado,
        nonce: nonceValue
      },
    }).done(function (data) {
      event.target.nextElementSibling.remove();
      $('.select-cidade-valor').html('<option value="" disabled selected hidden>Selecione uma cidade</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        $('.select-cidade-valor').append('<option value="' + o.coordenadas + '">' + o.nome + '</option>');
      });
      $('.select-cidade-valor').removeClass('step-hidden-valor');
    });
  });

  $('#campusUf').change(function () {
    let nonceValue = document.querySelector('#wp_nonce_get_cidades').value
    var valorSelecionado = $(this).val();
    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getCidades',
        estado: valorSelecionado,
        nonce: nonceValue
      },
    }).done(function (data) {
      $('#campusCidade').html('<option value="" disabled selected hidden>Selecione uma cidade</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        $('#campusCidade').append('<option value="' + o.coordenadas + '">' + o.nome + '</option>');
      });
      $('#campusCidade').prop('disabled', false);
    });
  });

  $('.select-inscreva-cidade').change(function (event) {
    let valorSelecionado = $(this).val();
    let nonceValue = document.querySelector("#wp_nonce_get_polos").value;

    let ldsring = document.createElement("div");
    let ldsringDiv1 = document.createElement("div");
    let ldsringDiv2 = document.createElement("div");
    let ldsringDiv3 = document.createElement("div");
    let ldsringDiv4 = document.createElement("div");
    let ldsringDiv5 = document.createElement("div");
    ldsring.classList.add('lds-ring');
    ldsring.appendChild(ldsringDiv1);
    ldsring.appendChild(ldsringDiv2);
    ldsring.appendChild(ldsringDiv3);
    ldsring.appendChild(ldsringDiv4);
    ldsring.appendChild(ldsringDiv5);
    event.target.after(ldsring);

    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getPolos',
        coordenadas: valorSelecionado,
        nomecurso: $('.scconteudo-ncurso').html(),
        cursoid: $('.cursoid').val(),
        nonce: nonceValue,
      },
    }).done(function (data) {
      event.target.nextElementSibling.remove();
      $('.select-inscreva-polo').html('<option value="" disabled selected hidden>Selecione um polo</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        if (o.nome == 'NA') {
          $('.select-inscreva-polo').html('<option value="" disabled selected hidden></option>');
        } else {
          $('.select-inscreva-polo').append('<option value="' + o.id + '">' + o.nome + '</option>');
        }
      });
      $('.select-inscreva-polo').removeClass('hidden-item-step');
    });
  });

  $('.select-cidade-valor').change(function (event) {

    let ldsring = document.createElement("div");
    let ldsringDiv1 = document.createElement("div");
    let ldsringDiv2 = document.createElement("div");
    let ldsringDiv3 = document.createElement("div");
    let ldsringDiv4 = document.createElement("div");
    let ldsringDiv5 = document.createElement("div");
    ldsring.classList.add('lds-ring');
    ldsring.appendChild(ldsringDiv1);
    ldsring.appendChild(ldsringDiv2);
    ldsring.appendChild(ldsringDiv3);
    ldsring.appendChild(ldsringDiv4);
    ldsring.appendChild(ldsringDiv5);
    event.target.after(ldsring);

    let valorSelecionado = $(this).val();
    let nonceValue = document.querySelector("#wp_nonce_get_polos").value;
    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getPolos',
        coordenadas: valorSelecionado,
        nomecurso: $('.scconteudo-ncurso').html(),
        cursoid: ($('.cursoid').val() ? $('.cursoid').val() : null),
        nonce: nonceValue
      },
    }).done(function (data) {
      event.target.nextElementSibling.remove();
      $('.select-polo-valor, .select-polo-valor-pdp').html('<option value="" disabled selected hidden>Selecione um polo</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        if (o.nome == 'NA') {
          $('.select-polo-valor, .select-polo-valor-pdp').html('<option disabled selected hidden></option>');
        } else {
          $('.select-polo-valor, .select-polo-valor-pdp').append('<option value="' + o.id + '">' + o.nome + '</option>');
        }
      });
      $('.select-polo-valor, .select-polo-valor-pdp').removeClass('step-hidden-valor');
    });
  });

  $('#campusCidade').change(function () {
    var valorSelecionado = $(this).val();
    let nonceValue = document.querySelector("#wp_nonce_get_polos").value;
    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getPolos',
        coordenadas: valorSelecionado,
        nomecurso: $("#curso option:selected").text(),
        cursoid: $('#curso').val(),
        nonce: nonceValue
      },
    }).done(function (data) {
      $('#campusPolo').html('<option value="" disabled selected hidden>Selecione um polo</option>');
      var obj = JSON.parse(data);
      obj.forEach(function (o, index) {
        if (o.nome == 'NA') {
          $('#campusPolo').html('<option disabled selected hidden></option>');
        } else {
          $('#campusPolo').append('<option value="' + o.id + '">' + o.nome + '</option>');
        }
      });
      $('#campusPolo').attr('disabled', false);
    });
  });

  $('#campusPolo').change(function () {
    $('.informe-sobrevoce').show();
  });

  $('.select-polo-valor, .select-polo-valor-pdp').change(function () {
    $('.step-3.step-hidden-valor').removeClass('step-hidden-valor');
  });

  $('.select-polo-valor').change(function () {
    var valorSelecionado = $(this).val();
    let tipoCurso = document.querySelector('#tipo_curso').value;
    let nonceValue = document.querySelector('#wp_nonce_get_dados_polo').value;
    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getDadosPolo',
        poloSelecionado: valorSelecionado,
        postid: $('body').attr('class').match(/postid-([0-9]+)/)[1],
        cursoid: $('.cursoid').val(),
        nonce: nonceValue,
        tipoCurso: tipoCurso,
        idLead: sessionStorage.getItem('idLeadSessionSF'),
      },
    }).done(function (data) {
      var obj = JSON.parse(data);
    });
  });

  $('.select-quanto-curso-valor').change(function () {
    $('.select-quanto-escolaridade-valor').removeClass('step-hidden-valor');
  });

  $('.select-quanto-escolaridade-valor').change(function () {
    $('.select-quanto-profissional-valor').removeClass('step-hidden-valor');
  });

  $('.select-quanto-profissional-valor').change(function () {
    $('.pdp-footer.step-hidden-valor').removeClass('step-hidden-valor');
  });

  $('.select-inscreva-polo').change(function () {
    let valorSelecionado = $(this).val();
    let nonceValue = document.querySelector('#wp_nonce_get_dados_polo').value;
    let tipoCurso = document.querySelector('#tipo_curso').value;
    $.ajax({
      url: WpObj.ajaxurl,
      method: 'POST',
      data: {
        action: 'getDadosPolo',
        poloSelecionado: valorSelecionado,
        postid: $('body').attr('class').match(/postid-([0-9]+)/)[1],
        cursoid: $('.cursoid').val(),
        tipoCurso: tipoCurso,
        idLead: sessionStorage.getItem('idLeadSessionSF'),
        nonce: nonceValue,
      },
    }).done(function (data) {
      let obj = JSON.parse(data);

      $('.nome-polo-ead').html('Polo ' + obj['nome']);
      $('.maps-polo-ead').attr('href', 'https://www.google.com/maps/place/' + obj['coordenadas']);
      $('.endereco-polo-ead').html(obj['endereco'] + ', ' + obj['numero'] + ', ' + obj['bairro'] + ', ' + obj['cidade'] + '/' + obj['uf']);
      $('.nome-polo-ead').html('Polo ' + obj['nome']);
      $('.certificadora-polo-ead').attr('src', obj['logoCertificadora']);
      $('.portaria-polo-ead').html('Autorização do Curso: ' + obj['portaria']);
      $('.hidden-item-step.container-data-polo').removeClass('hidden-item-step');
      $('.hidden-item-step.container-data-formas').removeClass('hidden-item-step');

      $('.formas-de-ingresso-ead-select').each(function (index, element) {
        element.selectedIndex = 0
      });
      $('.conteudo-formas-de-ingresso-ead').hide();

      $(".postid").after("<input type='hidden' class='urlProvaOnline' value='" + obj['urlProvaOnline'] + "'>");
      $(".postid").after("<input type='hidden' class='urlAgendadaDigital' value='" + obj['urlAgendadaDigital'] + "'>");
      $(".postid").after("<input type='hidden' class='urlIngressoEnem' value='" + obj['urlIngressoEnem'] + "'>");
      $(".postid").after("<input type='hidden' class='urlSegundaGraduacao' value='" + obj['urlSegundaGraduacao'] + "'>");
      $(".postid").after("<input type='hidden' class='urlSegundaGraduacao20' value='" + obj['urlSegundaGraduacao20'] + "'>");
      $(".postid").after("<input type='hidden' class='urlTransferencia' value='" + obj['urlTransferencia'] + "'>");
      $(".postid").after("<input type='hidden' class='urlPedagogiaLicenciados' value='" + obj['urlPedagogiaLicenciados'] + "'>");
      $(".postid").after("<input type='hidden' class='urlR2' value='" + obj['urlR2'] + "'>");
      $(".postid").after("<input type='hidden' class='urlRetorneCurso' value='" + obj['urlRetorneCurso'] + "'>");
      $(".postid").after("<input type='hidden' class='urlEnemAntecipado' value='#enemantecipado'>");
    });
  });

  $('.formas-de-ingresso-ead-select').change(function () {
    var formaSelecionada = $(this).val();
    var textoFormaSelecionada = $('option:selected', this).html();
    var UTM = UtmUrlParameters;
    let idAnalyticsParameter = idAnalyticsValue ? ("&idAnalytics=" + idAnalyticsValue) : "";
    $('.conteudo-formas-de-ingresso-ead').hide();

    let dataUrl = {
      'Prova On-line': 'urlProvaOnline',
      'Prova Agendada Digital': 'urlAgendadaDigital',
      'Ingresso via Enem': 'urlIngressoEnem',
      'Segunda Graduação': 'urlSegundaGraduacao',
      'Transferência': 'urlTransferencia',
      'Segunda Graduação 2.0': 'urlSegundaGraduacao20',
      'Formação Pedagógica - R2': 'urlR2',
      'Pedagogia para Licenciados': 'urlPedagogiaLicenciados',
      'Retorne ao curso': 'urlRetorneCurso'
    };

    if (document.querySelector('.modal-enem-antecipado')) {
      if (document.querySelector('.modal-enem-antecipado').length > 0) {
        dataUrl['Ingresso via Enem'] = 'urlEnemAntecipado';
      }
    }

    let seletor = dataUrl[textoFormaSelecionada] && `.${dataUrl[textoFormaSelecionada]}`;

    if (seletor) {
      $('.forma-de-ingresso-' + formaSelecionada + ' .cta-formas-de-ingresso-ead').attr('href', $(seletor).val() + '&' + UTM + idAnalyticsParameter);
    }

    $('.combo-formas-ingresso-ead').show();
    $('.forma-de-ingresso-' + formaSelecionada).show();
  });

  $('#vPresencial').click(function () {
    $(this).addClass('act');
    $('#vOnline').removeClass('act');
    $('#vSemiPresencial').removeClass('act');
    $('#pPresencial').css('display', 'flex');
    $('#pOnline').css('display', 'none');
    $('#pSemiPresencial').css('display', 'none');
  });
  $('#vOnline').click(function () {
    $(this).addClass('act');
    $('#vPresencial').removeClass('act');
    $('#vSemiPresencial').removeClass('act');
    $('#pPresencial').css('display', 'none');
    $('#pOnline').css('display', 'flex');
    $('#pSemiPresencial').css('display', 'none');
  });
  $('#vSemiPresencial').click(function () {
    $(this).addClass('act');
    $('#vOnline').removeClass('act');
    $('#vPresencial').removeClass('act');
    $('#pPresencial').css('display', 'none');
    $('#pOnline').css('display', 'none');
    $('#pSemiPresencial').css('display', 'flex');
  });

  /* Modal Saida - Por tempo */
  if ($('.n-modal-saida.modal-saida.deixe-modal').length > 0) {
    $("body").addClass('com-modalsaida');
    if (screen.width < 991) {
      setTimeout(function () {
        $('.bg_lightbox.modalsaida-duvidas').removeClass('disable-modal');
        $('.n-modal-saida.modal-saida.deixe-modal').removeClass('disable-modal');
        $("body").addClass('blockBody');
      }, 6000);
    } else {
      window.addEventListener('mousemove', (e) => {
        if (e.clientY <= 0 ||
          e.clientY >= (window.innerHeight - 2) ||
          e.clientX <= 0 ||
          e.clientX >= (window.innerWidth - 2)
        ) {
          if (document.querySelector('.n-modal-saida.modal-saida.deixe-modal').getAttribute('active') !== 'true') {
            $('.bg_lightbox.modalsaida-duvidas').removeClass('disable-modal');
            $('.n-modal-saida.modal-saida.deixe-modal').removeClass('disable-modal');
            document.querySelector('body').classList.toggle('blockBody');
            document.querySelector('.n-modal-saida.modal-saida.deixe-modal').setAttribute('active', 'true');
          }
        }
      });
      $('body').on('mouseleave', (e) => {
        if ('INPUT' !== e.target.nodeName) {
        }
      });
    }
  } else {
    $("body").addClass('sem-modalsaida');
  }
  /* END Modal Saida - Por tempo */
  /* Validação de ecolha do combo de cursos */

  $('#nossosCursosOferecidos').on('change', function () {
    itemSelecionado = '';
    var e = document.getElementById('nossosCursosOferecidos');
    var itemSelecionado = e.options[e.selectedIndex].value;
    $('#cursoDesejado').css('display', 'none');
    $('#cursoDesejado').prop('required', false);
    if (itemSelecionado == 'nao_encontrou') {
      $('#cursoDesejado').css('display', 'block');
      $('#cursoDesejado').prop('required', true);
    }
  });

  /*
  *
  *   Validação de ecolha do combo de cursos no formulário ajuda ajuda o consultor
  **/

  $('#nossosCursosOferecidosAjudaConsultores').on('change', function () {
    itemSelecionado = '';
    var e = document.getElementById('nossosCursosOferecidosAjudaConsultores');
    var itemSelecionado = e.options[e.selectedIndex].value;
    $('#cursoDesejadoAjudaConsultor').css('display', 'none');
    $('#cursoDesejadoAjudaConsultor').prop('required', false);
    if (itemSelecionado == 'nao_encontrou') {
      $('#cursoDesejadoAjudaConsultor').css('display', 'block');
      $('#cursoDesejadoAjudaConsultor').prop('required', true);
    }
  });

  $('.botao-deixe').click(function () {
    //$('.deixe-modal').show();
    $('.deixe-botao').hide();
    Event.preventDefault();
  });

  $('.features-area a').click(function () {
    href = $(this).attr('href');
    if (href) {
    } else {
      event.preventDefault();
    }
  });

  let query = location.search.slice(1);
  let partes = query.split("&");
  let data = {};
  partes.forEach(function (parte) {
    let chaveValor = parte.split("=");
    let chave = chaveValor[0];
    let valor = chaveValor[1];

    if (valor == 'bMedicina') {
      // Medicina: Vestibular
      $('.formasingresso-nav li a').removeClass('active');
      $('#formasingresso-nav-medicina').addClass('active');
      $(".formasingresso-nav-mobile #formasingresso-nav").val('Medicina: Vestibular');
      $('.formasingresso-conteudo-item').hide();
      $('#formasingresso-conteudo-Medicina').show();
    }

    if (valor == 'historia') {
      $('html, body').animate(
        {
          scrollTop: $('.nossahistoria').offset().top
        },
        2000
      );
    }

    if (valor == 'corpoDiretivo') {
      $('html, body').animate(
        {
          scrollTop: $('.corpoDiretivo').offset().top
        },
        2000
      );
    }
  });

  // Fechar formulário/valores PDP Presencial
  $('#seta-pdp').click(function () {
    $('.boxInsc').hide();

    $(".pdppres-inscreva-se").removeClass("disable-btns-pdppres");
    $(".pdpres-veja-valor").removeClass("disable-btns-pdppres");
  });

  $('#seta-pdp-mobile').click(function () {
    $('.boxInsc').hide();

    $(".pdppres-inscreva-se").removeClass("disable-btns-pdppres");
    $(".pdpres-veja-valor").removeClass("disable-btns-pdppres");
  });
  // END - Fechar formulário/valores PDP Presencial

  $('.faleConsultor').on('click', function () {
    $('html, body').animate(
      {
        scrollTop: $('.adquira').offset().top,
      },
      2000
    );
  });

  $('.open-prova-tradicional').click(function () {
    $('#bVestibular .forma').hide();
    $('#bVestibular .forma:nth-of-type(2)').show();
    $('html, body').animate(
      {
        scrollTop: $('.cards-seletivo').offset().top,
      },
      1000
    );
    $('#bVestibular .result').show();
  });

  $('.open-prova-agendada').click(function () {
    $('#bVestibular .forma').hide();
    $('#bVestibular .forma:nth-of-type(3)').show();
    $('html, body').animate(
      {
        scrollTop: $('.cards-seletivo').offset().top,
      },
      1000
    );
    $('#bVestibular .result').show();
  });

  $('.open-igresso-enem').click(function () {
    $('#bVestibular .forma').hide();
    $('#bVestibular .forma:nth-of-type(4)').show();
    $('html, body').animate(
      {
        scrollTop: $('.cards-seletivo').offset().top,
      },
      1000
    );
    $('#bVestibular .result').show();
  });

  $('.saiba-mais-ps').hide();

  $('#bVestibular .btn-padrao').on('click', function (event) {
    event.preventDefault();

    $(this)
      .parents('.coluna')
      .first()
      .find('.saiba-mais-ps')
      .toggle()
      .addClass('animated fadeIn');

    $(this)
      .text($(this).text() == 'Saiba mais' ? 'Esconder' : 'Saiba mais')
      .toggleClass('esconder');
    return false;
  });

  if ($('.scconteudo-tp-campus-conteudo-galeria').length > 0) {
    if (!document.querySelector('.scconteudo-tp-campus-conteudo-galeria').classList.contains('slick-initialized')) {
      $('.scconteudo-tp-campus-conteudo-galeria').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });
    }
  }

  if ($('.podeinteressar-colunas').length > 0) {
    if (!document.querySelector('.podeinteressar-colunas').classList.contains('slick-initialized')) {
      $('.podeinteressar-colunas').slick({
        infinite: true,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true
      });
    }
  }

  //Slider Bilioteca
  if ($('#publicacoesSlider .slides').length) {
    if (!document.querySelector('#publicacoesSlider .slides').classList.contains('slick-initialized')) {
      $('#publicacoesSlider .slides').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              variableWidth: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: true,
            },
          },
        ],
      });
    }
  }

  //Slider Instituicoes Certificadoras
  if ($('#instituicoesCertificadoras').length) {
    if (!document.querySelector('#instituicoesCertificadoras').classList.contains('slick-initialized')) {
      $('#instituicoesCertificadoras').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
  }

  $('.itemBannerHome').click(function () {
    var dataHref = $(this).attr('data-href');
    window.open(dataHref, '_blank');
  });

  $('.plus2').click(function () {
    if (!$(this).parent().next().html().trim()) {
      var url = $(this).prev().attr('href');
      window.location.href = url;
    }
  });

  $('#btnAlunoNew').addClass('codigoEmpresa_' + coEmpresa);

  if ($('#matriz-vazia').length > 0) {
    $('#MatrizCurricular').hide();
  }

  $('body').addClass('bodyEmpresa_' + coEmpresa);

  if (window.location.href.indexOf("/polo/") > -1) {
    $('.header').addClass('act');
  }

  $('#cursosGratisBtn').addClass('cor-texto-' + coEmpresa);

  // Texto/cor rotativo no botão "Sou aluno" do header

  // function rematriculaSouAlunoTexto(){
  //   if ($(".lista-linksuteis-link1").html() === "Sou Aluno"){
  //     $(".lista-linksuteis-link1").html("Rematrícula");
  //     $(".lista-links-uteis #id1").toggleClass("btnRematriculaTopo", 150);
  //   }  else {
  //     $(".lista-linksuteis-link1").html("Sou Aluno");
  //     $(".lista-links-uteis #id1").toggleClass("btnRematriculaTopo", 150);
  //   }
  // }
  // setInterval(rematriculaSouAlunoTexto, 1500);

  // END Texto/cor rotativo no botão "Sou aluno" do header

  var autoAdjustMenu = function () {
    //Separa as IES que terão um tipo de layout fixo do header.
    var layoutUm = ['unicid', 'unifran', 'unipe', 'cruzeirovirtual'];
    var layoutDois = ['cruzeiro', 'udf', 'brazcubas'];
    var layoutTres = ['ceunsp', 'cesuca', 'modulo', 'fass'];
    //--------------------------------------------------

    var allLayouts = layoutUm.concat(layoutDois, layoutTres);

    if ($('#menu1').length > 0) {
      var studyAtX = $('#menu1 > div.submenu > div.menuwsub');
      var prevDiv = $('#menu1 > div.submenu');
      var columns = studyAtX.css('columns').match(/\d/)[0];

      //Lista de itens fixos
      var processoSeletivo = studyAtX
        .find('ul > li > h3 > a:contains(" Processo Seletivo ")')
        .closest('ul');
      var cursosLivresGratis = studyAtX
        .find('li > h3 > a:contains(" Cursos Livres Grátis ")')
        .closest('ul');
      var medicina = studyAtX
        .find('li > h3 > a:contains(" Medicina ")')
        .closest('ul');
      var cursosLivres = studyAtX
        .find(
          'li > h3 > a:contains(" Cursos Livres "):not(:contains("Grátis"))'
        )
        .closest('ul');
      var cursosDeIdiomas = studyAtX
        .find('li > h3 > a:contains(" Cursos de Idiomas ")')
        .closest('ul');
      var cursosPreparatorios = studyAtX
        .find('li > h3 > a:contains(" Cursos Preparatórios ")')
        .closest('ul');
      var graduacao = studyAtX
        .find('li > h3 > a:contains(" Graduação ")')
        .closest('ul');
      var MBAeEsp = studyAtX
        .find('li > h3 > a:contains(" MBA e Especialização ")')
        .closest('ul');
      var mestrado = studyAtX
        .find('li > h3 > a:contains(" Mestrado e Doutorado ")')
        .closest('ul');
      var ead = studyAtX
        .find('li > h3 > a:contains(" EAD e Semipresencial ")')
        .closest('ul');
      var tecnicoEad = studyAtX
        .find('li > h3 > a:contains(" Técnicos EAD ")')
        .closest('ul');
      var internationalStudent = studyAtX
        .find('li > h3 > a:contains(" Internacional Student ")')
        .closest('ul');

      cursosLivresGratis
        .find('li > h3 > a')
        .attr('id', 'item-diferenciado-menu');
      //--------------------------------------------------

      var adjustCols = function (col) {
        if (
          col.width() > 0 &&
          prevDiv.children('[class^="colEspec"]').length == 0
        ) {
          var widthToUpdate = Math.round(col.width() / columns);

          var generateCols = function () {
            for (var i = 0; i < columns; i++) {
              prevDiv.prepend(
                '<div class="colEspec' +
                i +
                '" style="min-width:' +
                widthToUpdate +
                'px;"></div>'
              );
            }
          };

          generateCols();

          //A contagem é ao contrário mesmo.
          var firstCol = $('.colEspec2');
          var secondCol = $('.colEspec1');
          var thirdCol = $('.colEspec0');

          var colArray = [firstCol, secondCol, thirdCol];
          //--------------------------------------------------

          //Opçeõs para inicio da página (quando carregar)
          var mobMenu = function (type) {
            if (type == 1) {
              thirdCol
                .append(processoSeletivo)
                .append(cursosLivresGratis)
                .append(medicina)
                .append(cursosLivres)
                .append(cursosDeIdiomas)
                .append(cursosPreparatorios)
                .append(graduacao)
                .append(MBAeEsp)
                .append(mestrado)
                .append(ead)
                .append(tecnicoEad)
                .append(internationalStudent);
            }
            if (type == 2) {
              thirdCol
                .append(processoSeletivo)
                .append(cursosLivresGratis)
                .append(cursosLivres)
                .append(cursosDeIdiomas)
                .append(cursosPreparatorios)
                .append(graduacao)
                .append(MBAeEsp)
                .append(mestrado)
                .append(ead)
                .append(tecnicoEad);
            }
            if (type == 3) {
              thirdCol
                .append(processoSeletivo)
                .append(cursosLivresGratis)
                .append(medicina)
                .append(cursosLivres)
                .append(cursosDeIdiomas)
                .append(cursosPreparatorios)
                .append(graduacao)
                .append(MBAeEsp)
                .append(mestrado)
                .append(ead)
                .append(tecnicoEad);
            }
          };

          var fullMenu = function (type, cols) {
            if (type == 1) {
              cols[0].append(processoSeletivo).append(cursosLivresGratis);
              cols[1]
                .append(medicina)
                .append(cursosLivres)
                .append(cursosDeIdiomas)
                .append(cursosPreparatorios);
              cols[2]
                .append(graduacao)
                .append(MBAeEsp)
                .append(mestrado)
                .append(ead)
                .append(tecnicoEad)
                .append(internationalStudent);
            }
            if (type == 2) {
              cols[0].append(processoSeletivo);
              cols[1]
                .append(cursosLivresGratis)
                .append(cursosLivres)
                .append(cursosDeIdiomas)
                .append(cursosPreparatorios);
              cols[2]
                .append(graduacao)
                .append(MBAeEsp)
                .append(mestrado)
                .append(ead)
                .append(tecnicoEad);
            }
            if (type == 3) {
              cols[0].append(processoSeletivo).append(cursosLivresGratis);
              cols[1]
                .append(medicina)
                .append(cursosLivres)
                .append(cursosDeIdiomas)
                .append(cursosPreparatorios);
              cols[2]
                .append(graduacao)
                .append(MBAeEsp)
                .append(mestrado)
                .append(ead)
                .append(tecnicoEad)
                .append(internationalStudent);
            }
          };

          if ($('.opmenu').is(':visible')) {
            if (layoutUm.indexOf(coEmpresa) != -1) {
              mobMenu(1);
            }
            if (layoutDois.indexOf(coEmpresa) != -1) {
              mobMenu(2);
            }
            if (layoutTres.indexOf(coEmpresa) != -1) {
              mobMenu(3);
            }
          } else {
            if (layoutUm.indexOf(coEmpresa) != -1) {
              fullMenu(1, colArray);
            }
            if (layoutDois.indexOf(coEmpresa) != -1) {
              fullMenu(2, colArray);
            }
            if (layoutTres.indexOf(coEmpresa) != -1) {
              fullMenu(3, colArray);
            }
          }
          //--------------------------------------------------

          var adjustToFull = function () {
            var currentCols = prevDiv.find('div[class^="col"]');

            if (currentCols.length == 1) {
              columns = 3;
              currentCols.remove();
              if (layoutUm.indexOf(coEmpresa) != -1) {
                fullMenu(1, colArray);
              }
              if (layoutDois.indexOf(coEmpresa) != -1) {
                fullMenu(2, colArray);
              }
              if (layoutTres.indexOf(coEmpresa) != -1) {
                fullMenu(3, colArray);
              }
            }
          };

          //Ajusta o menu na transição de tela (mobile -> full <- mobile)
          $(window).resize(function () {
            if (!$('.opmenu').is(':visible')) {
              adjustToFull();
            }
          });
          //--------------------------------------------------

          //Caso já tenha um menu personalizado, e a IES esteja na lista de layouts, esconde o original.
          if (firstCol.length > 0 && allLayouts.indexOf(coEmpresa) != -1) {
            studyAtX.css('display', 'none');
          }
          //--------------------------------------------------
        }
      };

      //TODO melhorar esse trigger pra algo mais universal
      if (allLayouts.indexOf(coEmpresa) != -1) {
        $('#menu1').hover(function () {
          adjustCols(studyAtX);
        });
      }
    }
  };

  autoAdjustMenu();

  //Adiciona os itens de "Cursos Livres Grátis" para as IES habilitadas
  var free_courses_active = true;

  //Edite esse array para adicionar e retirar IES dos "Cursos Livres Gratis"

  if (free_courses_active) {
    var btn_area_aluno = $('.menuTop .area-aluno');
    if (btn_area_aluno.length > 0) {
      $(
        '<a class="cursos-gratis" id="cursosGratisBtn" href="https://www.cruzeirodosulvirtual.com.br/cursos-gratuitos/?utm_source=portal_novo_' +
        coEmpresa +
        '&utm_medium=header_novo&utm_campaign=cursoslivres' +
        coEmpresa +
        '&utm_medium=header_novo&utm_campaign=cursosgratis" target="_blank" rel="noopener noreferrer">Cursos Grátis' +
        '<div class="cursos-gratis-novo">Novo</div></a>'
      ).insertBefore(btn_area_aluno);

      var free_courses = $('a.cursos-gratis');
      var free_courses_new = $('.cursos-gratis-novo');
      var h1_to_get_main_color = $('.menuTop select');

      if (free_courses.length > 0) {
        free_courses.css({
          position: 'absolute',
          right: '264px',
          'text-align': 'center',
          color: h1_to_get_main_color.css('color'),
          'font-size': '12px',
          'font-family': 'work_sansmedium',
          padding: '11px 21px 0 !important',
          display: 'inline-block',
          border: '0',
        });

        free_courses_new.css({
          float: 'right',
          margin: '-1px 0 0 8px',
          'font-size': '8px',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
          padding: '3px 6px 1px',
          color: h1_to_get_main_color.css('color'),
          'border-color': '#FFF',
          'border-style': 'solid',
          'border-width': '.5px',
          'border-radius': '5px',
        });

        if (coEmpresa == 'unicid') {
          free_courses_new.css('color', '#97CE89');
        }

        var hexDigits = [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          'a',
          'b',
          'c',
          'd',
          'e',
          'f',
        ];

        //Function to convert rgb color to hex format
        function rgb2hex(rgb) {
          rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
          return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]) + ' !important';
        }

        function hex(x) {
          return isNaN(x)
            ? '00'
            : hexDigits[(x - (x % 16)) / 16] + hexDigits[x % 16];
        }

        free_courses.hover(
          function () {
            free_courses.css('background-color', '');
            free_courses.attr(
              'style',
              free_courses.attr('style') +
              'background-color: ' +
              rgb2hex(h1_to_get_main_color.css('color'))
            );
            free_courses_new.css('color', '#fff');

            //Alteração especifica para FASS
            if (coEmpresa == 'fass') {
              free_courses_new.css('color', '#003B76');
              free_courses_new.css('border-color', '#003B76');
              free_courses.css('color', '#003B76');
            }
          },
          function () {
            free_courses.css('background-color', '');
            free_courses.attr(
              'style',
              free_courses.attr('style') +
              'background-color: ' +
              rgb2hex($('.menuTop').css('background-color'))
            );
            free_courses_new.css('color', h1_to_get_main_color.css('color'));

            //Alteração especifica para FASS
            if (coEmpresa == 'fass') {
              free_courses.css('color', '#fff');
              free_courses_new.css('border-color', '#fff');
            }
          }
        );

        free_courses.css('color', '#fff');
      }
    }

    var free_courses_link = $('#menu1').find('#item-diferenciado-menu');
    var full_css = {
      display: 'block',
      float: 'right',
      margin: '0px -10px 0 0',
      'font-size': '8px',
      'font-weight': 'bold',
      'text-transform': 'uppercase',
      padding: '0px 4px 0px 5px',
      color: '#fff',
      'background-color': '#003b71',
      'border-color': '#FFF',
      'border-style': 'solid',
      'border-width': '.5px',
      'border-radius': '5px',
      'line-height': '12px',
    };

    if (free_courses_link.length > 0) {
      $('<div class="cursos-gratis-novo-menu">Novo</div>').insertBefore(
        free_courses_link
      );

      var free_courses_menu = $('#menu1');
      var free_courses_menu_new = free_courses_menu.find(
        '.cursos-gratis-novo-menu'
      );
      free_courses_menu_new.css(full_css);

      $(window).resize(function () {
        if ($('.opmenu').is(':visible')) {
          $('.cursos-gratis-novo-menu').css('display', 'none');
        } else {
          $('.cursos-gratis-novo-menu').css('display', 'block');
        }
      });

      if ($('.opmenu').is(':visible')) {
        $('.cursos-gratis-novo-menu').css('display', 'none');
      } else {
        $('.cursos-gratis-novo-menu').css('display', 'block');
      }
    }
  }

  if ($('#header .botao-biblioteca').length > 0) {
    $('#header .menuTop .boxCenter').addClass('boxCenterExpand');
    $('#header .menuTop .boxCenter .cursos-gratis').addClass(
      'cursosGratisExpand'
    );
  }
}