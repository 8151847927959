import { CalculadoraEnemBuscarNota } from "./CalculadoraEnemBuscarNota";
import { CalculadoraEnemMudaPassos } from "./CalculadoraEnemMudaPassos";

export const CalculadoraEnemTrocarCurso = () => {
    document.querySelector('.calculadora-enem__trocar-curso').addEventListener('click', (event) => {
        event.preventDefault();

        document.querySelector('.calculadora-enem__formulario').dataset.passoAtual = 0;
        document.querySelector('.calculadora-enem__passo--ativo')?.classList.remove('calculadora-enem__passo--ativo');

        CalculadoraEnemMudaPassos('avancar');

        document.querySelector('.calculadora-enem__dots').classList.add('calculadora-enem__dots--inativo');
        document.querySelector('.calculadora-enem__botao--avancar').textContent = 'Alterar';

        document.querySelector('.calculadora-enem__titulo--etapa--ativo')?.classList.remove('calculadora-enem__titulo--etapa--ativo');
        document.querySelector('.calculadora-enem__titulo--etapa[data-numero-passo="3"]')?.classList.remove('calculadora-enem__titulo--etapa--ativo');

        document.querySelector('.calculadora-enem__botoes').classList.remove('calculadora-enem__botoes--oculto');

        CalculadoraEnemBuscarNota();
    });
}