export const AbrirFecharItemSiteMap = () => {
  const itens: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.footer__site-map .coluna__header');

  itens.forEach((item: HTMLElement) => {
    item.addEventListener('click', (evento) => {
      let tela = window.innerWidth;

      if (tela <= 992) {
        evento.preventDefault;
        let coluna: HTMLElement = <HTMLElement>item.parentElement;
        let itens: HTMLElement = <HTMLElement>item.nextElementSibling;
        let icone: HTMLImageElement = <HTMLImageElement>item.childNodes.item(5);

        if (itens.style.height === '') {
          coluna.style.gap = '10px';
          itens.style.height = `${itens.scrollHeight}px`;
          icone.src = '/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0064B.svg';
        } else {
          coluna.style.gap = '';
          itens.style.height = '';
          icone.src = '/wp-content/themes/cruzeiroportais2019/assets/imagens/icons/CSEDICON0064.svg';
        }
      }
    })
  });
}