import { SetCookie } from "../../Utils/cookies";
import { CreateAjax } from "../../Utils/createAjax";
import { DadoDeLead, EnviaLead } from "../../Utils/enviaLead";
import { TesteVocacionalTelasPerguntas } from "./testeVocacionalTelas";

const AbrirTermos = (modulo: HTMLElement) => {
    const inputCheckbox = <HTMLInputElement>modulo.querySelector('input[type=checkbox]');
    const itemCheckbox = <HTMLDetailsElement>modulo.querySelector('details.item');
    inputCheckbox.addEventListener('change', () => itemCheckbox.open = inputCheckbox.checked);
}

const BuscaModalidades = (modulo: HTMLElement) => {
    const campoNivel = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #nivel');
    const campoModalidade = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #modalidade');

    campoNivel.addEventListener('change', async () => {
        campoModalidade.innerHTML = '<option value="" selected disabled>Carregando modalidades...</option>';
        const form = new FormData();

        // @ts-ignore: Unreachable code error
        form.append("nonce", TV.getModalidadesN.trim().replace(/[<>\/\\;]|--/g, ''));
        form.append("action", "getModalidadesTesteVocacional");
        form.append("nivel", campoNivel.value.trim().replace(/[<>\/\\;]|--/g, ''));

        await CreateAjax(form)
            .then(response => {
                campoModalidade.innerHTML = '<option value="" selected disabled>Selecione uma modalidade</option>';

                response.forEach(item => {
                    campoModalidade.innerHTML += `<option value="${item.valor}">${item.nome}</option>`;
                });
            });
    });
}

const BuscaInstituicoes = (modulo: HTMLElement) => {
    const campoNivel = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #nivel');
    const campoModalidade = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #modalidade');
    const campoInstituicao = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #instituicao');
    const campoCidade = <HTMLSelectElement>modulo.querySelector('.formulario #cidade');
    const campoEstado = <HTMLSelectElement>modulo.querySelector('.formulario #uf');

    campoModalidade.addEventListener('change', async () => {
        campoInstituicao.innerHTML = '<option value="" selected disabled>Carregando instituições...</option>';
        const form = new FormData();

        // @ts-ignore: Unreachable code error
        form.append("nonce", TV.getInstituicoesN.trim().replace(/[<>\/\\;]|--/g, ''));
        form.append("action", "getInstituicoesTesteVocacional");
        form.append("nivel", campoNivel.value.trim().replace(/[<>\/\\;]|--/g, ''));
        form.append("modalidade", campoModalidade.value.trim().replace(/[<>\/\\;]|--/g, ''));
        form.append("cidade", campoCidade.value.trim().replace(/[<>\/\\;]|--/g, ''));
        form.append("estado", campoEstado.value.trim().replace(/[<>\/\\;]|--/g, ''));

        await CreateAjax(form)
            .then(response => {
                campoInstituicao.innerHTML = '<option value="" selected disabled>Selecione uma modalidade</option>';

                let iesArray: any = [];
                let responseIes = response.map((item) => item.ies);

                responseIes.forEach(elemento => {
                    if (!iesArray.map((item) => item.ies).includes(elemento)) {
                        const itens = response.filter((item) => item.ies == elemento && item);
                        const ies = UnificarArray(itens, 'ies');
                        const cidade = UnificarArray(itens, 'cidade');
                        const estado = UnificarArray(itens, 'estado');
                        const codigo = itens[0].codigo;

                        iesArray.push({ ies, cidade, estado, codigo, modalidade: itens[0].modalidade });
                    }
                });

                iesArray.forEach(elemento => {
                    campoInstituicao.innerHTML += (elemento.modalidade === 'ead')
                        ? `<option value="${elemento.codigo}">${elemento.ies} | EAD</option>`
                        : `<option value="${elemento.codigo}">${elemento.ies} | ${elemento.cidade} / ${elemento.estado}</option>`;
                });
            });
    });
}

const UnificarArray = (array: any, key: string) => [...new Set(array.map((item) => item[key]))]
    .join(',_').replace(/,_([^,_]*)$/gm, ' e $1').replace('_', ' ')
    .replace('UP', 'Universidade Positivo');

const SubmitFormulario = (modulo: HTMLElement) => {
    const submit = <HTMLFormElement>modulo.querySelector('.segundo-formulario');
    submit.addEventListener('submit', (evento: Event) => {
        evento.preventDefault();

        const elementoIdentificador = <HTMLInputElement>modulo.querySelector('.identificador');
        const identificador = elementoIdentificador.value;

        const leadAtual = sessionStorage.getItem('leadAtual') || '[]';
        let dadosLead: DadoDeLead[] = JSON.parse(leadAtual);

        const elementoNivel = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #nivel');
        const elementoModalidade = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #modalidade');
        const elementoInstituicao = <HTMLSelectElement>modulo.querySelector('.segundo-formulario #instituicao');
        const elementoTermos = <HTMLInputElement>modulo.querySelector('.segundo-formulario #termos');
        const elementoMensagem = <HTMLParagraphElement>modulo.querySelector('.segundo-formulario .mensagem');

        const nivel = elementoNivel.value.trim().replace(/[<>\/\\;]|--/g, '');
        const modalidade = elementoModalidade.value.trim().replace(/[<>\/\\;]|--/g, '');
        const instituicao = elementoInstituicao.value.trim().replace(/[<>\/\\;]|--/g, '');
        const termos = Boolean(elementoTermos.checked);

        let mensagemErro = '';
        elementoMensagem.style.display = 'none';
        elementoMensagem.textContent = '';

        (nivel === '') && (mensagemErro = 'O campo nivel de ensino não foi preenchido.');
        (modalidade === '') && (mensagemErro = 'O campo modalidade de estudo não foi preenchido.');
        (instituicao === '') && (mensagemErro = 'O campo de instituição não foi preenchido.');
        (termos === false) && (mensagemErro = 'Os termos de uso deve ser aceito para seguir.');

        if (mensagemErro !== '') {
            elementoMensagem.style.display = 'block';
            elementoMensagem.textContent = mensagemErro;
            return;
        }

        const telefone = dadosLead.filter((item) => item.name == 'celular')[0].value;
        const nome = dadosLead.filter((item) => item.name == 'nome')[0].value;
        const sobrenome = dadosLead.filter((item) => item.name == 'sobrenome')[0].value;

        dadosLead = dadosLead.filter(item => (
            item.name !== 'celular' &&
            item.name !== 'nome' &&
            item.name !== 'sobrenome'
        ));

        dadosLead.push(
            { name: 'curso_tipo', value: nivel },
            { name: 'modalidade', value: modalidade },
            { name: 'ies', value: instituicao },
            { name: 'telefone', value: telefone },
            { name: 'nome', value: `${nome} ${sobrenome}` },
        );

        sessionStorage.setItem('leadAtual', JSON.stringify(dadosLead));
        SetCookie('leadAtual', JSON.stringify(dadosLead), 1);
        EnviaLead(identificador, 'modal', dadosLead);
        TesteVocacionalTelasPerguntas(modulo);
    });
};

const TesteVocacionalSegundoFormulario = (modulo: HTMLElement) => {
    BuscaModalidades(modulo);
    BuscaInstituicoes(modulo);
    AbrirTermos(modulo);
    SubmitFormulario(modulo);
}

export default TesteVocacionalSegundoFormulario;