import { DataLayerCreateVirtualPageView } from "../../Utils/dataLayerCreate";
import { PdpDataLayerConteudo } from "./DataLayer/pdpDataLayerConteudo";
import { PdpDataLayerSobreVoceEscolaridade } from "./DataLayer/pdpDataLayerSobreVoceEscolaridade";
import { PdpDataLayerTelaCidadesTurmas } from "./DataLayer/pdpDataLayerTelaCidadesTurmas";
import { PdpDataLayerTelaDois } from "./DataLayer/pdpDataLayerTelaDois";
import { PdpDataLayerTelaFormasIngresso } from "./DataLayer/pdpDataLayerTelaFormasIngresso";
import { PdpDataLayerTelaInvestimentoGraduacaoPlanos } from "./DataLayer/pdpDataLayerTelaInvestimentoGraduacaoPlanos";
import { PdpDataLayerTelaQuaseLa } from "./DataLayer/pdpDataLayerTelaQuaseLa";
import { PdpDataLayerTelaQuatro } from "./DataLayer/pdpDataLayerTelaQuatro";
import { PdpDataLayerTelaSeis } from "./DataLayer/pdpDataLayerTelaSeis";
import { PdpDataLayerTelaTres } from "./DataLayer/pdpDataLayerTelaTres";
import { PdpDataLayerTelaUm } from "./DataLayer/pdpDataLayerTelaUm";

export const PdpDataLayer = () => {
  DataLayerCreateVirtualPageView(window.location.pathname.split('/')[2]);

  if (document.querySelector('.sidebar-pdp-tela-um-acoes')) {
    PdpDataLayerTelaUm();
  }

  if (document.querySelector('.sidebar-pdp-tela-sobre-voce--escolaridade')) {
    PdpDataLayerSobreVoceEscolaridade();
  }

  if (document.querySelector('.sidebar-pdp-tela-investimento-graduacao--planos')) {
    PdpDataLayerTelaInvestimentoGraduacaoPlanos();
  }

  if (document.querySelector('.sidebar-pdp-tela-dois')) {
    PdpDataLayerTelaDois();
  }

  if (document.querySelector('.sidebar-pdp-tela-formas-ingresso')) {
    PdpDataLayerTelaFormasIngresso();
  }

  if (document.querySelector('.sidebar-pdp-tela-tres')) {
    PdpDataLayerTelaTres();
  }

  if (document.querySelector('.sidebar-pdp-tela-seis')) {
    PdpDataLayerTelaSeis();
  }

  if (document.querySelector('.sidebar-pdp-tela-quatro')) {
    PdpDataLayerTelaQuatro();
  }

  if (document.querySelector('.sidebar-pdp-tela-quase-la')) {
    PdpDataLayerTelaQuaseLa();
  }

  if (document.querySelector('.sidebar-pdp-tela-cidades-turmas ')) {
    PdpDataLayerTelaCidadesTurmas();
  }

  PdpDataLayerConteudo();
}